/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

import Spinner from '../../shared/atoms/Spinner';
import * as S from './style';
import Location from '../Locations/Location';
import GroupButtons from '../GroupsList/EditGroup/GroupButtons';
import AssetsSensorsList from '../../AssetsSensorsList';
import BottomContainer from '../../BottomContainer';
import { useLocationsCacheContext } from '../../../context/LocationsCacheContext';

const LocationsList = ({ goBack }) => {
  const {
    locations,
    assets: assetsRedux,
    isLoading,
  } = useLocationsCacheContext();

  const [componentToShow, setComponentToShow] = useState('locationsList');
  const [locationId, setLocationId] = useState('');
  const { t } = useTranslation();

  const setLocation = newLocationId => {
    setLocationId(newLocationId);
  };

  const showASList = newComponentToShow => {
    setComponentToShow(newComponentToShow);
  };

  const onClickActions = locId => {
    showASList('assetsSensorsList');
    setLocation(locId);
  };

  const showAssets = location => onClickActions(location.properties.item_id);

  const emptyMsgDisplay =
    !isLoading && !locations.length ? (
      <S.EmptyMessage>
        {t('components.main_list.groups.location.empty')}
      </S.EmptyMessage>
    ) : null;

  const locationsDisplay = !isLoading ? (
    locations
      .filter(location => {
        return (
          assetsRedux.filter(
            a => a.properties.location_id === location.properties.item_id,
          ).length > 0
        );
      })
      .sort((a, b) => {
        return a.properties.name > b.properties.name ? 1 : -1;
      })
      .map(location => {
        const assets = assetsRedux.filter(
          a => a.properties.location_id === location.properties.item_id,
        );
        return (
          <Location
            key={location.properties.name}
            location={location}
            onClick={() => showAssets(location)}
            subItems={assets || location.properties.buildings}
            subItemsType={
              location.properties.buildings ? 'buildings' : 'assets'
            }
            shouldFly={false}
          />
        );
      })
  ) : (
    <Spinner />
  );

  return componentToShow === 'locationsList' ? (
    <S.LocationsList>
      <Box paddingTop="5px">
        <Button
          variant="text"
          onClick={goBack}
          sx={{ padding: '0 10px 0 0', color: 'white' }}
        >
          <ChevronLeft fontSize="large" color="primary" />
          {t('tools_menu.back')}
        </Button>
      </Box>
      <Box padding="0 1rem">
        <Typography sx={{ whiteSpace: 'break-spaces' }}>
          {t('components.main_list.groups.location.title')}
        </Typography>
      </Box>
      {emptyMsgDisplay}
      <S.ListContainer>{locationsDisplay}</S.ListContainer>
      <BottomContainer>
        <GroupButtons
          isDisabled
          goBackToBegining={goBack}
          title={t('modal.cancel')}
          modaltext={t('modal.discard.description')}
        />
      </BottomContainer>
    </S.LocationsList>
  ) : (
    <AssetsSensorsList
      locationId={locationId}
      goBack={() => showASList('locationsList')}
      componentToShow={goBack}
    />
  );
};

LocationsList.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default LocationsList;
