/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import { primaryColor, industryDefaults } from '@themes';

export const Location = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  scrollbar-color: ${primaryColor} #c5c5c52b;
  overflow-y: hidden;
  overflow-x: hidden;

  ${props =>
    !props.$hasSubItems &&
    css`
      margin-bottom: 0.2rem;
    `}

  &:hover {
    cursor: pointer;
  }
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IndustryIndicator = styled.div`
  background-color: ${props =>
    props.$industry
      ? industryDefaults[props.$industry]
      : industryDefaults.default};
  width: 0.7rem;
  max-width: 0.7rem;
  min-width: 0.7rem;
  overflow: none;
`;

export const LocationInfo = styled.div`
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const LocationName = styled.p`
  margin: 0;
  color: ${primaryColor};
  font-weight: 500;
  word-wrap: break-word;
`;

export const StatusIcon = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
`;

export const ContainerOpened = styled.div`
  opacity: 0;
  max-height: 0;
  visibility: visible;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  overflow: hidden;

  &:hover {
    cursor: initial;
  }

  ${props =>
    props.$isOpen &&
    css`
      opacity: 1;
      max-height: fit-content;
    `}
`;
