/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import { primaryColor } from '@themes';

export const ListContainerGroup = styled.div`
  overflow-y: auto;
  scrollbar-color: ${primaryColor};
  overflow-x: hidden;
  width: 100%;
  padding: 0;
  flex: 1;
`;

export const GroupsListFilled = styled.div`
  ${ListContainerGroup};
  width: 100%;
`;

export const GroupsListEmpty = styled.div`
  width: 100%;
  margin-top: 0rem;
  margin-left: 1rem;
`;

export const DefaultMessage = styled.div`
  color: ${primaryColor};
  text-transform: none;
  font-size: 1.125rem;
  text-align: left;
  font-weight: 400;
  white-space: break-spaces;
`;

export const ButtonPlusGroup = styled.div`
  margin-top: -1.563rem;
  margin-left: 110px;
  height: 1.563rem;
  width: 1.625rem;
  background-color: ${primaryColor};
  border: 0.063rem solid ${primaryColor};
  border-radius: 0.25rem;
  color: #ffffff;
  font-size: 1.625rem;
  text-align: center;
  line-height: 0.875rem;
  position: absolute;
  padding-top: 0.2rem;
  padding-left: 0.063rem;
`;
