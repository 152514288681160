import { del, get, post, put } from '../axios/axiosGateway';

/**
 * @param {Object} params - Values for filtering. Can sometimes filter by exclusion.
 */
export const getLocations = ({ params } = {}) => {
  return get('/locations', params);
};

export const postLocation = payload => {
  return post('/locations', payload);
};

export const putLocation = (payload, params) => {
  return put('/locations', payload, params);
};

export const delLocation = params => {
  return del('/locations', params);
};

export const getCachedLocations = () => {
  return get('/cached-locations');
};

export const postCachedLocations = payload => {
  return post('/cached-locations', payload);
};
