import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocationsCache } from '../hooks/useLocationsCache';
import { useGeneralContext } from './GeneralContext';

const LocationsCacheContext = createContext({ state: {}, actions: {} });

const LocationsCacheProvider = ({ children }) => {
  const { user } = useGeneralContext();
  const [disabledRequest, setDisabledRequest] = useState(false);
  const [shouldCollapse, setShouldCollapse] = useState(false);

  const { refetch, isLoading, data, isFetching } = useLocationsCache(
    !disabledRequest && !!user,
  );

  const locations = useMemo(
    () =>
      (data?.data?.features || [])
        .filter(feature => feature.geometry.type === 'Polygon')
        .sort((a, b) => a.properties.name.localeCompare(b.properties.name)),
    [data],
  );

  const assets = useMemo(
    () =>
      (data?.data?.features || [])
        .filter(feature => feature.geometry.type === 'Point')
        .sort((a, b) => a.properties.name.localeCompare(b.properties.name)),
    [data],
  );

  const lastUpdated = useMemo(() => new Date(data?.date), [data]);

  const values = useMemo(
    () => ({
      refetch,
      isLoading,
      locations,
      assets,
      lastUpdated,
      setDisabledRequest,
      shouldCollapse,
      setShouldCollapse,
      isFetching,
    }),
    [
      refetch,
      isLoading,
      locations,
      assets,
      lastUpdated,
      shouldCollapse,
      isFetching,
    ],
  );

  return (
    <LocationsCacheContext.Provider value={values}>
      {children}
    </LocationsCacheContext.Provider>
  );
};

export const useLocationsCacheContext = () => {
  return useContext(LocationsCacheContext);
};

LocationsCacheProvider.defaultProps = {
  children: null,
};

LocationsCacheProvider.propTypes = {
  children: PropTypes.element,
};

export default LocationsCacheProvider;
