/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import {
  primaryColor,
  bgButtonHover,
  customButtonDisabled,
} from '../../../../themes';

export const Container = styled.div``;

export const ActionButton = styled.button`
  white-space: nowrap;
  display: inline-block;
  font-weight: bold;
  text-transform: ${props => props.$uppercase && 'uppercase'};
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  padding: 0.375rem 1rem;
  border: 1px solid ${primaryColor};
  border-color: ${primaryColor};
  outline: ${primaryColor};
  min-width: ${props => props.$minWidth}px;
  background-color: ${primaryColor};
  margin: ${props => props.$margin};

  &:hover {
    background-color: ${bgButtonHover};
    border: 1px solid ${bgButtonHover};
    border-color: ${bgButtonHover};
    outline: ${bgButtonHover};
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: unset;
      cursor: not-allowed;
      opacity: 0.4;
      border: 1px solid ${customButtonDisabled} !important;
      background-color: ${customButtonDisabled} !important;
      color: ${primaryColor};
    `}

  ${props =>
    props.$secondary &&
    css`
      min-width: 120px;
      background-color: transparent;
      color: ${primaryColor};

      &:hover {
        background-color: ${primaryColor};
        color: #fff;
        ${props => (props.disabled ? ` ${primaryColor}` : ' #fff')};
      }
    `}

  ${props =>
    props.$secondary &&
    props.disabled &&
    css`
      background-color: transparent !important;
      &:hover {
        color: ${primaryColor};
      }
    `}
`;
