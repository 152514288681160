import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ModalFactory from '../components/ModalFactory';

const ModalFactoryContext = createContext({ state: {}, actions: {} });

const ModalFactoryProvider = ({ children }) => {
  const [modal, setModal] = useState({
    show: false,
    type: '',
    props: {},
  });

  const openModal = (type, props) => {
    setModal(prev => ({
      ...prev,
      show: false,
    }));
    setModal({
      type,
      props,
      show: true,
    });
  };
  const closeModal = () => setModal(prev => ({ ...prev, show: false }));

  const values = useMemo(() => ({ openModal, closeModal }), []);

  return (
    <ModalFactoryContext.Provider value={values}>
      {children}
      <ModalFactory
        type={modal.type}
        modalShow={modal.show}
        onSenderModalHide={closeModal}
        onClose={closeModal}
        onClickOutside={closeModal}
        {...modal.props}
      />
    </ModalFactoryContext.Provider>
  );
};

export const useModalFactory = () => {
  return useContext(ModalFactoryContext);
};

ModalFactoryProvider.defaultProps = {
  children: null,
};

ModalFactoryProvider.propTypes = {
  children: PropTypes.element,
};

export default ModalFactoryProvider;
