/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import { useQuery } from '@tanstack/react-query';
import { getPermissions } from '../services/permissionsService';

export async function getPermissionsService() {
  try {
    const {
      data: { results },
    } = await getPermissions();
    return results;
  } catch {
    return null;
  }
}

export function useRolePermissions() {
  return useQuery({
    queryKey: ['rolePermissions'],
    queryFn: () => getPermissionsService(),
    staleTime: 1000 * 60 * 10, // 10 minutes,
    placeholderData: {
      map: {
        location: {
          see: true,
          manage: true,
        },
      },
      settings: {
        sms: {
          see: true,
          manage: true,
        },
        email: {
          see: true,
          manage: true,
        },
        rules: {
          see: true,
          manage: true,
        },
        'rules-trigger': {
          see: true,
        },
      },
      management: {
        'asset-wizard': {
          see: true,
          manage: true,
        },
        users: {
          see: true,
          manage: true,
        },
        roles: {
          see: true,
          manage: true,
        },
        'asset-library': {
          see: true,
          manage: true,
        },
        assets: {
          see: true,
          manage: true,
        },
        'sensor-library': {
          see: true,
          manage: true,
        },
        sensors: {
          see: true,
          manage: true,
        },
        'component-library': {
          see: true,
          manage: true,
        },
        components: {
          see: true,
          manage: true,
        },
        'service-tickets': {
          see: true,
          manage: true,
        },
      },
      menu: {
        configuration: {
          see: true,
        },
        management: {
          see: true,
        },
        report: {
          see: true,
        },
      },
      facilities: {
        ticket: {
          see: true,
        },
      },
    },
  });
}
