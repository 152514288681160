/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import { assetItemSelectedBG } from '@themes';

export const AssetContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  transition: background-color 0.2s;
  background-color: ${props => props.isSelected && assetItemSelectedBG};

  &:hover {
    cursor: pointer;
  }
`;

export const AssetInfo = styled.div`
  padding: 0.5rem 1rem;
`;

export const AssetName = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
`;

export const AssetPosition = styled.p`
  margin: 0;
  font-style: italic;
  font-size: 16px;
`;

export const StatusIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 1rem;
`;
