import React from 'react';

import { useFeatures } from '@paralleldrive/react-feature-toggles';
import i18next from 'i18next';
import { Dropdown } from 'react-bootstrap';

import Content from './Content';
import { getAllLanguagesAvailable } from '../../../utils/getAllLanguagesAvailable';

const languages = getAllLanguagesAvailable();

const LanguageSelect = () => {
  const features = useFeatures();

  return (
    features.includes('localization') && (
      <Dropdown
        onSelect={eventKey => {
          i18next.changeLanguage(eventKey);
        }}
        style={{
          marginRight: '1.5rem',
        }}
      >
        <Dropdown.Toggle
          variant="custom"
          id="dropdown-basic"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Content isToggle language={i18next.language} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {languages.map(key => (
            <Dropdown.Item key={key} eventKey={key}>
              <Content language={key} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  );
};

export default LanguageSelect;
