/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import * as Yup from 'yup';

import { fetchFeatures } from './redux/actions/featuresActions';
import { getIndustriesAction } from './redux/actions/industriesActions';
import './themes';
import { getTokenValue } from './axios/axiosAuth';

Yup.setLocale({
  mixed: {
    required: 'validation.required',
  },
  string: {
    email: 'validation.invalid_email',
    max: 'validation.max_length',
  },
  number: {
    positive: 'validation.positive_integer',
  },
});

const AppWrapper = ({ children }) => {
  const appFeatures = useSelector(store => store.featuresState.features);
  const theme = useSelector(store => store.theme.industry);
  const industries = useSelector(store => store.industriesState);

  const dispatch = useDispatch();
  const fetchFeaturesDispatch = args => dispatch(fetchFeatures(args));
  const getIndustriesActionDispatch = args =>
    dispatch(getIndustriesAction(args));

  useEffect(() => {
    const hasToken = getTokenValue();
    fetchFeaturesDispatch();

    if (hasToken) {
      getIndustriesActionDispatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FeatureToggles features={appFeatures}>
      <ThemeProvider theme={{ industry: theme }} industries={{ industries }}>
        {children}
      </ThemeProvider>
    </FeatureToggles>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppWrapper;
