/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './style';
import { useBuildingDetails } from '../../context/BuildingContext';

const warningIcon = `${process.env.REACT_APP_STORAGE_BASE_URL}/warning_icon.svg`;
const dangerIcon = `${process.env.REACT_APP_STORAGE_BASE_URL}/danger_icon.svg`;

const FloorsList = ({ floorsList }) => {
  const {
    selectedFloor,
    selectedFloorFromModal,
    floorDetailsVisibility,
    changeFloorDetailsVisibility,
    changeSelectedFloor,
    changeSelectedAsset,
    changeFilteredStatus,
  } = useBuildingDetails();

  const checkWarningIcon = useCallback(floor => {
    if (floor.status_count.danger > 0)
      return <img src={dangerIcon} alt="Danger icon." />;
    if (floor.status_count.warning > 0)
      return <img src={warningIcon} alt="Warning icon." />;

    return null;
  }, []);

  const reverseFloorsList = useCallback(() => {
    const reversedList = Object.keys(floorsList)
      .map(floor =>
        Object.assign(floorsList[floor], {
          statusIcon: checkWarningIcon(floorsList[floor]),
        }),
      )
      .reverse();

    if (reversedList && reversedList[0]?.floor_name === 'Basement') {
      const basement = reversedList[0];

      reversedList.shift();
      reversedList.push(basement);
    }

    return reversedList;
  }, [checkWarningIcon, floorsList]);

  const [sortedFloorList, setSortedFloorList] = useState(reverseFloorsList());

  const handleChangeOnFloorDetailsVisibility = useCallback(
    visibility => {
      if (!visibility) {
        changeFloorDetailsVisibility(false);
        changeSelectedAsset(null);
        changeFilteredStatus([]);
      } else changeFloorDetailsVisibility(true);
    },
    [changeFilteredStatus, changeFloorDetailsVisibility, changeSelectedAsset],
  );

  const handleSelectedFloor = useCallback(
    floor => {
      if (!floorDetailsVisibility && !selectedFloor) {
        handleChangeOnFloorDetailsVisibility(true);
        changeSelectedFloor(floor);
      } else if (selectedFloor?.floor_name === floor?.floor_name) {
        handleChangeOnFloorDetailsVisibility(!floorDetailsVisibility);
        changeSelectedFloor(floorDetailsVisibility ? null : floor);
      } else if (selectedFloor?.floor_name !== floor?.floor_name) {
        handleChangeOnFloorDetailsVisibility(true);
        changeSelectedFloor(floor);
        changeFilteredStatus([]);
        changeSelectedAsset([]);
      }
    },
    [
      changeFilteredStatus,
      changeSelectedAsset,
      changeSelectedFloor,
      floorDetailsVisibility,
      handleChangeOnFloorDetailsVisibility,
      selectedFloor,
    ],
  );

  useEffect(() => {
    if (floorsList) setSortedFloorList(reverseFloorsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorsList]);

  useEffect(() => {
    if (
      selectedFloorFromModal &&
      selectedFloorFromModal?.floor_name !== selectedFloor?.floor_name
    ) {
      handleSelectedFloor(selectedFloorFromModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloorFromModal]);

  return (
    sortedFloorList && (
      <S.FloorsContainer>
        {sortedFloorList.map((floor, index) => (
          <S.FloorsInfo
            key={floor.floor_name}
            onClick={() => handleSelectedFloor(floor)}
          >
            <S.Floor
              isFirst={index === 0}
              isLast={index === Object.keys(sortedFloorList).length - 1}
              isSelected={selectedFloor === floor}
            >
              {floor.floor_name}
              <div>{floor.description}</div>
            </S.Floor>
            <S.AssetsCount hasWarningIcon={floor.statusIcon}>
              {floor.assets_count > 0
                ? String(floor.assets_count).padStart(2, '0')
                : 0}
              {floor.assets_count !== 1 ? ' assets' : ' asset'}
              {floor.statusIcon}
            </S.AssetsCount>
          </S.FloorsInfo>
        ))}
      </S.FloorsContainer>
    )
  );
};

FloorsList.defaultProps = {
  floorsList: {},
};

FloorsList.propTypes = {
  floorsList: PropTypes.shape(),
};

export default FloorsList;
