import { useFeatures } from '@paralleldrive/react-feature-toggles';
import i18next from '../services/i18n';

const hasOneUpperCase = `(?=.*?[A-Z])`;
const hasOneLowerCase = `(?=(.*[a-z])+)`;
const hasOneNumber = `(?=(.*[\\d])+)`;
const hasOneSpecialCharacter = `(?=(.*[\\W])+)`;

const passwordRegex = new RegExp(
  `^${hasOneUpperCase}${hasOneLowerCase}${hasOneNumber}${hasOneSpecialCharacter}(?!.*\\s).{8,}$`,
);

const usePasswordStrength = () => {
  const features = useFeatures();
  const weakPasswordMessage = i18next.t(
    'management_menu.users_management.users.validation.password_pattern',
  );

  const isWeak = password =>
    !features.includes('management-weakpassword') &&
    !passwordRegex.test(password);

  return { isWeak, weakPasswordMessage };
};

export default usePasswordStrength;
