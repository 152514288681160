/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getGroupsAction } from '../../../redux/actions/groupsActions';
import GroupItem from './GroupItem';
import EditGroup from './EditGroup';
import Spinner from '../../shared/atoms/Spinner';
import * as S from './style';
import { useRolePermissions } from '../../../hooks/useRolePermissions';
import { useServiceTickets } from '../../../hooks/useServiceTickets';
import { useGeneralContext } from '../../../context/GeneralContext';

const GroupsList = ({ goBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getGroupsActionDispatch = args => dispatch(getGroupsAction(args));

  const { data: serviceLogs } = useServiceTickets();

  const groups = useSelector(store => store.groupsState.groups);

  const [componentToShow, setComponentToShow] = useState('groupList');
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: userPermissions } = useRolePermissions();
  const { setSideMenuForm } = useGeneralContext();

  useEffect(() => {
    getGroupsActionDispatch().finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewComponentToShow = (newComponentToShow, newGroupToEdit) => {
    setComponentToShow(newComponentToShow);
    setGroupToEdit(newGroupToEdit);
    if (newGroupToEdit) setSideMenuForm(true);
    else goBack();
  };

  const goBackToGroups = () => {
    goBack();
  };

  const emptyDisplay = userPermissions?.map?.location?.manage ? (
    <S.GroupsListEmpty>
      <S.DefaultMessage>
        <div>{t('components.main_list.groups.default.click')}</div>
        <S.ButtonPlusGroup>+</S.ButtonPlusGroup>
        <div>{t('components.main_list.groups.default.create')}</div>
      </S.DefaultMessage>
    </S.GroupsListEmpty>
  ) : (
    <S.GroupsListEmpty>
      <S.DefaultMessage>
        {t('components.main_list.groups.default.no_groups_available')}
      </S.DefaultMessage>
    </S.GroupsListEmpty>
  );

  const filteredComponent =
    groups.length === 0 ? (
      emptyDisplay
    ) : (
      <S.GroupsListFilled>
        {groups.map((item, index) => {
          const itemKey = `${item}_${index};`;
          return (
            <GroupItem
              key={itemKey}
              group={item}
              openEdit={value => setNewComponentToShow(value, item)}
              goBack={goBackToGroups}
              serviceLogs={serviceLogs}
              canManage={userPermissions?.map?.location?.manage}
            />
          );
        })}
      </S.GroupsListFilled>
    );

  const groupComponentList = loading ? (
    <Spinner />
  ) : (
    <S.ListContainerGroup id="groups-list-div">
      {filteredComponent}
    </S.ListContainerGroup>
  );

  return componentToShow === 'groupList' ? (
    groupComponentList
  ) : (
    <EditGroup groupToEdit={groupToEdit} goBack={setNewComponentToShow} />
  );
};

GroupsList.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default GroupsList;
