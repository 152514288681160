/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, ListItemButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AssetItem from '../AssetItem';
import ModalActions from '../../../ModalActions';
import * as S from './style';

const GroupItem = ({ openEdit, goBack, group, serviceLogs, canManage }) => {
  const [arrowDirection, setArrowDirection] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const { t } = useTranslation();

  const openToEdit = () => openEdit('editPage');

  const goOneBack = () => goBack('groups');

  const setModalToShow = status => {
    setModalShow(status);
    setArrowDirection(true);
  };

  const changeArrowDirection = () => {
    setArrowDirection(prevState => !prevState);
  };

  const assetsList = useMemo(
    () => [...new Set(group.sensors.map(g => g.sensor_asset_id))],
    [group.sensors],
  );

  return (
    <Box>
      <ListItemButton onClick={changeArrowDirection}>
        <S.Info>
          <S.GroupName>{group.name}</S.GroupName>
          <S.LocationName>{group.location_name}</S.LocationName>
        </S.Info>
        <S.GroupButtonNext>
          <div>
            {arrowDirection ? (
              <S.ArrowDirectionRight />
            ) : (
              <S.ArrowDirectionDown />
            )}
          </div>
        </S.GroupButtonNext>
      </ListItemButton>
      {!arrowDirection && (
        <Box>
          {assetsList
            .toSorted((a, b) => {
              return a > b ? 1 : -1;
            })
            .map(asset => (
              <AssetItem
                key={`asset_item_list_${asset}`}
                assetId={asset}
                group={group}
                serviceLogs={serviceLogs}
              />
            ))}
          {canManage && (
            <S.AssetItemButtons>
              <S.AssetButton type="button" onClick={openToEdit}>
                {t('components.main_list.buttons.edit')}
              </S.AssetButton>
              <S.AssetButton type="button" onClick={() => setModalToShow(true)}>
                {t('components.main_list.buttons.delete')}
              </S.AssetButton>
              <ModalActions
                show={modalShow}
                onHide={() => setModalToShow(false)}
                title={t('components.main_list.modal.delete_group.title')}
                modaltext={t(
                  'components.main_list.modal.delete_group.description',
                  { name: group.name },
                )}
                goBack={goOneBack}
                itemtodelete={group.item_id}
              />
            </S.AssetItemButtons>
          )}
        </Box>
      )}
    </Box>
  );
};

GroupItem.defaultProps = {
  canManage: false,
};

GroupItem.propTypes = {
  openEdit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  serviceLogs: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  canManage: PropTypes.bool,
};

export default GroupItem;
