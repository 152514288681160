/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';
import { darkGrey, primaryColor } from '@themes';

export const CompleteListContainer = styled.div`
  margin-bottom: 15px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  & > .asset-sensor-check input {
    margin-top: 38px !important;
    margin-left: 0 !important;
  }
`;

export const LocationItem = styled.div`
  margin-bottom: -23px;
  overflow-y: auto;
  scrollbar-color: ${primaryColor} ${darkGrey};
  flex: 1 1;
  width: 120%;
  margin-left: -10px;
  margin-top: -50px;
  padding: 40px 10px 15px 15px;
  cursor: pointer;
`;

export const NameAsset = styled.label`
  float: right;
  margin-left: 10px;
  padding-top: 8px;
  cursor: pointer;
`;

export const CheckboxAsset = styled.input`
  vertical-align: bottom;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

export const InfoLocationItem = styled.div`
  width: 160px;
  margin-top: -10px;
  margin-left: 40px;

  & > p {
    margin: 0;
    color: ${primaryColor};
  }

  & > p:last-child {
    color: #fff;
    font-size: 0.9rem;
  }
`;

export const NextBtnAssetItem = styled.div`
  margin-top: -35px;
  margin-left: 15rem;
  margin-bottom: 20px;

  & > i {
    color: ${primaryColor};
  }
`;

export const SensorListItemGroup = styled.div`
  display: block;
  background-color: ${darkGrey};
  width: 100%;
`;

export const SensorsList = styled.div`
  background-color: ${darkGrey};
  overflow: ${props => (props.isHidden ? 'hidden' : 'visible')};
  opacity: ${props => (props.isHidden ? 0 : 0.9)};
  max-height: ${props => (props.isHidden ? 0 : `${props.componentHeight}rem`)};
  flex-direction: column;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  margin-bottom: 24px;
  padding-left: 4px;
`;

export const NoSensors = styled.div`
  padding-left: 1.4rem;
`;
