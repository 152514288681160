/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import {
  alertWarning,
  alertDanger,
  alertNormal,
  alertOff,
  gradientBGPrimary,
  gradientBGSecondary,
} from '@themes';

const handleNotificationType = type => {
  switch (type) {
    case 'danger':
      return alertDanger;
    case 'warning':
      return alertWarning;
    case 'normal':
      return alertNormal;
    default:
      return alertOff;
  }
};

export const Spinner = styled.div`
  margin-bottom: -1rem;
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: row;
  background: ${gradientBGPrimary};
  background: linear-gradient(
    90deg,
    ${gradientBGPrimary} 0%,
    ${gradientBGSecondary} 100%
  );
  opacity: 0.96;
  min-height: auto;
  margin-bottom: 0.8rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  -webkit-box-shadow: -0.25rem 0.688rem 1.813rem -0.625rem rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -0.25rem 0.688rem 1.813rem -0.625rem rgba(0, 0, 0, 0.75);
  box-shadow: -0.25rem 0.688rem 1.813rem -0.625rem rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 0.9rem;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export const InfoCombo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  opacity: ${props => (props.$attrDeleting ? 0.1 : 1)};

  h6:not(.single-dismiss),
  div:not(.single-dismiss) {
    padding-left: 0.5rem;
    padding-right: 0.8rem;
  }
`;

export const SingleDismiss = styled.div`
  text-align: right;
  color: white;

  > i:hover {
    cursor: pointer;
  }
`;

export const VisualCombo = styled.img`
  margin-bottom: 2.5rem;
  opacity: ${props => (props.$attrDeleting ? 0.2 : 1)};
`;

export const Title = styled.h6`
  color: ${props => handleNotificationType(props.$notificationType)};
  text-align: right;
  font-weight: 800;
  margin-top: -1.25rem;
  margin-right: 1rem;
`;

export const Message = styled.div`
  text-align: right;
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;
`;

export const OvlMessage = styled.div`
  margin-bottom: 0.5rem;
`;

export const Time = styled.div`
  font-size: 0.7rem;
  text-align: right;
  margin-right: 0.5rem;
`;

export const OverlayCombo = styled.div`
  bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  width: 15rem;
`;
