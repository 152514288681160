/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { Store } from '@redux/store';
import Routes from '@routes';
import HooksProvider from '@hooks';

// Import FilePond
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.min.css';
// Import the plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Import the plugin code
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import { throttle } from 'lodash';
import { forceCheck } from 'react-lazyload';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './services/queryClient';

import AppWrapper from './AppWrapper';
import 'bootstrap/dist/css/bootstrap.min.css';
import GeneralContext from './context/GeneralContext';
import MQTTContext from './context/MQTTContext';
import { primaryColorObj, secondaryColorObj } from './themes';
import './services/i18n';
import ConfirmationContext from './context/ConfirmationContext';
import LocationsCacheContext from './context/LocationsCacheContext';
import MapPopUpsContext from './context/MapPopUpsContext';
import ModalFactoryContext from './context/ModalFactoryContext';

// Register the plugin
FilePond.registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview,
);

document.addEventListener(
  'scroll',
  throttle(() => forceCheck(), 16),
  true,
);

const theme = createTheme({
  typography: {
    fontFamily: [
      'Ubuntu',
      'BlinkMacSystemFont',
      '-apple-system',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    text: {
      primary: '#FFFFFF',
      secondary: '#040E20',
    },
    primary: {
      main: primaryColorObj.default,
    },
    secondary: {
      main: '#114851',
    },
    background: {
      default: '#09152D',
      light: '#071734',
      paper: '#EDEBEB',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#071734',
          border: `1px solid ${primaryColorObj.default}`,
          height: '100%',
          color: '#FFFFFF',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        subheader: {
          color: '#FFFFFF',
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: primaryColorObj.default,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${primaryColorObj.default} !important`,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          margin: 0,
          marginLeft: '0.09vh !important',
          paddingBottom: '0px !important',
          borderTopLeftRadius: '0px !important',
          borderTopRightRadius: '0px !important',
          color: '#586069 !important',
        },
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: '#02BDBD !important',
            color: '#FFFFFF',
          },
          '&.Mui-focused': {
            backgroundColor: '#039e9e !important',
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          color: '#040E20',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#040E20',
          '&:hover fieldset': {
            borderColor: `${primaryColorObj.default} !important`,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: secondaryColorObj.default,
          fontSize: '0.90rem',
          padding: '0.75em 1em',
          textAlign: 'center',
          fontWeight: '500',
          border: '1px solid #fff',
        },
        arrow: {
          '&::before': {
            border: '1px solid #fff',
            backgroundColor: secondaryColorObj.default,
            boxSizing: 'border-box',
            borderRadius: '2px',
          },
        },
      },
    },
  },
});

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Provider store={Store}>
              <AppWrapper>
                <GeneralContext>
                  <LocationsCacheContext>
                    <MapPopUpsContext>
                      <MQTTContext>
                        <HooksProvider>
                          <ConfirmationContext>
                            <ModalFactoryContext>
                              <Routes />
                            </ModalFactoryContext>
                          </ConfirmationContext>
                        </HooksProvider>
                      </MQTTContext>
                    </MapPopUpsContext>
                  </LocationsCacheContext>
                </GeneralContext>
              </AppWrapper>
            </Provider>
          </BrowserRouter>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
