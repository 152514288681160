import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const StyledLabel = styled.label`
  font-size: 1.1rem;
  padding-right: 10px;
`;

export const StyledSelect = styled(Form.Select)`
  width: 100%;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  label {
    font-size: 1.1rem;
  }

  ${props =>
    props.disabled
      ? css`
          background-color: #ccc;
        `
      : css`
          background: #fff;
        `}
`;
