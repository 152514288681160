import React from 'react';

import { Provider, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';

import { Store } from '@redux/store';
import { statusColorObj } from '@themes';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import CustomTooltip from '../shared/atoms/CustomTooltip';
import Spinner from '../shared/atoms/Spinner';
import * as S from './style';

const dotSVG = `${process.env.REACT_APP_STORAGE_BASE_URL}/dot.svg`;

const MapIconWrapper = props => (
  <Provider store={Store}>
    <MapIcon {...props} />
  </Provider>
);

const Loading = () => {
  const theme = useSelector(store => store.theme.industry);
  const industries = useSelector(store => store.industriesState);
  return (
    <ThemeProvider theme={{ industry: theme }} industries={{ industries }}>
      <Spinner theme={{ industry: '#ffffff' }} />
    </ThemeProvider>
  );
};

const Fallback = () => <span>Invalid URL!</span>;

const MapIcon = ({
  id,
  src,
  name,
  status,
  dotIcon,
  coordinates,
  onClick,
  isOverlapping,
}) => {
  const handleClick = ev => onClick(ev, { id, coordinates, name, status });

  const renderContent = () => (
    <S.MapIcon
      $dotIcon={dotIcon}
      onClick={handleClick}
      $status={status || false}
    >
      <div className={status}>
        <ReactSVG
          id={id}
          src={dotIcon ? dotSVG : src}
          onError={error => console.error(error)}
          evalScripts="always"
          fallback={Fallback}
          loading={Loading}
          renumerateIRIElements={false}
        />
        {!isOverlapping && <h5>{name}</h5>}
      </div>
    </S.MapIcon>
  );

  return isOverlapping ? (
    <CustomTooltip
      title={name}
      slotProps={{
        popper: {
          modifiers: [{ name: 'offset', options: { offset: [0, -15] } }],
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            color: 'white',
            backgroundColor: statusColorObj[status],
            font: "12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif",
            fontSize: '1.25rem',
            fontWeight: 'bold',
          },
        },
        arrow: {
          sx: {
            '&::before': {
              backgroundColor: statusColorObj[status],
            },
          },
        },
      }}
    >
      {renderContent()}
    </CustomTooltip>
  ) : (
    renderContent()
  );
};

MapIcon.defaultProps = {
  onClick: () => {},
  coordinates: [],
  status: 'default',
  dotIcon: false,
  isOverlapping: false,
  name: '',
};

MapIcon.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  name: PropTypes.string,
  dotIcon: PropTypes.bool,
  isOverlapping: PropTypes.bool,
  coordinates: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  onClick: PropTypes.func,
};

export default MapIconWrapper;
