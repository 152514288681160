/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';
import { Dropdown, ButtonGroup, Form } from 'react-bootstrap';
import {
  corvalentDarkBlue,
  corvalentLightGrey,
  corvalentMediumGrey,
} from '../../../themes';

export const DropdownContainer = styled(Dropdown)`
  display: flex;
  margin-bottom: 1rem;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '202px')};
  margin-bottom: 0.2rem;
`;

export const FormControl = styled(Form.Control)`
  border-radius: 5px 0 0 5px;

  && {
    margin-bottom: 0 !important;
  }
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  width: 83px;
  border-radius: 0 5px 5px 0;
  border: none;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.9;
  }

  &:after {
    display: none;
  }
`;

export const DropdownTail = styled.div`
  position: absolute;
  top: -40px;
  right: 5px;
  border-color: transparent transparent white transparent;
  border-style: solid;
  border-width: 1.25em;
  pointer-events: none;
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  && {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    width: ${({ $colors }) => `${$colors * 60}px`};

    &[x-placement^='top'] {
      ${DropdownTail} {
        transform: rotate(180deg);
        top: initial;
        bottom: -40px;
      }
    }
  }
`;

export const DropdownItem = styled(Dropdown.Item)`
  padding: 0;
  width: 2.7em;
  height: 2.7em;
  border-radius: 2px;

  && {
    flex: 0 0 auto;
  }

  &&:not(:disabled):not(.disabled) {
    &.active,
    &.focus,
    &:hover {
      border: 2px solid ${corvalentDarkBlue};
      background-color: ${props => props.$backgroundColor};
      opacity: 0.9;
    }
  }
`;

export const ColorButtonGroup = styled(ButtonGroup)`
  && {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const ColorHexLabel = styled.div`
  width: 100%;
  border: 1px solid ${corvalentLightGrey};
  color: ${corvalentMediumGrey};
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
`;
