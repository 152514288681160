export default permissions => {
  if (Object.keys(permissions).length === 0) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const [, value] of Object.entries(permissions)) {
    if (value?.see) {
      return true;
    }
  }
  return false;
};
