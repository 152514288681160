/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Stack } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Spinner from '../../../shared/atoms/Spinner';
import * as S from './style';
import { getAssets } from '../../../../services/assetService';
import AssetSensorItem from '../AssetSensorItem';
import GroupButtons from './GroupButtons';
import CustomPrompt from '../../../CustomPrompt';
import BottomContainer from '../../../BottomContainer';
import { useConfirmation } from '../../../../context/ConfirmationContext';

const EditGroup = ({ groupToEdit, goBack }) => {
  const history = useHistory();
  const [assets, setAssets] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [itemsToAdd, setItemsToAdd] = useState([]);
  const [changesMade, setChangesMade] = useState(false);
  const [isNameInputOK, setIsNameInputOK] = useState(true);
  const [originalName] = useState(groupToEdit.name);
  const { t } = useTranslation();
  const { openConfirmation } = useConfirmation();

  useEffect(() => {
    getAssets({
      location_id: groupToEdit.sensors[0].sensor_location_id,
    }).then(resp => {
      setAssets(resp?.data?.results);
      setItemsToAdd(groupToEdit.sensors);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBackAction = () => {
    setCheckedItems([]);
    setItemsToAdd([]);

    if (changesMade) {
      openConfirmation({
        message: t('modal.discard.description'),
        handleAction: () => {
          groupToEdit.name = originalName;
          goBack('groupList');
        },
        title: t('modal.cancel'),
      });
    } else {
      groupToEdit.name = originalName;
      goBack('groupList');
    }
  };

  const itemsToAddAction = itemsList => {
    const itemsToAddLocal = [];

    if (checkedItems.includes(itemsList)) {
      checkedItems[checkedItems.indexOf(itemsList)] = itemsList;
    } else {
      checkedItems.push(itemsList);
    }

    checkedItems.forEach(checked => {
      if (checked) {
        checked.forEach(item => {
          if (item.isChecked) itemsToAddLocal.push(item);
        });
      }
    });

    setCheckedItems([...checkedItems]);
    setItemsToAdd(itemsToAddLocal);
  };

  const handleDiscard = nextLocation => {
    goBack('groupList');

    history.push(nextLocation);
    groupToEdit.name = originalName;
  };

  const handleNameChange = e => {
    const element = e.target;
    let isOK = false;

    if (element.value.length === 0) {
      element.classList.remove('modal-input-save-edit');
      element.classList.add('modal-input-save-error-edit');
    } else {
      element.classList.remove('modal-input-save-error-edit');
      element.classList.add('modal-input-save-edit');
      groupToEdit.name = element.value;
      isOK = true;
    }

    setIsNameInputOK(isOK);
  };

  const invalidName = isInvalid => {
    const label = document.getElementById('error-name');
    const name = document.getElementById('name-input');

    if (isInvalid) {
      label.classList.remove('error-message-edit-success');
      label.classList.add('error-message-edit');
      name.classList.remove('modal-input-save-edit');
      name.classList.add('modal-input-save-error-edit');
    } else {
      label.classList.remove('error-message-edit');
      label.classList.add('error-message-edit-success');
      name.classList.remove('modal-input-save-error-edit');
      name.classList.add('modal-input-save-edit');
    }
  };

  return (
    <S.MainLocationDivEdit>
      <Box paddingLeft="1rem">
        <Button
          variant="text"
          onClick={goBackAction}
          sx={{ padding: '0 10px 0 0', color: 'white' }}
        >
          <ChevronLeft fontSize="large" color="primary" />
          {t('tools_menu.back')}
        </Button>
      </Box>
      <Stack overflow="auto">
        <Stack paddingRight="1rem" paddingLeft="1rem">
          <label htmlFor="name-input">
            {t('components.main_list.groups.edit.label')}
          </label>
          <S.InputName
            id="name-input"
            type="text"
            maxLength="255"
            $isNameInputOK={isNameInputOK}
            defaultValue={groupToEdit.name}
            onChange={e => {
              handleNameChange(e);
              setChangesMade(true);
            }}
          />
          <S.ErrorMessageEditSuccess id="error-name">
            {t('components.main_list.groups.edit.name_error')}
          </S.ErrorMessageEditSuccess>
        </Stack>
        <Box>
          {assets ? (
            assets
              .sort((a, b) => {
                return a.name > b.name ? 1 : -1;
              })
              .map(item => (
                <AssetSensorItem
                  key={item.item_id}
                  asset={item}
                  groupToEdit={groupToEdit}
                  itemsToAdd={itemsList => itemsToAddAction(itemsList)}
                  somethingChanged={changed => setChangesMade(changed)}
                />
              ))
          ) : (
            <Spinner />
          )}
        </Box>
      </Stack>
      <BottomContainer visible>
        <GroupButtons
          isDisabled={itemsToAdd.length === 0}
          goBackToBegining={() => goBack('groupList')}
          pageName="AssetsSensorsList"
          onCancel={() =>
            openConfirmation({
              message: t('modal.discard.description'),
              handleAction: () => {
                groupToEdit.name = originalName;
                goBack('groupList');
              },
              title: t('modal.cancel'),
            })
          }
          title={t('modal.cancel')}
          modaltext={t('modal.discard.description')}
          qtt={itemsToAdd.length}
          locationName={groupToEdit.location_name}
          locationId={groupToEdit.sensors[0].sensor_location_id}
          itemsToAdd={itemsToAdd}
          groupId={groupToEdit.item_id}
          changesMade={changesMade}
          isNameInputOK={isNameInputOK}
          groupName={groupToEdit.name}
          invalidName={isInvalid => invalidName(isInvalid)}
        />
      </BottomContainer>
      <CustomPrompt
        when={changesMade}
        modalTitle={t('modal.discard.title')}
        modalText={t('modal.discard.description')}
        actionText={t('modal.cancel')}
        discardText={t('modal.discard.button_text_cancel')}
        onDiscard={handleDiscard}
      />
    </S.MainLocationDivEdit>
  );
};

EditGroup.propTypes = {
  groupToEdit: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default EditGroup;
