/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState, forwardRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Divider,
  Drawer,
  Stack,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Locations from '../Locations';
import GroupsList from '../GroupsList';
import GroupedButtons from '../../GroupedButtons';
import ModalWithActions from '../../ModalWithActions';
import StackNavigation from '../StackNavigation';
import BuildingDetails from '../../BuildingDetails';
import FloorDetails from '../../FloorDetails';
import * as S from './style';
import { useRolePermissions } from '../../../hooks/useRolePermissions';
import { useBuildingDetails } from '../../../context/BuildingContext';
import { useIsTabletOrMobile } from '../../../hooks/useIsTabletOrMobile';
import LocationsList from '../LocationsList';
import MenuOptions from './MenuOptions';
import SensorCounter from './SensorCounter';
import { useGeneralContext } from '../../../context/GeneralContext';
import { openAnimated } from '../../../redux/actions/sideDrawerActions';

const LOCATIONS_CONTEXT = 0;
const GROUPS_CONTEXT = 1;
const LOCATION_CREATION = 2;
const GROUP_CREATION = 4;

const InitialPage = props => {
  return <Locations {...props} />;
};

const drawerWidth = 280;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: 'transparent',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  backgroundColor: 'transparent',
});

const MiniDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MainList = forwardRef((props, ref) => {
  const smallHeight = useMediaQuery('(max-height:800px)');
  const smallerHeight = useMediaQuery('(max-height:500px)');
  const isOpen = useSelector(store => store.sideDrawerState.isOpen);
  const [context, setContext] = useState(LOCATIONS_CONTEXT);
  const [creationContext, setCreationContext] = useState(null);
  const [modalInputText, setModalInputText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const isTabletOrMobile = useIsTabletOrMobile();
  const history = useHistory();
  const { data: userPermissions } = useRolePermissions();
  const { setHideDrawers } = useBuildingDetails();
  const { t } = useTranslation();
  const { sideMenuForm, setSideMenuForm } = useGeneralContext();
  const dispatch = useDispatch();

  const isMapPage = useMemo(
    () =>
      history.location.pathname.includes('/portal/maps') &&
      !history.location.pathname.includes('/facilities'),
    [history.location.pathname],
  );

  useEffect(() => {
    if (!isOpen) {
      setContext(LOCATIONS_CONTEXT);
      setCreationContext(null);
      setShowModal(false);
      setSideMenuForm(false);
    }
  }, [isOpen, setSideMenuForm]);

  useEffect(() => {
    if (!isOpen && isMapPage) {
      dispatch(openAnimated());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelCreation = () => {
    setCreationContext(null);
    setShowModal(false);
    setSideMenuForm(false);
  };

  const renderContextComponent = () => {
    if (context === GROUPS_CONTEXT && creationContext === GROUP_CREATION)
      return <LocationsList goBack={cancelCreation} />;
    if (context === LOCATIONS_CONTEXT)
      return (
        <StackNavigation
          InitialPage={InitialPage}
          onPopPage={cancelCreation}
          creationContext={creationContext}
          modalInputText={modalInputText}
          setHideDrawers={setHideDrawers}
        />
      );

    if (context === GROUPS_CONTEXT)
      return <GroupsList goBack={cancelCreation} />;

    return null;
  };

  const handleGBClick = clicked => {
    setContext(clicked.index);
  };

  const openLocations = () => {
    setShowModal(true);
    setModalInputText('');
  };

  const create = () => {
    switch (context) {
      case GROUPS_CONTEXT:
        setCreationContext(GROUP_CREATION);
        setSideMenuForm(true);
        break;
      case LOCATIONS_CONTEXT:
        openLocations();
        setSideMenuForm(true);
        break;
      default:
        break;
    }
  };

  const onModalAction = () => {
    setCreationContext(LOCATION_CREATION);
    setShowModal(false);
  };

  const hideModal = () => {
    setShowModal(false);
    setSideMenuForm(false);
  };

  const inputText = (placeholder, onChange) => (
    <input
      placeholder={placeholder}
      onChange={onChange}
      style={{
        width: '100%',
        padding: '2px',
        paddingLeft: '8px',
        borderRadius: '4px',
        color: '#000',
        borderStyle: 'solid',
        borderColor: '#D8D8D8',
        backgroundColor: '#D8D8D8',
      }}
    />
  );

  const handleInputText = e => {
    setModalInputText(e.target.value);
  };

  const goToMap = () => {
    history.push('/portal/maps');
  };

  const checkModalToShow = () => {
    if (isMapPage)
      return (
        <ModalWithActions
          show={showModal}
          modalTitle={t('components.main_list.modal.new_location.title')}
          modalText={t('components.main_list.modal.new_location.description')}
          btActionText={t('modal.confirm')}
          btActionDisabled={!modalInputText}
          inputComponent={inputText('', handleInputText)}
          action={onModalAction}
          onHide={hideModal}
        />
      );

    return (
      <ModalWithActions
        show={showModal}
        modalTitle={t('components.main_list.modal.create_location.title')}
        modalText={t('components.main_list.modal.create_location.description')}
        btActionText={t('components.main_list.modal.create_location.action')}
        btActionDisabled={false}
        action={goToMap}
        onHide={hideModal}
      />
    );
  };

  return (
    <MiniDrawer variant="permanent" open={isOpen} ref={ref}>
      <Stack
        sx={{
          background: 'linear-gradient(90deg, #02112d 0%, #152648 100%)',
          marginTop: '50px',
          overflow: 'hidden',
          pointerEvents: 'all',
          height: '100%',
        }}
      >
        {checkModalToShow()}
        {isOpen && !sideMenuForm && userPermissions && (
          <Box
            sx={{
              width: '100%',
              color: 'white',
              padding: '1rem 1rem 10px 1rem',
            }}
          >
            <S.ComboButtons>
              <Box width="100%" paddingRight="1rem">
                <GroupedButtons
                  buttonState={context}
                  buttons={[
                    t('components.main_list.filter.Locations'),
                    t('components.main_list.filter.Groups'),
                  ]}
                  onClick={handleGBClick}
                />
              </Box>
              {userPermissions?.map?.location?.manage && (
                <S.Create>
                  <S.ButtonCreate
                    type="button"
                    onClick={create}
                    disabled={context === 0 && isTabletOrMobile}
                    $isButtonDisabled={context === 0 && isTabletOrMobile}
                  >
                    <i className="fas fa-plus" />
                  </S.ButtonCreate>
                </S.Create>
              )}
            </S.ComboButtons>
          </Box>
        )}
        {renderContextComponent()}
        {!sideMenuForm && (
          <>
            <Box
              padding="10px 10px 0 10px"
              display={smallerHeight ? 'none' : 'block'}
            >
              <Divider
                sx={{
                  borderColor: theme => theme.palette.primary.main,
                  width: '100%',
                  borderBottomWidth: 1,
                  opacity: 1,
                }}
              />
            </Box>
            <Box display={smallerHeight ? 'none' : 'block'}>
              <MenuOptions smallHeight={smallHeight} />
            </Box>
            <Box
              padding="0 10px 0 10px"
              display={smallHeight ? 'none' : 'block'}
            >
              <Divider
                sx={{
                  borderColor: theme => theme.palette.primary.main,
                  width: '100%',
                  borderBottomWidth: 1,
                  opacity: 1,
                }}
              />
            </Box>
            <Box display={smallHeight ? 'none' : 'block'}>
              <SensorCounter />
            </Box>
          </>
        )}
        <BuildingDetails />
        <FloorDetails />
      </Stack>
    </MiniDrawer>
  );
});

export default MainList;
