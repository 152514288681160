import { setRefreshTokenValue, setTokenValue } from '../axios/axiosAuth';
import { get, post, put } from '../axios/axiosGateway';

export const getUsers = params => {
  return get('/users', params);
};

export const getUserGroups = params => {
  return get('/users-groups', params);
};

export const validateUserEmail = params => {
  return post('/validate-user-email', params);
};

export const addUser = userToAdd => {
  return post(`/users`, {
    name: userToAdd.name,
    email: userToAdd.email,
    phone: userToAdd.phone,
    role: userToAdd.role,
    password: userToAdd.password,
    user_group: userToAdd.user_group,
  });
};

export const putUser = values => {
  return put(`/userChange?item_id=${values.item_id}`, values);
};

export const softDeleteUser = values => {
  return put(`/userChange?item_id=${values.item_id}`, {
    item_id: values.item_id,
    email: values.email,
    deleted: true,
    deleted_date: new Date(),
  });
};

export const resetOwnPassword = async data => {
  return put('/userChange', data).then(res => {
    setTokenValue(res.data.results.user_token);
    setRefreshTokenValue(res.data.results.refresh_token);
  });
};
