import { useQuery } from '@tanstack/react-query';
import { getSensors } from '../services/sensorService';

const getSensorsReq = async params => {
  const {
    data: { results },
  } = await getSensors(params);
  return results || [];
};

export const useSensors = params => {
  return useQuery({
    queryKey: ['sensors', JSON.stringify(params)],
    queryFn: () => getSensorsReq(params),
    placeholderData: [],
    enabled: !!params.asset_id,
  });
};
