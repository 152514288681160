import i18next from '../services/i18n';

const getStatusTranslation = status => {
  if (!status) return '';

  const splitedStatus = status.split(' ');

  if (status.includes('High above ')) {
    return i18next.t('components.gauge.status_options.high', {
      value: splitedStatus[2],
    });
  }

  if (status.includes('Highest above ')) {
    return i18next.t('components.gauge.status_options.highest', {
      value: splitedStatus[2],
    });
  }

  if (status.includes('Low below ')) {
    return i18next.t('components.gauge.status_options.low', {
      value: splitedStatus[2],
    });
  }

  if (status.includes('Lowest below ')) {
    return i18next.t('components.gauge.status_options.lowest', {
      value: splitedStatus[2],
    });
  }

  if (status.includes('Normal between ')) {
    return i18next.t('components.gauge.status_options.normal', {
      low_value: splitedStatus[2],
      high_value: splitedStatus[4],
    });
  }

  return '';
};

export default getStatusTranslation;
