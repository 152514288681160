import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CustomSelect from '../../CustomSelect';

const WeatherSelector = ({
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
  submitForm,
  config,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={2} sx={{ width: '500px' }}>
        <Grid item xs={12}>
          <CustomSelect
            id="unit"
            label={t('atoms.widget.selector.unit')}
            options={(config.options || []).map(c => ({
              value: c,
              label: t(`atoms.widget.selector.options_weather.${c}`),
            }))}
            value={values.unit}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
            required
          />
        </Grid>
      </Grid>
    </form>
  );
};

WeatherSelector.propTypes = {
  errors: PropTypes.any.isRequired,
  handleBlur: PropTypes.any.isRequired,
  handleChange: PropTypes.any.isRequired,
  config: PropTypes.object.isRequired,
  submitForm: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  values: PropTypes.shape({
    unit: PropTypes.string,
  }).isRequired,
};

export default WeatherSelector;
