/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import {
  FETCH_RULES_ENGINE,
  RECEIVED_RULES_ENGINE,
  UPDATED_RULES_QUERY,
  DELETE_RULES_ENGINE_PENDING,
  DELETE_RULES_ENGINE_FULFILLED,
  UPDATE_RULES_ENGINE_FULFILLED,
  UPDATE_RULES_ENGINE_PENDING,
  ADD_RULES_ENGINE_PENDING,
  ADD_RULES_ENGINE_FULFILLED,
  RECEIVED_ERROR,
  UPDATE_TEMPLATE_PREVIEW_DATA,
  CLEAR_TEMPLATE_PREVIEW_DATA,
  UPDATE_LAST_MODIFIED_RULE,
  UPDATE_CURRENTLY_SELECTED_ASSET,
  UPDATE_CURRENTLY_SELECTED_ASSET_TYPE,
  TRIGGER_ELEMENT_FOCUS,
  UPDATE_SELECTED_TAGS_DATA_FORMAT,
} from '../actions/actionTypes';

const initialState = {
  rulesEngines: {},
  lastCreation: null,
  rulesQueryParams: {},
  isPending: null,
  message: null,
  error: null,
  templatePreviewData: {},
  lastModifiedRule: {},
  currentlySelectedAsset: {},
  currentlySelectedAssetType: {},
  currentlySelectedTagsDataFormat: {
    assetStatus: null,
    sensorReading: null,
    sensorStatus: null,
    lastUpdated: '@LastUpdated',
  },
  elementToFocus: null,
};

export const rulesEngineReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_RULES_ENGINE:
      return {
        ...state,
        error: null,
        message: null,
        isPending: true,
      };

    case UPDATED_RULES_QUERY:
      return {
        ...state,
        rulesQueryParams: action.newValue,
      };

    case RECEIVED_RULES_ENGINE:
      return {
        ...state,
        rulesEngines: action.newValue,
        message: null,
        isPending: false,
      };

    case ADD_RULES_ENGINE_PENDING:
      return {
        ...state,
        isPending: true,
        lastCreation: null,
        error: null,
        message: null,
      };

    case ADD_RULES_ENGINE_FULFILLED:
      return {
        ...state,
        rulesEngines: action.newValue,
        isPending: false,
        lastCreation: action.newValue,
        message: 'Rule created successfully!',
      };

    case UPDATE_RULES_ENGINE_PENDING:
    case DELETE_RULES_ENGINE_PENDING:
      return {
        ...state,
        isPending: true,
        error: null,
        message: null,
      };

    case UPDATE_RULES_ENGINE_FULFILLED:
      return {
        ...state,
        rulesEngines: state.rulesEgines.map(rule =>
          rule.item_id !== action.newValue.item_id ? rule : action.newValue,
        ),
        isPending: false,
        message: 'Rule updated successfully!',
      };

    case DELETE_RULES_ENGINE_FULFILLED:
      return {
        ...state,
        rulesENgines: state.rulesEngines.filter(
          rule => rule.item_id !== action.item_id,
        ),
        isPending: false,
        message: 'Rule deleted successfully!',
      };

    case RECEIVED_ERROR:
      return {
        ...state,
        error: action.error,
        isPending: false,
      };
    case UPDATE_TEMPLATE_PREVIEW_DATA:
      return {
        ...state,
        templatePreviewData: { ...action.templatePreviewData },
      };

    case CLEAR_TEMPLATE_PREVIEW_DATA:
      return {
        ...state,
        templatePreviewData: {},
      };
    case UPDATE_LAST_MODIFIED_RULE:
      return {
        ...state,
        lastModifiedRule: { ...action.lastModifiedRule },
      };

    case UPDATE_CURRENTLY_SELECTED_ASSET:
      return {
        ...state,
        currentlySelectedAsset: {
          ...action.currentlySelectedAsset,
        },
      };

    case UPDATE_CURRENTLY_SELECTED_ASSET_TYPE:
      return {
        ...state,
        currentlySelectedAssetType: {
          ...action.currentlySelectedAssetType,
        },
      };

    case UPDATE_SELECTED_TAGS_DATA_FORMAT:
      return {
        ...state,
        currentlySelectedTagsDataFormat: {
          ...state.currentlySelectedTagsDataFormat,
          ...action.currentTagsInfo,
        },
      };

    case TRIGGER_ELEMENT_FOCUS:
      return {
        ...state,
        elementToFocus: action.elementToFocus,
      };

    default:
      return state;
  }
};
