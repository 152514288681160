import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';

import CustomSelect from '../../CustomSelect';
import { useAssets } from '../../../../../hooks/useAssets';
import CustomFormControl from '../../CustomFormControl';
import SensorSelector from '../ChartSelector/SensorSelector';
import CustomLabel from '../../CustomLabel';
import ColorMenu from '../../../../DigitalSensorsForm/ColorMenu';
import ErrorFeedbackP from '../../ErrorFeedbackP';
import { widgetIcons } from '../Snapshot';

const SnapshotSelector = ({
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  values,
  errors,
  touched,
  submitForm,
  location,
}) => {
  const { data: assets } = useAssets({ location_id: location });
  const { t } = useTranslation();

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={2} sx={{ width: '500px' }}>
        <Grid item xs={12}>
          <CustomFormControl
            id="title"
            label={t('atoms.widget.selector.title')}
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelect
            id="icon"
            label={t('atoms.widget.selector.icon')}
            options={Object.keys(widgetIcons).map(key => ({
              value: key,
              label: t(`atoms.widget.selector.options_snapshot.${key}`),
            }))}
            value={values.icon}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
            required
          />
        </Grid>
        <Grid
          item
          xs={6}
          justifyContent="flex-start"
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <CustomLabel required htmlFor="color">
            {t('components.digital_sensors_form.color')}
          </CustomLabel>
          <ColorMenu
            id="color"
            selectedColor={values.color}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            touched={touched}
            fullWidth
            colorOptions={{
              green: 'rgba(20, 145, 83, 0.8)',
              orange: 'rgba(211, 78, 50, 0.8)',
              lightblue: 'rgba(99, 145, 201, 0.8)',
              darkblue: 'rgba(24, 43, 84, 0.8)',
              purple: 'rgba(124, 42, 134, 0.8)',
              grey: 'rgba(140, 140, 140, 0.8)',
            }}
          />
          <ErrorFeedbackP error={errors.color} touched={touched.color} />
        </Grid>
        <Grid item xs={12}>
          <FieldArray
            name="items"
            render={arrayHelpers =>
              values.items?.map((item, index) => (
                <SensorSelector
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.sensor}-${index}`}
                  assets={assets}
                  values={values}
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  index={index}
                  isList
                />
              ))
            }
          />
        </Grid>
      </Grid>
    </form>
  );
};

SnapshotSelector.propTypes = {
  errors: PropTypes.any.isRequired,
  handleBlur: PropTypes.any.isRequired,
  handleChange: PropTypes.any.isRequired,
  setFieldValue: PropTypes.any.isRequired,
  setFieldTouched: PropTypes.any.isRequired,
  location: PropTypes.string.isRequired,
  submitForm: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  values: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    items: PropTypes.array,
  }).isRequired,
};

export default SnapshotSelector;
