/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_URI}`,
});

const TOKEN_KEY = '@corvalent-Token';
const REFRESH_TOKEN_KEY = '@corvalent-Refresh-Token';
const USER_KEY = 'user';
const LAST_SELECTED_LOCATION_KEY = 'lastSelectedLocation';
const ZOOM_KEY = 'zoom';

function checkAuth(token) {
  return instance.post('/api/v/1/user/checkauth', null, {
    headers: {
      'ClearBlade-SystemKey': `${process.env.REACT_APP_SYSTEM_KEY}`,
      'ClearBlade-UserToken': token,
    },
  });
}

function saveUserSession({ user_id, isLogin }) {
  return instance.post(
    `/${process.env.REACT_APP_WEBHOOK_URI_PATH}/${process.env.REACT_APP_SYSTEM_KEY}/user-session-log`,
    {
      user_id,
      action: isLogin ? 'Login' : 'Logout',
    },
    {
      headers: {
        'ClearBlade-SystemKey': `${process.env.REACT_APP_SYSTEM_KEY}`,
        'ClearBlade-UserToken': process.env.REACT_APP_SYSTEM_TOKEN,
      },
    },
  );
}

export async function authenticate(email, password) {
  const response = await instance.post(
    '/api/v/1/user/auth',
    {
      email,
      password,
    },
    {
      headers: {
        'ClearBlade-SystemKey': `${process.env.REACT_APP_SYSTEM_KEY}`,
        'ClearBlade-SystemSecret': `${process.env.REACT_APP_SYSTEM_SECRET}`,
      },
    },
  );
  setTokenValue(response.data.user_token);
  setRefreshTokenValue(response.data.refresh_token);
  await saveUserSession({
    user_id: response.data.user_id,
    isLogin: true,
  });
  return response.data;
}

export function refreshAuthenticate(refresh_token, access_token) {
  return instance.post(
    '/api/v/1/user/auth',
    {
      grant_type: 'refresh_token',
      refresh_token,
      access_token,
    },
    {
      headers: {
        'ClearBlade-SystemKey': `${process.env.REACT_APP_SYSTEM_KEY}`,
        'ClearBlade-SystemSecret': `${process.env.REACT_APP_SYSTEM_SECRET}`,
      },
    },
  );
}

export async function logout(resetUser) {
  const token = localStorage.getItem(TOKEN_KEY);
  const { user_id } = getUserCache();

  if (!token) {
    return null;
  }

  try {
    await saveUserSession({
      user_id,
    });

    const response = await instance.post('/api/v/1/user/logout', null, {
      headers: {
        'ClearBlade-UserToken': token,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
  } finally {
    removeTokenValue();
    removeRefreshTokenValue();
    resetUser?.();
  }
}

export function userInfo(userToken) {
  return instance.get('/api/v/1/user/info', {
    headers: {
      'ClearBlade-UserToken': userToken,
    },
  });
}

export function isAuthenticated() {
  const token = localStorage.getItem(TOKEN_KEY);

  if (!token) {
    localStorage.removeItem(TOKEN_KEY);
    return null;
  }

  return checkAuth(token)
    .then(res => {
      return res?.data ? res.data.is_authenticated : null;
    })
    .catch(() => {
      const refreshToken = getRefreshTokenValue();
      if (!refreshToken) return null;

      return refreshAuthenticate(refreshToken, token)
        .then(response => {
          setTokenValue(response.data.user_token);
          setRefreshTokenValue(response.data.refresh_token);
          return true;
        })
        .catch(authError => {
          console.error(authError);
          return null;
        });
    });
}

export function isAuthenticatedProm() {
  const token = localStorage.getItem(TOKEN_KEY);

  return new Promise((resolve, reject) => {
    if (!token) {
      localStorage.removeItem(TOKEN_KEY);
      reject(new Error('No token found!'));
    } else {
      checkAuth(token)
        .then(res => {
          resolve(res?.data?.is_authenticated ? token : false);
        })
        .catch(err => {
          localStorage.removeItem(TOKEN_KEY);
          localStorage.removeItem(REFRESH_TOKEN_KEY);
          reject(new Error(err));
        });
    }
  });
}

export function getZoomCache() {
  return JSON.parse(localStorage.getItem(ZOOM_KEY));
}

export function setZoomCache(zoom) {
  return localStorage.setItem(ZOOM_KEY, zoom);
}

export function removeZoomCache() {
  return localStorage.removeItem(ZOOM_KEY);
}

export function getLastSelectedLocationCache() {
  return JSON.parse(localStorage.getItem(LAST_SELECTED_LOCATION_KEY));
}

export function setLastSelectedLocationCache(location) {
  return localStorage.setItem(
    LAST_SELECTED_LOCATION_KEY,
    JSON.stringify(location),
  );
}

export function removeLastSelectedLocationCache() {
  return localStorage.removeItem(LAST_SELECTED_LOCATION_KEY);
}

export function getUserCache() {
  return JSON.parse(localStorage.getItem(USER_KEY));
}

export function setUserCache(user) {
  return localStorage.setItem(USER_KEY, JSON.stringify(user));
}

export function removeUserCache() {
  return localStorage.removeItem(USER_KEY);
}

export function getTokenValue() {
  return localStorage.getItem(TOKEN_KEY);
}

export function setTokenValue(token) {
  return localStorage.setItem(TOKEN_KEY, token);
}

export function removeTokenValue() {
  return localStorage.removeItem(TOKEN_KEY);
}

export function getRefreshTokenValue() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function setRefreshTokenValue(token) {
  return localStorage.setItem(REFRESH_TOKEN_KEY, token);
}

export function removeRefreshTokenValue() {
  return localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function resetPasswordByEmail({ email }) {
  return instance.post(
    `/${process.env.REACT_APP_WEBHOOK_URI_PATH}/${process.env.REACT_APP_SYSTEM_KEY}/reset-password`,
    { email },
    {
      headers: {
        'ClearBlade-UserToken': process.env.REACT_APP_SYSTEM_TOKEN,
      },
    },
  );
}

export function updatePassword({ password, uid }) {
  return instance.put(
    `/${process.env.REACT_APP_WEBHOOK_URI_PATH}/${process.env.REACT_APP_SYSTEM_KEY}/reset-password`,
    { password, uid },
    {
      headers: {
        'ClearBlade-UserToken': process.env.REACT_APP_SYSTEM_TOKEN,
      },
    },
  );
}

export function validateResetPasswordExpiredLink({ uid }) {
  return instance.get(
    `/${process.env.REACT_APP_WEBHOOK_URI_PATH}/${process.env.REACT_APP_SYSTEM_KEY}/reset-password`,
    {
      headers: {
        'ClearBlade-UserToken': process.env.REACT_APP_SYSTEM_TOKEN,
      },
      params: {
        uid,
      },
    },
  );
}

export const getAuthFeatures = () =>
  instance.get(
    `/${process.env.REACT_APP_WEBHOOK_URI_PATH}/${process.env.REACT_APP_SYSTEM_KEY}/features`,
    {
      headers: {
        'ClearBlade-UserToken': process.env.REACT_APP_SYSTEM_TOKEN,
      },
    },
  );
