import { api } from './api';

export const get = (url, params) => {
  return api.get(url, {
    params,
  });
};

export const put = (url, data, params) => {
  return api.put(url, data, {
    params,
  });
};

export const post = (url, data, params, headers) => {
  return api.post(url, data, {
    params,
    headers,
  });
};

export const del = (url, params) => {
  return api.delete(url, {
    params,
  });
};

export default api;
