/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import {
  primaryColor,
  assetItemBG,
  lightGrey,
  bgSecondary,
  wellListBackground,
  wellListBackgroundGradient,
} from '@themes';

export const LocationManagement = styled.div`
  background: linear-gradient(
    90deg,
    ${wellListBackground} 0%,
    ${wellListBackgroundGradient} 100%
  );
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 1rem;

  & header {
    margin: 1rem;
    margin-bottom: 0;
  }
`;

export const CVPrimary = styled.h6`
  color: ${primaryColor};
`;

export const HeaderItems = styled.div`
  margin: ${props => (props.$editing ? '0rem -1rem -1rem -1rem' : ' ')};
  padding: ${props => (props.$editing ? '.6rem 1rem 1rem 1rem' : ' ')};
  background-color: ${props => (props.$editing ? assetItemBG : ' ')};

  & label {
    font-size: 1.1rem;
  }
`;

export const NameContainer = styled.div`
  width: 100%;
`;

export const LocationNameInput = styled.input`
  height: 2rem;
  width: 100% !important;
  background-color: ${lightGrey};
  color: ${bgSecondary};
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border: none;
  border-radius: 0.2rem;
`;

export const IndustrySelectContainer = styled.div`
  margin-bottom: 1rem;
`;

export const IndustrySelect = styled.select`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

export const DnDAssetsList = styled.div`
  padding: 0 1rem 1rem 1rem;
  background-color: ${assetItemBG};
  margin-top: -1px;
`;

export const NewAssetBtn = styled.div`
  text-align: center;
`;
