/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useBuildingDetails } from '../../context/BuildingContext';
import { useModal } from '../../context/ModalContext';
import { useIsTabletOrMobile } from '../../hooks/useIsTabletOrMobile';
import * as S from './style';
import { useLocationsCacheContext } from '../../context/LocationsCacheContext';
import {
  closeAnimated,
  openAnimated,
} from '../../redux/actions/sideDrawerActions';
import StatusIcons from '../StatusIcons';
import FloorsList from '../FloorsList';

const search = `${process.env.REACT_APP_STORAGE_BASE_URL}/search-2.svg`;
const arrow = `${process.env.REACT_APP_STORAGE_BASE_URL}/arrow.svg`;

const mapStateToProps = store => ({
  drawerIsOpen: store.sideDrawerState.isOpen,
});

const mapDispatchToProps = dispatch => {
  return {
    closeAnimated: args => dispatch(closeAnimated(args)),
  };
};

const BuildingDetails = ({ drawerIsOpen, closeAnimated }) => {
  const { locations, shouldCollapse, setShouldCollapse } =
    useLocationsCacheContext();
  const { changeModalVisibility, modalVisibility } = useModal();
  const {
    buildingDetailsVisibility,
    changeSelectedBuilding,
    selectedBuilding,
    selectedFloor,
    changeBuildingDetailsVisibility,
    changeFloorDetailsVisibility,
    hideDrawers,
  } = useBuildingDetails();
  const isTabletOrMobile = useIsTabletOrMobile();
  const {
    location: { pathname },
  } = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedBuilding) openAnimated();
    if (selectedFloor) changeFloorDetailsVisibility(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (drawerIsOpen) {
      changeBuildingDetailsVisibility(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerIsOpen]);

  useEffect(() => {
    if (selectedBuilding) {
      const buildingsLocation = locations.filter(
        location => location.properties.location_type === 'building',
      );

      let filteredBuilding = {};

      buildingsLocation.map(buildingLoc =>
        buildingLoc.properties.buildings.forEach(build => {
          if (build.properties.item_id === selectedBuilding.properties.item_id)
            filteredBuilding = build;
        }),
      );
      changeSelectedBuilding(filteredBuilding);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  useEffect(() => {
    if (
      !window.location.pathname.includes('/portal/maps') ||
      window.location.pathname.includes('/facilities')
    ) {
      closeAnimated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  return (
    selectedBuilding &&
    buildingDetailsVisibility && (
      <S.BuildingDetails
        $hideDrawers={hideDrawers || !drawerIsOpen}
        data-testid="building-details"
        $openSide={buildingDetailsVisibility}
        $shouldCollapse={shouldCollapse}
        $floorSelected={!!selectedFloor}
      >
        {isTabletOrMobile && (
          <S.CollapseMenuButton
            onClick={() => setShouldCollapse(prev => !prev)}
            shouldCollapse={shouldCollapse}
          >
            <img src={arrow} alt="colapse menu" />
          </S.CollapseMenuButton>
        )}
        <S.BuildingDetailsContent
          $openSide={buildingDetailsVisibility}
          $shouldCollapse={shouldCollapse}
          $floorSelected={!!selectedFloor}
        >
          {isTabletOrMobile && (
            <S.GoBackItemContainer>
              <S.GoBackItem onClick={() => changeSelectedBuilding(null)}>
                <S.Chevron>
                  <i className="fas fa-chevron-left" aria-hidden="true" />
                </S.Chevron>
                {t('components.building_details.back_to')}
              </S.GoBackItem>
            </S.GoBackItemContainer>
          )}
          <h5>{selectedBuilding?.properties?.name}</h5>
          <S.AssetsInfo>
            <S.AssetsCount>
              {selectedBuilding?.properties?.assets_count > 0
                ? String(selectedBuilding?.properties?.assets_count).padStart(
                    2,
                    '0',
                  )
                : 0}
            </S.AssetsCount>
            {t('components.building_details.assets_count', {
              count: selectedBuilding?.properties?.assets_count,
            })}
          </S.AssetsInfo>
          {pathname.includes('maps') && (
            <S.Find
              onClick={() => changeModalVisibility(!modalVisibility)}
              id="find-an-asset-div"
            >
              <img src={search} alt="Search icon." />
              <p>{t('components.building_details.title')}</p>
            </S.Find>
          )}
          <S.AssetsStatus>
            <b>{t('components.building_details.status_title')}</b>
            <StatusIcons
              statusCount={selectedBuilding?.properties?.status_count}
            />
          </S.AssetsStatus>
          <FloorsList floorsList={selectedBuilding?.properties?.floors} />
          <S.Footer>{t('components.building_details.help')}</S.Footer>
        </S.BuildingDetailsContent>
      </S.BuildingDetails>
    )
  );
};

BuildingDetails.defaultProps = {
  drawerIsOpen: false,
  closeAnimated: () => {},
};

BuildingDetails.propTypes = {
  drawerIsOpen: PropTypes.bool,
  closeAnimated: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingDetails);
