/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: linear-gradient(90deg, #02112d 0%, #152648 100%);
  padding: 10px 10px 0 10px;
  margin-top: 1rem;

  & button {
    flex: 1;
    height: 35px;
    font-size: 12px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    &:last-child {
      margin-left: 10px;
    }
  }

  ${props =>
    !props.$visible &&
    css`
      display: none;
    `}
`;
