/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import PropTypes from 'prop-types';
import React from 'react';
import ChangeOwnPasswordModal from './ChangeOwnPasswordModal';
import AboutModal from './AboutModal';
import SelectorChartWidgetModal from './SelectorChartWidgetModal';
import SelectorSnapshotWidgetModal from './SelectorSnapshotWidgetModal';
import SelectorWeatherWidgetModal from './SelectorWeatherWidgetModal';
import DocumentsModal from '../DocumentsModal';
import ThresholdsModal from '../ThresholdsModal';
import CustomRange from '../CustomRangeModal';
import AssetPositionModal from '../AssetPositionModal';

const ModalFactory = ({ type, ...commonProps }) => {
  switch (type) {
    case 'changeOwnPassword':
      return <ChangeOwnPasswordModal {...commonProps} />;
    case 'aboutSystem':
      return <AboutModal {...commonProps} />;
    case 'selectorChartWidget':
      return <SelectorChartWidgetModal {...commonProps} />;
    case 'selectorSnapshotWidget':
      return <SelectorSnapshotWidgetModal {...commonProps} />;
    case 'selectorWeatherWidget':
      return <SelectorWeatherWidgetModal {...commonProps} />;
    case 'documentsModal':
      return (
        commonProps.modalShow && (
          <DocumentsModal
            avoid={['action-button-with-action', 'action-action']}
            onClickOutsideMobileOnly={false}
            {...commonProps}
          />
        )
      );
    case 'thresholds':
      return (
        commonProps.modalShow && (
          <ThresholdsModal
            avoid={['action-button-with-action', 'action-action']}
            onClickOutsideMobileOnly={false}
            {...commonProps}
          />
        )
      );
    case 'range':
      return (
        commonProps.modalShow && (
          <CustomRange avoid={['select-menu']} {...commonProps} />
        )
      );
    case 'assetPositionModal':
      return (
        commonProps.modalShow && (
          <AssetPositionModal
            avoid={['action-button-with-action', 'action-action']}
            onClickOutsideMobileOnly={false}
            {...commonProps}
          />
        )
      );
    default:
      return null;
  }
};

ModalFactory.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ModalFactory;
