import React, { createContext, useContext, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useMapPopUps } from '../hooks/useMapPopUps';
import { useGeneralContext } from './GeneralContext';
import { useLocationsCacheContext } from './LocationsCacheContext';
import { useIsTabletOrMobile } from '../hooks/useIsTabletOrMobile';

const MapPopUpsContext = createContext({ state: {}, actions: {} });

const MapPopUpsProvider = ({ children }) => {
  const [mapRemovedAssetsList, setMapRemovedAssetsList] = useState([]);
  const { user } = useGeneralContext();
  const { assets } = useLocationsCacheContext();
  const isTabletOrMobile = useIsTabletOrMobile();
  const history = useHistory();

  const isMapPage = useMemo(
    () =>
      history.location.pathname.includes('/portal/maps') &&
      !history.location.pathname.includes('/facilities'),
    [history.location.pathname],
  );

  const { isLoading, data, refetch } = useMapPopUps(
    user?.user_id,
    isTabletOrMobile,
    isMapPage,
  );

  const [showsMapPopUp, setShowsMapPopUp] = useState(!isTabletOrMobile);
  const mapPopUpsIdList = useMemo(() => data?.[0]?.list, [data]);
  const tableId = useMemo(() => data?.[0]?.item_id, [data]);
  const mapPopUpsAssetList = useMemo(
    () =>
      mapPopUpsIdList?.map(item => {
        const assetItem = assets.find(
          asset => item.asset_id === asset.properties.item_id,
        );
        if (!assetItem)
          setMapRemovedAssetsList(prev =>
            Array.from(new Set([...prev, item.asset_id])),
          );

        return (
          assetItem && {
            ...assetItem.properties,
            popUpPosition: item.popUpPosition,
          }
        );
      }),
    [assets, mapPopUpsIdList],
  );

  const values = useMemo(
    () => ({
      isLoading,
      mapPopUpsAssetList,
      mapPopUpsIdList,
      mapRemovedAssetsList,
      tableId,
      refetch,
      showsMapPopUp,
      setShowsMapPopUp,
    }),
    [
      isLoading,
      mapPopUpsAssetList,
      mapPopUpsIdList,
      mapRemovedAssetsList,
      tableId,
      refetch,
      showsMapPopUp,
      setShowsMapPopUp,
    ],
  );

  return (
    <MapPopUpsContext.Provider value={values}>
      {children}
    </MapPopUpsContext.Provider>
  );
};

export const useMapPopUpsContext = () => {
  return useContext(MapPopUpsContext);
};

MapPopUpsProvider.defaultProps = {
  children: null,
};

MapPopUpsProvider.propTypes = {
  children: PropTypes.element,
};

export default MapPopUpsProvider;
