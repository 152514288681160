import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { List, useTheme, Box } from '@mui/material';
import {
  AccountBox,
  Assessment,
  Build,
  Dashboard,
  Help,
  Info,
  Inventory,
  Map,
  Settings,
} from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomListItemButton from '../../../shared/atoms/CustomListItemButton';
import existChildPermissions from '../../../../utils/existChildPermissions';
import { useRolePermissions } from '../../../../hooks/useRolePermissions';
import { useIsTabletOrMobile } from '../../../../hooks/useIsTabletOrMobile';
import { closeAnimated } from '../../../../redux/actions/sideDrawerActions';
import CustomTooltip from '../../../shared/atoms/CustomTooltip';
import { useModalFactory } from '../../../../context/ModalFactoryContext';

const MenuOptions = ({ smallHeight }) => {
  const history = useHistory();
  const location = useLocation();
  const { openModal } = useModalFactory();
  const { data: userPermissions } = useRolePermissions();
  const isTabletOrMobile = useIsTabletOrMobile();
  const isDrawerOpen = useSelector(store => store.sideDrawerState.isOpen);
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const closeAnimatedDispatch = useCallback(
    args => dispatch(closeAnimated(args)),
    [dispatch],
  );

  const dropdownItems = useMemo(
    () => [
      {
        title: t('tools_menu.dashboard'),
        action: () => {
          closeAnimatedDispatch();
          history.push('/portal/dashboard');
        },
        visibility: true,
        icon: (
          <Dashboard
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected: location.pathname.includes('dashboard'),
      },
      {
        title: t('tools_menu.map'),
        action: () => history.push('/portal/maps'),
        visibility: true,
        icon: (
          <Map
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected:
          location.pathname.includes('/portal/maps') &&
          !location.pathname.includes('/facilities'),
      },
      {
        title: t('tools_menu.management'),
        action: () => {
          closeAnimatedDispatch();
          history.push('/portal/management');
        },
        visibility:
          userPermissions?.menu?.management?.see &&
          existChildPermissions(userPermissions?.management) &&
          !isTabletOrMobile,
        icon: (
          <Inventory
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected:
          location.pathname.includes('management') &&
          !location.pathname.includes('tickets'),
      },
      {
        title: t('tools_menu.user_management'),
        action: () => {
          closeAnimatedDispatch();
          history.push('/portal/management');
        },
        visibility:
          userPermissions?.menu?.management?.see &&
          existChildPermissions(userPermissions?.management) &&
          userPermissions?.management?.users &&
          isTabletOrMobile,
        icon: (
          <Inventory
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected:
          location.pathname.includes('management') &&
          !location.pathname.includes('tickets'),
      },
      {
        title: t('tools_menu.service_tickets'),
        action: () => {
          closeAnimatedDispatch();
          history.push('/portal/management/tickets');
        },
        visibility: userPermissions?.management?.['service-tickets']?.see,
        icon: (
          <Build
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected: location.pathname.includes('tickets'),
      },
      {
        title: t('tools_menu.create_report'),
        action: () => {
          closeAnimatedDispatch();
          history.push('/portal/reports');
        },
        visibility: userPermissions?.menu?.report?.see,
        icon: (
          <Assessment
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected: location.pathname.includes('reports'),
      },
      {
        title: t('tools_menu.change_password'),
        action: () => openModal('changeOwnPassword'),
        visibility: userPermissions?.menu?.report?.see,
        icon: (
          <AccountBox
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected: false,
      },
      {
        title: t('tools_menu.configuration'),
        action: () => {
          closeAnimatedDispatch();
          history.push('/portal/settings');
        },
        visibility:
          userPermissions?.menu?.configuration?.see &&
          existChildPermissions(userPermissions?.settings),
        icon: (
          <Settings
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected: location.pathname.includes('settings'),
      },
      {
        title: t('tools_menu.about'),
        action: () => openModal('aboutSystem'),
        visibility: userPermissions?.menu?.report?.see,
        icon: (
          <Info
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected: false,
      },
      {
        title: t('tools_menu.help'),
        action: () => window.open(process.env.REACT_APP_MANUAL_URL),
        visibility: userPermissions?.menu?.report?.see,
        icon: (
          <Help
            sx={{
              color: 'white',
              fontSize: 24,
              [theme.breakpoints.down('sm')]: {
                fontSize: 20,
              },
            }}
          />
        ),
        selected: false,
      },
    ],
    [
      closeAnimatedDispatch,
      history,
      isTabletOrMobile,
      location.pathname,
      openModal,
      t,
      theme.breakpoints,
      userPermissions?.management,
      userPermissions?.menu?.configuration?.see,
      userPermissions?.menu?.management?.see,
      userPermissions?.menu?.report?.see,
      userPermissions?.settings,
    ],
  );

  return (
    <List>
      {dropdownItems
        .filter(item => item.visibility)
        .map(item => (
          <CustomTooltip
            key={item.title}
            title={!isDrawerOpen && item.title}
            placement="right"
          >
            <Box>
              <CustomListItemButton
                icon={item.icon}
                text={item.title}
                onClick={item.action}
                selected={item.selected}
                smallHeight={smallHeight}
                paddingLeft={isDrawerOpen ? null : '1.5rem'}
              />
            </Box>
          </CustomTooltip>
        ))}
    </List>
  );
};

MenuOptions.propTypes = {
  smallHeight: PropTypes.bool.isRequired,
};

export default MenuOptions;
