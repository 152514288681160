/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import * as S from './style';
import { withClickOutside } from '../Wrappers/WithClickOutside';
import DocumentItem from './DocumentItem';

const DocumentsModal = forwardRef(
  ({ title, isSensorOnly, docsOrigin, onClose }, ref) => {
    const [assetDoc, setAssetDoc] = useState(null);
    const [sensorsDocs, setSensorsDocs] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
      if (docsOrigin && !isSensorOnly) {
        setAssetDoc(docsOrigin?.properties?.documentation);
        setSensorsDocs(
          docsOrigin?.properties?.sensors?.filter(
            sensor => sensor?.documentation !== null,
          ),
        );
      } else setSensorsDocs(docsOrigin?.documentation ? [docsOrigin] : []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docsOrigin]);

    return (
      <S.Container
        ref={ref}
        className="animate__animated animate__fadeIn animate__faster"
        id="doc-modal-container"
      >
        <S.Header>
          <h6>{title}</h6>
        </S.Header>
        <S.Content>
          <S.ContentBody>
            {!assetDoc && !sensorsDocs.length && (
              <S.EmptyList>
                <p>
                  {t(
                    'machines.facilities.center_pane.modal.documentation.validation.no_doc',
                  )}
                </p>
              </S.EmptyList>
            )}
            {assetDoc && (
              <DocumentItem
                assetTypeId={docsOrigin?.properties?.asset_type}
                title={t(
                  'machines.facilities.center_pane.modal.documentation.title',
                  { message_type: 'asset' },
                )}
                docs={[assetDoc]}
              />
            )}
            {sensorsDocs?.map(sensor => (
              <DocumentItem
                key={sensor.item_id}
                isSensorOnly={isSensorOnly}
                sensorTypeId={sensor.sensor_type_id}
                title={t(
                  'machines.facilities.center_pane.modal.documentation.title',
                  { message_type: 'sensor', name: sensor.name },
                )}
                docs={[sensor.documentation]}
              />
            ))}
          </S.ContentBody>
        </S.Content>
        <S.Footer>
          <button type="button" onClick={onClose}>
            {t('modal.close')}
          </button>
        </S.Footer>
      </S.Container>
    );
  },
);

DocumentsModal.defaultProps = {
  isSensorOnly: false,
};

DocumentsModal.propTypes = {
  title: PropTypes.string.isRequired,
  docsOrigin: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  isSensorOnly: PropTypes.bool,
};

DocumentsModal.displayName = 'DocumentsModal';

export default withClickOutside(DocumentsModal);
