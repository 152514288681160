/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { corvalentGreen } from '../../themes';

const backgroundStyle = css`
  display: inline-block;
  line-height: 1.25;
  padding-inline: 1rem;
  opacity: 0.9;
  width: max-content;
`;

export const TextValue = styled.span`
  background-color: ${props => props.$color};
  border-radius: 2px;
  ${props => props.$textBackground && backgroundStyle}
`;

export const IconValue = styled.i`
  &::after {
    font-family: 'Font Awesome 5 Free';
    content: '${props => `\\${props.$unicode}`}';
    font-weight: 900;
  }
  font-style: normal;
  color: ${props => props.$color};
`;

export const NotConfigured = styled.div`
  font-size: 1rem;
  line-height: 1.6;
`;

export const CustomLink = styled(Link)`
  color: ${corvalentGreen.default};
`;
