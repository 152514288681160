/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { LocationOff } from '@mui/icons-material';

import { updateLocationToFlyId } from '../../../redux/actions/assetsActions';
import { openAnimated } from '../../../redux/actions/sideDrawerActions';
import Location from './Location';
import LocationManagement from '../LocationManagement';
import Spinner from '../../shared/atoms/Spinner';
import * as S from './style';
import { useGeneralContext } from '../../../context/GeneralContext';
import { useLocationsCacheContext } from '../../../context/LocationsCacheContext';
import { useRolePermissions } from '../../../hooks/useRolePermissions';

const LocationWrapper = ({
  editing,
  setEditing,
  featureCollection,
  ...props
}) => {
  const { assets } = useLocationsCacheContext();

  return (
    <LocationManagement
      name={editing.name}
      editing={editing}
      clearEditing={() => setEditing(null)}
      locationIndustry={editing.industry ? editing.industry : ''}
      assetsRedux={(assets || []).filter(
        a =>
          a.properties.location_id === editing.item_id &&
          !a.properties.has_location,
      )}
      featureCollection={featureCollection}
      {...props}
    />
  );
};

LocationWrapper.propTypes = {
  editing: PropTypes.shape({
    industry: PropTypes.any,
    item_id: PropTypes.any,
    name: PropTypes.any,
  }).isRequired,
  featureCollection: PropTypes.any.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const Locations = ({
  creationContext,
  pushPage,
  stackSize,
  modalInputText,
  visible,
  popPage,
}) => {
  const dispatch = useDispatch();
  const updateLocationToFlyIdDispatch = useCallback(
    locationId => dispatch(updateLocationToFlyId(locationId)),
    [dispatch],
  );
  const { t } = useTranslation();
  const { data: userPermissions } = useRolePermissions();

  const { locations, assets, isLoading, refetch } = useLocationsCacheContext();
  const isDrawerOpen = useSelector(store => store.sideDrawerState.isOpen);
  const locationToFlyId = useSelector(
    store => store.assetsState.locationToFlyId,
  );

  const [editing, setEditing] = useState(null);
  const [featureCollection, setFeatureCollection] = useState(null);
  const { sideMenuForm, setSideMenuForm } = useGeneralContext();

  const createFeatureCollectionByLocationId = useCallback(
    locationId => {
      const data = window.map.getSource('locations')?._data;
      if (data) {
        const ft = data.features.filter(
          feature => feature.properties.item_id === locationId,
        );
        const assetsFt = (assets || []).filter(
          a =>
            a.properties.has_location &&
            a.properties.location_id === locationId,
        );
        const fts = ft.concat(assetsFt);

        setFeatureCollection({
          type: 'FeatureCollection',
          features: fts,
        });
      }
    },
    [assets],
  );

  const locationEditor = useCallback(
    locationID => {
      if (!locationID) {
        setEditing(null);
        setSideMenuForm(false);
      } else {
        const locationToEdit = locations.find(
          loc => loc.properties.item_id === locationID,
        )?.properties;
        setEditing(locationToEdit);
        createFeatureCollectionByLocationId(locationToEdit?.item_id);
        setSideMenuForm(true);
      }
    },
    [createFeatureCollectionByLocationId, locations, setSideMenuForm],
  );

  useEffect(() => {
    if (locationToFlyId) {
      window.map.once('idle', () => {
        locationEditor(locationToFlyId);
      });
    }
  }, [locationEditor, locationToFlyId]);

  useEffect(() => {
    if (!sideMenuForm && stackSize > 1) {
      setEditing(null);
      setFeatureCollection(null);
      popPage();
    }
  }, [popPage, sideMenuForm, stackSize]);

  useEffect(() => {
    if (creationContext === 2 && stackSize === 1) {
      pushPage(props => (
        <LocationManagement name={modalInputText} {...props} />
      ));
    } else if (featureCollection && editing) {
      refetch();
      if (stackSize === 1) {
        pushPage(props => (
          <LocationWrapper
            editing={editing}
            setEditing={setEditing}
            featureCollection={featureCollection}
            {...props}
            popPage={() => {
              setEditing(null);
              setFeatureCollection(null);
              props.popPage();
            }}
          />
        ));
      }
      dispatch(openAnimated());
      updateLocationToFlyIdDispatch(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creationContext, featureCollection, editing]);

  if (isLoading)
    return (
      <Stack
        flex={1}
        justifyContent="center"
        sx={{ transform: isDrawerOpen ? 'none' : 'scale(0.6)' }}
      >
        <Spinner />
      </Stack>
    );

  if (locations?.length === 0 && !isDrawerOpen) {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center">
        <LocationOff fontSize="large" />
      </Stack>
    );
  }

  if (locations?.length === 0) {
    return (
      <S.AssetsList>
        <S.LocationsListEmpty>
          {userPermissions?.map?.location?.manage ? (
            <S.DefaultMessage>
              <div>{t('components.locations.validation.click_1')}</div>
              <S.ButtonPlusLocation>+</S.ButtonPlusLocation>
              <div>{t('components.locations.validation.click_2')}</div>
            </S.DefaultMessage>
          ) : (
            <S.DefaultMessage>
              {t('components.locations.validation.no_locations_available')}
            </S.DefaultMessage>
          )}
        </S.LocationsListEmpty>
      </S.AssetsList>
    );
  }

  return (
    <S.AssetsList $visible={visible}>
      <S.LocationListing>
        {locations.map(location => (
          <Location
            key={location.properties.item_id}
            location={location}
            subItems={
              location.properties.buildings
                ? location.properties.buildings
                : (assets || []).filter(
                    a =>
                      a.properties.location_id ===
                        location.properties.item_id &&
                      a.properties.has_location,
                  )
            }
            subItemsType={
              location.properties.buildings ? 'buildings' : 'assets'
            }
            onEdit={locationEditor}
            shouldFly
          />
        ))}
      </S.LocationListing>
    </S.AssetsList>
  );
};

Locations.defaultProps = {
  creationContext: null,
  pushPage: () => ({}),
  stackSize: 0,
  modalInputText: '',
  filter: '',
};

Locations.propTypes = {
  creationContext: PropTypes.number,
  pushPage: PropTypes.func,
  stackSize: PropTypes.number,
  modalInputText: PropTypes.string,
  filter: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  popPage: PropTypes.func.isRequired,
};

export default Locations;
