/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
/* eslint-disable react/prop-types */

import React, { useRef, useEffect } from 'react';

const clickHandler = (e, callback) => {
  callback?.(e);
};

const useWithClickOutside = (
  ref,
  onClickOutside,
  onClickOutsideMobileOnly,
  avoid,
) => {
  useEffect(() => {
    const handleOutsideClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        if (avoid && !avoid.includes(e.target.id)) {
          if (onClickOutsideMobileOnly) {
            if (window.matchMedia('(max-width: 768px)').matches) {
              clickHandler(e, onClickOutside);
            }
          } else {
            clickHandler(e, onClickOutside);
          }
        }
      }
    };

    document.addEventListener('click', handleOutsideClick, true);

    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export const withClickOutside = WrappedComponent => props => {
  const componentRef = useRef(null);
  useWithClickOutside(
    componentRef,
    props.onClickOutside,
    props.onClickOutsideMobileOnly,
    props.avoid,
  );

  return <WrappedComponent ref={componentRef} {...props} />;
};
