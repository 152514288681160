/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { ThreeDots } from 'react-loader-spinner';
import PropTypes from 'prop-types';

import { spinnerCollors } from '../../../../themes';
import * as S from './style';

const SpinnerComponent = ({ changecolor, noTestId }) => {
  const theme = useTheme();
  return (
    <S.LoaderPlace data-testid={!noTestId && 'loadingSpinner'}>
      <ThreeDots
        ariaLabel="three-dots-loading"
        color={changecolor ? spinnerCollors[theme?.industry] : '#ffffff'}
        height={70}
        width={70}
      />
    </S.LoaderPlace>
  );
};

SpinnerComponent.defaultProps = {
  changecolor: null,
  noTestId: false,
};

SpinnerComponent.propTypes = {
  changecolor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noTestId: PropTypes.bool,
};

const Spinner = memo(SpinnerComponent, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});

export default Spinner;
