import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { v4 as uuidV4 } from 'uuid';
import mqtt from 'mqtt';
import { getTokenValue } from '../axios/axiosAuth';
import { useGeneralContext } from './GeneralContext';

const MQTTContext = createContext();

const transformWsUrl = (url, options, client) => {
  client.options.username = getTokenValue();
  client.options.clientId = uuidV4();
  return url;
};

const Provider = ({ children }) => {
  const { user } = useGeneralContext();
  const [connected, setConnected] = useState(false);
  const mqttClient = useRef(null);

  useEffect(() => {
    const hasToken = getTokenValue();

    if (hasToken && !mqttClient.current) {
      try {
        mqttClient.current = mqtt.connect(`${process.env.REACT_APP_MQTT_URL}`, {
          clientId: uuidV4(),
          username: getTokenValue(),
          password: `${process.env.REACT_APP_SYSTEM_KEY}`,
          port: 8904,
          transformWsUrl,
        });

        mqttClient.current.on('connect', () => {
          setConnected(true);
        });

        mqttClient.current.on('error', error => {
          console.error('mqttClient error', error);
          setConnected(false);
        });
        mqttClient.current.on('offline', () => {
          setConnected(false);
        });
        mqttClient.current.on('end', () => {
          setConnected(false);
        });
        mqttClient.current.on('disconnect', () => {
          setConnected(false);
        });
        mqttClient.current.on('reconnect', () => {});
        mqttClient.current.on('close', () => {
          setConnected(false);
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (hasToken && mqttClient.current?.disconnected) {
      mqttClient.current?.reconnect();
    }

    if (!hasToken && mqttClient.current) {
      mqttClient.current?.end();
    }

    return () => {
      mqttClient.current?.end();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const disconnect = useCallback(() => {
    mqttClient.current?.end();
  }, []);

  const connect = useCallback(() => {
    mqttClient.current?.reconnect();
  }, []);

  const value = useMemo(
    () => ({ connected, mqttClient: mqttClient.current, disconnect, connect }),
    [connect, connected, disconnect],
  );

  return <MQTTContext.Provider value={value}>{children}</MQTTContext.Provider>;
};

export const useMQTTContext = () => useContext(MQTTContext);

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Provider;
