import { del, get, post, put } from '../axios/axiosGateway';

export const getServiceLogs = params => {
  return get('/service-logs', params);
};

export const getNextNumber = () => {
  return get('/service-logs', { returnIndex: true });
};

export const postServiceLog = data => {
  return post('/service-logs', data);
};

export const putServiceLog = (data, params) => {
  return put('/service-logs', data, params);
};

export const delServiceLog = params => {
  return del('/service-logs', params);
};
