import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

const useTimeRangeOptions = () => {
  const { t } = useTranslation();

  const timeRanges = useMemo(
    () => ({
      custom: {
        key: 'custom',
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.custom',
        ),
      },
      '5_minutes': {
        key: '5_minutes',
        time: 300,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'minutes', time: '5' },
        ),
        start: () =>
          new Date(
            new Date().setMinutes(new Date().getMinutes() - 5),
          ).toISOString(),
        end: () => new Date().toISOString(),
      },
      '15_minutes': {
        key: '15_minutes',
        time: 900,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'minutes', time: '15' },
        ),
        start: () =>
          new Date(
            new Date().setMinutes(new Date().getMinutes() - 15),
          ).toISOString(),
        end: () => new Date().toISOString(),
      },
      '30_minutes': {
        key: '30_minutes',
        time: 1800,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'minutes', time: '30' },
        ),
        start: () =>
          new Date(
            new Date().setMinutes(new Date().getMinutes() - 30),
          ).toISOString(),
        end: () => new Date().toISOString(),
      },
      '1_hour': {
        key: '1_hour',
        time: 3600,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'hours', time: '1' },
        ),
        start: () =>
          new Date(
            new Date().setHours(new Date().getHours() - 1),
          ).toISOString(),
        end: () => new Date().toISOString(),
      },
      '3_hours': {
        key: '3_hours',
        time: 10800,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'hours', time: '3' },
        ),
        start: () =>
          new Date(
            new Date().setHours(new Date().getHours() - 3),
          ).toISOString(),
        end: () => new Date().toISOString(),
      },
      '6_hours': {
        key: '6_hours',
        time: 21600,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'hours', time: '6' },
        ),
        start: () =>
          new Date(
            new Date().setHours(new Date().getHours() - 6),
          ).toISOString(),
        end: () => new Date().toISOString(),
      },
      '12_hours': {
        key: '12_hours',
        time: 43200,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'hours', time: '12' },
        ),
        start: () =>
          new Date(
            new Date().setHours(new Date().getHours() - 12),
          ).toISOString(),
        end: () => new Date().toISOString(),
      },
      '24_hours': {
        key: '24_hours',
        time: 86400,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'hours', time: '24' },
        ),
        start: () =>
          new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
        end: () => new Date().toISOString(),
      },
      '2_days': {
        key: '2_days',
        time: 172800,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'days', time: '2' },
        ),
        start: () =>
          new Date(new Date().setDate(new Date().getDate() - 2)).toISOString(),
        end: () => new Date().toISOString(),
      },
      '7_days': {
        key: '7_days',
        time: 604800,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'days', time: '7' },
        ),
        start: () =>
          new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
        end: () => new Date().toISOString(),
      },
      '30_days': {
        key: '30_days',
        time: 2592000,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'days', time: '30' },
        ),
        start: () =>
          new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
        end: () => new Date().toISOString(),
      },
      '45_days': {
        key: '45_days',
        time: 3888000,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'days', time: '45' },
        ),
        start: () =>
          new Date(new Date().setDate(new Date().getDate() - 45)).toISOString(),
        end: () => new Date().toISOString(),
      },
      '90_days': {
        key: '90_days',
        time: 7776000,
        text: t(
          'components.generic_table_filter_header.filter.time_range.state.pattern',
          { message_type: 'days', time: '90' },
        ),
        start: () =>
          new Date(new Date().setDate(new Date().getDate() - 90)).toISOString(),
        end: () => new Date().toISOString(),
      },
    }),
    [t],
  );

  return { timeRanges };
};

export default useTimeRangeOptions;
