/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import * as S from './style';

const StackNavigation = ({ setHideDrawers, ...props }) => {
  const { t } = useTranslation();
  const [pageStack, setPageStack] = useState([props.InitialPage]);
  const [CurrentComponent, setCurrentComponent] = useState(
    () => props.InitialPage,
  );
  const [index, setIndex] = useState(0);
  const getCurrentIndex = () => ({ index });
  const goToPage = ind => {
    if (!ind) throw Error('Invalid index');
    if (ind > pageStack.length - 1) throw Error('Index is bigger than stack');

    setCurrentComponent(() => pageStack[ind]);
  };

  const pushPage = useCallback(
    Page => {
      setPageStack(pageStack.concat(Page));
      setIndex(index + 1);
      setCurrentComponent(() => Page);
      setHideDrawers(true);
    },
    [index, pageStack, setHideDrawers],
  );

  const popPage = () => {
    if (props.onPopPage) props.onPopPage({ next: index - 1, current: index });

    if (index === 0) return;

    const component = pageStack[index - 1];

    setCurrentComponent(() => component);
    setPageStack(pageStack.slice(0, index));
    setIndex(index - 1);
    setHideDrawers(false);
  };

  return (
    <>
      <div>
        {!props.dismiss && index === 0 ? null : (
          <S.StackControls>
            <Button
              variant="text"
              onClick={popPage}
              sx={{ padding: '0 10px 0 0', color: 'white' }}
            >
              <ChevronLeft fontSize="large" color="primary" />
              {t('tools_menu.back')}
            </Button>
          </S.StackControls>
        )}
      </div>
      {pageStack.map(Page => (
        <Page
          key={Page.name}
          visible={Page === CurrentComponent}
          getCurrentIndex={getCurrentIndex}
          goToPage={goToPage}
          pushPage={pushPage}
          popPage={popPage}
          stackSize={pageStack.length}
          {...props}
        />
      ))}
    </>
  );
};

StackNavigation.defaultProps = {
  dismiss: null,
};

StackNavigation.propTypes = {
  InitialPage: PropTypes.func.isRequired,
  dismiss: PropTypes.func,
  onPopPage: PropTypes.func.isRequired,
  setHideDrawers: PropTypes.func.isRequired,
};

export default StackNavigation;
