/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import ModalWithActions from '../../ModalWithActions';
import SnapshotSelector from '../../shared/atoms/Widget/SnapshotSelector';

const SelectorSnapshotWidgetModal = ({
  modalShow,
  onSenderModalHide,
  onSubmit,
  current,
  ...props
}) => {
  const formRef = useRef();
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(true);

  const validateForm = vals => {
    const errors = {};

    if (!vals.title.length) errors.title = t('validation.required');
    if (!vals.icon.length) errors.icon = t('validation.required');
    if (!vals.color.length) errors.color = t('validation.required');
    errors.items = [];
    vals.items.forEach((item, index) => {
      errors.items.push({});
      if (!item.asset) {
        errors.items[index].asset = t('validation.required');
      }
      if (!item.sensor) {
        errors.items[index].sensor = t('validation.required');
      }
    });

    if (
      errors.title ||
      errors.icon ||
      errors.color ||
      errors.items.some(item => item.asset || item.sensor)
    ) {
      setIsValid(false);
    } else {
      delete errors.items;
      setIsValid(true);
    }

    return errors;
  };

  return (
    <ModalWithActions
      show={modalShow}
      modalTitle={t('atoms.widget.selector.header_snapshot')}
      modalText=""
      action={() => formRef.current?.handleSubmit()}
      onHide={onSenderModalHide}
      btActionDisabled={!isValid}
      inputComponent={
        <Formik
          innerRef={formRef}
          initialValues={{
            title: current?.title || '',
            icon: current?.icon || '',
            color: current?.color || '',
            items: current?.items || [{ asset: '', sensor: '' }],
          }}
          validateOnBlur
          validateOnChange
          validate={validateForm}
          enableReinitialize={false}
          onSubmit={onSubmit}
        >
          {formikProps => <SnapshotSelector {...formikProps} {...props} />}
        </Formik>
      }
    />
  );
};

SelectorSnapshotWidgetModal.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  current: PropTypes.object.isRequired,
  onSenderModalHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SelectorSnapshotWidgetModal;
