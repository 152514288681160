/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import {
  IS_LOADING,
  SERVICE_LOG_FETCH,
  UPDATED_SERVICE_LOG_QUERY,
  SERVICE_LOG_CREATE_LOG,
  SERVICE_LOG_UPDATE_LOG,
  SERVICE_LOG_DELETE_LOG,
  RECEIVED_ERROR,
  UNLOAD,
} from '../actions/actionTypes';

const initialState = {
  serviceLogs: [],
  serviceLogQueryParams: {},
  isLoading: false,
  isError: false,
};

export const serviceLogsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case SERVICE_LOG_FETCH:
      return {
        ...state,
        serviceLogs: action.newValue,
        isLoading: false,
      };

    case UPDATED_SERVICE_LOG_QUERY:
      return {
        ...state,
        serviceLogQueryParams: action.newValue,
      };

    case SERVICE_LOG_CREATE_LOG:
      return {
        ...state,
        serviceLogs: (state.logs || []).concat([action.newValue]),
        isLoading: false,
      };

    case SERVICE_LOG_UPDATE_LOG:
      return {
        ...state,
        [action.key]: action.newValue,
        isLoading: false,
      };

    case SERVICE_LOG_DELETE_LOG:
      // eslint-disable-next-line no-case-declarations
      const serviceLogId = action.item_id;

      return {
        ...state,
        serviceLogs: state.serviceLogs.filter(
          log => log.item_id !== serviceLogId,
        ),
        isLoading: false,
      };

    case UNLOAD:
      return {
        serviceLogs: [],
        isLoading: false,
        isError: false,
      };

    case RECEIVED_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};
