import { useQuery } from '@tanstack/react-query';
import { getMapPopUps } from '../services/mapPopUpService';

const getMapPopUpsFromDB = async userId => {
  const {
    data: { results },
  } = await getMapPopUps({
    user: userId,
  });
  return results || [];
};

export const useMapPopUps = (userId, isTabletOrMobile, isMapPage) => {
  return useQuery({
    queryKey: ['mapPopUps'],
    queryFn: () => getMapPopUpsFromDB(userId),
    staleTime: 0,
    enabled: !isTabletOrMobile && !!userId && isMapPage,
  });
};
