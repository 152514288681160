import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

import CustomToast from '../components/CustomToast';
import {
  getLastSelectedLocationCache,
  getTokenValue,
  getUserCache,
  getZoomCache,
  logout,
  removeUserCache,
  setLastSelectedLocationCache,
  setUserCache,
  setZoomCache,
  userInfo,
} from '../axios/axiosAuth';
import Loader from '../components/shared/molecules/Loader';

const GeneralContext = createContext();

const Provider = ({ children }) => {
  const [authenticatedUser, setAuthenticatedUser] = useState(getUserCache());
  const [showPredictive, setShowPredictive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastSelectedLocation, setLastSelectedLocation] = useState(
    getLastSelectedLocationCache(),
  );
  const [zoom, setZoom] = useState(getZoomCache() || 6);
  const [toast, setToast] = useState({
    message: '',
    show: false,
    severity: 'info',
  });
  const [reportData, setReportData] = useState(null);
  const [sideMenuForm, setSideMenuForm] = useState(false);

  const openToast = (message, severity = 'info') => {
    setToast(prev => ({
      ...prev,
      show: false,
    }));
    setToast({
      message,
      severity,
      show: true,
    });
  };
  const closeToast = () => setToast(prev => ({ ...prev, show: false }));

  const setUser = user => {
    setUserCache(user);
    setAuthenticatedUser(user);
  };

  const resetUser = () => {
    removeUserCache();
    setAuthenticatedUser(null);
  };

  const setLastSelectedLoc = location => {
    setLastSelectedLocationCache(location);
    setLastSelectedLocation(location);
  };

  const setMapZoom = zoom => {
    setZoomCache(zoom);
    setZoom(zoom);
  };

  const values = useMemo(
    () => ({
      user: authenticatedUser,
      setUser,
      resetUser,
      openToast,
      closeToast,
      showPredictive,
      setShowPredictive,
      loading,
      setLoading,
      setLastSelectedLocation: setLastSelectedLoc,
      lastSelectedLocation,
      setMapZoom,
      zoom,
      reportData,
      setReportData,
      sideMenuForm,
      setSideMenuForm,
    }),
    [
      authenticatedUser,
      lastSelectedLocation,
      loading,
      reportData,
      showPredictive,
      sideMenuForm,
      zoom,
    ],
  );

  useEffect(() => {
    const token = getTokenValue();
    if (!authenticatedUser && token) {
      setLoading(true);
      userInfo(token)
        .then(res => {
          setUser(res.data);
        })
        .catch(() => {
          logout(resetUser);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (authenticatedUser && !token) {
      resetUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GeneralContext.Provider value={values}>
        {children}
      </GeneralContext.Provider>
      <Loader open={loading} />
      <CustomToast
        data-testid="toast"
        isError={toast.severity === 'error'}
        isWarning={toast.severity === 'warning'}
        show={toast.show}
        message={toast.message}
        autohide
        onToastClose={() => setToast(prev => ({ ...prev, show: false }))}
        topProp={100}
      />
    </>
  );
};

export const useGeneralContext = () => useContext(GeneralContext);

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Provider;
