/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import { primaryColor, secondaryColor } from '@themes';

export const Subtitle = styled.div`
  background-color: ${secondaryColor};
  width: 100%;
  height: 2rem;
  padding: 0.4rem 1rem 1rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
`;

export const DocsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  & + & {
    border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe;
  }
`;

export const DocItem = styled.button`
  color: ${primaryColor};
  height: 3rem;
  padding: 0 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  transition: color 0.2s;
  border: none;

  &:hover {
    cursor: pointer;
    color: ${secondaryColor};
  }
`;
