import React, { useMemo } from 'react';
import { PowerSettingsNew, Sensors } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { statusColorObj } from '../../../../themes';
import CustomTooltip from '../../../shared/atoms/CustomTooltip';
import { useLocationsCacheContext } from '../../../../context/LocationsCacheContext';

const SensorCounter = () => {
  const { t } = useTranslation();
  const isDrawerOpen = useSelector(store => store.sideDrawerState.isOpen);
  const { assets } = useLocationsCacheContext();
  const counter = useMemo(() => {
    let on = 0;
    let off = 0;
    assets.forEach(asset => {
      asset.properties.sensors.forEach(sensor =>
        sensor.state === 'off' ? ++off : ++on,
      );
    });
    return { on, off };
  }, [assets]);

  return (
    <Stack padding={isDrawerOpen ? '1rem' : '1rem 0.7rem 1rem 0.7rem'}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent={isDrawerOpen ? 'flex-start' : 'center'}
      >
        <Sensors />{' '}
        {isDrawerOpen && (
          <Typography>{t('tools_menu.sensor_counter.sensors')}:</Typography>
        )}
      </Stack>
      <CustomTooltip
        title={
          !isDrawerOpen &&
          `${counter.on} ${t('tools_menu.sensor_counter.sensors')} ON`
        }
        placement="right"
      >
        <Stack
          direction="row"
          spacing={isDrawerOpen ? 2 : 0}
          alignItems="center"
        >
          <PowerSettingsNew
            fontSize="small"
            sx={{ color: statusColorObj.normal }}
          />
          <Typography variant="body2" sx={{ paddingLeft: '5px' }}>
            {`${isDrawerOpen ? 'ON: ' : ''}${counter.on}`}
          </Typography>
        </Stack>
      </CustomTooltip>
      <CustomTooltip
        title={
          !isDrawerOpen &&
          `${counter.off} ${t('tools_menu.sensor_counter.sensors')} OFF`
        }
        placement="right"
      >
        <Stack
          direction="row"
          spacing={isDrawerOpen ? 2 : 0}
          alignItems="center"
        >
          <PowerSettingsNew
            fontSize="small"
            sx={{ color: statusColorObj.danger }}
          />
          <Typography variant="body2" sx={{ paddingLeft: '5px' }}>
            {`${isDrawerOpen ? 'OFF: ' : ''}${counter.off}`}
          </Typography>
        </Stack>
      </CustomTooltip>
    </Stack>
  );
};

export default SensorCounter;
