/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import { listBackground, listBackgroundGradient, primaryColor } from '@themes';

export const BuildingDetails = styled.div`
  background: ${listBackground};
  background: linear-gradient(
    180deg,
    ${listBackground} 0%,
    ${listBackgroundGradient} 100%
  );
  -webkit-transform: translateZ(0);
  -webkit-box-shadow: 0.4375rem 0.4375rem 0.375rem 0rem rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0.4375rem 0.4375rem 0.375rem 0rem rgba(0, 0, 0, 0.23);
  box-shadow: 0.4375rem 0.4375rem 0.375rem 0rem rgba(0, 0, 0, 0.23);
  top: 50px;
  bottom: 0;
  left: 0;
  overflow: inherit;
  transition:
    transform 0.07s,
    opacity 0.07s;
  transform: translate(0%);
  position: ${props => props.$openSide && 'fixed'};
  margin-left: ${props => (props.$openSide ? '17.4rem' : '0rem')};
  width: ${props => (props.$openSide ? '17rem' : '0rem')};
  opacity: ${props => (props.$openSide && !props.$hideDrawers ? 1 : 0)};
  pointer-events: ${props =>
    props.$openSide && !props.$hideDrawers ? 'all' : 'none'};
  z-index: -1;

  @media (max-device-width: 450px) {
    width: calc(100vw - 3.5rem);
    z-index: 1000;
    margin-left: 100%;
  }

  @media (min-device-width: 450px) and (max-device-width: 1224px) {
    z-index: 1000;
    margin-left: 100%;
  }

  ${props =>
    props.$shouldCollapse &&
    css`
      transform: translate(-100%);
      transition: transform 0.3s ease 0.3s;
    `}
`;

export const BuildingDetailsContent = styled.div`
  padding: 1rem;
  height: 100%;
`;

export const GoBackItem = styled.span`
  color: ${primaryColor};
`;

export const GoBackItemContainer = styled.div`
  margin-bottom: 0.5rem;
`;

export const Chevron = styled.span`
  padding-right: 0.5rem;
  font-weight: bold;
`;

export const CollapseMenuButton = styled.div`
  position: absolute;
  height: 50px;
  width: 25px;
  right: -25px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
  background-color: #02bdbd;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 2000;

  &:hover {
    background-color: #009b9b;
    transition: background-color 0.3s ease;
  }

  img {
    width: 15px;
    height: 15px;
    transform: rotateY(180deg);
    transition: transform 0.3s ease;

    ${props =>
      props.shouldCollapse &&
      css`
        transform: rotateY(0);
        z-index: 2;
        transition: transform 0.3s ease;
      `}
  }
`;

export const AssetsInfo = styled.div`
  margin-top: 0.5rem;
`;

export const AssetsCount = styled.h6`
  font-size: 42px;
  font-weight: 400;
  margin-bottom: -0.5rem;
`;

export const Find = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: baseline;
  align-content: center;

  &:hover {
    cursor: pointer;
  }

  > img {
    height: 14px;
    width: 14px;
    filter: invert(60%) sepia(88%) saturate(2386%) hue-rotate(137deg)
      brightness(93%) contrast(98%);
  }

  > p {
    margin-left: 0.5rem;
    font-weight: 500;
    color: ${primaryColor};
  }
`;

export const AssetsStatus = styled.div`
  margin-top: 0.5rem;
`;

export const Footer = styled.p`
  margin-top: 1rem;
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
`;
