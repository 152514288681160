/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalWithActions from '../../ModalWithActions';
import * as S from './style';

const AboutModal = ({ onSenderModalHide, modalShow }) => {
  const { t } = useTranslation();

  const formatRevisionDate = dateString => {
    const month = dateString.slice(0, 2);
    const day = dateString.slice(2, 4);
    const year = dateString.slice(4, 8);

    return [month, day, year].join('.');
  };

  const renderBody = () => {
    return (
      <S.BodyContainer>
        <S.InfoSpan>
          <strong>{t('modal.about.revision')}</strong>
          {formatRevisionDate(process.env.REACT_APP_REVISION)}
        </S.InfoSpan>
        <S.InfoSpan>
          <strong>{t('modal.about.version')}</strong>
          {process.env.REACT_APP_VERSION}
        </S.InfoSpan>
      </S.BodyContainer>
    );
  };

  return (
    <ModalWithActions
      show={modalShow}
      modalTitle={t('modal.about.title')}
      btActionText="OK"
      cancelActionVisible={false}
      action={onSenderModalHide}
      onHide={onSenderModalHide}
      inputComponent={renderBody()}
    />
  );
};

AboutModal.propTypes = {
  onSenderModalHide: PropTypes.func.isRequired,
  modalShow: PropTypes.bool.isRequired,
};

export default AboutModal;
