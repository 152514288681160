/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';
import { darkGrey, industryDefaults, primaryColor } from '../../../../themes';

export const TableRowTr = styled.tr`
  border-bottom: none;
`;

export const AssetItemDiv = styled.div`
  display: flex;
  cursor: pointer;
  overflow: hidden;
  background-color: ${props =>
    props.$industry
      ? industryDefaults[props.$industry]
      : industryDefaults.default};
  opacity: 0.9;
`;

export const GaugeAssetImg = styled.img`
  height: 25px;
  width: 25px;
  margin-top: 15px;
  margin-left: 1rem;
`;

export const GaugeSensorImg = styled.img`
  height: 14px;
  width: 14px;
  margin-bottom: 11px;
`;

export const InfoAssetDiv = styled.div`
  padding-top: 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  margin: 9px;
  margin-right: 8px;
  flex: 1;
`;

export const TextContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CoordinatesAssetP = styled.p`
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 0;
`;

export const GroupNameAssetP = styled.p`
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: ${primaryColor};
`;

export const NextBtnDivGroupAssetDiv = styled.div`
  color: ${primaryColor};
  align-self: center;
  margin-right: 0.45rem;

  .fas .fa-chevron-right .next-btn .arrow {
    color: ${primaryColor};
  }
`;

export const GroupTable = styled.table`
  margin-top: 0.5rem;
`;

export const SensorItem = styled.div`
  overflow: hidden;
  max-height: ${props => (props.isHidden ? 0 : `${props.componentHeight}rem`)};
  opacity: ${props => (props.isHidden ? 0.5 : 1)};
  background-color: ${darkGrey};
  flex-direction: column;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  padding-left: 1.1rem;
`;

export const SensorNameAsset = styled.div`
  margin-top: -1rem;
  margin-bottom: -0.4rem;
  padding-left: 5px;
  white-space: break-spaces;
`;

export const CounterAssetItem = styled.div``;

export const WithAlertDiv = styled.div`
  margin-top: -0.7rem;
  padding-left: 0.5rem;
  float: left;
`;
