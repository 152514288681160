/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import * as S from './style';
import { useFilePath } from '../../../hooks/useFilePath';

const DocumentItem = ({
  title,
  docs,
  isSensorOnly,
  assetTypeId,
  sensorTypeId,
}) => {
  const { buildDocPath, getFilename } = useFilePath();

  const downloadFile = useCallback(
    file => {
      window.open(buildDocPath(file, assetTypeId, sensorTypeId));
    },
    [assetTypeId, buildDocPath, sensorTypeId],
  );

  return (
    <>
      {!isSensorOnly && <S.Subtitle>{title}</S.Subtitle>}
      {docs.length &&
        docs.map(doc => (
          <S.DocsContainer key={doc}>
            <S.DocItem onClick={() => downloadFile(doc)}>
              {getFilename(doc)}
              <i className="fa fa-download" aria-hidden="true" />
            </S.DocItem>
          </S.DocsContainer>
        ))}
    </>
  );
};

DocumentItem.defaultProps = {
  isSensorOnly: false,
  assetTypeId: '',
  sensorTypeId: '',
};

DocumentItem.propTypes = {
  title: PropTypes.string.isRequired,
  docs: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSensorOnly: PropTypes.bool,
  assetTypeId: PropTypes.string,
  sensorTypeId: PropTypes.string,
};

export default DocumentItem;
