/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import { primaryColor, buildingItemBG, buildingItemSelectedBG } from '@themes';

export const Building = styled.div`
  background-color: ${props =>
    props.$isBuildingSelected ? buildingItemSelectedBG : buildingItemBG};
  padding-left: 0.8rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  transition: background-color 0.2s;
`;

export const BuildingInfo = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Name = styled.p`
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: ${primaryColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const AssetsCount = styled.p`
  padding: 0;
  margin: 0;
  font-size: 15px;
`;
