/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as S from './style';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useLocationsCacheContext } from '../../context/LocationsCacheContext';
import CustomTooltip from '../shared/atoms/CustomTooltip';
import { clickOnWellList } from '../../redux/actions';
import AssetIcon from '../shared/atoms/AssetIcon';

const AssetItemFloorList = ({ asset, setSelectedAsset, selectedAsset }) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { setShouldCollapse } = useLocationsCacheContext();
  const dispatch = useDispatch();
  const clickOnWellListDispatch = args => dispatch(clickOnWellList(args));

  const coordinates = asset?.geometry?.coordinates;
  const properties = asset?.properties;

  const flyToAssetPosition = useCallback(() => {
    clickOnWellList({
      asset: properties,
      lngLat: coordinates,
    });

    if (
      history.location.pathname.includes('/portal/maps') &&
      !history.location.pathname.includes('/facilities')
    ) {
      window.map.flyTo({
        center: coordinates,
        zoom: isMobile ? 18.5 : 20,
      });

      window.map.fire('flystart');
    } else {
      history.push({
        pathname: `/portal/maps/${parseFloat(coordinates[0]).toFixed(
          4,
        )},${parseFloat(coordinates[1]).toFixed(4)};20.0`,
      });
    }
  }, [coordinates, properties, isMobile, history]);

  const handleAssetClicked = useCallback(() => {
    if (isMobile) setShouldCollapse(true);
    flyToAssetPosition();
    setSelectedAsset(asset.properties.item_id);
  }, [
    asset.properties.item_id,
    setShouldCollapse,
    flyToAssetPosition,
    isMobile,
    setSelectedAsset,
  ]);

  const goToAssetDetails = useCallback(() => {
    history.push(
      `/portal/maps${
        asset?.geometry?.coordinates?.length
          ? `/${parseFloat(asset.geometry.coordinates[0]).toFixed(
              4,
            )},${parseFloat(asset.geometry.coordinates[1]).toFixed(4)}/`
          : '/'
      }facilities/${asset.properties.item_id}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, asset.properties.item_id]);

  return (
    asset && (
      <S.AssetContainer
        isSelected={asset.properties.item_id === selectedAsset}
        onClick={() => handleAssetClicked()}
      >
        <S.AssetInfo>
          <S.AssetName>{asset.properties.name}</S.AssetName>
          <S.AssetPosition>{asset.properties.current_position}</S.AssetPosition>
        </S.AssetInfo>
        <CustomTooltip title={t('components.asset.view_details')}>
          <IconButton
            sx={{ marginRight: '5px' }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              clickOnWellListDispatch({
                asset: asset.properties,
                lngLat: asset.geometry.coordinates,
              });

              goToAssetDetails();
            }}
            size="small"
          >
            <Visibility sx={{ width: '20px', color: 'white' }} />
          </IconButton>
        </CustomTooltip>
        <AssetIcon status={properties.status} includeTooltip />
      </S.AssetContainer>
    )
  );
};

AssetItemFloorList.defaultProps = {
  selectedAsset: null,
};

AssetItemFloorList.propTypes = {
  asset: PropTypes.shape().isRequired,
  setSelectedAsset: PropTypes.func.isRequired,
  selectedAsset: PropTypes.string,
};

export default AssetItemFloorList;
