/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import usePasswordStrength from '../../../hooks/usePasswordStrength';
import { resetOwnPassword } from '../../../services/userService';
import ChangeOwnPasswordForm from './ChangeOwnPasswordForm';
import ModalWithActions from '../../ModalWithActions';
import { useGeneralContext } from '../../../context/GeneralContext';

const ChangeOwnPasswordModal = ({ onSenderModalHide, modalShow }) => {
  const { openToast, user } = useGeneralContext();
  const { isWeak, weakPasswordMessage } = usePasswordStrength();
  const [newPassValid, setNewPassValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef();
  const { t } = useTranslation();

  const validateForm = vals => {
    const errors = {};

    if (!vals.oldPassword.length) errors.oldPassword = t('validation.required');

    if (!vals.newPassword.length) errors.newPassword = t('validation.required');
    else if (isWeak(vals.newPassword)) errors.newPassword = weakPasswordMessage;

    if (!vals.confirmPassword.length)
      errors.confirmPassword = t('validation.required');

    if (
      vals.confirmPassword.length > 0 &&
      vals.confirmPassword !== vals.newPassword
    )
      errors.confirmPassword = t('validation.no_match');

    return errors;
  };

  const submitResetPass = data => {
    setIsLoading(true);
    resetOwnPassword({
      item_id: user.user_id,
      email: user.email,
      newPassword: data.newPassword,
      oldPassword: data.oldPassword,
    })
      .then(() => {
        openToast(t('modal.change_password.update_success'));
      })
      .catch(() => {
        openToast(t('modal.change_password.update_error'), 'error');
      })
      .finally(() => {
        setIsLoading(false);
        onSenderModalHide();
      });
  };

  return (
    <ModalWithActions
      show={modalShow}
      modalTitle={t('modal.change_password.title')}
      modalText=""
      onHide={onSenderModalHide}
      action={formRef.current?.handleSubmit}
      btActionDisabled={!newPassValid || isLoading}
      inputComponent={
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validateOnBlur
          validateOnChange
          validate={validateForm}
          enableReinitialize={false}
          onSubmit={submitResetPass}
          innerRef={formRef}
        >
          {props => (
            <ChangeOwnPasswordForm
              {...props}
              setNewPassValid={setNewPassValid}
            />
          )}
        </Formik>
      }
    />
  );
};

ChangeOwnPasswordModal.propTypes = {
  onSenderModalHide: PropTypes.func.isRequired,
  modalShow: PropTypes.bool.isRequired,
};

export default ChangeOwnPasswordModal;
