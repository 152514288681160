/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useIsTabletOrMobile } from '../../hooks/useIsTabletOrMobile';
import ActionButton from '../shared/atoms/ActionButton';
import * as S from './style';

const ModalWithActions = ({
  modalText,
  modalTitle,
  action,
  onHide,
  show,
  inputComponent,
  btActionText,
  btCancelText,
  btActionDisabled,
  name,
  width,
  titleSize,
  footerActions,
  actionButtonVisible,
  cancelActionVisible,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const { t } = useTranslation();

  const withNameChunk = !modalText ? null : (
    <div className="modal-text">
      {modalText[0]}
      <b className="modal-name">{name}</b>
      {modalText[1]}
    </div>
  );

  const noNameChunk = !modalText ? null : (
    <div className="modal-text">{modalText || ''}</div>
  );

  return (
    <S.ModalWithActions
      aria-labelledby="contained-modal-title-vcenter"
      size="sm"
      show={show}
      onHide={onHide}
      dialogClassName="custom-with-action"
      centered
      width={width}
      titlesize={titleSize}
      $discreteFooter={!footerActions}
      $isTabletOrMobile={isTabletOrMobile}
    >
      <S.ModalWithActions.Header>
        <S.ModalWithActions.Title id="contained-modal-title-vcenter">
          {modalTitle || ''}
        </S.ModalWithActions.Title>
      </S.ModalWithActions.Header>
      <S.ModalWithActions.Body>
        {name ? withNameChunk : noNameChunk}
        {!inputComponent ? null : inputComponent}
      </S.ModalWithActions.Body>

      <S.ModalWithActions.Footer>
        {cancelActionVisible && (
          <ActionButton
            onClick={onHide}
            label={btCancelText || t('modal.cancel')}
            secondary
          />
        )}
        {actionButtonVisible && (
          <ActionButton
            onClick={action}
            label={!btActionText ? t('modal.confirm') : btActionText}
            disabled={btActionDisabled}
          />
        )}
      </S.ModalWithActions.Footer>
    </S.ModalWithActions>
  );
};

ModalWithActions.defaultProps = {
  modalText: '',
  btCancelText: null,
  btActionText: null,
  footerActions: true,
  actionButtonVisible: true,
  cancelActionVisible: true,
  name: null,
  width: null,
  titleSize: null,
  inputComponent: null,
  btActionDisabled: false,
  action: () => ({}),
};

ModalWithActions.propTypes = {
  modalText: PropTypes.any,
  modalTitle: PropTypes.any.isRequired,
  btActionText: PropTypes.any,
  btCancelText: PropTypes.any,
  name: PropTypes.string,
  action: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  btActionDisabled: PropTypes.bool,
  inputComponent: PropTypes.node,
  width: PropTypes.string,
  titleSize: PropTypes.string,
  footerActions: PropTypes.bool,
  actionButtonVisible: PropTypes.bool,
  cancelActionVisible: PropTypes.bool,
};

export default ModalWithActions;
