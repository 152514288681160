/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';
import {
  primaryColor,
  assetItemBG,
  assetItemSelectedBG,
  assetItemHoverBG,
} from '../../../../../themes';

export const Asset = styled.div`
  background-color: ${props =>
    props.$isSelected ? assetItemSelectedBG : assetItemBG};
  padding-left: 0.8rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;

  &:hover {
    background-color: ${props =>
      props.$isSelected ? assetItemSelectedBG : assetItemHoverBG};
  }
`;

export const AssetDetails = styled.div`
  &:hover {
    cursor: pointer;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TicketIcon = styled.div`
  margin-left: 0.5rem;
  color: ${primaryColor};
  cursor: pointer;
`;

export const StatusIndicator = styled.div`
  margin-right: 0.74rem;
  float: right;
  display: flex;
  justify-content: center;
`;

export const StatusIcon = styled.img`
  height: 1rem;
  width: 1rem;
`;
