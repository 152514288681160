/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import {
  ADD_SENSOR_FULFILLED,
  ADD_SENSOR_PENDING,
  DELETE_SENSOR_FULFILLED,
  DELETE_SENSOR_PENDING,
  FETCH_SENSORS,
  UPDATED_SENSORS_QUERY,
  RECEIVED_ERROR,
  RECEIVED_SENSORS,
  UPDATE_SENSOR_FULFILLED,
  UPDATE_SENSOR_PENDING,
} from '../actions/actionTypes';

const initialState = {
  sensors: [],
  sensorsQueryParams: {},
  isFetching: false,
  isError: false,
};

export const sensorsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SENSORS:
      return {
        ...state,
        error: null,
        message: null,
        isPending: true,
      };

    case UPDATED_SENSORS_QUERY:
      return {
        ...state,
        sensorsQueryParams: action.newValue,
      };

    case RECEIVED_SENSORS:
      return {
        ...state,
        sensors: action.newValue,
        message: null,
        isPending: false,
      };

    case ADD_SENSOR_PENDING:
      return {
        ...state,
        isPending: true,
        lastCreation: null,
        error: null,
        message: null,
      };

    case ADD_SENSOR_FULFILLED: {
      const sensors = state.sensors.items || state.sensors;
      return {
        ...state,
        sensors: {
          items: sensors.concat([action.newValue]),
        },
        isPending: false,
        lastCreation: action.newValue,
        message: 'Sensor created successfully!',
      };
    }

    case UPDATE_SENSOR_PENDING:
      return {
        ...state,
        isPending: true,
        error: null,
        message: null,
      };

    case UPDATE_SENSOR_FULFILLED: {
      const sensors = state.sensors.items || state.sensors;
      return {
        ...state,
        sensors: {
          items: sensors.map(assetType =>
            assetType.item_id !== action.newValue.item_id
              ? assetType
              : action.newValue,
          ),
        },
        isPending: false,
        message: 'Sensor updated successfully!',
      };
    }

    case DELETE_SENSOR_PENDING:
      return {
        ...state,
        isPending: true,
        error: null,
        message: null,
      };

    case DELETE_SENSOR_FULFILLED:
      return {
        ...state,
        sensors: {
          items: state.sensors.items.filter(
            assetType => assetType.item_id !== action.item_id,
          ),
        },
        isPending: false,
        message: 'Sensor deleted successfully!',
      };

    case RECEIVED_ERROR:
      return {
        ...state,
        error: action.error,
        isPending: false,
      };

    default:
      return state;
  }
};
