/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import { statusColorObj } from '@themes';

export const MapIcon = styled.div`
  cursor: pointer;
  text-align: center;

  h5 {
    font-weight: bold;
  }

  svg {
    overflow: visible;
    ${props =>
      !props.$dotIcon &&
      css`
        height: 2.5rem;
        width: 2.5rem;
      `};
  }

  .off, .default, .danger, .warning, .normal, .nothreshold {
    h5 {
      overflow: visible;
      font-weight: bold;
      ${props =>
        !props.$dotIcon &&
        css`
          /* text-shadow: 0.5px -1px 0px rgb(255 255 255),
            0px -0.5px 0px rgb(255 255 255), -0.5px 1px 1px rgb(255 255 255),
            0px -1px 1px rgb(255 255 255);
        `}; */
        text-shadow: 0.5px -1px 0px rgb(255 255 255), 1px -0.5px 0px rgb(255 255 255), -0.5px 1.4px 0px rgb(255 255 255), -1px 0px 1px rgb(255 255 255);
    }

    path {
      stroke: white;
      stroke-width: 3%;
    }
  }

  .nothreshold {
    h5 {
      color: ${statusColorObj.primary};
    }
    ${props =>
      props.$dotIcon
        ? css`
            use {
              fill: ${statusColorObj.primary};
            }
          `
        : css`
            path {
              fill: ${statusColorObj.primary};
            }
          `};

  }

  .off, .default {
    h5 {
      color: ${statusColorObj.off};
    }
    ${props =>
      props.$dotIcon
        ? css`
            use {
              fill: ${statusColorObj.off};
            }
          `
        : css`
            path {
              fill: ${statusColorObj.off};
            }
          `};
  }

  .danger {
    h5 {
      color: ${statusColorObj.danger};
    }
    ${props =>
      props.$dotIcon
        ? css`
            use {
              fill: ${statusColorObj.danger};
            }
          `
        : css`
            path {
              fill: ${statusColorObj.danger};
            }
          `};
  }

  .warning {
    h5 {
      color: ${statusColorObj.warning};
    }
    ${props =>
      props.$dotIcon
        ? css`
            use {
              fill: ${statusColorObj.warning};
            }
          `
        : css`
            path {
              fill: ${statusColorObj.warning};
            }
          `};
  }

  .normal {
    h5 {
      color: ${statusColorObj.normal};
    }
    ${props =>
      props.$dotIcon
        ? css`
            use {
              fill: ${statusColorObj.normal};
            }
          `
        : css`
            path {
              fill: ${statusColorObj.normal};
            }
          `};
  }
`;
