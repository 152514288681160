/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';
import { Form } from 'react-bootstrap';

import { errorColor } from '../../../../themes';

export const Label = styled.label`
  cursor: pointer;
`;

export const AssetFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AssetForm = styled(Form)`
  padding: 0 1rem 0 1.5rem;
`;

export const ActionDiv = styled.div`
  text-align: right;
  margin-top: auto;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

export const ErrorFeedbackP = styled.p`
  color: ${errorColor.default};
  line-height: 24px;
  font-size: 16px;
  min-height: 24px;
  margin-top: -0.5rem;
`;

export const NotFound = styled.p`
  margin-top: 2rem;
  margin-left: 2rem;
`;

export const AssetLabel = styled.label`
  margin-top: 1rem;
  margin-left: 1.5rem;
  font-size: 26px;
  font-weight: 500;
`;
