/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React from 'react';
import styled from 'styled-components';

import { primaryColor, wellListBackground } from '@themes';

const ArrowLeft = () => {
  return <i className="fas fa-chevron-left " />;
};

export const LocationsList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding-bottom: 1rem;
`;

export const BackMsgDiv = styled.div`
  padding-top: 10px;
`;

export const BackButtonSpan = styled.span`
  color: ${primaryColor};
  margin-left: 1.2rem;
  padding-right: 0.5rem;
  &:hover {
    cursor: pointer;
  }
`;

export const ArrowDirectionLeft = styled(ArrowLeft)``;

export const BackButtonTextLocations = styled.h1`
  margin-top: -1.5625rem;
  margin-left: 2.5rem;
  text-transform: none;
  font-weight: 400;
  font-size: 1.1875rem;
  text-align: left;
  width: 70%;
  margin-bottom: -1rem;
`;

export const EmptyMessage = styled.div`
  width: 20vh;
  color: ${primaryColor};
  text-transform: none;
  font-size: 1.125rem;
  text-align: left;
  font-weight: 400;
  margin-left: 1.2rem;
  margin-top: 0.5rem;
`;

export const ListContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const FormButtonsLocationsList = styled.div`
  height: 3.75rem;
  width: 114%;
  background-color: ${wellListBackground};
  position: absolute;
  bottom: 0;
  left: -2.4375rem;
`;
