/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Info } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import CustomTooltip from '../../shared/atoms/CustomTooltip';

const LastUpdatedPopover = ({ lastUpdated }) => {
  const [lastUpdatedFormatted, setLastUpdatedFormatted] = useState(null);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    setLastUpdatedFormatted(lastUpdated.toLocaleString(i18n.language));
  }, [i18n.language, lastUpdated]);

  return (
    <CustomTooltip
      title={
        <div>
          <strong>{t('components.floor_details.last_updated')}</strong>
          <br />
          <strong>{lastUpdatedFormatted}</strong>
          <br />
          <p>{t('components.floor_details.updated_info')}</p>
        </div>
      }
      placement="right"
    >
      <Info color="primary" sx={{ marginLeft: '5px' }} />
    </CustomTooltip>
  );
};

LastUpdatedPopover.propTypes = {
  lastUpdated: PropTypes.instanceOf(Date).isRequired,
};

export default LastUpdatedPopover;
