/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import { primaryColor, sensorListBg } from '@themes';

export const AssetsListDivAssets = styled.div`
  padding-top: 10px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const AssetsListDiv = styled.div`
  margin-top: -1rem;
  min-height: 0;
  cursor: pointer;
`;

export const MainLocationDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding-bottom: 1rem;
`;

export const BackBtnSpan = styled.span`
  margin-left: 1.2rem;
  padding-right: 0.5rem;
`;

export const BackBtn = styled.i`
  color: ${primaryColor};
`;

export const BackBtnTextAssets = styled.h1`
  margin-top: -1.5625rem;
  margin-left: 2.5rem;
  text-transform: none;
  font-weight: 400;
  font-size: 1.1875rem;
  text-align: left;
  width: 70%;
`;

export const FormButtonsAssets = styled.div`
  height: 60px;
  width: 114%;
  background-color: ${sensorListBg};
  position: absolute;
  bottom: 0;
  left: -39px;
`;

export const EmptyList = styled.div`
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
`;
