/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import axios from 'axios';
import {
  getRefreshTokenValue,
  getTokenValue,
  refreshAuthenticate,
  removeTokenValue,
  removeRefreshTokenValue,
  setRefreshTokenValue,
  setTokenValue,
} from './axiosAuth';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_URI}/${process.env.REACT_APP_WEBHOOK_URI_PATH}/${process.env.REACT_APP_SYSTEM_KEY}`,
  responseType: 'json',
});

api.interceptors.request.use(
  request => {
    request.headers['ClearBlade-UserToken'] = getTokenValue();
    return request;
  },
  error => {
    console.error(error);
    throw error;
  },
);

api.interceptors.response.use(
  response => {
    if (!response.data.success) {
      throw response.data.results;
    }
    return response;
  },
  error => {
    const refreshToken = getRefreshTokenValue();

    if (
      error.response?.status === 401 ||
      (error.response?.status === 400 &&
        error.response?.data.error.detail.includes('Invalid token'))
    ) {
      const token = getTokenValue();
      const originalConfig = error.config;

      if (!refreshToken) {
        removeTokenValue();
        removeRefreshTokenValue();
      }

      return refreshAuthenticate(refreshToken, token)
        .then(response => {
          setTokenValue(response.data.user_token);
          setRefreshTokenValue(response.data.refresh_token);
          return api(originalConfig);
        })
        .catch(authError => {
          console.error(authError);
          removeTokenValue();
          removeRefreshTokenValue();
        });
    }
    throw error;
  },
);
