import { del, get, post, put } from '../axios/axiosGateway';

export const getMapPopUps = params => {
  return get('/map-pop-ups', params);
};

export const postMapPopUp = values => {
  return post('/map-pop-ups', values);
};

export const putMapPopUp = (item_id, values) => {
  return put(`/map-pop-ups?item_id=${item_id}`, values);
};

export const deleteMapPopUp = item_id => {
  return del(`/map-pop-ups?item_id=${item_id}`);
};
