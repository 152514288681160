/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import {
  primaryColor,
  redButton,
  gradientBGPrimary,
  gradientBGSecondary,
} from '@themes';

export const NotificationContainer = styled.div`
  z-index: 100;
  background: transparent;
  position: fixed;
  right: 0;
  top: 0;
  max-height: 95%;
  padding: 0.5rem;
  width: ${({ $isSmallMobile }) => ($isSmallMobile ? '100%' : '17.5rem')};
  scrollbar-color: ${primaryColor};
  margin-top: 50px;
  overflow-y: auto;
  transition:
    transform 0.07s,
    opacity 0.07s;
  transform: translate(100%);
  opacity: 0;

  ${props =>
    props.$open &&
    css`
      transition:
        transform 0.07s,
        opacity 0.07s;
      transform: translate(0%);
      opacity: 1;
    `}
`;

export const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
`;

export const Dismiss = styled.div`
  margin-bottom: 0.5rem;
  text-align: right;
`;

export const DismissButton = styled.button`
  width: 100%;
  border: none;
  background: ${redButton};
  color: white;
  border-radius: 0.25rem;
  z-index: 100;
  height: 2rem;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }

  i {
    margin-left: 0.2rem;
  }
`;

export const SingleDismiss = styled.div`
  text-align: right;
  color: white;

  > i:hover {
    cursor: pointer;
  }
`;

export const NoNotifications = styled.div`
  text-align: center;
  padding: 0.5em;
  border-radius: 3px;
  color: #fff;
  font-weight: 800;
  -webkit-box-shadow: -4px 11px 29px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px 11px 29px -10px rgba(0, 0, 0, 0.75);
  box-shadow: -4px 11px 29px -10px rgba(0, 0, 0, 0.75);
  background: ${gradientBGPrimary};
  background: linear-gradient(
    90deg,
    ${gradientBGPrimary} 0%,
    ${gradientBGSecondary} 100%
  );
  opacity: 0.96;
`;
