/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import getStatusTranslation from '../../utils/getStatusTranslation';
import { withClickOutside } from '../Wrappers/WithClickOutside';
import * as S from './style';

const ThresholdsModal = forwardRef(({ title, sensorInfo, onClose }, ref) => {
  const { t } = useTranslation();

  const reorderInfos = () =>
    Object.entries(sensorInfo)
      .map(info => {
        let index;
        switch (info[0]) {
          case 'lowest':
            index = 0;
            break;
          case 'low':
            index = 1;
            break;
          case 'normal':
            index = 2;
            break;
          case 'high':
            index = 3;
            break;
          case 'highest':
            index = 4;
            break;
          default:
            index = 99;
        }
        return [index, ...info];
      })
      .sort((infoA, infoB) => (infoA[0] > infoB[0] ? 1 : -1));

  return (
    <S.Container
      ref={ref}
      className="animate__animated animate__fadeIn animate__faster"
      id="doc-modal-container"
    >
      <S.Header>
        <h6>
          {t('machines.facilities.center_pane.modal.threshold.title', {
            name: title,
          })}
        </h6>
      </S.Header>
      <S.ContentBody>
        {!sensorInfo ? (
          <S.EmptyList>
            <p>
              {t(
                'machines.facilities.center_pane.modal.threshold.validation.no_sensor',
              )}
            </p>
          </S.EmptyList>
        ) : (
          <S.Table cellSpacing="0">
            <S.TableHead>
              <S.HeadRow>
                <S.HeaderCell>
                  {t('machines.facilities.center_pane.modal.threshold.range')}
                </S.HeaderCell>
                <S.HeaderCell>
                  {t('machines.facilities.center_pane.modal.threshold.status')}
                </S.HeaderCell>
                <S.HeaderCell>
                  {t(
                    'machines.facilities.center_pane.modal.threshold.description',
                  )}
                </S.HeaderCell>
              </S.HeadRow>
            </S.TableHead>
            <S.TableBody>
              {reorderInfos().map(info => (
                <S.DataRow key={info[0]} $status={info[1]}>
                  <S.TableCell>{info[2].range}</S.TableCell>
                  <S.TableCell>
                    {getStatusTranslation(info[2].status)}
                  </S.TableCell>
                  <S.TableCell>{info[2].description}</S.TableCell>
                </S.DataRow>
              ))}
            </S.TableBody>
          </S.Table>
        )}
      </S.ContentBody>
      <S.Footer>
        <button type="button" onClick={onClose}>
          {t('modal.close')}
        </button>
      </S.Footer>
    </S.Container>
  );
});

ThresholdsModal.propTypes = {
  title: PropTypes.string.isRequired,
  sensorInfo: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withClickOutside(ThresholdsModal);
