/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import {
  FETCH_INDUSTRIES,
  RECEIVED_INDUSTRIES,
  RECEIVED_ERROR,
} from '../actions/actionTypes';

const initialState = {
  industries: [],
  error: null,
  message: null,
  isPending: false,
};

export const industriesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_INDUSTRIES:
      return {
        ...state,
        error: null,
        message: null,
        isPending: true,
      };

    case RECEIVED_INDUSTRIES:
      return {
        ...state,
        industries: action.newValue,
        message: null,
        isPending: false,
      };

    case RECEIVED_ERROR:
      return {
        ...state,
        error: action.error,
        isPending: false,
      };

    default:
      return state;
  }
};
