/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import {
  FETCH_HISTORY,
  RECEIVED_HISTORY,
  HISTORY_ERROR,
  HISTORY_CLEAN_ERROR,
} from '../actions/actionTypes';

const initialState = {
  sensorHistory: [],
  alertHistory: [],
  index: [],
  minMax: {},
  loaded: false,
  loading: false,
  error: null,
};

export const historyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_HISTORY:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case RECEIVED_HISTORY:
      return {
        ...state,
        sensorHistory: action.sensorHistory || [],
        alertHistory: action.alertHistory || [],
        index: action.index || [],
        minMax: action.minMax || {},
        loading: false,
        loaded: true,
        error: null,
      };

    case HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case HISTORY_CLEAN_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: null,
      };

    default:
      return state;
  }
};
