/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import {
  primaryColor,
  secondaryColor,
  modalGradientPrimary,
  statusColorObj,
} from '@themes';

export const Container = styled.div`
  background: ${modalGradientPrimary};
  height: fit-content;
  white-space: nowrap;
  max-width: 95%;
  max-height: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  border: 2px solid ${primaryColor};
  border-radius: 4px;
  display: inline;
  flex-direction: column;

  &.animate__animated.animate__fadeIn.animate__faster {
    animation-duration: 0.3s;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 3rem;
  width: 100%;

  > h6 {
    height: 3rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    padding: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media only screen and (max-width: 768px) {
    > h6 {
      white-space: break-spaces;
      height: 4rem;
      text-align: center;
    }
  }
`;

export const ContentBody = styled.div`
  overflow: auto;
  text-align: center;
  background: white;
`;

export const EmptyList = styled.div`
  color: #585858;
  padding-top: 1rem;
`;

export const Table = styled.table`
  border: none;
  width: 100%;
`;

export const TableHead = styled.thead``;

export const HeadRow = styled.tr`
  background-color: ${secondaryColor};
`;

export const DataRow = styled.tr`
  color: black;

  ${props => {
    let color;
    switch (props.$status) {
      case 'highest':
      case 'lowest':
        color = statusColorObj.danger;
        break;
      case 'low':
      case 'high':
        color = statusColorObj.warning;
        break;
      case 'Off':
        color = statusColorObj.off;
        break;
      default:
        color = statusColorObj.normal;
    }

    return (
      props.$status &&
      css`
        background-color: ${color};
      `
    );
  }}
`;

export const TableBody = styled.tbody``;

export const HeaderCell = styled.th`
  font-weight: normal;
  text-transform: uppercase;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const TableCell = styled.td`
  padding: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 3rem;
  width: 100%;

  > button {
    background-color: ${primaryColor};
    color: ${secondaryColor};
    height: 3rem;
    width: 100%;
    border: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    transition:
      background-color 0.2s,
      color 0.2s;

    &:hover {
      background-color: ${secondaryColor};
      color: ${primaryColor};
    }
  }
`;
