/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useTimeRangeOptions from '../../hooks/useTimeRangeOptions';
import * as S from './style';

const HistoryRange = ({
  id,
  value,
  launchCustomRange,
  setTimeRange,
  hideCustom,
  handleBlur,
  fullWidth,
}) => {
  const handleChange = useCallback(
    e => {
      const { value: selectedValue } = e.target;

      if (selectedValue === 'custom') launchCustomRange();
      else setTimeRange(selectedValue);
    },
    [launchCustomRange, setTimeRange],
  );

  const { t } = useTranslation();
  const { timeRanges } = useTimeRangeOptions();

  const renderOptions = () =>
    Object.entries(timeRanges)
      .filter(([key]) => !hideCustom || key !== 'custom')
      .map(([key, { text }]) => (
        <S.SelectItem key={key} value={key}>
          {text}
        </S.SelectItem>
      ));

  return (
    <S.SelectMenu
      id={id}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      $fullWidth={fullWidth}
    >
      <S.SelectItem value="" disabled>
        {t(
          'components.generic_table_filter_header.filter.time_range.state.select',
        )}
      </S.SelectItem>
      {renderOptions()}
    </S.SelectMenu>
  );
};

HistoryRange.defaultProps = {
  launchCustomRange: () => null,
  handleBlur: () => null,
  value: '',
  hideCustom: false,
  fullWidth: false,
};

HistoryRange.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  launchCustomRange: PropTypes.func,
  hideCustom: PropTypes.bool,
  fullWidth: PropTypes.bool,
  setTimeRange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
};

export default HistoryRange;
