/* eslint-disable react/prop-types */
/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import { Box } from '@mui/material';
import { useFeatures } from '@paralleldrive/react-feature-toggles';
import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import Spinner from '../components/shared/atoms/Spinner';
import { useGeneralContext } from '../context/GeneralContext';
import PrivateRoute from './PrivateRoute';
import { useRolePermissions } from '../hooks/useRolePermissions';
import { getTokenValue } from '../axios/axiosAuth';

const Login = lazy(() => import('../pages/Login'));
const Maps = lazy(() => import('../pages/Maps'));
const DisplayView = lazy(
  () => import('../components/Management/DisplayManagement/DisplayView'),
);
const Facilities = lazy(() => import('../pages/Facilities'));
const Settings = lazy(() => import('../pages/Settings'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Management = lazy(() => import('../pages/Management'));
const Reports = lazy(() => import('../pages/Reports'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const NotFound = lazy(() => import('../pages/NotFound'));

const Routes = () => {
  const features = useFeatures();
  const { data: userPermissions } = useRolePermissions();
  const management = userPermissions?.management;

  const { lastSelectedLocation, zoom, openToast, resetUser } =
    useGeneralContext();
  const initialPosition = process.env.REACT_APP_MAP_INITIAL_POSITION?.split(
    ' ',
  ) || [-98.647, 31.904, 6];
  let locationUrl;
  if (lastSelectedLocation) {
    locationUrl = `${parseFloat(lastSelectedLocation.properties.lng).toFixed(
      4,
    )},${parseFloat(lastSelectedLocation.properties.lat).toFixed(
      4,
    )};${parseFloat(zoom).toFixed(1)}`;
  } else {
    locationUrl = `${parseFloat(initialPosition[0]).toFixed(4)},${parseFloat(
      initialPosition[1],
    ).toFixed(4)};${parseFloat(initialPosition[2]).toFixed(1)}`;
  }
  const history = useHistory();

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        !history.location.pathname.includes('/portal/login') &&
        !history.location.pathname.includes('/portal/resetPassword') &&
        !getTokenValue()
      ) {
        resetUser();
        openToast('Session is invalid, please login again!', 'warning');
        history.push({
          pathname: '/portal/login',
          search: history.location.search,
          state: { from: history.location },
        });
      }
    }, 5000);
    return () => clearInterval(timer);
  }, [history, openToast, resetUser]);

  return (
    <Box
      sx={{
        minHeight: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Suspense
        fallback={
          <Box display="flex" height="100%" alignItems="center">
            <Spinner />
          </Box>
        }
      >
        <Switch>
          <Route path="/portal/login" component={Login} />
          <Route
            exact
            path="/portal/maps"
            render={props => (
              <Redirect
                to={{
                  pathname: `/portal/maps/${locationUrl}`,
                  search: props.location.search,
                }}
              />
            )}
          />

          {features.includes('management-displays') &&
            management?.displays?.see && (
              <PrivateRoute
                exact
                path="/display/:name"
                component={DisplayView}
                hideNavbar
              />
            )}

          <PrivateRoute exact path="/portal/maps/:location" component={Maps} />

          <PrivateRoute
            exact
            path="/portal/maps/:location?/facilities/:id"
            component={Facilities}
          />

          <PrivateRoute
            exact
            path="/portal/maps/:location?/facilities/:id/sensors/:sensor"
            component={Facilities}
          />

          {features.includes('sidemenu-statistics') && (
            <PrivateRoute
              exact
              path="/portal/maps/:location?/facilities/:id/statistics"
              component={Facilities}
            />
          )}

          {/* //----- Sidemenu feature Routes START ----- */}
          {features.includes('sidemenu-parameters') && (
            <PrivateRoute
              exact
              path="/portal/maps/:location?/facilities/:id/parameters"
              component={Facilities}
            />
          )}

          {features?.includes('sidemenu-parts') && (
            <PrivateRoute
              exact
              path="/portal/maps/:location?/facilities/:id/parts"
              component={Facilities}
            />
          )}

          {features?.includes('sidemenu-openticket') && (
            <PrivateRoute
              exact
              path="/portal/maps/:location?/facilities/:id/openticket"
              component={Facilities}
            />
          )}
          {/* //----- Sidemenu feature Routes END ----- */}

          <PrivateRoute
            exact
            path="/portal/maps/:location?/facilities/:id/position"
            component={Facilities}
          />

          <PrivateRoute
            exact
            path="/portal/maps/:location?/facilities/:id/monitoring"
            component={Facilities}
          />

          <PrivateRoute path="/portal/settings" component={Settings} />
          <PrivateRoute path="/portal/dashboard" component={Dashboard} />
          <PrivateRoute path="/portal/management" component={Management} />
          <PrivateRoute path="/portal/reports" component={Reports} />

          <Route
            exact
            path="/portal"
            render={props => (
              <Redirect
                to={{
                  pathname: '/portal/login',
                  search: props.location.search,
                }}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={props => (
              <Redirect
                to={{
                  pathname: '/portal/login',
                  search: props.location.search,
                }}
              />
            )}
          />
          <Route path="/portal/resetPassword/:id" component={ResetPassword} />
          <Route
            path="*"
            render={props => <NotFound history={props.history} />}
          />
        </Switch>
      </Suspense>
    </Box>
  );
};

export default Routes;
