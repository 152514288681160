import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ModalWithActions from '../components/ModalWithActions';

const ConfirmationContext = createContext(null);

const ConfirmationContextProvider = ({ children }) => {
  const [confirmation, setConfirmation] = useState({
    open: false,
    message: null,
    handleClose: null,
    handleAction: null,
    hideCancel: false,
    title: '',
    actionLabel: '',
  });

  const openConfirmation = useCallback(
    ({
      message,
      handleAction,
      handleClose,
      hideCancel,
      title = '',
      actionLabel = '',
    }) =>
      setConfirmation({
        open: true,
        message,
        handleClose,
        handleAction,
        hideCancel,
        title,
        actionLabel,
      }),
    [],
  );

  const closeConfirmation = useCallback(
    () => setConfirmation(prev => ({ ...prev, open: false })),
    [],
  );

  const { t } = useTranslation();

  const value = useMemo(() => ({ openConfirmation }), [openConfirmation]);

  const {
    open,
    message,
    handleClose,
    handleAction,
    hideCancel,
    title,
    actionLabel,
  } = confirmation;

  return (
    <ConfirmationContext.Provider value={value}>
      {children}
      <ModalWithActions
        show={open}
        modalTitle={title || t('modal.confirmation.title')}
        modalText={message}
        onHide={() => {
          handleClose?.();
          closeConfirmation();
        }}
        action={async () => {
          if (handleAction) {
            await handleAction();
          }
          closeConfirmation();
        }}
        width="500px"
        footerActions={false}
        cancelActionVisible={hideCancel}
        btActionText={actionLabel}
      />
    </ConfirmationContext.Provider>
  );
};

export const useConfirmation = () => useContext(ConfirmationContext);

ConfirmationContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ConfirmationContextProvider;
