/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import {
  floorDetailsBackground,
  primaryColor,
  textArea,
  lightGrey,
} from '@themes';
import { secondaryColor } from '../../themes';

export const FloorDetails = styled.div`
  margin-left: 34.4rem;
  position: fixed;
  width: 17rem;
  background: ${floorDetailsBackground};
  background: linear-gradient(
    180deg,
    ${floorDetailsBackground} 0%,
    ${floorDetailsBackground} 100%
  );
  -webkit-transform: translateZ(0);
  -webkit-box-shadow: 0.4375rem 0.4375rem 0.375rem 0rem rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0.4375rem 0.4375rem 0.375rem 0rem rgba(0, 0, 0, 0.23);
  box-shadow: 0.4375rem 0.4375rem 0.375rem 0rem rgba(0, 0, 0, 0.23);
  top: 50px;
  bottom: 0;
  left: 0;
  padding: 1rem;
  opacity: 0;
  z-index: -2;
  transition:
    transform 0.07s,
    opacity 0.07s;
  transform: translate(0%);
  pointer-events: none;

  ${props =>
    props.$openSide &&
    !props.$hideDrawers &&
    css`
      transition:
        transform 0.07s,
        opacity 0.07s;
      transform: translate(0%);
      opacity: 1;
      pointer-events: all;
    `}

  ${props =>
    props.$shouldCollapse &&
    css`
      @media (min-device-width: 1224px) {
        animation: collapse 0.8s linear 0.1s forwards;
      }

      @media (max-device-width: 1224px) {
        transform: translate(-100%);
        transition: transform 0.3s ease 0.3s;
      }
    `}

    @keyframes collapse {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-200%);
    }
    70% {
      transform: translateX(-193%);
    }
    100% {
      transform: translateX(-195%);
    }
  }

  @media (max-device-width: 450px) {
    width: calc(100vw - 3.5rem);
    z-index: 2000;
    margin-left: 100%;
  }

  @media (min-device-width: 450px) and (max-device-width: 1224px) {
    z-index: 2000;
    margin-left: 100%;
  }
`;

export const GoBackItem = styled.span`
  color: ${primaryColor};
`;

export const GoBackItemContainer = styled.div`
  margin-bottom: 0.5rem;
`;

export const Chevron = styled.span`
  padding-right: 0.5rem;
  font-weight: bold;
`;

export const CollapseMenuButton = styled.div`
  position: absolute;
  height: 50px;
  width: 25px;
  right: -25px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
  background-color: #02bdbd;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #009b9b;
    transition: background-color 0.3s ease;
  }

  img {
    width: 15px;
    height: 15px;
    transform: rotateY(180deg);
    transition: transform 0.3s ease;
    ${props =>
      props.shouldCollapse &&
      css`
        transform: rotateY(0);
        z-index: 2;
        transition: transform 0.3s ease;
      `}
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex: auto;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;

export const AssetsCount = styled.h6`
  font-size: 16px;
  font-weight: 400;
  margin-top: -0.5rem;
`;

export const Resfresh = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;

  > img {
    width: 1rem;
    height: 1rem;
    filter: invert(60%) sepia(88%) saturate(2386%) hue-rotate(137deg)
      brightness(93%) contrast(98%);

    &:hover {
      cursor: pointer;
    }
  }
`;

export const RefreshIcon = styled.img`
  ${props =>
    props.$isLoading &&
    css`
      -webkit-animation: spin 0.5s linear infinite;
      -moz-animation: spin 0.5s linear infinite;
      animation: spin 0.5s linear infinite;

      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    `};
`;

export const AssetsStatus = styled.div`
  margin-top: 1rem;
`;

export const AssetsList = styled.div`
  width: 100%;
  bottom: 0;
  top: 11rem;
  left: 0;
  overflow: auto;

  @media (max-height: 630px) {
    max-height: 60%;
  }

  @media (min-height: 630px) and (max-height: 730px) {
    max-height: 65%;
  }

  @media (min-device-height: 730px) and (max-device-height: 1024px) {
    max-height: 70%;
  }

  @media (min-device-height: 1024px) {
    max-height: 80%;
  }

  ${props =>
    props.isEmptyList &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

export const EmptyList = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  white-space: break-spaces;
`;

export const PopoverContent = styled.div`
  padding: 1rem 1rem 0rem 1rem;
  max-width: 270px;
  background-color: ${secondaryColor};

  strong:first-of-type {
    font-style: italic;
  }
`;

export const PopoverAnchorDiv = styled.div`
  &::after {
    opacity: ${props => (props.$arrow ? 1 : 0)};
    transition: opacity 353ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    margin-left: 0.2rem;
    border-right: 10px solid white;
    z-index: 999;
  }
`;

export const PopoverAnchorIcon = styled.img`
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
  margin-bottom: 5px;
  cursor: pointer;
`;

export const Select = styled.select`
  background: ${lightGrey};
  color: ${textArea};
  width: 100%;
  margin: 1rem 0 1rem;
  height: 2rem;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  cursor: pointer;
`;
