/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
/* eslint-disable no-unused-vars */

import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const DELAY = 6000;

const CustomToast = ({
  isError,
  isWarning,
  message,
  show,
  autohide,
  onToastClose,
  topProp,
  rightProp,
}) => {
  const getIconType = (error, warning) => {
    if (error)
      return (
        <div>
          <S.ToastErrorIcon className="fas fa-times-circle" />
        </div>
      );
    if (warning)
      return (
        <div>
          <S.ToastWarningIcon className="fas fa-exclamation-circle" />
        </div>
      );
    return (
      <div>
        <S.ToastIcon className="fas fa-check-circle" />
      </div>
    );
  };

  return (
    <S.ToastMain
      show={show}
      delay={DELAY}
      autohide={autohide}
      topprop={topProp}
      rightprop={rightProp}
      onClose={() => onToastClose(false)}
    >
      <S.ToastMain.Body>
        <S.ToastBody>
          {getIconType(isError, isWarning)}
          <div>{message}</div>
        </S.ToastBody>
      </S.ToastMain.Body>
    </S.ToastMain>
  );
};

CustomToast.defaultProps = {
  isError: false,
  isWarning: false,
  message: '',
  show: false,
  autohide: false,
  onToastClose: () => null,
  topProp: 10,
  rightProp: 10,
};

CustomToast.propTypes = {
  isError: PropTypes.bool,
  isWarning: PropTypes.bool,
  message: PropTypes.string,
  show: PropTypes.bool,
  autohide: PropTypes.bool,
  onToastClose: PropTypes.func,
  topProp: PropTypes.number,
  rightProp: PropTypes.number,
};

export default CustomToast;
