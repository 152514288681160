import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Info } from '@mui/icons-material';
import { getIn } from 'formik';

import * as S from './style';
import CustomLabel from '../CustomLabel';
import CustomTooltip from '../CustomTooltip';
import ErrorFeedbackP from '../ErrorFeedbackP';

const CustomSelect = ({
  value,
  onChange,
  onBlur,
  label,
  id,
  options,
  optionsGroups,
  disabled,
  hideSelect,
  info,
  touched,
  errors,
  required,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <Stack direction="row" alignItems="center">
        <CustomLabel htmlFor={id} required={required}>
          {label}
        </CustomLabel>
        {info && (
          <CustomTooltip title={info} placement="top">
            <Info sx={{ marginLeft: '10px', marginTop: '8px' }} />
          </CustomTooltip>
        )}
      </Stack>
      <S.StyledSelect
        id={id}
        name={id}
        onChange={onChange}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
      >
        {!hideSelect && (
          <option disabled value="">
            {t('configuration_menu.rules_trigger.select', { label })}
          </option>
        )}
        {options?.map(opt => (
          <option style={{ color: 'black' }} key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
        {optionsGroups?.map(group => (
          <optgroup key={group.category.value} label={group.category.label}>
            {group.list.map?.(opt => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </optgroup>
        ))}
      </S.StyledSelect>
      <ErrorFeedbackP touched={getIn(touched, id)} error={getIn(errors, id)} />
    </div>
  );
};

CustomSelect.defaultProps = {
  disabled: false,
  hideSelect: false,
  required: false,
  info: '',
  touched: {},
  errors: {},
  onBlur: () => null,
};

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.array.isRequired,
  optionsGroups: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  hideSelect: PropTypes.bool,
  required: PropTypes.bool,
  info: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
};

export default CustomSelect;
