/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
import {
  SERVICE_LOG_FETCH,
  UPDATED_SERVICE_LOG_QUERY,
  SERVICE_LOG_CREATE_LOG,
  SERVICE_LOG_UPDATE_LOG,
  SERVICE_LOG_DELETE_LOG,
  RECEIVED_ERROR,
  IS_LOADING,
  UNLOAD,
} from './actionTypes';
import {
  delServiceLog,
  getServiceLogs,
  postServiceLog,
  putServiceLog,
} from '../../services/serviceLogService';

export const _loadingServiceLogs = () => ({
  type: IS_LOADING,
});

export const _fetchServiceLogs = value => ({
  type: SERVICE_LOG_FETCH,
  newValue: value,
});

export const updatedServiceLogsQuery = values => ({
  type: UPDATED_SERVICE_LOG_QUERY,
  newValue: values,
});

export const _postServiceLogs = value => ({
  type: SERVICE_LOG_CREATE_LOG,
  newValue: value,
});

export const _putServiceLogs = (key, value) => ({
  type: SERVICE_LOG_UPDATE_LOG,
  newValue: value,
  key,
});

export const _deleteServiceLogs = id => ({
  type: SERVICE_LOG_DELETE_LOG,
  item_id: id,
});

export const _unloadServiceLogs = () => ({
  type: UNLOAD,
});

export const _receivedError = error => ({
  type: RECEIVED_ERROR,
  error,
});

export const getServiceLogsAction = ({
  itemId = null,
  page = null,
  amount = null,
  keyword = null,
  status = null,
  statusList = null,
  sortingField = null,
  sortingOrder = null,
  asset = null,
  dateStart = null,
  dateEnd = null,
} = {}) => {
  return dispatch => {
    const itemIdParam = itemId !== '' ? itemId : null;
    const pageParam = page !== '' ? page : null;
    const amountParam = amount !== '' ? amount : null;
    const keywordParam = keyword !== '' ? keyword : null;
    const statusParam = status !== '' ? status : null;
    const sortingFieldParam = sortingField || null;
    const sortingOrderParam = sortingOrder || null;
    const assetParam = asset !== '' ? asset : null;
    const dateStartParam = dateStart !== '' ? dateStart : null;
    const dateEndParam = dateEnd !== '' ? dateEnd : null;

    dispatch(_loadingServiceLogs());
    dispatch(
      updatedServiceLogsQuery({
        amount: parseInt(amount),
        keyword,
        status,
        statusList,
        asset,
      }),
    );

    return getServiceLogs({
      itemId: itemIdParam,
      page: pageParam,
      amount: amountParam,
      keyword: keywordParam,
      status: statusParam,
      sortingField: sortingFieldParam,
      sortingOrder: sortingOrderParam,
      asset: assetParam,
      dateStart: dateStartParam,
      dateEnd: dateEndParam,
    })
      .then(res => res.data)
      .then(data => {
        if (!data.results) throw new Error('No Service Logs Found!');
        else dispatch(_fetchServiceLogs(data.results));
      })
      .catch(err => dispatch(_receivedError(err)));
  };
};

export const getServiceLogByIdAction = id => {
  return dispatch => {
    dispatch(_loadingServiceLogs());

    return getServiceLogs({ item_id: id })
      .then(res => res.data)
      .then(data => {
        if (!data.results)
          dispatch(_receivedError('Fetching was not possible'));
        else dispatch(_fetchServiceLogs(data.results));
      })
      .catch(err =>
        dispatch(_receivedError(String(err.response.data.error.message))),
      );
  };
};

export const addServiceLogsAction = body => {
  return dispatch => {
    dispatch(_loadingServiceLogs());

    return postServiceLog(body)
      .then(res => res.data)
      .then(data => {
        if (!data.results || !data.results.data)
          throw new Error('No Service Logs Found!');
        else dispatch(_postServiceLogs(data.results.data));
      })
      .catch(err => dispatch(_receivedError(err)));
  };
};

export const updateServiceLogsAction = (key, body) => {
  return dispatch => {
    dispatch(_loadingServiceLogs());

    return putServiceLog(body, { item_id: body.item_id })
      .then(res => res.data)
      .then(data => {
        if (!data.results || !data.results.data)
          throw new Error('No Service Logs Found!');
        else dispatch(_putServiceLogs(key, body));
      })
      .catch(err => dispatch(_receivedError(err)));
  };
};

export const removeServiceLogsAction = id => {
  return dispatch => {
    dispatch(_loadingServiceLogs());

    return delServiceLog({ item_id: id })
      .then(res => res.data)
      .then(data => {
        if (!data.results || !data.results.data)
          throw new Error('No Service Logs Found!');
        else dispatch(_deleteServiceLogs(id));
      })
      .catch(err => dispatch(_receivedError(err)));
  };
};

export const removeAllServiceLogs = () => {
  return dispatch => {
    dispatch(_loadingServiceLogs());
    dispatch(_unloadServiceLogs());
  };
};
