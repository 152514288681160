/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState, useEffect } from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import ModalWithActions from '../ModalWithActions';

const CustomPrompt = ({
  when,
  modalTitle,
  modalText,
  onDiscard,
  onSave,
  actionText,
  discardText,
  error,
  setError,
  actionButtonVisible,
  cancelActionVisible,
}) => {
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [next, setNext] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setShowModal(false);
    };
  }, []);

  useEffect(() => {
    if (error) setShowModal(true);
  }, [error]);

  // eslint-disable-next-line no-use-before-define
  useEffect(() => () => removeAssetFilter(), []);

  const handleBlockedNavigation = nextLocation => {
    if (shouldBlockNavigation) {
      setShouldBlockNavigation(false);
      setShowModal(true);
      setNext(nextLocation);

      return false;
    }

    return true;
  };

  const removeAssetFilter = () => {
    let asset = window.location.search;
    asset = asset.slice(asset.indexOf('facilities/'), asset.length);
    const newRoute = window.location.search.replace(asset, '');

    window.history.pushState(
      {},
      document.title,
      asset.indexOf('facilities/') !== -1 ? newRoute : window.location.search,
    );
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ModalWithActions
        show={showModal}
        modalTitle={modalTitle || (showModal && t('modal.custom.title'))}
        modalText={modalText || (showModal && t('modal.custom.description'))}
        onHide={() => {
          setShowModal(false);
          setShouldBlockNavigation(true);
          onDiscard(next);
          removeAssetFilter();
          setError(false);
        }}
        action={() => {
          setShouldBlockNavigation(true);
          setShowModal(false);
          setError(false);
          onSave();
        }}
        btActionText={
          actionText || (showModal && t('modal.custom.button_text_save'))
        }
        btCancelText={
          discardText || (showModal && t('modal.custom.button_text_cancel'))
        }
        width="fit-content"
        footerActions={false}
        actionButtonVisible={actionButtonVisible && showModal}
        cancelActionVisible={cancelActionVisible && showModal}
      />
    </>
  );
};

CustomPrompt.defaultProps = {
  when: null,
  modalTitle: '',
  modalText: '',
  onDiscard: () => null,
  onSave: () => null,
  actionText: '',
  discardText: '',
  setError: () => null,
  error: null,
  actionButtonVisible: true,
  cancelActionVisible: true,
};

CustomPrompt.propTypes = {
  when: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalText: PropTypes.string,
  onDiscard: PropTypes.func,
  onSave: PropTypes.func,
  setError: PropTypes.func,
  actionText: PropTypes.string,
  discardText: PropTypes.string,
  error: PropTypes.bool,
  actionButtonVisible: PropTypes.bool,
  cancelActionVisible: PropTypes.bool,
};

export default withRouter(CustomPrompt);
