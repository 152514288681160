/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React from 'react';
import styled from 'styled-components';

import { primaryColor, secondaryColor } from '@themes';

const ArrowRight = () => {
  return <i className="fas fa-chevron-right" />;
};
const ArrowDown = () => {
  return <i className="fas fa-chevron-down" />;
};

export const GroupItem = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  overflow: hidden;
  margin-left: 0.2rem;
  margin-right: 0.4rem;
`;

export const Info = styled.div`
  width: 10em;
  flex: 1;

  p {
    margin: 0;
    color: ${primaryColor};
  }
`;

export const GroupName = styled.p`
  font-size: 1.0625rem;
  font-weight: 500;
`;

export const LocationName = styled.p`
  color: #ffffff !important;
`;

export const GroupButtonNext = styled.div`
  margin-right: 1rem;
  align-self: center;

  i {
    color: ${primaryColor};
  }
`;

export const AssetItemButtons = styled.div`
  width: 100%;
  background-color: ${primaryColor};
`;

export const AssetButton = styled.button`
  height: 1.875rem;
  width: 50%;
  background-color: ${primaryColor};
  border: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;

  &:hover {
    background-color: ${secondaryColor} !important;
  }
`;

export const ArrowDirectionRight = styled(ArrowRight)`
  color: red;
`;

export const ArrowDirectionDown = styled(ArrowDown)`
  color: ${primaryColor};
`;

export const GroupSubItemClass = styled.div`
  overflow: hidden;
  opacity: ${props => (props.arrowDirection ? 0 : 1)};
  max-height: ${props =>
    props.arrowDirection ? 0 : `${props.componentHeight}rem`};
  margin-bottom: 1rem;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
`;
