/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
import {
  GET_LOCATIONS_PENDING,
  GET_LOCATIONS_FULFILLED,
  CREATE_LOCATIONS_PENDING,
  CREATE_LOCATIONS_FULFILLED,
  UPDATE_LOCATIONS_PENDING,
  UPDATE_LOCATIONS_FULFILLED,
  DELETE_LOCATIONS_PENDING,
  DELETE_LOCATIONS_FULFILLED,
  LOCATION_API_RECEIVED_ERROR,
  LOCATION_API_RESET_ERROR,
} from './actionTypes';
import {
  delLocation,
  getLocations,
  postLocation,
  putLocation,
} from '../../services/locationService';

export const fetchLocationsAPI = () => ({
  type: GET_LOCATIONS_PENDING,
});

export const receivedLocationsAPI = value => ({
  type: GET_LOCATIONS_FULFILLED,
  newValue: value,
});

export const receivedError = error => ({
  type: LOCATION_API_RECEIVED_ERROR,
  error,
});

export const resetErrorAPI = () => ({
  type: LOCATION_API_RESET_ERROR,
});

export const getLocationsAPI = () => {
  return dispatch => {
    dispatch(fetchLocationsAPI());

    return getLocations()
      .then(res => res.data)
      .then(data => {
        if (!data.success) return dispatch(receivedError('Error'));
        return dispatch(receivedLocationsAPI(data.results));
      })
      .catch(err => dispatch(receivedError(err.response)));
  };
};

export const addLocationsAPI = body => {
  return dispatch => {
    dispatch({ type: CREATE_LOCATIONS_PENDING });

    return postLocation(body)
      .then(res => res.data)
      .then(data => {
        if (!data.success) dispatch(receivedError('No location found!'));
        else
          dispatch({
            type: CREATE_LOCATIONS_FULFILLED,
            newValue: { item_id: data.results.item_id, ...body },
            success: 'Location successfully created',
          });
      })
      .catch(err =>
        dispatch(
          receivedError(JSON.parse(err.response.data.error.detail).results),
        ),
      );
  };
};

export const updateLocationsAPI = (body, itemId) => {
  return dispatch => {
    dispatch({ type: UPDATE_LOCATIONS_PENDING });

    return putLocation(body, { item_id: itemId })
      .then(res => res.data)
      .then(data => {
        if (!data.success) dispatch(receivedError('No location found!'));
        else
          dispatch({
            type: UPDATE_LOCATIONS_FULFILLED,
            newValue: body,
            success: 'Location successfully updated',
          });
      })
      .catch(err => dispatch(receivedError(err.response)));
  };
};

export const deleteLocationsAPI = itemId => {
  return dispatch => {
    dispatch({ type: DELETE_LOCATIONS_PENDING });

    return delLocation({ item_id: itemId })
      .then(res => res.data)
      .then(data => {
        if (!data.success) dispatch(receivedError('No location found!'));
        else
          dispatch({
            type: DELETE_LOCATIONS_FULFILLED,
            success: 'Location successfully deleted',
          });
      })
      .catch(err => dispatch(receivedError(err.response)));
  };
};
