/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import mapboxgl from 'mapbox-gl';
import MapBoxDraw from '@mapbox/mapbox-gl-draw';
import Joyride from 'react-joyride';
import center from '@turf/center';
import PropTypes from 'prop-types';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DRAW_STYLES } from '@constants/drawStyles';
import {
  addLocationsAPI,
  updateLocationsAPI,
  deleteLocationsAPI,
  resetErrorAPI,
} from '@redux/actions/locationsAPIActions';
import { updateToast } from '@redux/actions';
import BottomContainer from '../../BottomContainer';
import CustomButton from '../../shared/atoms/CustomButton';
import DraggableIcon from '../../DraggableIcon';
import MapIcon from '../../MapIcon';
import * as S from './style';
import { useRolePermissions } from '../../../hooks/useRolePermissions';
import { layers } from '../../FloorDetails';
import UserGroupSelect from '../../shared/molecules/UserGroupSelect';
import CustomLabel from '../../shared/atoms/CustomLabel';
import ErrorFeedbackP from '../../shared/atoms/ErrorFeedbackP';
import { closeAnimated } from '../../../redux/actions/sideDrawerActions';
import { putAsset } from '../../../services/assetService';
import { useConfirmation } from '../../../context/ConfirmationContext';
import { useLocationsCacheContext } from '../../../context/LocationsCacheContext';

const joyrideStyling = {
  options: {
    width: 200,
  },
};

const mapStateToProps = store => ({
  locationsAPIState: store.locationsAPIState,
  industriesState: store.industriesState,
});

const LocationManagement = ({
  name,
  locationIndustry,
  editing,
  clearEditing,
  popPage,
  featureCollection,
  assetsRedux,
  locationsAPIState,
  industriesState,
}) => {
  const { t } = useTranslation();
  const { openConfirmation } = useConfirmation();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [locationSubtitle, setLocationSubtitle] = useState(
    t('tools_menu.location.subtitle'),
  );
  const [locationCenter, setLocationCenter] = useState(null);
  const [assetChanges, setAssetChanges] = useState(false);
  const [locationChanges, setLocationChanges] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [industry, setIndustry] = useState(locationIndustry);
  const industryChanged = useMemo(
    () => locationIndustry !== industry,
    [industry, locationIndustry],
  );
  const [userGroup, setUserGroup] = useState(editing?.user_group || []);
  const userGroupChanged = useMemo(
    () => editing?.user_group !== userGroup,
    [editing?.user_group, userGroup],
  );
  const [editName, setEditName] = useState(name);
  const nameChanged = useMemo(
    () => editing?.name !== editName,
    [editName, editing?.name],
  );
  const content = useSelector(state => state.locationsAPIState);
  const dispatch = useDispatch();
  const { setDisabledRequest, refetch } = useLocationsCacheContext();
  const [assetList, setAssetList] = useState(assetsRedux || []);
  const [industriesList, setIndustriesList] = useState([]);
  const [editingIcons, setEditingIcons] = useState([]);

  const steps = [
    {
      target: '.mapbox-gl-draw_polygon',
      content: t('tools_menu.location.draw'),
      disableBeacon: true,
      placement: 'left-start',
      styles: joyrideStyling,
    },
  ];

  useEffect(() => {
    setIndustriesList(industriesState.industries);
  }, [industriesState]);

  useEffect(() => {
    setAssetList(assetsRedux);
  }, [assetsRedux]);

  const handleAssetDelete = e => {
    const deletedAsset = { ...e.features[0] };
    deletedAsset.properties.has_location = false;

    if (featureCollection) {
      const featuresArr = window.originalFeaturesArr.map(f => {
        if (f.properties.item_id !== deletedAsset.properties.item_id) return f;
        return deletedAsset;
      });

      window.originalFeaturesArr = featuresArr;

      setAssetChanges(true);
      setAssetList(
        featuresArr.filter(
          f => f.geometry.type === 'Point' && !f.properties.has_location,
        ),
      );
      window.map.editingIcons = window.map.editingIcons.filter(
        i => i.point_id !== deletedAsset.properties.item_id,
      );
      setEditingIcons(prev =>
        prev.filter(i => i.key !== deletedAsset.properties.item_id),
      );
    }
  };

  const disablePolygonButton = disabled =>
    (document.querySelector('.mapbox-gl-draw_polygon').disabled = disabled);

  const centerCoordinates = geoJSON => {
    const centerGeoJson = center(geoJSON);

    if (!centerGeoJson.geometry) return [];

    return centerGeoJson.geometry.coordinates;
  };

  const handleLocationCreate = e => {
    if (e.features.length && e.features[0].geometry.type === 'Polygon') {
      disablePolygonButton(true);
      const centerCoords = centerCoordinates(e.features[0]);
      setLocationChanges(e.features[0]);

      setLocationCenter(centerCoords);
      setLocationSubtitle(
        `${centerCoords[0].toFixed(4)}, ${centerCoords[1].toFixed(4)}`,
      );

      const pointId = e.features[0].id;

      window.Draw.setFeatureProperty(pointId, 'icon', 'well');
      window.Draw.setFeatureProperty(pointId, 'name', name);
      window.Draw.setFeatureProperty(pointId, 'type', 'well');
      window.Draw.setFeatureProperty(pointId, 'assets', []);
      window.Draw.setFeatureProperty(pointId, 'status', 'Off');
    }
  };

  const deleteLocation = feature => {
    if (!feature) {
      dispatch(
        updateToast({
          message: 'Error while deleting location!',
          show: true,
          isError: true,
        }),
      );

      return null;
    }

    setLocationChanges(feature);

    dispatch(deleteLocationsAPI(feature.properties.item_id));
    clearEditing();
  };

  const drawNewIcon = feature => {
    const { item_id, icon, status } = feature.properties;
    const iconName = feature.properties.name;

    const el = document.createElement('div');

    const iconEl = createPortal(
      <MapIcon
        id={item_id}
        name={iconName}
        src={icon}
        status={status.toLowerCase()}
      />,
      el,
      item_id,
    );

    const newMarker = new mapboxgl.Marker(el)
      .setLngLat(feature.geometry.coordinates)
      .addTo(window.map);

    newMarker.getElement().setAttribute('id', item_id);

    const newIcon = {
      point_id: item_id,
      name: iconName,
      marker: newMarker,
      lat: feature.geometry.coordinates[1],
      lng: feature.geometry.coordinates[0],
      icon,
      selected: false,
      iconEl,
    };

    window.map.editingIcons = [...window.map.editingIcons, newIcon];

    return iconEl;
  };

  const drawMapIcons = featuresArr => {
    const iconsToEdit = featuresArr.features
      .filter(feature => feature.geometry.type === 'Point')
      .map(feature => feature);

    setEditingIcons(iconsToEdit.map(feature => drawNewIcon(feature)));
  };

  const updateIconsOnScreen = e => {
    const selectedIcon = e.features[0]
      ? window.map.editingIcons.filter(
          icon => icon.point_id === e.features[0].properties.item_id,
        )[0]
      : false;
    const deselectedIcons = window.map.editingIcons.filter(
      icon => icon.selected === true,
    );

    deselectedIcons.forEach(icon => {
      const pointProps = window.map
        .queryRenderedFeatures()
        .filter(
          item =>
            item.properties.user_item_id === icon.point_id &&
            item.layer.id.includes('point-stroke'),
        )[0];

      new mapboxgl.Marker(icon.marker.getElement())
        .setLngLat([
          pointProps.geometry.coordinates[0],
          pointProps.geometry.coordinates[1],
        ])
        .addTo(window.map);
      icon.selected = false;
    });

    if (selectedIcon) {
      selectedIcon.selected = true;
      selectedIcon.marker.remove();
    }
  };

  const clearState = () => {
    setLocationChanges(null);
    setAssetChanges(false);

    setLocationSubtitle(t('tools_menu.location.subtitle'));

    window.originalFeaturesArr = [];
  };

  const handleChangeMode = e => {
    if (e.mode === 'draw_polygon') setIsTourOpen(false);
  };

  const handleSelectionChange = e => {
    updateIconsOnScreen(e);
  };

  const handleAssetUpdate = e => {
    const updatedAsset = e.features[0];

    if (featureCollection) {
      setAssetChanges(true);

      const featuresArr = window.originalFeaturesArr.map(f => {
        if (f.properties.item_id !== updatedAsset.properties.item_id) return f;
        const temp = { ...f, geometry: updatedAsset.geometry };
        temp.properties.has_location = true;

        return temp;
      });

      window.originalFeaturesArr = featuresArr;
    }
  };

  const handleLocationUpdate = e => {
    if (e.features.length && e.features[0].geometry.type === 'Point') {
      handleAssetUpdate(e);
    } else {
      const centerCoords = centerCoordinates(e.features[0]);
      setLocationCenter(centerCoords);
      setLocationSubtitle(
        `${centerCoords[0].toFixed(4)}, ${centerCoords[1].toFixed(4)}`,
      );

      if (featureCollection) {
        const tempLocation = window.Draw.get(e.features[0].id);

        [tempLocation.properties.lat, tempLocation.properties.lng] =
          centerCoords;

        setLocationChanges(tempLocation);
      }
    }
  };

  const handleLocationDelete = e => {
    if (e.features.length && e.features[0].geometry.type === 'Point') {
      handleAssetDelete(e);
    } else if (e.features.length && e.features[0].geometry.type === 'Polygon') {
      disablePolygonButton(false);

      if (featureCollection) deleteLocation(e.features[0]);
      else clearState();
    }
  };

  const removeAllEventListeners = () => {
    window.map.off('draw.modechange', handleChangeMode);
    window.map.off('draw.selectionchange', handleSelectionChange);
    window.map.off('draw.create', handleLocationCreate);
    window.map.off('draw.update', handleLocationUpdate);
    window.map.off('draw.delete', handleLocationDelete);
  };

  const addDrawControl = () => {
    if (!window.Draw) {
      const Draw = new MapBoxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          point: false,
          combine_features: false,
          trash: true,
        },
        userProperties: true,
        styles: DRAW_STYLES,
      });

      window.map.addControl(Draw, 'top-right');

      window.Draw = Draw;

      window.originalFeaturesArr = [];

      if (featureCollection) {
        disablePolygonButton(true);
        window.Draw.set(featureCollection);
        window.originalFeaturesArr = featureCollection.features;
        window.Draw.changeMode('simple_select');
      }

      window.map.on('draw.modechange', handleChangeMode);
      window.map.on('draw.selectionchange', handleSelectionChange);
      window.map.on('draw.create', handleLocationCreate);
      window.map.on('draw.update', handleLocationUpdate);
      window.map.on('draw.delete', e => handleLocationDelete(e));
    }
  };

  const handleIndustryChange = e => {
    setIndustry(e.target.value);
  };

  const handleUserGroupChange = (_, tags) => {
    setUserGroup(tags);
  };

  const handleNameChange = e => {
    setEditName(e.target.value);
  };

  const addTour = () => {
    setTimeout(() => {
      setIsTourOpen(true);
    }, 100);
  };

  const removeDrawControl = () => {
    if (!window.Draw) return;
    window.map.removeControl(window.Draw);
    window.Draw = null;
  };

  const saveLocation = () => {
    const feature = window.Draw.get(locationChanges.id);
    [feature.properties.lat, feature.properties.lng] = locationCenter;

    if (!feature) {
      return dispatch(
        updateToast({
          message: 'Error while creating location!',
          show: true,
          isError: true,
        }),
      );
    }

    setLocationChanges([feature]);

    const fullPathArr = feature.geometry.coordinates[0];

    dispatch(
      addLocationsAPI({
        name,
        user_group: userGroup,
        industry: industry.toLowerCase(),
        location_type: 'default',
        coordinates: fullPathArr
          .map(coordPair => coordPair.toString())
          .join(';'),
      }),
    );
  };

  const updateAllAssets = () => {
    const locationTemp = (window.originalFeaturesArr || []).filter(
      feature => feature.geometry.type === 'Polygon',
    )[0];
    const assetFeatures = (window.originalFeaturesArr || []).filter(
      feature => feature.geometry.type === 'Point',
    );

    if (!assetFeatures.length || !locationTemp) return null;

    return Promise.all(
      assetFeatures.map(async assetWillUpdate => {
        const params = {
          location_id: locationTemp.properties.item_id,
          lat: assetWillUpdate.geometry.coordinates[1],
          lng: assetWillUpdate.geometry.coordinates[0],
          item_id: assetWillUpdate.properties.item_id,
          name: assetWillUpdate.properties.name,
          type: assetWillUpdate.properties.type || '',
          edge_id: assetWillUpdate.properties.edge_id || '',
          image: assetWillUpdate.properties.asset_image || '',
          has_location: assetWillUpdate.properties.has_location,
        };
        return putAsset(assetWillUpdate.properties.item_id, params);
      }),
    );
  };

  const updateLocation = () => {
    if (
      !locationChanges &&
      !industryChanged &&
      !nameChanged &&
      !userGroupChanged
    ) {
      return dispatch(
        updateToast({
          message: 'Something is wrong! It seems like there are no changes.',
          show: true,
          isError: true,
        }),
      );
    }

    if (
      !locationChanges &&
      !industryChanged &&
      !userGroupChanged &&
      nameChanged
    ) {
      editing.name = editName;
      dispatch(
        updateLocationsAPI(
          {
            item_id: editing.item_id,
            name: editName,
          },
          editing.item_id,
        ),
      );
    }

    if (locationChanges) {
      const fullPathArr = locationChanges.geometry.coordinates[0];

      dispatch(
        updateLocationsAPI(
          {
            item_id: locationChanges.properties.item_id,
            name: editName,
            user_group: userGroup,
            industry: industry.toLowerCase(),
            coordinates: fullPathArr
              .map(coordPair => coordPair.toString())
              .join(';'),
          },
          locationChanges.properties.item_id,
        ),
      );

      locationChanges.properties.industry = industry.toLowerCase();
      locationChanges.properties.name = editName;
      locationChanges.properties.user_group = userGroup;
    } else {
      editing.name = editName;
      dispatch(
        updateLocationsAPI(
          {
            item_id: editing.item_id,
            name: editName,
            user_group: userGroup,
            industry: industry.toLowerCase(),
          },
          editing.item_id,
        ),
      );
    }

    updateAllAssets();
  };

  const actionButton = () => {
    if (featureCollection) {
      if (locationChanges || industryChanged || nameChanged || userGroupChanged)
        updateLocation();
      else {
        updateAllAssets()
          .then(() => {
            dispatch(
              updateToast({
                message: 'Location updated successfully!',
                show: true,
                isError: false,
              }),
            );
            setTimeout(() => popPage(), 200);
          })
          .catch(() => {
            dispatch(
              updateToast({
                message: 'Error while updating location!',
                show: true,
                isError: false,
              }),
            );

            setTimeout(() => popPage(), 200);
          });
      }
    } else {
      saveLocation();
    }
  };

  const handleDropMaster = (id, dropResult) => {
    const evt = e => {
      const temp = { ...dropResult.asset };
      temp.properties.has_location = true;
      temp.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];

      window.Draw.add(temp);

      setAssetChanges(true);
      setAssetList(
        assetList.filter(a => a.properties.item_id !== temp.properties.item_id),
      );

      if (
        !window.originalFeaturesArr.find(
          a => a.properties.item_id === temp.properties.item_id,
        )
      ) {
        window.originalFeaturesArr = window.originalFeaturesArr.concat([temp]);
      } else {
        handleAssetUpdate({ features: [temp] });
      }
      setEditingIcons(prev => [...prev, drawNewIcon(temp)]);

      window.map.off('mousemove', evt);
    };

    window.map.on('mousemove', evt);
  };

  useEffect(() => {
    if (locationsAPIState.error) {
      dispatch(
        updateToast({
          essage: locationsAPIState.error,
          show: true,
          isError: true,
        }),
      );

      dispatch(resetErrorAPI());
      popPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsAPIState]);

  useEffect(() => {
    window.map.editingIcons = [];
    window.map.visibleMarkers.forEach(icon => icon.marker.remove());

    addDrawControl();
    setDisabledRequest(true);

    if (!featureCollection) addTour();

    let customBuildingVisibility = 'visible';
    let layersVisibility = 'visible';

    if (featureCollection) {
      window.map.setLayoutProperty('locations_layer', 'visibility', 'none');
      window.map.setLayoutProperty('locations_outline', 'visibility', 'none');
      window.map.setLayoutProperty('unclustered-point', 'visibility', 'none');
      window.map.setLayoutProperty('clusters', 'visibility', 'none');
      window.map.setLayoutProperty(
        'unclustered_point_far',
        'visibility',
        'none',
      );
      window.map.setLayoutProperty('cluster-count', 'visibility', 'none');
      customBuildingVisibility = window.map.getLayoutProperty(
        'custom_building',
        'visibility',
      );
      window.map.setLayoutProperty('custom_building', 'visibility', 'none');
      drawMapIcons(featureCollection);
      layers.forEach(layer => {
        layersVisibility = window.map.getLayoutProperty(layer, 'visibility');
        window.map.setLayoutProperty(layer, 'visibility', 'none');
      });
    }

    return () => {
      window.map.editingIcons.forEach(icon => icon.marker.remove());
      window.map.editingIcons = [];

      removeDrawControl();
      clearState();
      removeAllEventListeners();

      setTimeout(() => setDisabledRequest(false), 1000);

      window.map.setLayoutProperty('locations_layer', 'visibility', 'visible');
      window.map.setLayoutProperty(
        'locations_outline',
        'visibility',
        'visible',
      );
      window.map.setLayoutProperty(
        'unclustered-point',
        'visibility',
        'visible',
      );
      window.map.setLayoutProperty('clusters', 'visibility', 'visible');
      window.map.setLayoutProperty(
        'unclustered_point_far',
        'visibility',
        'visible',
      );
      window.map.setLayoutProperty('cluster-count', 'visibility', 'visible');
      window.map.setLayoutProperty(
        'custom_building',
        'visibility',
        customBuildingVisibility,
      );
      layers.forEach(layer => {
        window.map.setLayoutProperty(layer, 'visibility', layersVisibility);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!content.isPending && isLoading) {
      setIsLoading(false);

      dispatch(
        updateToast({
          message: content.success,
          show: true,
          isError: false,
        }),
      );

      setTimeout(() => {
        refetch();
        popPage();
      }, 1000);
    } else if (content.isPending) {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.isPending]);

  const disableConditions =
    ((!locationChanges || !industryChanged || !userGroupChanged) &&
      !editing?.item_id) ||
    (!locationChanges &&
      !industryChanged &&
      !userGroupChanged &&
      !assetChanges &&
      editName === name &&
      !!editing?.item_id) ||
    isLoading ||
    editName.replace(/\s/g, '') === '' ||
    userGroup.length === 0;

  return (
    <S.LocationManagement>
      <Joyride
        steps={steps}
        run={isTourOpen}
        spotlightClicks
        disableOverlayClose
        styles={{
          buttonNext: {
            display: 'none',
          },
          buttonClose: {
            display: 'none',
          },
          tooltipContainer: {
            marginBottom: -15,
          },
        }}
      />
      <header>
        <S.CVPrimary>
          {t('tools_menu.location.title')} {name}
        </S.CVPrimary>
        <Typography sx={{ whiteSpace: 'break-spaces' }} gutterBottom>
          {locationSubtitle}
        </Typography>
        <S.HeaderItems $editing={editing}>
          {editing && (
            <Stack>
              <CustomLabel required htmlFor="name">
                {t('tools_menu.location.name')}
              </CustomLabel>
              <S.LocationNameInput
                id="name"
                type="text"
                name="name"
                value={editName}
                onChange={handleNameChange}
              />
              <ErrorFeedbackP
                touched={nameChanged}
                error={editName === '' && 'Required!'}
              />
            </Stack>
          )}
          <UserGroupSelect
            setFieldValue={handleUserGroupChange}
            setFieldTouched={() => null}
            handleBlur={() => null}
            touched={{ user_group: userGroupChanged }}
            errors={
              userGroupChanged && userGroup.length === 0
                ? {
                    user_group: 'Required!',
                  }
                : {}
            }
            value={userGroup}
          />
          <S.IndustrySelectContainer>
            <CustomLabel required htmlFor="industry">
              {t('tools_menu.location.industry')}
            </CustomLabel>
            <S.IndustrySelect
              id="industry"
              name="industry"
              onChange={handleIndustryChange}
              value={industry}
            >
              <option value="" disabled>
                {t('tools_menu.location.select')}
              </option>
              {industriesList.length > 0 &&
                industriesList.map(industryItem => (
                  <option key={industryItem.name} value={industryItem.name}>
                    {industryItem.title}
                  </option>
                ))}
            </S.IndustrySelect>
          </S.IndustrySelectContainer>
        </S.HeaderItems>
      </header>
      {featureCollection && (
        <DnDAssetsList
          assets={assetList}
          didDrop={handleDropMaster}
          editing={editing}
          popPage={popPage}
        />
      )}

      <BottomContainer>
        <CustomButton
          onClick={e => {
            e.preventDefault();
            e.target.blur();
            if (
              !locationChanges &&
              !nameChanged &&
              !userGroupChanged &&
              !industryChanged
            )
              popPage();
            else
              openConfirmation({
                message: t('modal.discard.description'),
                handleAction: popPage,
                title: t('modal.cancel'),
              });
          }}
        >
          {t('buttons.cancel')}
        </CustomButton>
        <CustomButton
          onClick={e => {
            e.preventDefault();
            e.target.blur();
            actionButton();
          }}
          disabled={disableConditions}
        >
          {isLoading ? t('tools_menu.location.loading') : t('buttons.save')}
        </CustomButton>
      </BottomContainer>
      {editingIcons}
    </S.LocationManagement>
  );
};

const DnDAsset = ({ asset, didDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'BOX',
    item: { name: 'AssetSideListBucket', asset },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) didDrop(asset.properties.item_id, dropResult);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDragging) return <div ref={drag} />;

  return <DraggableIcon setRef={drag} title={asset.properties.name} />;
};

const DnDAssetsList = ({ assets, didDrop, editing, popPage }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { data: userPermissions } = useRolePermissions();
  const dispatch = useDispatch();
  const closeAnimatedAction = () => dispatch(closeAnimated());

  const hasAsset = assets.some(a => !a.properties.has_location);

  return (
    <S.DnDAssetsList>
      <Typography sx={{ whiteSpace: 'break-spaces' }} gutterBottom>
        {hasAsset
          ? t('tools_menu.location.drag_n_drop')
          : t('tools_menu.location.click_or_add')}
      </Typography>
      <S.NewAssetBtn>
        <Box sx={{ overflow: 'auto' }}>
          {assets
            .filter(a => !a.properties.has_location)
            .map(asset => (
              <DnDAsset
                key={asset.properties.name}
                asset={asset}
                didDrop={didDrop}
              />
            ))}
        </Box>
        {userPermissions?.management?.assets?.manage && (
          <CustomButton
            style={{ width: '100%' }}
            onClick={e => {
              e.preventDefault();
              e.target.blur();
              history.push({
                pathname: '/portal/management/assets/new',
                search: location.search,
                currentLocation: editing,
              });
              popPage();
              closeAnimatedAction();
            }}
          >
            {t('tools_menu.location.add_new_asset')}
          </CustomButton>
        )}
      </S.NewAssetBtn>
    </S.DnDAssetsList>
  );
};

LocationManagement.defaultProps = {
  clearEditing: () => null,
  assetsRedux: [],
  editing: null,
  featureCollection: null,
  locationIndustry: '',
};

LocationManagement.propTypes = {
  assetsRedux: PropTypes.array,
  clearEditing: PropTypes.func,
  editing: PropTypes.object,
  featureCollection: PropTypes.shape({
    features: PropTypes.any,
  }),
  industriesState: PropTypes.shape({
    industries: PropTypes.any,
  }).isRequired,
  locationIndustry: PropTypes.string,
  locationsAPIState: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  popPage: PropTypes.func.isRequired,
};

DnDAsset.propTypes = {
  asset: PropTypes.object.isRequired,
  didDrop: PropTypes.func.isRequired,
};

DnDAssetsList.propTypes = {
  assets: PropTypes.array.isRequired,
  didDrop: PropTypes.func.isRequired,
  editing: PropTypes.object.isRequired,
  popPage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(LocationManagement);
