/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import * as S from './style';
import { getAssets } from '../../services/assetService';
import Spinner from '../shared/atoms/Spinner';
import AssetSensorItem from '../LeftMenu/GroupsList/AssetSensorItem';
import GroupButtons from '../LeftMenu/GroupsList/EditGroup/GroupButtons';
import BottomContainer from '../BottomContainer';

const AssetsSensorsList = ({ locationId, goBack, componentToShow }) => {
  const [assets, setAssets] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [itemsToAdd, setItemsToAdd] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getAssets({ location_id: locationId }).then(resp => {
      setAssets(resp?.data?.results);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBackAction = () => {
    setCheckedItems([]);
    setItemsToAdd([]);
    goBack('plus');
  };

  const goBackToBegining = () => {
    setCheckedItems([]);
    setItemsToAdd([]);
    componentToShow('groups');
  };

  const itemsToAddAction = itemsList => {
    const itemsToAddLocal = [];

    if (checkedItems !== undefined) {
      if (checkedItems.includes(itemsList)) {
        checkedItems[checkedItems.indexOf(itemsList)] = itemsList;
      } else {
        checkedItems.push(itemsList);
      }

      checkedItems.forEach(checked => {
        if (checked) {
          checked.forEach(item => {
            if (item.isChecked) itemsToAddLocal.push(item);
          });
        }
      });
    }

    setCheckedItems([...checkedItems]);
    setItemsToAdd(itemsToAddLocal);
  };

  const assetDisplay = () => {
    if (!assets) {
      return <Spinner />;
    }
    return assets?.length > 0 ? (
      assets
        .sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        })
        .map((item, i) => (
          <AssetSensorItem
            key={i}
            asset={item}
            itemsToAdd={itemsList => itemsToAddAction(itemsList)}
          />
        ))
    ) : (
      <S.EmptyList>{t('components.main_list.groups.asset.empty')}</S.EmptyList>
    );
  };

  return (
    <S.MainLocationDiv>
      <Box paddingTop="5px">
        <Button
          variant="text"
          onClick={goBackAction}
          sx={{ padding: '0 10px 0 0', color: 'white' }}
        >
          <ChevronLeft fontSize="large" color="primary" />
          {t('tools_menu.back')}
        </Button>
      </Box>
      <Box padding="5px 1rem 0 1rem">
        <Typography sx={{ whiteSpace: 'break-spaces' }}>
          {t('components.main_list.groups.asset.title')}
        </Typography>
      </Box>
      <S.AssetsListDivAssets>
        <div>{assetDisplay()}</div>
      </S.AssetsListDivAssets>
      <BottomContainer>
        <GroupButtons
          isDisabled={itemsToAdd.length <= 0}
          goBackToBegining={goBackToBegining}
          pageName="AssetsSensorsList"
          title={t('modal.cancel')}
          modaltext={t('modal.discard.description')}
          qtt={itemsToAdd.length}
          locationId={locationId}
          itemsToAdd={itemsToAdd}
        />
      </BottomContainer>
    </S.MainLocationDiv>
  );
};

AssetsSensorsList.propTypes = {
  locationId: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  componentToShow: PropTypes.func.isRequired,
};

export default AssetsSensorsList;
