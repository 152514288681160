export default calculatedArea => {
  switch (true) {
    case calculatedArea <= 1000:
      return 19;
    case calculatedArea <= 20000:
      return 18;
    case calculatedArea > 20000 && calculatedArea <= 50000:
      return 17;
    case calculatedArea > 50000 && calculatedArea <= 100000:
      return 17.5;
    case calculatedArea > 100000 && calculatedArea <= 500000:
      return 16.5;
    case calculatedArea > 500000 && calculatedArea <= 1000000:
      return 15.5;
    case calculatedArea > 1000000 && calculatedArea <= 1500000:
      return 14.5;
    case calculatedArea > 1500000 && calculatedArea <= 200000:
      return 13.5;
    default:
      return 17;
  }
};
