/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const ActionButtonComponent = ({
  type,
  label,
  onClick,
  disabled,
  secondary,
  minWidth,
  margin,
  uppercase,
  ...props
}) => {
  return (
    <S.ActionButton
      type={type}
      disabled={disabled}
      $secondary={secondary}
      onClick={onClick}
      $minWidth={minWidth}
      $margin={margin}
      $uppercase={uppercase}
      {...props}
    >
      {label}
    </S.ActionButton>
  );
};

ActionButtonComponent.defaultProps = {
  type: 'button',
  label: 'Unlabeled Button',
  disabled: false,
  secondary: false,
  minWidth: 150,
  margin: '0rem 0rem 0rem 1rem',
  uppercase: true,
};

ActionButtonComponent.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  minWidth: PropTypes.any,
  margin: PropTypes.string,
  uppercase: PropTypes.bool,
};

const ActionButton = memo(ActionButtonComponent, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});

export default ActionButton;
