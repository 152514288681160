import i18next from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .use(ICU)
  .init({
    fallbackLng: 'en-US',
    debug: process.env.NODE_ENV === 'development',
  });

export default i18next;
