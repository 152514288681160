/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize};

  iframe#webpack-dev-server-client-overlay{display:none!important}

  html {
    min-height: 100%;
    color: #fff;
  }

  body {
    min-height: 100%;
    font-family: "Ubuntu", BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: '#fff';
    background-color: #071734;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  &:root {
  --sensor-danger: #FF5454;
  --sensor-warning: #EEA626;
  --sensor-normal: #63DC76;
  --sensor-off:#DAD2D2;
  --sensor-default: #02BDBD;
}

  *:focus {
    outline: 0;
  }

  button:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  #app {
    height: 100%
  }

  h1,h2,h3,h4,h5,h6 {
    color: #fff;
  }

  /* Normalizes input color on autofill */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  /**
  * Correct the inability to style clickable types in iOS and Safari.
  */

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  .vh-center {
    justify-content: center;
    align-items: center;
  }

  .cv-primary {
    color:#02BDBD
  }

  .cv-secondary {
    color: #114851;
  }

  .cv-normal {
    color: #1FB814
  }

  .cv-warning {
    color: #EEA626;
  }

  .cv-danger {
    color: #E11B00;
  }

  .cv-off {
    color: #808080;
  }

  .cv-bg-off {
    background-color: #808080;
  }

  .styled-border-bottom {
    border-bottom: 1px solid #02BDBD;
  }

  .styled-border-left {
    border-left: 1px solid #02BDBD;
  }

  .styled-border-right {
    border-right: 1px solid #02BDBD;
  }

  .cv-bg-primary {
    background-color:#02BDBD;
  }

  .cv-bg-secondary {
    background-color:#071734;
  }

  .cv-bg-tertiary {
    background-color:#09152D;
  }

  .cv-bg-normal {
    background-color: #1FB814
  }

  .cv-bg-warning {
    background-color: #EEA626;
  }

  .cv-bg-danger {
    background-color: #E11B00;
  }

  .__react_component_tooltip {
    border-radius: 3px;
    display: inline-block;
    font-size: 13px;
    left: 999em;
    opacity: 0;
    padding: 8px 21px;
    position: fixed;
    pointer-events: none;
    transition: opacity 0.3s;
    top: 999em;
    visibility: hidden;
    z-index: 999;
  }

  .__react_component_tooltip.show {
    margin-top: 0;
    visibility: visible;
  }

  .__react_component_tooltip.place-left::before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    right: -8px;
    top: 50%;
    margin-top: -5px;
  }

  .__react_component_tooltip::before {
    border-left: 8px solid transparent;
    content: "";
    width: 0;
    height: 0;
    position: absolute;
  }

  .__react_component_tooltip::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
  }

  .react-phone-number-input__input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .input-sms {
    margin-bottom: 18px;
    color: #495057; // workaround. react-phone-number-input classes had their name changed. we should add the right ones now.
  }

  .react-phone-number-input__country-select {
    max-width: 100%;
    color: #08152d;
    }

  .react-phone-number-input__country-select {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .react-phone-number-input__icon {
    display: flex;
    align-items: center;
  }

  .row.content {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    min-height: 0;
    overflow: auto;
  }

  @media (min-width: 768px) {
    .row.content {
      flex-wrap: unset;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    }
  }

  .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Opera and Firefox */
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #02BDBD;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #071734;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 1px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: #071734;
  }

  .scrollbar-color {
    scrollbar-color:#02BDBD #c5c5c52b;
    overflow: auto;
  }

  input.form-control{
    background-color: #EDEBEB;
  }

  .mapboxgl-ctrl-group >
  button:disabled {
    cursor: not-allowed;
    background-color: #d6d3d3;
  }

  .mapboxgl-ctrl-top-right {
    padding-top: 40px;
  }

  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
      transition: all 200ms ease;
      transition-property: left, top;
  }
  .react-grid-item.cssTransforms {
      transition-property: transform;
  }
  .react-grid-item.resizing {
      z-index: 1;
  }

  .react-grid-item.react-draggable-dragging {
      transition: none;
      z-index: 3;
  }

  .react-grid-item.react-grid-placeholder {
      background: #000;
      opacity: 0.1;
      transition-duration: 100ms;
      z-index: 2;
      border-radius: 4px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
  }

  .react-grid-item:hover > .react-resizable-handle {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0;
      right: 0;
      background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
      background-position: bottom right;
      padding: 0 3px 3px 0;
      background-repeat: no-repeat;
      background-origin: content-box;
      box-sizing: border-box;
      cursor: se-resize;
  }

  .react-datepicker-popper {
    z-index: 100;
  }
`;
