/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import { combineReducers } from 'redux';
import { assetsReducer } from './assetsReducer';
import { assetTypesReducer } from './assetTypesReducer';
import { groupsReducer } from './groupsReducer';
import { usersReducer } from './usersReducer';
import { usersGroupsReducer } from './usersGroupsReducer';
import { wellsReducer } from './wellsReducer';
import { settingsReducer } from './settingsReducer';
import { toastReducer } from './toastReducer';
import { sensorsReducer } from './sensorsReducer';
import { sensorTypesReducer } from './sensorTypesReducer';
import { serviceLogsReducer } from './serviceLogsReducer';
import { serviceTicketsReducer } from './serviceTicketReducer';
import { authReducer } from './authReducer';
import { locationsAPIReducer } from './locationsAPIReducer';
import { sideDrawerReducer } from './sideDrawerReducer';
import { notificationReducer } from './notificationReducer';
import { historyReducer } from './historyReducer';
import { rulesEngineReducer } from './rulesEngineReducer';
import { industryFilterReducer } from './industryFilterReducer';
import { themeReducer } from './themeReducer';
import { readingsHistoryReducer } from './readingsHistoryReducer';
import { industriesReducer } from './industriesReducer';
import { outdoorHistoryReducer } from './outdoorHistoryReducer';
import { indoorHistoryReducer } from './indoorHistoryReducer';
import { runParametersReducer } from './runParametersReducer';
import { featuresReducer } from './featuresReducer';
import { mapReducer } from './mapReducer';

export const Reducers = combineReducers({
  assetsState: assetsReducer,
  assetTypesState: assetTypesReducer,
  groupsState: groupsReducer,
  locationsAPIState: locationsAPIReducer,
  usersState: usersReducer,
  usersGroupsState: usersGroupsReducer,
  wellsState: wellsReducer,
  settingsState: settingsReducer,
  toastState: toastReducer,
  sensorsState: sensorsReducer,
  sensorTypesState: sensorTypesReducer,
  authState: authReducer,
  sideDrawerState: sideDrawerReducer,
  serviceLogsState: serviceLogsReducer,
  serviceTicketState: serviceTicketsReducer,
  notificationState: notificationReducer,
  historyState: historyReducer,
  readingsHistoryState: readingsHistoryReducer,
  rulesEnginesState: rulesEngineReducer,
  industriesState: industriesReducer,
  industryFilterState: industryFilterReducer,
  theme: themeReducer,
  outdoorHistoryState: outdoorHistoryReducer,
  indoorHistoryState: indoorHistoryReducer,
  runParametersState: runParametersReducer,
  featuresState: featuresReducer,
  mapState: mapReducer,
});
