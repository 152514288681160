/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

import {
  modalGradientPrimary,
  modalGradientSecondary,
  primaryColor,
  customButtonBg,
  lightGrey,
  textArea,
  alertDanger,
} from '@themes';

export const ModalActionsSave = styled(Modal)`
  .custom-save-modal > .modal-content {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    background-image: linear-gradient(
      ${modalGradientPrimary},
      ${modalGradientSecondary}
    );
    border: 0.063rem solid ${primaryColor};
  }

  .custom-save-modal > .modal-content > .modal-header {
    border-color: transparent;
  }

  .custom-save-modal > .modal-content > .modal-header > .modal-title {
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    font-size: 0.875rem;
  }

  .custom-save-modal > .modal-content > .modal-body > .modal-text {
    margin-top: -0.938rem;
    margin-bottom: 1.563rem;
    font-size: 1rem;
    font-weight: 200;
  }

  .custom-save-modal > .modal-content > .modal-body {
    border-color: transparent;
    text-align: center;
    margin-bottom: 0.313rem;
  }

  .custom-save-modal > .modal-content > .modal-footer > .btn-footer {
    width: 50%;
    color: ${customButtonBg};
    background-color: ${primaryColor};
    border-color: transparent;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }

  .custom-save-modal > .modal-content > .modal-footer {
    margin: 0;
    padding: 0;
    border-top: none;
    flex-wrap: nowrap;
  }

  .custom-save-modal > .modal-content > .modal-footer > .btn-footer-left {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0.313rem;
  }

  .custom-save-modal
    > .modal-content
    > .modal-footer
    > button.btn-footer-left:focus {
    margin-bottom: 0;
  }

  .custom-save-modal
    > .modal-content
    > .modal-footer
    > button.btn-footer-right:focus {
    margin-bottom: 0;
  }

  .custom-save-modal > .modal-content > .modal-footer > .btn-footer-right {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0.313rem;
  }

  .custom-save-modal > .modal-content > .modal-footer > .btn:enabled:hover,
  .btn-footer-right:enabled:hover {
    background-color: ${customButtonBg};
    color: ${primaryColor};
    border-bottom-color: ${primaryColor};
  }

  .custom-save-modal > .modal-content > .modal-footer > .btn:focus,
  .btn:active:focus,
  .btn.active:focus,
  .btn.focus,
  .btn:active.focus,
  .btn.active.focus {
    outline: none;
    box-shadow: none;
    margin: 0;
  }

  .btn:disabled {
    cursor: not-allowed;
  }

  .modal-body-save {
    border-color: transparent;
    text-align: left;
    margin-bottom: 0.313rem;
  }

  .modal-text-save {
    margin-top: -0.938rem !important;
    margin-bottom: 0.313rem;
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
    font-weight: 200;
  }

  .modal-input-save,
  .modal-input-save-error {
    height: 1.75rem;
    width: 100%;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    background-color: ${lightGrey};
    color: ${textArea};
    border: 0;
    border-radius: 0.25rem;
  }

  .modal-input-save-error {
    border: 0.063rem solid ${alertDanger};
  }

  .modal-input-save:focus,
  .modal-input-save:active,
  .modal-input-save-error:focus,
  .modal-input-save-error:active {
    border: 0;
    outline: none;
  }

  .error-message {
    margin-top: -1.25rem;
    margin-bottom: 1.25rem !important;
    margin-left: -5.063rem;
    font-size: 0.688rem;
  }
`;
