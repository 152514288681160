/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { openAnimated } from '@redux/actions/sideDrawerActions';
import { updateSelectedBuilding } from '@redux/actions/mapAction';
import * as S from './style';
import { useBuildingDetails } from '../../../../../context/BuildingContext';
import { useGeneralContext } from '../../../../../context/GeneralContext';
import { useIsTabletOrMobile } from '../../../../../hooks/useIsTabletOrMobile';
import { clickOnWellList } from '../../../../../redux/actions';
import { useLocationsCacheContext } from '../../../../../context/LocationsCacheContext';

const Building = ({ building }) => {
  const {
    buildingDetailsVisibility,
    changeBuildingDetailsVisibility,
    changeSelectedBuilding,
    selectedBuilding,
    floorDetailsVisibility,
    changeFloorDetailsVisibility,
    changeSelectedAsset,
    changeSelectedFloor,
  } = useBuildingDetails();
  const history = useHistory();
  const isTabletOrMobile = useIsTabletOrMobile();
  const [sideMenuInteraction, setSideMenuInteraction] = useState(false);
  const [isStyleLoaded, setIsStyleLoaded] = useState(false);
  const { lastSelectedLocation } = useGeneralContext();
  const { shouldCollapse, setShouldCollapse } = useLocationsCacheContext();

  const dispatch = useDispatch();
  const clickOnWellListDispatch = useCallback(
    args => dispatch(clickOnWellList(args)),
    [dispatch],
  );
  const updateSelectedBuildingAction = useCallback(
    args => dispatch(updateSelectedBuilding(args)),
    [dispatch],
  );
  const openAnimatedAction = args => dispatch(openAnimated(args));

  const selectedBuildingId = useSelector(store => store.mapState.building);

  useEffect(() => {
    if (window.map) {
      window.map.on('styledataloading', () => setIsStyleLoaded(false));
      window.map.on('styledata', () => setIsStyleLoaded(true));
    }
  }, []);

  const deselectBuildingOnMap = useCallback(buildingId => {
    if (buildingId && window.map?.isStyleLoaded()) {
      window.map.removeFeatureState({
        source: 'buildings',
        id: buildingId,
      });
    }
  }, []);

  const selectBuildingOnMap = useCallback(
    buildingId => {
      if (buildingId && window.map?.isStyleLoaded()) {
        if (isStyleLoaded) {
          window.map.setFeatureState(
            {
              source: 'buildings',
              id: buildingId,
            },
            {
              clicked: true,
            },
          );
        }
      }
    },
    [isStyleLoaded],
  );

  const highlightBuildingOnMap = useCallback(
    buildingId => {
      deselectBuildingOnMap(selectedBuildingId);
      selectBuildingOnMap(buildingId);
      updateSelectedBuildingAction(buildingId);
    },
    [
      deselectBuildingOnMap,
      selectBuildingOnMap,
      selectedBuildingId,
      updateSelectedBuildingAction,
    ],
  );

  const flyToLocation = useCallback(() => {
    clickOnWellListDispatch({
      asset: building.properties,
      lngLat: building.geometry.coordinates,
    });
    if (
      history.location.pathname.includes('/portal/maps') &&
      !history.location.pathname.includes('/facilities')
    ) {
      window.map.flyTo({
        center: [building.properties.lng, building.properties.lat],
        pitch: 50,
        zoom: isTabletOrMobile ? 16 : 17,
      });
      window.map.fire('flystart');
    } else {
      history.push({
        pathname: `/portal/maps/${building.properties.lat},${building.properties.lng};20.0`,
      });
    }
  }, [building, clickOnWellListDispatch, history, isTabletOrMobile]);

  const handleBuildingDetailsView = useCallback(
    (currentBuilding, allowFlying) => {
      if (!buildingDetailsVisibility) {
        changeBuildingDetailsVisibility(true);
      }

      if (
        floorDetailsVisibility &&
        selectedBuilding?.properties.item_id !==
          currentBuilding?.properties.item_id
      ) {
        changeFloorDetailsVisibility(false);
      }

      if (!selectedBuilding) {
        changeSelectedBuilding(currentBuilding);
      }

      if (
        selectedBuilding?.properties.item_id !==
        currentBuilding?.properties.item_id
      ) {
        changeSelectedBuilding(currentBuilding);
        changeSelectedFloor(null);
        changeSelectedAsset(null);
      }

      if (selectedBuilding && allowFlying) flyToLocation();
      highlightBuildingOnMap(currentBuilding.properties.item_id);
    },
    [
      buildingDetailsVisibility,
      changeBuildingDetailsVisibility,
      changeSelectedAsset,
      changeSelectedBuilding,
      changeSelectedFloor,
      flyToLocation,
      highlightBuildingOnMap,
      selectedBuilding,
      changeFloorDetailsVisibility,
      floorDetailsVisibility,
    ],
  );

  const handleClickAction = () => {
    setSideMenuInteraction(true);
    setShouldCollapse(false);
    handleBuildingDetailsView(building, true);
  };

  useEffect(() => {
    if (sideMenuInteraction) {
      setSideMenuInteraction(false);
    } else if (
      lastSelectedLocation?.properties.item_id ===
        building.properties.location_id &&
      selectedBuildingId === building.properties.item_id &&
      !shouldCollapse
    ) {
      openAnimatedAction();
      handleBuildingDetailsView(building);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuildingId, lastSelectedLocation]);

  useEffect(() => {
    if (window.map && selectedBuilding) {
      window.map.jumpTo({
        center: {
          lng: selectedBuilding.properties.lng,
          lat: selectedBuilding.properties.lat,
        },
        pitch: 50,
        zoom: isTabletOrMobile ? 16 : 17,
      });

      window.map.on('load', () => {
        window.map.setFeatureState(
          {
            source: 'buildings',
            id: selectedBuildingId,
          },
          {
            clicked: true,
          },
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Building
      data-testid={`building-${building?.properties?.name}`}
      onClick={() => handleClickAction()}
      $isBuildingSelected={
        selectedBuilding?.properties?.item_id === building?.properties?.item_id
      }
    >
      <S.BuildingInfo>
        <S.Name>{building?.properties?.name}</S.Name>
        <S.AssetsCount>
          {building?.properties?.assets_count
            ? `0${building?.properties?.assets_count}`.slice(-2)
            : 0}
          {building?.properties?.assets_count === 0 ||
          building?.properties?.assets_count > 1
            ? ' Assets'
            : ' Asset'}
        </S.AssetsCount>
      </S.BuildingInfo>
    </S.Building>
  );
};

Building.propTypes = {
  building: PropTypes.shape({
    properties: PropTypes.shape(),
    geometry: PropTypes.shape(),
  }).isRequired,
};

export default Building;
