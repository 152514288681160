/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import StatusItem from './StatusItem';
import * as S from './style';

const StatusIcons = ({ statusCount }) => {
  const [sortedStatusCount, setSortedStatusCount] = useState();

  const sortStatusCount = useCallback(() => {
    return {
      normal: statusCount.normal,
      warning: statusCount.warning,
      danger: statusCount.danger,
      off: statusCount.off,
    };
  }, [statusCount]);

  useEffect(() => {
    if (statusCount) setSortedStatusCount(sortStatusCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCount]);

  return (
    <S.IconsContainer>
      {sortedStatusCount &&
        Object.keys(sortedStatusCount).map(status => (
          <StatusItem
            count={sortedStatusCount[status]}
            status={status}
            key={status}
          />
        ))}
    </S.IconsContainer>
  );
};

StatusIcons.propTypes = {
  statusCount: PropTypes.shape().isRequired,
};

export default StatusIcons;
