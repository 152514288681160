/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

// Common
export const RECEIVED_ERROR = 'RECEIVED_ERROR';
export const IS_LOADING = 'IS_LOADING';
export const UNLOAD = 'UNLOAD';
export const COLLAPSE_MENU = 'COLLAPSE_MENU';

// Auth
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const RECEIVED_USER_DATA = 'RECEIVED_USER_DATA';

// Assets
export const FETCH_ASSETS = 'FETCH_ASSETS';
export const UPDATED_ASSETS_QUERY = 'UPDATED_ASSETS_QUERY';
export const RECEIVED_ASSETS = 'RECEIVED_ASSETS';
export const ADD_ASSETS_PENDING = 'ADD_ASSETS_PENDING';
export const ADD_ASSETS_FULFILLED = 'ADD_ASSETS_FULFILLED';
export const UPDATE_ASSETS_PENDING = 'UPDATE_ASSETS_PENDING';
export const UPDATE_ASSETS_FULFILLED = 'UPDATE_ASSETS_FULFILLED';
export const DELETE_ASSETS_PENDING = 'DELETE_ASSETS_PENDING';
export const DELETE_ASSETS_FULFILLED = 'DELETE_ASSETS_FULFILLED';
export const RESET_ASSET_ERROR = 'RESET_ASSET_ERROR';
export const UPDATE_LOCATION_TO_FLY_ID = 'UPDATE_LOCATION_TO_FLY_ID';

// Asset Types
export const FETCH_ASSET_TYPES = 'FETCH_ASSET_TYPES';
export const UPDATED_ASSET_TYPE_QUERY = 'UPDATED_ASSET_TYPE_QUERY';
export const RECEIVED_ASSET_TYPES = 'RECEIVED_ASSET_TYPES';

// Map
export const SELECTED_BUILDING = 'SELECTED_BUILDING';

// Locations
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const RECEIVED_LOCATIONS = 'RECEIVED_LOCATIONS';
export const ADD_LOCATIONS = 'ADD_LOCATIONS';
export const DELETE_LOCATIONS = 'DELETE_LOCATIONS';
export const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';
export const UPDATE_ASSETS = 'UPDATE_ASSETS';
export const ENABLE_REQUEST = 'ENABLE_REQUEST';
export const DISABLE_REQUEST = 'DISABLE_REQUEST';
export const LOCATIONS_RECEIVED_ERROR = 'LOCATIONS_RECEIVED_ERROR';

// Groups
export const FETCH_GROUPS = 'FETCH_GROUPS';
export const RECEIVED_GROUPS = 'RECEIVED_GROUPS';

// API
export const GET_LOCATIONS_PENDING = 'GET_LOCATIONS_PENDING';
export const GET_LOCATIONS_FULFILLED = 'GET_LOCATIONS_FULFILLED';
export const CREATE_LOCATIONS_PENDING = 'CREATE_LOCATIONS_PENDING';
export const CREATE_LOCATIONS_FULFILLED = 'CREATE_LOCATIONS_FULFILLED';
export const UPDATE_LOCATIONS_PENDING = 'UPDATE_LOCATIONS_PENDING';
export const UPDATE_LOCATIONS_FULFILLED = 'UPDATE_LOCATIONS_FULFILLED';
export const DELETE_LOCATIONS_PENDING = 'DELETE_LOCATIONS_PENDING';
export const DELETE_LOCATIONS_FULFILLED = 'DELETE_LOCATIONS_FULFILLED';
export const LOCATION_API_RECEIVED_ERROR = 'LOCATION_API_RECEIVED_ERROR';
export const LOCATION_API_RESET_ERROR = 'LOCATION_API_RESET_ERROR';

// Sensors
export const FETCH_SENSORS = 'FETCH_SENSORS';
export const UPDATED_SENSORS_QUERY = 'UPDATED_SENSORS_QUERY';
export const RECEIVED_SENSORS = 'RECEIVED_SENSORS';
export const ADD_SENSOR_PENDING = 'ADD_SENSOR_PENDING';
export const ADD_SENSOR_FULFILLED = 'ADD_SENSOR_FULFILLED';
export const UPDATE_SENSOR_PENDING = 'UPDATE_SENSOR_PENDING';
export const UPDATE_SENSOR_FULFILLED = 'UPDATE_SENSOR_FULFILLED';
export const DELETE_SENSOR_PENDING = 'DELETE_SENSOR_PENDING';
export const DELETE_SENSOR_FULFILLED = 'DELETE_SENSOR_FULFILLED';

// Sensors types
export const FETCH_SENSOR_TYPES = 'FETCH_SENSOR_TYPES';
export const UPDATED_SENSORS_TYPES_QUERY = 'UPDATED_SENSORS_TYPES_QUERY';
export const RECEIVED_SENSOR_TYPES = 'RECEIVED_SENSOR_TYPES';
export const ADD_SENSOR_TYPES_PENDING = 'ADD_SENSOR_TYPES_PENDING';
export const ADD_SENSOR_TYPES_FULFILLED = 'ADD_SENSOR_TYPES_FULFILLED';
export const UPDATE_SENSOR_TYPES_PENDING = 'UPDATE_SENSOR_TYPES_PENDING';
export const UPDATE_SENSOR_TYPES_FULFILLED = 'UPDATE_SENSOR_TYPES_FULFILLED';
export const DELETE_SENSOR_TYPES_PENDING = 'DELETE_SENSOR_TYPES_PENDING';
export const DELETE_SENSOR_TYPES_FULFILLED = 'DELETE_SENSOR_TYPES_FULFILLED';

// Users
export const FETCH_USERS = 'FETCH_USERS';
export const UPDATED_USERS_QUERY = 'UPDATED_USERS_QUERY';
export const RECEIVED_USERS = 'RECEIVED_USERS';

// Groups
export const FETCH_USERS_GROUPS = 'FETCH_USERS_GROUPS';
export const UPDATED_USERS_GROUPS_QUERY = 'UPDATED_USERS_GROUPS_QUERY';
export const RECEIVED_USERS_GROUPS = 'RECEIVED_USERS_GROUPS';

// Wells
export const WELLS_UPDATE_VALUE = 'WELLS_UPDATE_VALUE';
export const CLICK_WELL_LIST = 'CLICK_WELL_LIST';

// Service Log
export const SERVICE_LOG_FETCH = 'SERVICE_LOG_FETCH';
export const SERVICE_LOG_FETCHED = 'SERVICE_LOG_FETCHED';
export const UPDATED_SERVICE_LOG_QUERY = 'UPDATED_SERVICE_LOG_QUERY';
export const SERVICE_LOG_CREATE_LOG = 'SERVICE_LOG_CREATE_LOG';
export const SERVICE_LOG_UPDATE_LOG = 'SERVICE_LOG_UPDATE_LOG';
export const SERVICE_LOG_DELETE_LOG = 'SERVICE_LOG_DELETE_LOG';

// Toast
export const TOAST_SHOW = 'TOAST_SHOW';

// Sidedrawer
export const OPEN_SIDE_DRAWER_ANIMATED = 'OPEN_SIDE_DRAWER_ANIMATED';
export const CLOSE_SIDE_DRAWER_ANIMATED = 'CLOSE_SIDE_DRAWER_ANIMATED';
export const CLOSE_SIDE_DRAWER = 'CLOSE_SIDE_DRAWER';

// Service Tickets
export const FETCH_SERVICE_TICKETS = 'FETCH_SERVICE_TICKETS';
export const RECEIVED_SERVICE_TICKETS = 'RECEIVED_SERVICE_TICKETS';

// Notifications
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const RECEIVED_NOTIFICATIONS = 'RECEIVED_NOTIFICATIONS';
export const WILL_SINGLE_DISMISS = 'WILL_SINGLE_DISMISS';
export const DID_SINGLE_DISMISS = 'DID_SINGLE_DISMISS';
export const DISMISS_NOTIFICATIONS = 'DISMISS_NOTIFICATIONS';
export const DISMISSED_NOTIFICATIONS = 'DISMISSED_NOTIFICATIONS';
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const ADDED_NOTIFICATIONS = 'ADDED_NOTIFICATIONS';
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
export const NOTIFICATIONS_CLEAN_ERROR = 'NOTIFICATIONS_CLEAN_ERROR';

// Sensor Alert History
export const FETCH_HISTORY = 'FETCH_HISTORY';
export const RECEIVED_HISTORY = 'RECEIVED_HISTORY';
export const HISTORY_ERROR = 'HISTORY_ERROR';
export const HISTORY_CLEAN_ERROR = 'HISTORY_CLEAN_ERROR';

// Sensor Complete Reading History
export const FETCH_READINGS_HISTORY = 'FETCH_READINGS_HISTORY';
export const RECEIVED_READINGS_HISTORY = 'RECEIVED_READINGS_HISTORY';
export const READINGS_HISTORY_ERROR = 'READINGS_HISTORY_ERROR';
export const READINGS_HISTORY_CLEAN_ERROR = 'READINGS_HISTORY_CLEAN_ERROR';

// Outdoor Position History
export const FETCH_OUTDOOR_HISTORY = 'FETCH_OUTDOOR_HISTORY';
export const RECEIVED_OUTDOOR_HISTORY = 'RECEIVED_OUTDOOR_HISTORY';
export const UPDATED_OUTDOOR_QUERY = 'UPDATED_OUTDOOR_QUERY';
export const OUTDOOR_HISTORY_ERROR = 'OUTDOOR_HISTORY_ERROR';

// Indoor Position History
export const FETCH_INDOOR_HISTORY = 'FETCH_INDOOR_HISTORY';
export const RECEIVED_INDOOR_HISTORY = 'RECEIVED_INDOOR_HISTORY';
export const UPDATED_INDOOR_QUERY = 'UPDATED_INDOOR_QUERY';
export const INDOOR_HISTORY_ERROR = 'INDOOR_HISTORY_ERROR';

// Run Parameters
export const UPDATE_TIMERS = 'UPDATE_TIMERS';

// Features
export const FETCH_FEATURES = 'FETCH_FEATURES';
export const FEATURES_RECEIVED_ERROR = 'FEATURES_RECEIVED_ERROR';

// Rules Engine
export const FETCH_RULES_ENGINE = 'FETCH_RULES_ENGINE';
export const UPDATED_RULES_QUERY = 'UPDATED_RULES_QUERY';
export const RECEIVED_RULES_ENGINE = 'RECEIVED_RULES_ENGINE';
export const ADD_RULES_ENGINE_PENDING = 'ADD_RULES_ENGINE_PENDING';
export const ADD_RULES_ENGINE_FULFILLED = 'ADD_RULES_ENGINE_FULFILLED';
export const UPDATE_RULES_ENGINE_PENDING = 'UPDATE_RULES_ENGINE_PENDING';
export const UPDATE_RULES_ENGINE_FULFILLED = 'UPDATE_RULES_ENGINE_FULFILLED';
export const DELETE_RULES_ENGINE_PENDING = 'DELETE_RULES_ENGINE_PENDING';
export const DELETE_RULES_ENGINE_FULFILLED = 'DELETE_RULES_ENGINE_FULFILLED';
export const UPDATE_TEMPLATE_PREVIEW_DATA = 'UPDATE_TEMPLATE_PREVIEW_DATA';
export const CLEAR_TEMPLATE_PREVIEW_DATA = 'CLEAR_TEMPLATE_PREVIEW_DATA';
export const UPDATE_LAST_MODIFIED_RULE = 'UPDATE_LAST_MODIFIED_RULE';
export const UPDATE_CURRENTLY_SELECTED_ASSET =
  'UPDATE_CURRENTLY_SELECTED_ASSET';
export const UPDATE_CURRENTLY_SELECTED_ASSET_TYPE =
  'UPDATE_CURRENTLY_SELECTED_ASSET_TYPE';
export const UPDATE_SELECTED_TAGS_DATA_FORMAT =
  'UPDATE_SELECTED_TAGS_DATA_FORMAT';
export const TRIGGER_ELEMENT_FOCUS = 'TRIGGER_ELEMENT_FOCUS';

// Industry Filter
export const UPDATE_INDUSTRY_FILTER = 'UPDATE_INDUSTRY_FILTER';

// Industries
export const FETCH_INDUSTRIES = 'FETCH_INDUSTRIES';
export const RECEIVED_INDUSTRIES = 'RECEIVED_INDUSTRIES';

// Theming
export const UPDATE_THEME = 'UPDATE_THEME';
export const RESET_THEME = 'RESET_THEME';
