/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';
import { Toast } from 'react-bootstrap';

import {
  dangerColor,
  warningColor,
  normalColor,
  secondaryColor,
} from '@themes';

export const ToastMain = styled(Toast)`
  z-index: 1;
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute !important;
  top: ${props => `${props.topprop || 10}px`} !important;
  right: ${props => `${props.rightprop || 10}px`} !important;
  background-color: ${secondaryColor} !important;
`;

export const ToastBody = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ToastIcon = styled.i`
  background-color: white;
  color: ${normalColor};
  border-radius: 100%;
  border: 1px solid white;
  font-size: 1.2rem;
  margin-right: 0.5rem;
`;

export const ToastWarningIcon = styled.i`
  background-color: white;
  color: ${warningColor};
  border-radius: 100%;
  border: 1px solid white;
  font-size: 1.2rem;
  margin-right: 0.5rem;
`;

export const ToastErrorIcon = styled.i`
  background-color: white;
  color: ${dangerColor};
  border-radius: 100%;
  border: 1px solid white;
  font-size: 1.2rem;
  margin-right: 0.5rem;
`;
