/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

import {
  bgPrimary,
  customButtonBg,
  gradientBGPrimary,
  gradientBGSecondary,
  secondaryColor,
} from '@themes';

export const CustomSaveAction = styled(Modal)`
  & .modal-content > .modal-footer > button.btn-footer-left:focus {
    margin-bottom: 0;
  }

  & .modal-content {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    background-image: linear-gradient(
      ${gradientBGSecondary},
      ${gradientBGPrimary}
    );
    border: 0.063rem solid ${bgPrimary};
  }

  & .modal-content > .modal-header {
    border-color: transparent;
  }

  & .modal-content > .modal-header > .modal-title {
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    font-size: 0.875rem;
  }

  & .modal-content > .modal-body > .modal-text {
    margin-top: -0.938rem;
    /* margin-bottom: 1.563rem; */
    font-size: 1rem;
    font-weight: 200;
  }

  & .modal-content > .modal-body {
    border-color: transparent;
    text-align: center;
  }

  & .modal-content > .modal-footer > .btn-footer {
    width: 50%;
    color: ${secondaryColor};
    background-color: ${bgPrimary};
    border-color: transparent;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }

  & .modal-content > .modal-footer > .btn-footer:hover {
    width: 50%;
    color: ${bgPrimary};
    background-color: ${bgPrimary};
    border-color: transparent;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }

  & .modal-content > .modal-footer {
    margin: 0;
    padding: 0;
    border-top: none;
    flex-wrap: nowrap;
  }

  & .modal-content > .modal-footer > .btn-footer-left {
    border-top-left-radius: 0;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0.313rem;
  }

  & .modal-content > .modal-footer > .btn-footer-right {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0.313rem;
  }

  & .btn-footer-left:hover,
  & .btn-footer-right:hover {
    background-color: ${customButtonBg} !important;
    color: ${bgPrimary};
    border-bottom-color: ${bgPrimary};
  }

  & .modal-content > .modal-footer > .btn:focus,
  & .btn:active:focus,
  & .btn.active:focus,
  & .btn.focus,
  & .btn:active.focus,
  & .btn.active.focus {
    outline: none;
    box-shadow: none;
    margin: 0;
  }
`;
