/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import {
  FETCH_USERS_GROUPS,
  UPDATED_USERS_GROUPS_QUERY,
  RECEIVED_USERS_GROUPS,
  RECEIVED_ERROR,
} from '../actions/actionTypes';

const initialState = {
  usersGroups: undefined,
  usersGroupsQueryParams: {},
  isFetching: false,
  isError: false,
};

export const usersGroupsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_USERS_GROUPS:
      return {
        ...state,
        isFetching: true,
      };

    case UPDATED_USERS_GROUPS_QUERY:
      return {
        ...state,
        usersGroupsQueryParams: action.newValue,
      };

    case RECEIVED_USERS_GROUPS:
      return {
        ...state,
        usersGroups: action.newValue,
        isFetching: false,
      };

    case RECEIVED_ERROR:
      return {
        ...state,
        isError: true,
        isFetching: false,
      };

    default:
      return state;
  }
};
