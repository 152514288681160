import React, { useCallback } from 'react';

import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

import PropTypes from 'prop-types';
import { ptBR, enUS } from 'date-fns/locale';

const localeOptions = {
  hour: '2-digit',
  minute: '2-digit',
  day: '2-digit',
  year: '2-digit',
  month: '2-digit',
  formatMatcher: 'basic',
};

const CustomDatePicker = ({ value, hasDatePlaceholder, ...props }) => {
  const { i18n } = useTranslation();

  const handleLocale = useCallback(() => {
    const formattedLanguage = i18n.language.replace('-', '');

    if (formattedLanguage === 'ptBR') return ptBR;

    return enUS;
  }, [i18n.language]);

  const handlePlaceholder = useCallback(() => {
    const formattedLanguage = i18n.language.replace('-', '');

    if (!hasDatePlaceholder) return null;

    if (formattedLanguage === 'ptBR') return 'DD/MM/YYYY';

    return 'MM/DD/YYYY';
  }, [hasDatePlaceholder, i18n.language]);

  return (
    <DatePicker
      {...props}
      placeholderText={handlePlaceholder()}
      locale={handleLocale()}
      value={value
        ?.toLocaleString(i18n.language, localeOptions)
        .replace(',', '')}
    />
  );
};

CustomDatePicker.defaultProps = {
  hasDatePlaceholder: false,
};

CustomDatePicker.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  hasDatePlaceholder: PropTypes.bool,
};

export default CustomDatePicker;
