/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import { dropdownItemCur } from '@themes';

export const img = styled.img`
  padding: 0.2rem;
  cursor: pointer;
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 45px;

  @media (max-width: 360px) {
    max-width: 80px;
  }

  @media (max-width: 270px) {
    display: none;
  }
`;

export const Navbar = styled.div`
  display: flex;
  vertical-align: middle;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  max-height: 3.125rem;
  height: 3.125rem;
  z-index: 10000;
  overflow: visible;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 0 0 1em;

  i.fas.fa-bars {
    cursor: pointer;
    margin-left: -0.5rem;
    margin-right: 0.3rem;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > span {
    color: red;
  }

  > .dropdown {
    margin-right: 0.313rem;
    margin-bottom: 0;
  }

  > .dropdown > .btn-custom {
    border: none;
    background: none;
    color: #fff;
    margin-right: -2rem;
  }

  .dropdown.show > .btn-custom {
    border: none;
    background: none;
  }

  > .dropdown > .btn-custom:hover {
    border: none;
    background: none;
    color: #fff;
  }

  > .dropdown > .btn-custom:focus {
    outline: none;
    box-shadow: none;
  }

  > a.dropdown-item.current {
    color: ${dropdownItemCur};
  }

  > .dropdown.show > .btn-custom:focus {
    outline: none;
    box-shadow: none;
  }

  > .dropdown > .dropdown-menu.show {
    overflow: hidden;
  }

  .dropdown-menu.show {
    @media (max-width: 450px) {
      width: 100vw !important;
    }
  }

  .dropdown-menu .dropdown-item {
    @media (max-width: 450px) {
      padding: 0.5rem 1.5rem;
      font-size: 1.1rem;
    }
  }
`;

export const UserName = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const UserIcon = styled.i`
  @media (min-width: 769px) {
    display: none;
  }
`;

export const Logo = styled.div`
  > button:focus {
    outline: 0;
  }
`;

export const IndustryTitle = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavAlert = styled.div`
  display: flex;
  align-items: center;
  min-width: 5.625rem;

  > button {
    padding-left: 2rem;
    padding-right: 1em;
  }

  > button:focus {
    outline: 0;
  }
`;

export const BtnAction = styled.button`
  border: none;
  background: none;

  .fa-bars {
    cursor: pointer;
  }

  .fa-bell {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export const NotificationsCounter = styled.div`
  margin-right: 1.5rem;
`;
