/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import {
  GET_LOCATIONS_PENDING,
  GET_LOCATIONS_FULFILLED,
  LOCATION_API_RECEIVED_ERROR,
  CREATE_LOCATIONS_PENDING,
  CREATE_LOCATIONS_FULFILLED,
  UPDATE_LOCATIONS_PENDING,
  UPDATE_LOCATIONS_FULFILLED,
  DELETE_LOCATIONS_PENDING,
  DELETE_LOCATIONS_FULFILLED,
  LOCATION_API_RESET_ERROR,
} from '../actions/actionTypes';

const initialState = {
  locations: [],
  lastLocationAdded: null,
  isPending: false,
  error: null,
};

export const locationsAPIReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LOCATIONS_PENDING:
      return {
        ...state,
        isPending: true,
        success: null,
        error: null,
      };

    case GET_LOCATIONS_FULFILLED:
      return {
        ...state,
        locations: [].concat(action.newValue),
        success: action.success,
        isPending: false,
      };

    case CREATE_LOCATIONS_PENDING:
      return {
        ...state,
        lastLocationAdded: null,
        isPending: true,
        success: null,
        error: null,
      };

    case CREATE_LOCATIONS_FULFILLED:
      return {
        ...state,
        lastLocationAdded: action.newValue,
        success: action.success,
        isPending: false,
      };

    case UPDATE_LOCATIONS_PENDING:
    case DELETE_LOCATIONS_PENDING:
      return {
        ...state,
        isPending: true,
        success: false,
        error: null,
      };

    case UPDATE_LOCATIONS_FULFILLED:
      return {
        ...state,
        locations: state.locations.map(l => {
          if (l.item_id === action.newValue.item_id) return action.newValue;

          return l;
        }),
        success: action.success,
        isPending: false,
      };

    case DELETE_LOCATIONS_FULFILLED:
      return {
        ...state,
        success: action.success,
        isPending: false,
      };

    case LOCATION_API_RECEIVED_ERROR:
      return {
        ...state,
        success: false,
        error: action.error,
      };

    case LOCATION_API_RESET_ERROR:
      return {
        ...state,
        isPending: false,
        error: null,
      };

    default:
      return state;
  }
};
