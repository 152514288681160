/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { getGroupsAction } from '@redux/actions/groupsActions';
import { updateToast } from '@redux/actions';
import { useTranslation } from 'react-i18next';
import * as S from './style';
import { deleteGroup } from '../../services/groupService';

const mapStateToProps = store => ({
  groups: store.groupsState.groups,
  toast: store.toastState.toast,
});

const mapDispatchToProps = dispatch => {
  return {
    getGroupsAction: args => dispatch(getGroupsAction(args)),
    updateToast: args => dispatch(updateToast(args)),
  };
};

const ModalActions = props => {
  const { goBack, title, modaltext, onHide, itemtodelete } = props;
  const { t } = useTranslation();

  function goBackAction() {
    goBack('groups');
  }

  function deleteItem() {
    deleteGroup(props.itemtodelete).then(response => {
      if (response.data.success) {
        props.updateToast({
          message: t('components.main_list.modal.delete_group.delete_success'),
          show: true,
          isError: false,
        });
        props.getGroupsAction();
        props.onHide();
      } else {
        props.updateToast({
          message: t('components.main_list.modal.delete_group.delete_error'),
          show: true,
          isError: true,
        });
      }
    });
  }

  return (
    <S.CustomSaveAction
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="custom-save-action"
      centered
    >
      <S.CustomSaveAction.Header>
        <S.CustomSaveAction.Title className="modal-title">
          {title}
        </S.CustomSaveAction.Title>
      </S.CustomSaveAction.Header>
      <S.CustomSaveAction.Body>
        <h4 className="modal-text">{modaltext}</h4>
      </S.CustomSaveAction.Body>
      <S.CustomSaveAction.Footer>
        <Button
          title=""
          onClick={onHide}
          className="btn btn-footer btn-footer-left"
        >
          {t('modal.cancel')}
        </Button>
        <Button
          title=""
          onClick={itemtodelete ? deleteItem : goBackAction}
          className="btn btn-footer btn-footer-right"
        >
          {t('modal.confirm')}
        </Button>
      </S.CustomSaveAction.Footer>
    </S.CustomSaveAction>
  );
};

ModalActions.defaultProps = {
  modalText: '',
  drawerIsOpen: false,
  itemtodelete: null,
};

ModalActions.propTypes = {
  title: PropTypes.string.isRequired,
  modalText: PropTypes.string,
  drawerIsOpen: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  updateToast: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  modaltext: PropTypes.string.isRequired,
  itemtodelete: PropTypes.string,
  getGroupsAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalActions);
