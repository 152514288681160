/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

const generalIcon = `${process.env.REACT_APP_STORAGE_BASE_URL}/signal.svg`;

const DraggableIcon = ({ iconClass, title, setRef }) => {
  return (
    <S.DraggableIcon>
      <S.IconImage>
        {iconClass ? (
          <i ref={setRef} className={iconClass} />
        ) : (
          <S.DefaultIcon
            ref={setRef}
            src={generalIcon}
            alt="General icon"
            draggable="false"
          />
        )}
      </S.IconImage>
      <S.TextData>
        <h6>{title}</h6>
      </S.TextData>
    </S.DraggableIcon>
  );
};

DraggableIcon.defaultProps = {
  iconClass: '',
  title: '',
  setRef: () => null,
};

DraggableIcon.propTypes = {
  iconClass: PropTypes.string,
  title: PropTypes.string,
  setRef: PropTypes.func,
};

export default DraggableIcon;
