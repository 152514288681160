/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
import { getIndustries } from '../../services/industryService';
import {
  FETCH_INDUSTRIES,
  RECEIVED_ERROR,
  RECEIVED_INDUSTRIES,
  UPDATE_INDUSTRY_FILTER,
} from './actionTypes';

export const fetchIndustries = () => ({
  type: FETCH_INDUSTRIES,
});

export const receivedIndustries = value => ({
  type: RECEIVED_INDUSTRIES,
  newValue: value,
});

export const updateIndustryFilter = value => {
  const industryFilterList = value.reduce((memo, cur) => {
    memo[cur.name] = true;
    return { ...memo };
  }, {});

  return {
    type: UPDATE_INDUSTRY_FILTER,
    newValue: industryFilterList,
  };
};

export const receivedError = error => ({
  type: RECEIVED_ERROR,
  error,
});

export const getIndustriesAction = id => {
  return dispatch => {
    dispatch(fetchIndustries());

    return getIndustries(id ? { item_id: id } : null)
      .then(res => res.data)
      .then(data => {
        if (!data.success || data.hasOwnProperty('error'))
          dispatch(receivedError('Error'));
        else {
          dispatch(updateIndustryFilter(data.results));
          dispatch(receivedIndustries(data.results));
        }
      })
      .catch(() => dispatch(receivedError()));
  };
};
