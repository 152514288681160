/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useBuildingDetails } from '../../context/BuildingContext';
import { useIsTabletOrMobile } from '../../hooks/useIsTabletOrMobile';
import LastUpdatedPopover from './LastUpdatedPopover';
import * as S from './style';
import { useLocationsCacheContext } from '../../context/LocationsCacheContext';
import CustomTooltip from '../shared/atoms/CustomTooltip';
import StatusIconsFilter from '../StatusIconsFilter';
import AssetItemFloorList from '../AssetItemFloorList';

const reload = `${process.env.REACT_APP_STORAGE_BASE_URL}/reload.svg`;
const arrow = `${process.env.REACT_APP_STORAGE_BASE_URL}/arrow.svg`;

export const layers = [
  'floor_label',
  'floors_outline',
  'floors_layer',
  'rooms_walls',
  'rooms_labels',
];

const mapStateToProps = store => ({
  drawerIsOpen: store.sideDrawerState.isOpen,
});

const FloorDetails = ({ drawerIsOpen }) => {
  const {
    lastUpdated,
    refetch,
    shouldCollapse,
    setShouldCollapse,
    isFetching,
  } = useLocationsCacheContext();
  const [updateLocations, setUpdateLocations] = useState(false);
  const [showingFloorLayer, setShowingFloorLayer] = useState(false);
  const [currentBuilding, setCurrentBuilding] = useState({});
  const [selectedFloorFiltered, setSelectedFloorFiltered] = useState(null);
  const isTabletOrMobile = useIsTabletOrMobile();
  const {
    floorDetailsVisibility,
    selectedBuilding,
    changeSelectedFloor,
    selectedFloor,
    changeSelectedAsset,
    selectedAsset,
    filteredStatus,
    assetTypes,
    changeAssetTypes,
    selectedFilter,
    changeSelectedFilter,
    hideDrawers,
  } = useBuildingDetails();

  const { t } = useTranslation();

  const [currentFloor, setCurrentFloor] = useState(selectedFloor);
  const [isStyleLoaded, setIsStyleLoaded] = useState(false);

  useEffect(() => {
    if (window.map) {
      // Create map style loaded behavior
      window.map.on('styledataloading', () => setIsStyleLoaded(false));
      window.map.on('styledata', () => setIsStyleLoaded(true));
    }
  }, []);

  const replaceFilteredStatusNames = useCallback(filtered => {
    if (filtered.length === 0) return ['Normal', 'Warning', 'Danger', 'Off'];
    return filtered.map(status => {
      switch (status) {
        case 'normal':
          return 'Normal';

        case 'warning':
          return 'Warning';

        case 'danger':
          return 'Danger';

        default:
          return 'Off';
      }
    });
  }, []);

  const handleRefreshLocations = useCallback(() => {
    window.map?.once('load', () => {
      window.map.updateMap();
    });

    if (!isFetching) {
      setUpdateLocations(false);
      refetch();
    }
  }, [isFetching, refetch]);

  const handleSelectFilter = useCallback(
    filter => {
      setUpdateLocations(true);
      changeSelectedFilter(filter);
    },
    [changeSelectedFilter],
  );

  const handleSelectedAsset = useCallback(
    asset => {
      changeSelectedAsset(asset);
    },
    [changeSelectedAsset],
  );

  const toggleAssetsVisibility = useCallback(() => {
    if (!window.map || !selectedFloorFiltered || !selectedBuilding) return;

    const filteredAssets = selectedFloorFiltered?.assets.map(
      asset => asset?.properties?.item_id,
    );

    if (window.map.isStyleLoaded()) {
      if (filteredAssets?.length > 0) {
        window.map.setFilter('unclustered-point', [
          'all',
          [
            '==',
            ['get', 'location_id'],
            `${selectedBuilding?.properties?.item_id}`,
          ],
          ['==', ['get', 'floor'], `${currentFloor?.floor_number}`],
          ['match', ['get', 'item_id'], filteredAssets, true, false],
        ]);
      } else {
        window.map.setFilter('unclustered-point', [
          'all',
          ['!=', 'cluster', true],
          ['!has', 'is_building'],
        ]);
      }
    }
  }, [currentFloor, selectedBuilding, selectedFloorFiltered]);

  const toggleLayersVisibility = useCallback(() => {
    if (isStyleLoaded) {
      if (!showingFloorLayer && !window.map.flying && selectedBuilding) {
        // Move camera 0.001 points to the left to compensate the sidemenu
        const newLat = (
          parseFloat(selectedBuilding.properties.lat) - 0.001
        ).toFixed(4);
        window.map.once('styledata', () => {
          window.map.flyTo({
            center: isTabletOrMobile
              ? [
                  selectedBuilding.properties.lng,
                  selectedBuilding.properties.lat,
                ]
              : [selectedBuilding.properties.lng, `${newLat}`],
            pitch: 50,
            zoom: isTabletOrMobile ? 17.5 : 18,
            bearing: -20,
          });
        });
      }
      if (currentFloor && selectedFloor && floorDetailsVisibility) {
        setShowingFloorLayer(true);
        layers.forEach(layer => {
          window.map.setLayoutProperty(layer, 'visibility', 'visible');

          window.map.setFilter(layer, [
            '==',
            ['get', 'item_id'],
            `${selectedBuilding.properties.item_id}-${currentFloor.floor_number}`,
          ]);
        });

        window.map.setLayoutProperty('custom_building', 'visibility', 'none');
        window.map.setLayoutProperty('3d-buildings', 'visibility', 'none');
      } else {
        setShowingFloorLayer(false);

        layers.forEach(layer => {
          window.map.setLayoutProperty(layer, 'visibility', 'none');
          window.map.setFilter(layer, null);
        });

        window.map.setFilter('unclustered-point', [
          'all',
          ['!=', 'cluster', true],
          ['!has', 'is_building'],
        ]);

        window.map.setLayoutProperty(
          'custom_building',
          'visibility',
          'visible',
        );
        window.map.setLayoutProperty('3d-buildings', 'visibility', 'visible');
      }
    }
  }, [
    isStyleLoaded,
    showingFloorLayer,
    selectedBuilding,
    currentFloor,
    selectedFloor,
    floorDetailsVisibility,
    isTabletOrMobile,
  ]);

  useEffect(() => {
    if (!selectedFloorFiltered) {
      return;
    }
    toggleAssetsVisibility();
    if (updateLocations) handleRefreshLocations();
    else setUpdateLocations(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloorFiltered]);

  const handleFilteredList = useCallback(() => {
    let filteredFloor = selectedFloor;

    if (selectedFloor && filteredStatus.length) {
      const newFilteredStatus = replaceFilteredStatusNames(filteredStatus);
      const { assets } = selectedFloor;
      const filteredAssetsList = assets.filter(asset =>
        newFilteredStatus.includes(asset.properties.status),
      );

      filteredFloor = {
        ...selectedFloor,
        assets: filteredAssetsList,
      };
    }

    if (selectedFilter !== 'all' && filteredFloor?.assets?.length > 0) {
      const { assets } = selectedFloor;
      const filteredAssetsList = assets.filter(
        asset => asset.properties.type === selectedFilter,
      );

      filteredFloor = {
        ...selectedFloor,
        assets: filteredAssetsList,
      };
    }

    setSelectedFloorFiltered(filteredFloor);
  }, [
    filteredStatus,
    replaceFilteredStatusNames,
    selectedFilter,
    selectedFloor,
  ]);

  useEffect(() => {
    handleSelectedAsset(selectedAsset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset]);

  useEffect(() => {
    if (selectedFloor?.floor_name !== currentFloor?.floor_name) {
      setCurrentFloor(selectedFloor);
      setUpdateLocations(true);
    }

    const floorsList = selectedFloor?.assets?.map(
      asset => asset.properties.type,
    );

    let filteredList = new Set();

    floorsList?.forEach(floor => filteredList.add(floor));

    filteredList = Array.from(filteredList);

    changeAssetTypes(filteredList);
    setSelectedFloorFiltered(selectedFloor);
    handleFilteredList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloor, selectedFilter]);

  useEffect(() => {
    if (
      selectedBuilding?.properties?.name !== currentBuilding?.properties?.name
    ) {
      setCurrentBuilding(selectedBuilding);
      setUpdateLocations(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding, currentBuilding]);

  useEffect(() => {
    if (isStyleLoaded) {
      changeSelectedFilter('all');
      toggleLayersVisibility();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFloor]);

  useEffect(() => {
    setUpdateLocations(true);
    handleFilteredList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredStatus]);

  useEffect(() => {
    const floor =
      selectedBuilding?.properties?.floors[selectedFloor?.floor_number];

    changeSelectedFloor(floor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding]);

  useEffect(() => {
    if (!floorDetailsVisibility) toggleLayersVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorDetailsVisibility]);

  useEffect(() => {
    if (window.map) {
      window.map.on('load', () => {
        if (selectedFloor) {
          setShowingFloorLayer(true);
          layers.forEach(layer => {
            window.map.setLayoutProperty(layer, 'visibility', 'visible');

            window.map.setFilter(layer, [
              '==',
              ['get', 'item_id'],
              `${selectedBuilding.properties.item_id}-${currentFloor.floor_number}`,
            ]);
          });

          window.map.setLayoutProperty('custom_building', 'visibility', 'none');
          window.map.setLayoutProperty('3d-buildings', 'visibility', 'none');

          const filteredAssets = selectedFloorFiltered?.assets.map(
            asset => asset?.properties?.item_id,
          );

          if (filteredAssets?.length > 0) {
            window.map.setFilter('unclustered-point', [
              'all',
              [
                '==',
                ['get', 'location_id'],
                `${selectedBuilding?.properties?.item_id}`,
              ],
              ['==', ['get', 'floor'], `${currentFloor?.floor_number}`],
              ['match', ['get', 'item_id'], filteredAssets, true, false],
            ]);
          }
        }
      });
    }
  }, [selectedFloor, currentFloor, selectedBuilding, selectedFloorFiltered]);

  if (!selectedFloorFiltered && !floorDetailsVisibility) {
    return null;
  }

  return (
    selectedFloorFiltered &&
    floorDetailsVisibility && (
      <S.FloorDetails
        $hideDrawers={hideDrawers || !drawerIsOpen}
        $openSide={floorDetailsVisibility}
        $shouldCollapse={shouldCollapse}
      >
        <S.CollapseMenuButton
          onClick={() => setShouldCollapse(prev => !prev)}
          shouldCollapse={shouldCollapse}
        >
          <img src={arrow} alt="colapse menu" />
        </S.CollapseMenuButton>
        {isTabletOrMobile && (
          <S.GoBackItemContainer>
            <S.GoBackItem onClick={() => changeSelectedFloor(null)}>
              <S.Chevron>
                <i className="fas fa-chevron-left" aria-hidden="true" />
              </S.Chevron>
              {t('components.floor_details.back_to')}
            </S.GoBackItem>
          </S.GoBackItemContainer>
        )}
        <S.Header>
          <div>
            <h4>{selectedFloorFiltered.floor_name}</h4>
            <S.AssetsCount>
              {selectedFloorFiltered.assets_count > 0
                ? String(selectedFloorFiltered.assets_count).padStart(2, '0')
                : 0}
              {t('components.floor_details.assets_count', {
                count: selectedFloorFiltered.assets_count !== 1,
              })}
            </S.AssetsCount>
          </div>
          <S.Resfresh>
            <CustomTooltip title={t('components.floor_details.refresh')}>
              <S.RefreshIcon
                src={reload}
                alt="Reload carts positioning."
                onClick={handleRefreshLocations}
                $isLoading={isFetching}
              />
            </CustomTooltip>
            <LastUpdatedPopover lastUpdated={lastUpdated} />
          </S.Resfresh>
        </S.Header>

        <S.AssetsStatus>
          <StatusIconsFilter statusCount={selectedFloorFiltered.status_count} />
        </S.AssetsStatus>

        <S.Select
          onChange={event => handleSelectFilter(event.target.value)}
          value={selectedFilter}
        >
          <option value="all">{t('components.floor_details.select')}</option>
          {assetTypes?.length &&
            assetTypes.map(filter => (
              <option key={filter} value={filter}>
                {filter}
              </option>
            ))}
        </S.Select>

        <S.AssetsList isEmptyList={!selectedFloorFiltered?.assets}>
          {selectedFloorFiltered?.assets?.length ? (
            selectedFloorFiltered.assets.map(asset => (
              <AssetItemFloorList
                key={`${asset?.properties?.item_id}-${asset?.properties?.item_id}`}
                asset={asset}
                setSelectedAsset={selected => handleSelectedAsset(selected)}
                selectedAsset={selectedAsset}
              />
            ))
          ) : (
            <S.EmptyList>{t('components.floor_details.empty')}</S.EmptyList>
          )}
        </S.AssetsList>
      </S.FloorDetails>
    )
  );
};

FloorDetails.propTypes = {
  drawerIsOpen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(FloorDetails);
