/* eslint-disable unused-imports/no-unused-imports */
/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React from 'react';
import { Chip, TextField, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import * as S from './style';
import CustomLabel from '../CustomLabel';
import ErrorFeedbackP from '../ErrorFeedbackP';

const CustomAutocomplete = ({
  tags,
  selected,
  chosenTags,
  onBlur,
  id,
  label,
  required,
  touched,
  error,
  loading,
  disabled,
  deletableTag,
  noMarginTop,
  labelInside,
  variant,
  multiple,
}) => {
  return (
    <>
      {!labelInside && (
        <CustomLabel
          $noMarginTop={noMarginTop}
          required={required}
          htmlFor={id}
        >
          {label}
        </CustomLabel>
      )}
      <S.Wrapper $hasIcon={labelInside}>
        <Autocomplete
          id={id}
          data-testid={id}
          multiple={multiple}
          loading={loading}
          options={tags}
          disabled={disabled}
          getOptionLabel={option => option}
          getOptionDisabled={opt => !deletableTag(opt)}
          value={selected || []}
          onChange={(_event, value, reason) => chosenTags(value, reason)}
          noOptionsText="This list is empty."
          includeInputInList
          renderInput={params => (
            <TextField
              {...params}
              variant={variant}
              data-testid="autocomplete-input"
              onBlur={onBlur}
              fullWidth
            />
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                sx={{
                  height: '1.8rem',
                  backgroundColor: '#02BDBD',
                  color: '#FFFFFF',
                }}
                {...getTagProps({ index })}
                label={option}
                onDelete={
                  deletableTag(option) ? getTagProps({ index }).onDelete : null
                }
              />
            ));
          }}
        />
      </S.Wrapper>
      {touched && error && <ErrorFeedbackP touched={touched} error={error} />}
    </>
  );
};

CustomAutocomplete.defaultProps = {
  touched: null,
  error: null,
  loading: false,
  disabled: false,
  deletableTag: () => true,
  noMarginTop: false,
  labelInside: false,
  multiple: true,
  variant: 'standard',
};

CustomAutocomplete.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  chosenTags: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  deletableTag: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  noMarginTop: PropTypes.bool,
  labelInside: PropTypes.bool,
  multiple: PropTypes.bool,
  touched: PropTypes.any,
  error: PropTypes.string,
  variant: PropTypes.string,
};

export default CustomAutocomplete;
