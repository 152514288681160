import { del, get, post, put } from '../axios/axiosGateway';

export const getGroups = params => {
  return get('/groups', params);
};

export const postGroup = data => {
  return post(`/groups`, data);
};

export const putGroup = (data, params) => {
  return put(`/groups`, data, params);
};

export const deleteGroup = item_id => {
  return del(`/groups`, { item_id });
};
