/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
import {
  FETCH_NOTIFICATIONS,
  RECEIVED_NOTIFICATIONS,
  NOTIFICATIONS_ERROR,
  ADD_NOTIFICATIONS,
  ADDED_NOTIFICATIONS,
  DISMISS_NOTIFICATIONS,
  DISMISSED_NOTIFICATIONS,
  WILL_SINGLE_DISMISS,
  DID_SINGLE_DISMISS,
} from './actionTypes';
import {
  getNotifications,
  putNotification,
} from '../../services/notificationService';

const beforeAction = type => ({
  type,
});

const afterAction = (type, value) => ({
  type,
  newValue: value,
});

const receivedError = error => ({
  type: NOTIFICATIONS_ERROR,
  error,
});

export const fetchNotifications = () => {
  return dispatch => {
    dispatch(beforeAction(FETCH_NOTIFICATIONS));

    return getNotifications()
      .then(res => res.data)
      .then(res => {
        if (!res.success) dispatch(receivedError(res));

        dispatch(afterAction(RECEIVED_NOTIFICATIONS, res.results));
      })
      .catch(err => dispatch(receivedError(err)));
  };
};

export const dismissNotification = (
  itemID,
  all = false,
  callback = () => null,
  industries = [],
) => {
  return dispatch => {
    const params = {};
    if (itemID) {
      params.item_id = itemID;
      dispatch(beforeAction(WILL_SINGLE_DISMISS));
    } else if (all) {
      params.all = all;
      dispatch(beforeAction(DISMISS_NOTIFICATIONS));
    }

    return putNotification({ industries }, params)
      .then(res => res.data)
      .then(res => {
        callback(res);

        if (itemID) {
          dispatch(afterAction(DID_SINGLE_DISMISS, itemID));
        } else if (all) {
          dispatch(
            afterAction(DISMISSED_NOTIFICATIONS, {
              ...res.results,
              industries,
            }),
          );
        }
      })
      .catch(err => dispatch(receivedError(err)));
  };
};

export const addNotification = notification => {
  return dispatch => {
    dispatch(beforeAction(ADD_NOTIFICATIONS));

    return Promise.resolve(notification).then(res => {
      dispatch(afterAction(ADDED_NOTIFICATIONS, res));
    });
  };
};
