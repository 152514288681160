/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const GroupedButtons = ({ buttons, buttonState, onClick }) => {
  const [selected, setSelected] = useState(
    !buttonState ? 'button-0' : `button-${buttonState}`,
  );

  useEffect(() => {
    setSelected(`button-${buttonState}`);
  }, [buttonState]);

  const handleClick = e => {
    e.target.blur();

    if (onClick)
      onClick({
        text: e.target.innerText,
        index: parseInt(e.target.id.replace('button-', ''), 10),
      });
  };

  const renderButtons = btns =>
    btns.map((buttonName, i) => (
      <S.Button
        key={buttonName}
        id={`button-${i}`}
        $side={i === 0}
        selected={selected === `button-${i}`}
        onClick={handleClick}
      >
        {buttonName}
      </S.Button>
    ));

  return (
    <S.GroupedButtons id="GroupedButtons">
      <S.BtnsGroup>{renderButtons(buttons)}</S.BtnsGroup>
    </S.GroupedButtons>
  );
};

GroupedButtons.defaultProps = {
  buttonState: null,
};

GroupedButtons.propTypes = {
  buttonState: PropTypes.number,
  buttons: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GroupedButtons;
