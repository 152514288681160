/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, {
  useEffect,
  useState,
  forwardRef,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as S from './style';
import { useGeneralContext } from '../../context/GeneralContext';
import {
  addNotification,
  dismissNotification,
  fetchNotifications,
} from '../../redux/actions/notificationActions';
import { clickOnWellList } from '../../redux/actions';
import Notification from '../Notification';
import { withClickOutside } from '../Wrappers/WithClickOutside';
import { useMQTTContext } from '../../context/MQTTContext';

const TOPIC = 'notification/assets/_platform';

const NotificationContainer = forwardRef(
  (
    { onUpdateCounter, switchNotification, containerState, background },
    ref,
  ) => {
    const notifications = useSelector(
      store => store.notificationState.notifications,
    );
    const industryFilterState = useSelector(store => store.industryFilterState);

    const dispatch = useDispatch();
    const fetchNotificationsDispatcher = useCallback(
      () => dispatch(fetchNotifications()),
      [dispatch],
    );
    const addNotificationDispatcher = useCallback(
      notification => dispatch(addNotification(notification)),
      [dispatch],
    );
    const dismissNotificationDispatcher = useCallback(
      (...props) => dispatch(dismissNotification(...props)),
      [dispatch],
    );
    const clickOnWellListDispatcher = useCallback(
      () => dispatch(clickOnWellList()),
      [dispatch],
    );

    const isSmallMobile = useMediaQuery('(max-width: 450px)');
    const [dismissingAll, setDismissingAll] = useState(false);
    const { openToast } = useGeneralContext();
    const { mqttClient } = useMQTTContext();

    const { t } = useTranslation();

    const filteredNotificationsByIndustry = useMemo(
      () =>
        notifications.filter(
          notification =>
            industryFilterState[JSON.parse(notification.message).industry],
        ),
      [industryFilterState, notifications],
    );

    const handleLiveNotification = useCallback(
      (topic, message) => {
        if (topic === TOPIC) {
          try {
            const notification = JSON.parse(message.toString());

            if (notification.message && notification.created_at) {
              addNotificationDispatcher(notification);
            }
          } catch (error) {
            console.error(error);
          }
        }
      },
      [addNotificationDispatcher],
    );

    const getToast = useCallback(
      (res, err) => {
        const errMsg = err || '';
        if (res.success) {
          setDismissingAll(false);
        } else {
          setDismissingAll(false);
          openToast(
            `${
              t('components.notification_container.validation.delete_error') +
              errMsg
            }`,
            'error',
          );
        }
      },
      [openToast, t],
    );

    useEffect(() => {
      fetchNotificationsDispatcher();

      if (mqttClient) {
        try {
          mqttClient.subscribe(TOPIC, err => {
            if (err) console.error(err);
          });

          mqttClient.on('message', handleLiveNotification);
        } catch (error) {
          console.error(error);
        }
      }

      return () => {
        try {
          mqttClient?.unsubscribe(TOPIC, {}, err =>
            console.error(`Error to unsubscribe from ${TOPIC}: ${err}`),
          );
        } catch (err) {
          console.error(err);
        }
      };
    }, [fetchNotificationsDispatcher, handleLiveNotification, mqttClient]);

    useEffect(() => {
      onUpdateCounter?.(filteredNotificationsByIndustry.length);
    }, [filteredNotificationsByIndustry.length, onUpdateCounter]);

    const dismissAll = () => {
      const industriesToDelete = Object.keys(industryFilterState).reduce(
        (memo, k) => {
          if (industryFilterState[k]) {
            return [...memo, k];
          }
          return [...memo];
        },
        [],
      );

      setDismissingAll(true);
      dismissNotificationDispatcher(
        null,
        true,
        getToast,
        industriesToDelete,
      ).then(() => switchNotification());
    };

    const singleDismiss = useCallback(
      (item_id, errorCallback) => {
        dismissNotificationDispatcher(item_id, false, errorCallback);
      },
      [dismissNotificationDispatcher],
    );

    const dangerNotifications = useMemo(
      () =>
        filteredNotificationsByIndustry
          .filter(notification => notification.status === 'Danger')
          .map(notification => (
            <Notification
              key={notification.item_id}
              notification={notification}
              onDismiss={singleDismiss}
              dismissAll={dismissingAll}
              clickOnWellList={clickOnWellListDispatcher}
              getToast={getToast}
            />
          )),
      [
        clickOnWellListDispatcher,
        dismissingAll,
        filteredNotificationsByIndustry,
        getToast,
        singleDismiss,
      ],
    );

    const warningNotifications = useMemo(
      () =>
        filteredNotificationsByIndustry
          .filter(notification => notification.status === 'Warning')
          .map(notification => (
            <Notification
              key={notification.item_id}
              notification={notification}
              onDismiss={singleDismiss}
              dismissAll={dismissingAll}
              clickOnWellList={clickOnWellListDispatcher}
              getToast={getToast}
            />
          )),
      [
        clickOnWellListDispatcher,
        dismissingAll,
        filteredNotificationsByIndustry,
        getToast,
        singleDismiss,
      ],
    );

    const normalNotifications = useMemo(
      () =>
        filteredNotificationsByIndustry
          .filter(notification => notification.status === 'Normal')
          .map(notification => (
            <Notification
              key={notification.item_id}
              notification={notification}
              onDismiss={singleDismiss}
              dismissAll={dismissingAll}
              clickOnWellList={clickOnWellListDispatcher}
              getToast={getToast}
            />
          )),
      [
        clickOnWellListDispatcher,
        dismissingAll,
        filteredNotificationsByIndustry,
        getToast,
        singleDismiss,
      ],
    );

    const statelessNotifications = useMemo(
      () =>
        filteredNotificationsByIndustry
          .filter(notification => notification.status === '')
          .map(notification => (
            <Notification
              key={notification.item_id}
              notification={notification}
              onDismiss={singleDismiss}
              dismissAll={dismissingAll}
              clickOnWellList={clickOnWellListDispatcher}
              getToast={getToast}
            />
          )),
      [
        clickOnWellListDispatcher,
        dismissingAll,
        filteredNotificationsByIndustry,
        getToast,
        singleDismiss,
      ],
    );

    return (
      <S.NotificationContainer
        className={`NotificationContainer ${containerState}`}
        $open={containerState === 'open'}
        $isSmallMobile={isSmallMobile}
        ref={ref}
        style={{ backgroundColor: background || 'transparent' }}
      >
        {filteredNotificationsByIndustry.length ? (
          <>
            <S.Dismiss>
              <S.DismissButton type="button" onClick={dismissAll}>
                {t('components.notification_container.dismiss_button')}
              </S.DismissButton>
            </S.Dismiss>
            <S.NotificationList>
              <div>{dangerNotifications}</div>
              <div>{warningNotifications}</div>
              <div>{normalNotifications}</div>
              <div>{statelessNotifications}</div>
            </S.NotificationList>
          </>
        ) : (
          <S.NoNotifications>
            {t('components.notification_container.validation.empty')}
          </S.NoNotifications>
        )}
      </S.NotificationContainer>
    );
  },
);

NotificationContainer.defaultProps = {
  background: 'null',
};

NotificationContainer.propTypes = {
  containerState: PropTypes.string.isRequired,
  onUpdateCounter: PropTypes.func.isRequired,
  switchNotification: PropTypes.func.isRequired,
  background: PropTypes.string,
};

NotificationContainer.displayName = 'NotificationContainer';

export default withClickOutside(NotificationContainer);
