import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomAutocomplete from '../../atoms/CustomAutocomplete';
import { useUserGroups } from '../../../../hooks/useUserGroups';
import { useGeneralContext } from '../../../../context/GeneralContext';
import convertToStringOnly from '../../../../utils/convertToStringOnly';
import getNames from '../../../../utils/getNames';

const UserGroupSelect = ({
  setFieldValue,
  setFieldTouched,
  handleBlur,
  touched,
  errors,
  value,
  disabled,
  disableFillAdmin,
  resetForm,
  initialValues,
}) => {
  const { data: userGroups, isFetching } = useUserGroups();
  const { openToast } = useGeneralContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      !isFetching &&
      userGroups.length > 0 &&
      value.length > 0 &&
      value.filter(v => !userGroups?.find(user => user.item_id === v)).length >
        0
    ) {
      setFieldTouched('user_group');
      setFieldValue(
        'user_group',
        value.filter(v => userGroups?.find(user => user.item_id === v)),
      );
    }
  }, [isFetching, setFieldTouched, setFieldValue, userGroups, value]);

  useEffect(() => {
    if (
      !disableFillAdmin &&
      userGroups.length > 0 &&
      value.length === 0 &&
      userGroups?.find(user => user.name === 'Administrator')
    ) {
      if (resetForm && initialValues) {
        setTimeout(() => {
          resetForm({
            values: {
              ...initialValues,
              user_group: [
                userGroups?.find(user => user.name === 'Administrator')
                  ?.item_id,
              ],
            },
          });
        }, 100);
      } else {
        setFieldValue('user_group', [
          userGroups?.find(user => user.name === 'Administrator')?.item_id,
        ]);
      }
    }
  }, [
    disableFillAdmin,
    initialValues,
    isFetching,
    resetForm,
    setFieldValue,
    userGroups,
    value,
  ]);

  const getUserGroupsId = selectedTags => {
    if (selectedTags.length && userGroups?.length) {
      return selectedTags?.map(selectedTag => {
        return userGroups?.find(user => user.name === selectedTag)?.item_id;
      });
    }
    return [];
  };

  const handleUsersChange = (tags, reason) => {
    if (!disableFillAdmin && reason === 'clear') {
      setFieldValue('user_group', [
        userGroups?.find(user => user.name === 'Administrator')?.item_id,
      ]);
    } else if (!disableFillAdmin && !tags.includes('Administrator')) {
      openToast(
        t('molecules.user_group_select.warning_administrator'),
        'warning',
      );
    } else {
      setFieldValue('user_group', getUserGroupsId(tags));
    }
  };

  return (
    <CustomAutocomplete
      id="user_group"
      label={t('molecules.user_group_select.text_input')}
      required
      loading={isFetching}
      tags={convertToStringOnly(userGroups)}
      selected={getNames(value, userGroups)}
      onBlur={handleBlur}
      chosenTags={handleUsersChange}
      touched={touched.user_group}
      error={errors.user_group}
      disabled={disabled}
      deletableTag={opt => disableFillAdmin || opt !== 'Administrator'}
    />
  );
};

UserGroupSelect.defaultProps = {
  disabled: false,
  disableFillAdmin: false,
  initialValues: null,
  resetForm: null,
};

UserGroupSelect.propTypes = {
  errors: PropTypes.shape({
    user_group: PropTypes.string,
  }).isRequired,
  value: PropTypes.any.isRequired,
  initialValues: PropTypes.any,
  disabled: PropTypes.bool,
  disableFillAdmin: PropTypes.bool,
  handleBlur: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  resetForm: PropTypes.func,
  touched: PropTypes.shape({
    user_group: PropTypes.any,
  }).isRequired,
};

export default UserGroupSelect;
