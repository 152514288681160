/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import {
  FETCH_ASSETS,
  UPDATED_ASSETS_QUERY,
  RECEIVED_ASSETS,
  DELETE_ASSETS_PENDING,
  DELETE_ASSETS_FULFILLED,
  UPDATE_ASSETS_FULFILLED,
  UPDATE_ASSETS_PENDING,
  ADD_ASSETS_PENDING,
  ADD_ASSETS_FULFILLED,
  RECEIVED_ERROR,
  RESET_ASSET_ERROR,
  UPDATE_LOCATION_TO_FLY_ID,
} from '../actions/actionTypes';

const initialState = {
  assets: [],
  assetsQueryParams: {},
  lastCreation: null,
  isPending: null,
  message: null,
  error: null,
};

export const assetsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ASSETS:
      return {
        ...state,
        error: null,
        message: null,
        isPending: true,
      };

    case UPDATED_ASSETS_QUERY:
      return {
        ...state,
        assetsQueryParams: action.newValue,
      };

    case RECEIVED_ASSETS:
      return {
        ...state,
        assets: action.newValue,
        message: null,
        isPending: false,
      };

    case ADD_ASSETS_PENDING:
      return {
        ...state,
        isPending: true,
        lastCreation: null,
        error: null,
        message: null,
      };

    case ADD_ASSETS_FULFILLED:
      return {
        ...state,
        assets: {
          ...state.assets,
          items: (state.assets.items || []).concat([action.newValue]),
        },
        isPending: false,
        lastCreation: action.newValue,
        message: 'Asset created successfully!',
      };
    case UPDATE_LOCATION_TO_FLY_ID: {
      return {
        ...state,
        locationToFlyId: action.locationToFlyId,
      };
    }
    case UPDATE_ASSETS_PENDING:
      return {
        ...state,
        isPending: true,
        error: null,
        message: null,
      };

    case UPDATE_ASSETS_FULFILLED:
      return {
        ...state,
        assets: [state.assets || state.assets.items].map(asset =>
          asset.item_id !== action.newValue.item_id ? asset : action.newValue,
        ),
        isPending: false,
        message: 'Asset updated successfully!',
      };

    case DELETE_ASSETS_PENDING:
      return {
        ...state,
        isPending: true,
        error: null,
        message: null,
      };

    case DELETE_ASSETS_FULFILLED:
      return {
        ...state,
        assets: {
          ...state.assets,
          items: state.assets.items.filter(
            asset => asset.item_id !== action.item_id,
          ),
        },
        isPending: false,
        message: 'Asset deleted successfully!',
      };

    case RECEIVED_ERROR:
      return {
        ...state,
        error: action.error,
        isPending: false,
      };

    case RESET_ASSET_ERROR:
      return {
        ...state,
        error: null,
        isPending: false,
      };

    default:
      return state;
  }
};
