/* eslint-disable unused-imports/no-unused-imports */
import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckCircle,
  Report,
  Warning,
  Wifi,
  WifiOff,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { statusColorObj } from '../../../../themes';
import CustomTooltip from '../CustomTooltip';

const AssetIcon = ({ status, includeTooltip }) => {
  const { t } = useTranslation();
  const statusTxt = (status || '').toLowerCase();
  let icon = null;

  switch (statusTxt) {
    case 'warning':
      icon = (
        <Warning
          sx={{
            color: statusColorObj.warning,
            WebkitFilter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
            filter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
          }}
        />
      );
      break;
    case 'danger':
      icon = (
        <Report
          sx={{
            color: statusColorObj.danger,
            WebkitFilter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
            filter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
          }}
        />
      );
      break;
    case 'normal':
      icon = (
        <CheckCircle
          sx={{
            color: statusColorObj.normal,
            WebkitFilter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
            filter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
          }}
        />
      );
      break;
    case 'nothreshold':
      icon = (
        <Wifi
          sx={{
            color: statusColorObj.primary,
            WebkitFilter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
            filter:
              'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7)) brightness(1.75)',
          }}
        />
      );
      break;
    default:
      icon = (
        <WifiOff
          sx={{
            color: statusColorObj.off,
            WebkitFilter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
            filter:
              'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7)) brightness(1.75)',
          }}
        />
      );
  }
  if (includeTooltip) {
    return (
      <CustomTooltip
        title={t(`components.location.status.${statusTxt.toUpperCase()}`)}
      >
        {icon}
      </CustomTooltip>
    );
  }
  return icon;
};

AssetIcon.defaultProps = {
  includeTooltip: false,
};

AssetIcon.propTypes = {
  status: PropTypes.string.isRequired,
  includeTooltip: PropTypes.bool,
};

export default AssetIcon;
