import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Stack } from '@mui/material';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../CustomSelect';
import HistoryRange from '../../../../HistoryRange';
import CustomLabel from '../../CustomLabel';
import { useAssets } from '../../../../../hooks/useAssets';
import SensorSelector from './SensorSelector';
import ErrorFeedbackP from '../../ErrorFeedbackP';
import CustomFormControl from '../../CustomFormControl';

const ChartSelector = ({
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
  submitForm,
  location,
  config,
}) => {
  const { data: assets } = useAssets({ location_id: location });
  const { t } = useTranslation();

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={2} sx={{ width: '500px' }}>
        <Grid item xs={12}>
          <CustomFormControl
            id="title"
            label={t('atoms.widget.selector.title')}
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelect
            id="chart"
            label={t('atoms.widget.selector.chart')}
            options={(config.options || []).map(c => ({
              value: c,
              label: t(`atoms.widget.selector.options.${c}`),
            }))}
            value={values.chart}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Stack justifyContent="flex-start" alignItems="flex-start">
            <CustomLabel htmlFor="time-range" required>
              {t('atoms.widget.selector.time_range')}
            </CustomLabel>
            <HistoryRange
              id="timeRange"
              value={values.timeRange}
              setTimeRange={value =>
                handleChange({ target: { value, id: 'timeRange' } })
              }
              hideCustom
              handleBlur={handleBlur}
              fullWidth
            />
            <ErrorFeedbackP
              touched={touched.timeRange}
              error={errors.timeRange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <FieldArray
            name="items"
            render={arrayHelpers =>
              values.items?.map((item, index) => (
                <SensorSelector
                  key={item.sensor}
                  assets={assets}
                  values={values}
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  index={index}
                  isList
                />
              ))
            }
          />
        </Grid>
      </Grid>
    </form>
  );
};

ChartSelector.propTypes = {
  errors: PropTypes.any.isRequired,
  handleBlur: PropTypes.any.isRequired,
  handleChange: PropTypes.any.isRequired,
  location: PropTypes.string.isRequired,
  submitForm: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
  config: PropTypes.any.isRequired,
  values: PropTypes.shape({
    chart: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.array,
    timeRange: PropTypes.string,
  }).isRequired,
};

export default ChartSelector;
