import { useQuery } from '@tanstack/react-query';
import { getServiceLogs } from '../services/serviceLogService';

const getSeviceTickets = async params => {
  const {
    data: { results },
  } = await getServiceLogs(params);
  return results || [];
};

export const useServiceTickets = params => {
  return useQuery({
    queryKey: ['serviceTickets', JSON.stringify(params)],
    queryFn: () => getSeviceTickets(params),
    placeholderData: [],
    staleTime: 0,
    refetchInterval: 1000 * 60 * 1, // 1 minute,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });
};
