/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { withClickOutside } from '../Wrappers/WithClickOutside';
import * as S from './style';
import formatLatLng from '../../utils/formatLatLng';
import { useLocationsCacheContext } from '../../context/LocationsCacheContext';

const AssetPositionModal = forwardRef(({ asset, onClose }, ref) => {
  const { locations } = useLocationsCacheContext();
  const [currentAsset, setCurrentAsset] = useState({});
  const { t } = useTranslation();
  const history = useHistory();

  const handleAssetPositionAction = () => {
    history.push(`${asset?.properties.item_id}/position`);
    onClose();
  };

  useEffect(() => {
    if (!asset?.properties?.is_indoor) {
      setCurrentAsset(asset);
    } else {
      let locationName = '';

      const currentLocation =
        locations.length &&
        locations
          .filter(loc => loc?.properties?.location_type === 'building')
          .map(loc =>
            loc?.properties?.buildings.filter(build => {
              if (
                build?.properties?.item_id === asset?.properties?.location_id
              ) {
                locationName = loc?.properties?.name;
                return build;
              }
              return false;
            }),
          );

      asset.properties.location_name = locationName;
      asset.properties.building_name =
        currentLocation?.[0]?.[0]?.properties?.name;

      setCurrentAsset(asset);
    }
  }, [asset, locations]);

  return (
    <S.Container
      ref={ref}
      className="animate__animated animate__fadeIn animate__faster"
      id="doc-modal-container"
    >
      <S.CloseButton onClick={onClose}>
        <i className="fas fa-times" />
      </S.CloseButton>
      {currentAsset?.properties?.is_indoor &&
      currentAsset?.properties?.is_building ? (
        <S.IndoorTitle>
          <S.IndoorDescription>
            <S.IndoorName>
              {t('machines.facilities.center_pane.modal.asset_position.title')}
            </S.IndoorName>
            <S.IndoorLocationName>
              {currentAsset?.properties?.location_name}
            </S.IndoorLocationName>
          </S.IndoorDescription>
          <i className="fas fa-map-marker-alt" />
        </S.IndoorTitle>
      ) : (
        <S.OutdoorTitle>
          <S.OutdoorDescription>
            {t('machines.facilities.center_pane.modal.asset_position.title')}
          </S.OutdoorDescription>
          <i className="fas fa-map-marker-alt" />
          <S.AssetName>{currentAsset?.properties?.name}</S.AssetName>
        </S.OutdoorTitle>
      )}
      {currentAsset?.properties?.is_indoor &&
      currentAsset?.properties?.is_building ? (
        <S.IndoorBody>
          <S.IndoorListElement>
            <S.Category>
              {t(
                'machines.facilities.center_pane.modal.asset_position.building',
              )}
            </S.Category>
            <S.Dots />
            <S.Value>{currentAsset?.properties?.building_name}</S.Value>
          </S.IndoorListElement>
          <S.IndoorListElement>
            <S.Category>
              {t('machines.facilities.center_pane.modal.asset_position.floor')}
            </S.Category>
            <S.Dots />
            <S.Value>{currentAsset?.properties?.floor_name}</S.Value>
          </S.IndoorListElement>
          <S.IndoorListElement>
            <S.Category>
              {t('machines.facilities.center_pane.modal.asset_position.room')}
            </S.Category>
            <S.Dots />
            <S.Value>{currentAsset?.properties?.current_position}</S.Value>
          </S.IndoorListElement>
        </S.IndoorBody>
      ) : (
        <S.OutdoorBody>
          <S.Coordinates>
            {formatLatLng(currentAsset?.properties?.lat)};{' '}
            {formatLatLng(currentAsset?.properties?.lng)}
          </S.Coordinates>
        </S.OutdoorBody>
      )}
      <S.Button onClick={handleAssetPositionAction}>
        <S.ViewDetails>
          {t(
            'machines.facilities.center_pane.modal.asset_position.view_history',
          )}
        </S.ViewDetails>
      </S.Button>
    </S.Container>
  );
});

AssetPositionModal.propTypes = {
  asset: PropTypes.shape({
    properties: PropTypes.shape({
      building_name: PropTypes.any,
      is_indoor: PropTypes.any,
      location_id: PropTypes.any,
      location_name: PropTypes.any,
      item_id: PropTypes.any,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withClickOutside(AssetPositionModal);
