/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ErrorFeedbackP } from '../../../AssetManagement/HandleAssetManagement/AssetManagementForm/style';
import InputPassword from '../../../shared/atoms/InputPassword';

const ChangeOwnPasswordForm = ({
  handleBlur,
  handleChange,
  values,
  errors,
  touched,
  isValid,
  setNewPassValid,
}) => {
  useEffect(() => {
    setNewPassValid(isValid);
  }, [isValid, setNewPassValid]);

  const { t } = useTranslation();

  return (
    <Form style={{ textAlign: 'left' }}>
      <Form.Label>
        {t('modal.change_password.label', { label_type: 'current' })}
      </Form.Label>
      <InputPassword
        name="oldPassword"
        value={values.oldPassword}
        onBlur={handleBlur}
        onChange={handleChange}
        maxLength="255"
        type="password"
        autoComplete="new-password"
      />
      {touched.oldPassword && errors.oldPassword ? (
        <ErrorFeedbackP style={{ paddingTop: '10px' }}>
          {errors.oldPassword}
        </ErrorFeedbackP>
      ) : null}
      <Form.Label>
        {t('modal.change_password.label', { label_type: 'new' })}
      </Form.Label>
      <InputPassword
        name="newPassword"
        value={values.newPassword}
        onBlur={handleBlur}
        onChange={handleChange}
        maxLength="255"
        type="password"
        autoComplete="new-password"
      />
      {touched.newPassword && errors.newPassword ? (
        <ErrorFeedbackP style={{ paddingTop: '10px' }}>
          {errors.newPassword}
        </ErrorFeedbackP>
      ) : null}
      <Form.Label>
        {t('modal.change_password.label', { label_type: 'confirm' })}
      </Form.Label>
      <InputPassword
        name="confirmPassword"
        value={values.confirmPassword}
        onBlur={handleBlur}
        onChange={handleChange}
        maxLength="255"
        type="password"
        autoComplete="new-password"
      />
      {touched.confirmPassword && errors.confirmPassword ? (
        <ErrorFeedbackP style={{ paddingTop: '10px' }}>
          {errors.confirmPassword}
        </ErrorFeedbackP>
      ) : null}
    </Form>
  );
};

ChangeOwnPasswordForm.displayName = 'ChangeOwnPasswordForm';
export default ChangeOwnPasswordForm;

ChangeOwnPasswordForm.propTypes = {
  errors: PropTypes.shape({
    oldPassword: PropTypes.any,
    confirmPassword: PropTypes.any,
    newPassword: PropTypes.any,
  }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.any.isRequired,
  setNewPassValid: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    oldPassword: PropTypes.any,
    confirmPassword: PropTypes.any,
    newPassword: PropTypes.any,
  }).isRequired,
  values: PropTypes.shape({
    oldPassword: PropTypes.any,
    confirmPassword: PropTypes.any,
    newPassword: PropTypes.any,
  }).isRequired,
};
