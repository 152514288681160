export default (selectedIds, list, id = 'item_id') => {
  if (selectedIds?.length && list?.length) {
    return selectedIds
      ?.filter(selectedId => list?.find(item => item[id] === selectedId))
      .map(selectedId => {
        return list?.find(item => item[id] === selectedId)?.name;
      });
  }
  return [];
};
