import PropTypes from 'prop-types';
import React from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

const CustomListItemButton = ({
  icon,
  text,
  onClick,
  selected,
  smallHeight,
  paddingLeft,
}) => {
  return (
    <ListItem disablePadding dense>
      <ListItemButton
        onClick={onClick}
        selected={selected}
        sx={theme => ({
          padding: smallHeight ? '0 1rem 0 1rem' : null,
          paddingLeft: paddingLeft ?? '1rem',

          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.secondary,

            '.MuiListItemText-primary': {
              fontWeight: 600,
            },

            svg: {
              color: theme.palette.text.secondary,
            },

            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              color: 'white',

              svg: {
                color: 'white',
              },
            },
          },
        })}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

CustomListItemButton.defaultProps = {
  selected: false,
  smallHeight: false,
  paddingLeft: '',
};

CustomListItemButton.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  smallHeight: PropTypes.bool,
  paddingLeft: PropTypes.string,
};

export default CustomListItemButton;
