import PropTypes from 'prop-types';
import React, { useEffect, Suspense, useMemo } from 'react';
import { useFeatures } from '@paralleldrive/react-feature-toggles';
import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router-dom';
import { Box, ClickAwayListener, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';

import { getAllLanguagesAvailable } from '../../utils/getAllLanguagesAvailable';
import { useGeneralContext } from '../../context/GeneralContext';
import { getTokenValue } from '../../axios/axiosAuth';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/shared/atoms/Spinner';
import MainList from '../../components/LeftMenu/MainList';
import { closeAnimated } from '../../redux/actions/sideDrawerActions';
import { useLocationsCacheContext } from '../../context/LocationsCacheContext';

const languages = getAllLanguagesAvailable();

const PrivateAsyncWrapper = ({
  component: Component,
  hideNavbar,
  ...props
}) => {
  const { loading } = useGeneralContext();
  const { setShouldCollapse } = useLocationsCacheContext();
  const token = getTokenValue();
  const features = useFeatures();
  const { i18n } = useTranslation();
  const isOpen = useSelector(store => store.sideDrawerState.isOpen);
  const dispatch = useDispatch();
  const closeAnimatedDispatch = args => dispatch(closeAnimated(args));
  const isMapPage = useMemo(
    () =>
      props.location.pathname.includes('/portal/maps') &&
      !props.location.pathname.includes('/facilities'),
    [props.location.pathname],
  );
  const upFromSm = useMediaQuery(theme => theme.breakpoints.up('sm'));

  useEffect(() => {
    if (features.includes('feat')) return;
    if (
      (!features.includes('localization') && i18n.language !== 'en-US') ||
      !languages.includes(i18n.language)
    )
      i18n.changeLanguage('en-US');
  }, [features, i18n]);

  const pageDisplay =
    !loading && token ? (
      <Box
        sx={
          isOpen && !isMapPage ? { opacity: 0.2, pointerEvents: 'none' } : null
        }
        height="100%"
        marginTop={hideNavbar ? 0 : '50px'}
        marginLeft={
          !props.location.pathname.includes('login') &&
          (!props.location.pathname.includes('display') ||
            props.location.pathname.includes('displays')) &&
          !props.location.pathname.includes('resetPassword') &&
          upFromSm
            ? '72px'
            : 0
        }
      >
        <Component {...props} />
      </Box>
    ) : (
      <Redirect
        to={{
          pathname: '/portal/login',
          state: { from: props.location },
          search: props.location.search,
        }}
      />
    );

  const navBarDisplay = !hideNavbar && (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        if (!isMapPage && isOpen) {
          setShouldCollapse(true);
          closeAnimatedDispatch();
        }
      }}
    >
      <Box>
        <Navbar />
        <MainList />
      </Box>
    </ClickAwayListener>
  );

  return loading ? (
    <>
      {navBarDisplay}
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
        }}
      >
        <Spinner changecolor="true" />
      </div>
    </>
  ) : (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      {navBarDisplay}
      <Suspense
        fallback={
          <Box display="flex" height="100%" alignItems="center">
            <Spinner />
          </Box>
        }
      >
        {pageDisplay}
      </Suspense>
    </DndProvider>
  );
};

PrivateAsyncWrapper.propTypes = {
  component: PropTypes.elementType.isRequired,
  hideNavbar: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const PrivateRoute = ({ component: Component, hideNavbar, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <PrivateAsyncWrapper
          {...props}
          component={Component}
          hideNavbar={hideNavbar}
        />
      )}
    />
  );
};

PrivateRoute.defaultProps = {
  hideNavbar: false,
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  hideNavbar: PropTypes.bool,
};

export default PrivateRoute;
