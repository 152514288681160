import PropTypes from 'prop-types';
import { IconButton, Stack } from '@mui/material';
import React from 'react';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../CustomSelect';
import { useSensors } from '../../../../../../hooks/useSensors';
import CustomTooltip from '../../../CustomTooltip';

const SensorSelector = ({
  assets,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  index,
  arrayHelpers,
  isList,
}) => {
  const { data: sensors } = useSensors({
    asset_id: isList ? values.items[index].asset : values.asset,
  });
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="flex-start" spacing={2}>
      <Stack
        width={`calc(100% - ${isList ? 100 : 0}px)`}
        direction="row"
        alignItems="flex-start"
        spacing={2}
      >
        <CustomSelect
          id={isList ? `items.${index}.asset` : 'asset'}
          label={t('atoms.widget.selector.asset')}
          options={assets.map(asset => ({
            value: asset.item_id,
            label: asset.name,
          }))}
          value={isList ? values.items[index].asset : values.asset}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
          touched={touched}
          required
        />
        <CustomSelect
          id={isList ? `items.${index}.sensor` : 'sensor'}
          label={t('atoms.widget.selector.sensor')}
          options={sensors.map(sensor => ({
            value: sensor.item_id,
            label: sensor.name,
          }))}
          value={isList ? values.items[index].sensor : values.sensor}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
          touched={touched}
          disabled={sensors.length === 0}
          required
        />
      </Stack>
      {isList && values.items.length - 1 === index && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          paddingTop="50px"
        >
          <CustomTooltip title={t('atoms.widget.selector.add')}>
            <span>
              <IconButton
                color="primary"
                sx={{ padding: 0 }}
                disabled={values.items.length > 3}
                onClick={() => arrayHelpers.push({ asset: '', sensor: '' })}
              >
                <AddCircle fontSize="large" />
              </IconButton>
            </span>
          </CustomTooltip>
          <CustomTooltip title={t('atoms.widget.selector.remove')}>
            <span>
              <IconButton
                color="primary"
                sx={{ padding: 0 }}
                disabled={index === 0}
                onClick={() => arrayHelpers.remove(index)}
              >
                <RemoveCircle fontSize="large" />
              </IconButton>
            </span>
          </CustomTooltip>
        </Stack>
      )}
    </Stack>
  );
};

SensorSelector.propTypes = {
  arrayHelpers: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  assets: PropTypes.array.isRequired,
  errors: PropTypes.any.isRequired,
  handleBlur: PropTypes.any.isRequired,
  handleChange: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  isList: PropTypes.bool.isRequired,
  touched: PropTypes.any.isRequired,
  values: PropTypes.shape({
    asset: PropTypes.string,
    items: PropTypes.shape({
      length: PropTypes.number,
    }),
    sensor: PropTypes.string,
  }).isRequired,
};

export default SensorSelector;
