/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, IconButton, ListItemButton, Stack } from '@mui/material';
import { Construction } from '@mui/icons-material';

import * as S from './style';
import Spinner from '../../../shared/atoms/Spinner';
import { clickOnWellList } from '../../../../redux/actions';
import { industryDefaults } from '../../../../themes';
import AssetIcon from '../../../shared/atoms/AssetIcon';
import { useRolePermissions } from '../../../../hooks/useRolePermissions';
import { updatedServiceLogsQuery } from '../../../../redux/actions/serviceLogActions';
import formatLatLng from '../../../../utils/formatLatLng';
import { useLocationsCacheContext } from '../../../../context/LocationsCacheContext';

const AssetItem = ({ group, serviceLogs, assetId }) => {
  const location = useLocation();
  const history = useHistory();
  const { data: userPermissions } = useRolePermissions();
  const { assets } = useLocationsCacheContext();

  const [arrowDirection, setArrowDirection] = useState(true);
  const asset = useMemo(
    () => assets.find(a => a.properties.item_id === assetId) || {},
    [assetId, assets],
  );
  const correctList = useMemo(
    () =>
      group.sensors.filter(
        item => item.sensor_asset_id === asset.properties?.item_id,
      ),
    [asset.properties?.item_id, group.sensors],
  );
  const filteredLogs = useMemo(
    () => serviceLogs?.filter(log => log.asset === assetId) || [],
    [assetId, serviceLogs],
  );

  const dispatch = useDispatch();
  const clickOnWellListDispatcher = args => dispatch(clickOnWellList(args));
  const updatedServiceLogsQueryDispatch = sensor_name =>
    dispatch(
      updatedServiceLogsQuery({
        sensor: sensor_name,
        asset: asset?.properties.name,
        keyword: sensor_name,
      }),
    );

  const changeArrowDirection = () => {
    setArrowDirection(prevState => !prevState);
  };

  const getCorrectStatus = item => {
    let status = '';

    asset.properties.sensors.map(sensor => {
      if (sensor.item_id === item.sensor_id) {
        if (sensor.state === 'off') status = 'off';
        else status = sensor.status;
      }
      return status.toLowerCase();
    });

    return status;
  };

  const goToDetails = sensor_name => {
    if (!userPermissions?.management?.['service-tickets']?.see) return;
    updatedServiceLogsQueryDispatch(sensor_name);
    history.push({
      pathname: '/portal/management/tickets/',
      search: location.search,
      state: {
        sensor_name,
        asset_name: asset.properties.name,
      },
    });
  };

  const checkHasTicket = sensor_id =>
    !!filteredLogs.filter(log => log.sensor === sensor_id).length;

  const flyToLocation = () => {
    if (asset.geometry?.coordinates) {
      clickOnWellListDispatcher({
        asset: asset.properties,
        lngLat: asset.geometry.coordinates,
      });

      if (
        history.location.pathname.includes('/portal/maps') &&
        !history.location.pathname.includes('/facilities')
      ) {
        window.map.flyTo({
          center: [
            asset.geometry.coordinates[0],
            asset.geometry.coordinates[1],
          ],
          zoom: 20,
        });
        window.map.fire('flystart');
      } else {
        history.push({
          pathname: `/portal/maps/${parseFloat(
            asset.geometry.coordinates[0],
          ).toFixed(4)},${parseFloat(asset.geometry.coordinates[1]).toFixed(
            4,
          )};20.0`,
        });
      }
    }
  };

  if (!asset?.properties) return null;

  return (
    <Box>
      <ListItemButton
        onClick={() => {
          if (arrowDirection) flyToLocation();
          changeArrowDirection();
        }}
        sx={{
          backgroundColor: asset.properties.industry
            ? industryDefaults[asset.properties.industry]
            : industryDefaults.default,
        }}
      >
        <AssetIcon status={asset.properties.status} />
        <S.InfoAssetDiv>
          <S.TextContainerDiv>
            <S.GroupNameAssetP>{asset.properties.name}</S.GroupNameAssetP>
            <S.CoordinatesAssetP>
              {formatLatLng(asset.geometry.coordinates[0])};{' '}
              {formatLatLng(asset.geometry.coordinates[1])}
            </S.CoordinatesAssetP>
          </S.TextContainerDiv>
          <S.NextBtnDivGroupAssetDiv>
            <i
              className={
                arrowDirection
                  ? 'fas fa-chevron-right next-btn arrow'
                  : 'fas fa-chevron-down next-btn arrow'
              }
            />
          </S.NextBtnDivGroupAssetDiv>
        </S.InfoAssetDiv>
      </ListItemButton>
      {!arrowDirection && (
        <Box padding="0.5rem 1rem" sx={{ backgroundColor: '#535d64' }}>
          {group === null ? (
            <Spinner />
          ) : (
            correctList.map(item => (
              <Stack
                key={item.sensor_id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <AssetIcon status={getCorrectStatus(item)} />
                  <S.SensorNameAsset>{item.sensor_name}</S.SensorNameAsset>
                </Stack>
                {checkHasTicket(item.sensor_id) && (
                  <IconButton
                    aria-label="cart"
                    onClick={() => goToDetails(item.sensor_name)}
                    size="small"
                  >
                    <Construction sx={{ width: '20px', color: 'white' }} />
                  </IconButton>
                )}
              </Stack>
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

AssetItem.defaultProps = {
  assetId: '',
  group: {},
};

AssetItem.propTypes = {
  serviceLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
  assetId: PropTypes.string,
  group: PropTypes.object,
};

export default AssetItem;
