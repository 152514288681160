import React, { useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const checkIfInstanceNameIsUndefined = missing_text => {
  let systemInstanceName = process.env.REACT_APP_SYSTEM_INSTANCE_NAME;
  if (!systemInstanceName) {
    systemInstanceName = missing_text;
  }
  return systemInstanceName;
};

const CenterTitle = () => {
  const { t, i18n } = useTranslation();
  const industryFilterState = useSelector(store => store.industryFilterState);
  const industriesState = useSelector(store => store.industriesState);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const checkActiveIndustries = active => {
    if (active.length >= 2 || !active.length)
      return t('tools_menu.navbar_title');

    const selectedIndustry =
      industriesState.industries?.length &&
      industriesState.industries.filter(item => active[0] === item.name);

    return selectedIndustry?.length
      ? selectedIndustry[0].title
      : t('tools_menu.navbar_title');
  };

  const checkActiveSystem = () => {
    const industry = industryFilterState;
    const active = [];

    Object.keys(industry).forEach(key => {
      if (industry[key] === true) active.push(key);
    });

    return checkActiveIndustries(active);
  };

  return (
    <Stack
      className="title drop animated zoomIn faster"
      display={{ xs: 'none', md: 'flex' }}
    >
      <Typography align="center">
        {checkIfInstanceNameIsUndefined(t('tools_menu.navbar_missing_name'))}{' '}
        {checkActiveSystem()}
      </Typography>
      <Typography align="center" variant="subtitle2">
        {`${time.toLocaleDateString(i18n.language)} ${time.toLocaleTimeString(
          i18n.language,
        )}`}
      </Typography>
    </Stack>
  );
};

export default CenterTitle;
