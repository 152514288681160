import { useQuery } from '@tanstack/react-query';
import { getAssets } from '../services/assetService';

const getAssetsReq = async params => {
  const {
    data: { results },
  } = await getAssets(params);
  return results || [];
};

export const useAssets = params => {
  return useQuery({
    queryKey: ['assets', JSON.stringify(params)],
    queryFn: () => getAssetsReq(params),
    placeholderData: [],
  });
};
