/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import {
  primaryColor,
  modalGradientPrimary,
  modalGradientSecondary,
  secondaryColor,
} from '@themes';

export const Container = styled.div`
  height: fit-content;
  width: 20rem;
  max-width: 95%;
  max-height: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  border: 2px solid ${primaryColor};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    ${modalGradientPrimary} 0%,
    ${modalGradientSecondary} 100%
  );

  &.animate__animated.animate__fadeIn.animate__faster {
    animation-duration: 0.3s;
  }
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 70%;
`;

export const Title = styled.h6`
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  align-self: flex-start;
  font-weight: 500;
`;

export const DatePickerContainer = styled.div`
  .react-datepicker {
    display: flex;
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 95px;
  }

  .react-datepicker-popper {
    z-index: 100;
  }
`;

export const CalendarStart = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-top: -1.7rem;
  z-index: 1;
  color: ${primaryColor};
  margin-right: 0.5rem;
`;

export const CalendarEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin-top: -1.7rem;
  z-index: 1;
  color: ${primaryColor};
  margin-right: 0.5rem;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 3rem;
  width: 100%;
  margin-top: 1rem;

  > button {
    background-color: ${primaryColor};
    color: ${secondaryColor};
    height: 3rem;
    width: 100%;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    transition:
      background-color 0.2s,
      color 0.2s;

    &:hover {
      background-color: ${secondaryColor};
      color: ${primaryColor};
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;
