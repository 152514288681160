/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import { primaryColor, sensorListBg } from '@themes';

export const AssetsListDivAssetsEdit = styled.div`
  min-height: 0;
  padding-top: 24px;
  padding-bottom: 15px;
  padding-right: 0;
  flex: 1;
  overflow-x: hidden;
  margin: 20px -14px 30px;
  height: 25px;
  cursor: pointer;
`;

export const NameGroup = styled.div`
  margin-top: -1rem;
`;

export const LocEdit = styled.div`
  margin-top: -1rem;
  cursor: pointer;
`;

export const MainLocationDivEdit = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding-bottom: 1rem;
`;

export const BackBtnSpanEdit = styled.span`
  padding-right: 0.5rem;

  & i {
    color: ${primaryColor};
  }
`;

export const BackBtnTextAssetsEdit = styled.h1`
  // margin-top: -23px;
  margin-left: 1.2rem;
  text-transform: none;
  font-weight: 400;
  font-size: 19px;
  text-align: left;
`;

export const InputName = styled.input`
  height: 35px;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #d8d8d8;
  color: #08152d;
  border: 0;
  border-radius: 4px;

  ${props =>
    !props.$isNameInputOK &&
    css`
      border: 1px solid red;
    `}

  &:focus,
  &:active {
    border: 0;
    outline: none;
  }
`;

export const FormButtonsAssetsEdit = styled.div`
  height: 60px;
  width: 114%;
  background-color: ${sensorListBg};
  position: absolute;
  bottom: 0;
  left: -39px;
`;

export const ErrorMessageEditSuccess = styled.label`
  display: none;
`;
