import { getUserCache } from '../axios/axiosAuth';
import { get } from '../axios/axiosGateway';

export const getPermissions = () => {
  if (!localStorage.getItem('user')) {
    return Promise.reject(new Error('User cache not found!'));
  }
  const user = getUserCache();

  return get(`/permissions?userID=${user?.user_id}`);
};
