/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
import { getAuthFeatures, getTokenValue } from '../../axios/axiosAuth';
import { getFeatures } from '../../services/featureService';

import { FETCH_FEATURES, FEATURES_RECEIVED_ERROR } from './actionTypes';

const _fetchFeatures = values => ({
  type: FETCH_FEATURES,
  newValues: values,
});

const _receivedError = err => ({
  type: FEATURES_RECEIVED_ERROR,
  error: err,
});

export const fetchFeatures = () => {
  const userToken = getTokenValue();

  return dispatch => {
    const localFeatures = localStorage.getItem('features');
    if (localFeatures) {
      dispatch(_fetchFeatures(JSON.parse(localFeatures)));
    }
    return (userToken ? getFeatures() : getAuthFeatures())
      .then(res => res.data)
      .then(data => {
        localStorage.setItem('features', JSON.stringify(data.results));
        dispatch(_fetchFeatures(data.results));
      })
      .catch(err => {
        dispatch(_receivedError(err));
      });
  };
};
