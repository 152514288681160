/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import { primaryColor, oceanShore } from '../../themes';

export const DraggableIcon = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 0.5rem;
`;

export const IconImage = styled.div`
  align-self: center;
  cursor: grab;

  & i {
    font-size: 1.5em;
    padding: 4px;
    border: 1px solid ${primaryColor};
    border-radius: 5px;
    color: ${oceanShore.default};
  }
`;

export const TextData = styled.div`
  margin-top: 0.2rem;
  align-self: center;
  max-width: 200px;
  text-align: start;

  & h6 {
    margin-bottom: 0.2rem;
  }
`;

export const DefaultIcon = styled.img`
  width: 2em;
  height: 2em;
  padding: 4px;
  border: 1px solid ${primaryColor};
  border-radius: 5px;
  color: ${oceanShore.default};
`;
