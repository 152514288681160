/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getGroupsAction } from '@redux/actions/groupsActions';
import { updateToast } from '@redux/actions';
import { useTranslation } from 'react-i18next';
import * as S from './style';
import { postGroup } from '../../services/groupService';

const mapStateToProps = store => ({
  groups: store.groupsState.groups,
  toast: store.toastState.toast,
});

const mapDispatchToProps = dispatch => {
  return {
    getGroupsAction: args => dispatch(getGroupsAction(args)),
    updateToast: args => dispatch(updateToast(args)),
  };
};

const ModalActionsSave = props => {
  const [groupName, setGroupName] = useState(props.groupName);
  const [isNameOK, setIsNameOk] = useState(true);
  const { t } = useTranslation();

  function handleChange(event) {
    setGroupName(event.target.value);
    setIsNameOk(true);
  }

  function formatObjectToSave() {
    const sensorsList = [];
    props.itemsToAdd.forEach(item => {
      sensorsList.push({
        sensor_location_id: props.locationId,
        sensor_asset_id: item.asset_id,
        sensor_id: item.item_id,
        sensor_name: item.name,
      });
    });

    return {
      name: groupName,
      location_id: props.locationId,
      sensors: sensorsList,
    };
  }

  const saveNewGroup = () => {
    postGroup(formatObjectToSave()).then(response => {
      if (response.data.success) {
        props.getGroupsAction().then(() => {
          props.updateToast({
            message: t('components.main_list.groups.modal.new.save_success'),
            show: true,
            isError: false,
          });
          props.goBack('groups');
        });
      } else {
        setIsNameOk(false);
        props.updateToast({
          message: t('components.main_list.groups.modal.new.save_error'),
          show: true,
          isError: true,
        });
      }
    });
  };
  const { title, groupId, modaltext, onHide, show } = props;

  const nameErrorDisplay = !isNameOK ? (
    <p className="error-message">
      {t('components.main_list.groups.edit.name_error')}
    </p>
  ) : null;

  const inputClassName = isNameOK
    ? 'modal-input-save'
    : 'modal-input-save-error';

  return (
    <S.ModalActionsSave
      onHide={onHide}
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="custom-save-modal"
      centered
    >
      <S.ModalActionsSave.Header>
        <S.ModalActionsSave.Title className="modal-title">
          {title}
        </S.ModalActionsSave.Title>
      </S.ModalActionsSave.Header>
      {groupId ? (
        <S.ModalActionsSave.Body>
          <h4 className="modal-text">
            {t('components.main_list.groups.modal.new.description')}
          </h4>
        </S.ModalActionsSave.Body>
      ) : (
        <>
          <S.ModalActionsSave.Body className="modal-body-save">
            <h4 className="modal-text-save">{modaltext}</h4>
            <input
              type="text"
              maxLength="255"
              className={inputClassName}
              value={groupName}
              onChange={handleChange}
            />
          </S.ModalActionsSave.Body>
          {nameErrorDisplay}
        </>
      )}
      <S.ModalActionsSave.Footer>
        <Button
          onClick={onHide}
          className="btn btn-secondary btn-footer btn-footer-left"
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={saveNewGroup}
          className="btn btn-secondary btn-footer btn-footer-right"
          disabled={groupName === ''}
        >
          {t('buttons.save')}
        </Button>
      </S.ModalActionsSave.Footer>
    </S.ModalActionsSave>
  );
};

ModalActionsSave.propTypes = {
  title: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  itemsToAdd: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  updateToast: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  getGroupsAction: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  modaltext: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalActionsSave);
