/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useMemo } from 'react';
import { ToggleButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { corvalentDarkBlueObj, corvalentLightGreyObj } from '../../../themes';
import * as S from './style';
import COLOR_OPTIONS from '../../../constants/binarySensorsColors';

const ColorMenu = ({
  id,
  selectedColor,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
  touched,
  fullWidth,
  colorOptions,
}) => {
  const theme = useTheme();

  const colorOptionsValues = useMemo(
    () =>
      colorOptions
        ? Object.values(colorOptions)
        : Object.values(COLOR_OPTIONS).map(
            themeColors => themeColors[theme.industry],
          ),
    [colorOptions, theme.industry],
  );

  const handleToggle = state => {
    !state && !touched[id] && setFieldTouched(id);
  };

  return (
    <S.DropdownContainer
      $fullWidth={fullWidth}
      align="end"
      onToggle={handleToggle}
    >
      <S.FormControl
        type="text"
        id={id}
        name={id}
        placeholder="#HEX"
        value={selectedColor}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled
      />
      <S.DropdownToggle
        style={{
          backgroundColor: selectedColor || corvalentLightGreyObj.default,
        }}
        data-testid="color-picker-toggle"
      />

      <S.DropdownMenu
        $colors={colorOptionsValues.length}
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 20],
              },
            },
          ],
        }}
      >
        <S.DropdownTail />
        <S.ColorButtonGroup>
          {colorOptionsValues.map(color => (
            <S.DropdownItem
              style={{
                backgroundColor: color,
                border:
                  selectedColor === color
                    ? `2px dashed ${corvalentDarkBlueObj.default}`
                    : '1px solid transparent',
                filter: selectedColor === color ? 'brightness(1.25)' : null,
                pointerEvents: selectedColor === color ? 'none' : null,
              }}
              as={ToggleButton}
              key={color}
              type="radio"
              id="radio"
              name="radio"
              value={color}
              $backgroundColor={color}
              checked={selectedColor === color}
              onClick={() => {
                setFieldValue(id, color);
              }}
            />
          ))}
        </S.ColorButtonGroup>
        <S.ColorHexLabel>{selectedColor || '#Hex'}</S.ColorHexLabel>
      </S.DropdownMenu>
    </S.DropdownContainer>
  );
};

ColorMenu.defaultProps = {
  fullWidth: false,
  colorOptions: null,
};

ColorMenu.propTypes = {
  id: PropTypes.string.isRequired,
  selectedColor: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  colorOptions: PropTypes.object,
};

export default ColorMenu;
