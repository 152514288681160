/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import { primaryColor } from '@themes';

export const FloorsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  border-radius: 4px;
  overflow: auto;

  @media (max-height: 630px) {
    max-height: 35%;
  }

  @media (min-height: 630px) and (max-height: 730px) {
    max-height: 45%;
  }

  @media (min-device-height: 730px) and (max-device-height: 1024px) {
    max-height: 50%;
  }

  @media (min-device-height: 1024px) {
    max-height: 60%;
  }
`;

export const FloorsInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

export const Floor = styled.div`
  height: fit-content;
  background-color: ${props => (props.isSelected ? primaryColor : `white`)};
  color: ${props => (props.isSelected ? `white` : primaryColor)};
  border: 0.5px solid ${primaryColor};
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition:
    background-color 0.1s,
    color 0.1s;

  &:hover {
    cursor: pointer;
  }

  > div {
    font-size: small;
    font-weight: 400;
    max-width: 95%;
    margin-bottom: 4px;
  }

  ${props =>
    props.isFirst &&
    css`
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    `}

  ${props =>
    props.isLast &&
    css`
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`;

export const AssetsCount = styled.div`
  display: flex;
  align-items: center;
  align-content: center;

  &:hover {
    cursor: pointer;
  }

  > img {
    height: 19px;
    width: 19px;
    margin-left: 0.5rem;
  }

  ${props =>
    !props.hasWarningIcon &&
    css`
      margin-right: 1.65rem;
    `}
`;
