/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import StatusItemButton from './StatusItemButton';
import * as S from './style';
import { useBuildingDetails } from '../../context/BuildingContext';

const StatusIconsFilter = ({ statusCount }) => {
  const [sortedStatusCount, setSortedStatusCount] = useState();
  const { changeFilteredStatus, filteredStatus } = useBuildingDetails();

  const sortStatusCount = useCallback(() => {
    return {
      normal: statusCount.normal,
      warning: statusCount.warning,
      danger: statusCount.danger,
      off: statusCount.off,
    };
  }, [statusCount]);

  const handleStatusSelect = useCallback(
    status => {
      if (!filteredStatus.includes(status))
        changeFilteredStatus(previousState => [...previousState, status]);
      else if (filteredStatus.includes(status))
        changeFilteredStatus(
          filteredStatus.filter(selected => selected !== status),
        );
    },
    [changeFilteredStatus, filteredStatus],
  );

  useEffect(() => {
    if (statusCount) setSortedStatusCount(sortStatusCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCount]);

  return (
    <S.IconsContainer>
      {sortedStatusCount &&
        Object.keys(sortedStatusCount).map(status => (
          <StatusItemButton
            key={status}
            count={sortedStatusCount[status]}
            status={status}
            isSelected={
              filteredStatus.length && filteredStatus.includes(status)
            }
            handleStatusSelect={() => handleStatusSelect(status)}
          />
        ))}
    </S.IconsContainer>
  );
};

StatusIconsFilter.propTypes = {
  statusCount: PropTypes.shape().isRequired,
};

export default StatusIconsFilter;
