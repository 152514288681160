import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as S from './style';

const DigitalSensorValue = ({
  binarySensor,
  sensorId,
  colorized,
  textBackground,
  verbose,
  enableInfo,
}) => {
  const { t } = useTranslation();

  const getColor = () => (colorized ? binarySensor?.color : 'inherit');

  if (
    enableInfo &&
    (!binarySensor?.color || (!binarySensor?.icon && !binarySensor?.text))
  )
    return verbose ? (
      <S.NotConfigured>
        {t('components.digital_sensor_value.no_information')}
        <S.CustomLink to={`/portal/management/sensors/${sensorId}/manage`}>
          {t('components.digital_sensor_value.link_text')}
        </S.CustomLink>
      </S.NotConfigured>
    ) : (
      '-'
    );

  return binarySensor?.icon ? (
    <S.IconValue $unicode={binarySensor?.icon} $color={getColor()} />
  ) : (
    <S.TextValue $color={getColor()} $textBackground={textBackground}>
      {binarySensor?.text}
    </S.TextValue>
  );
};

DigitalSensorValue.defaultProps = {
  colorized: false,
  textBackground: false,
  verbose: false,
  enableInfo: true,
};

DigitalSensorValue.propTypes = {
  binarySensor: PropTypes.shape({
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    text: PropTypes.string,
    icon: PropTypes.string,
    sensor_id: PropTypes.string,
    sensor_type_id: PropTypes.string,
  }).isRequired,
  sensorId: PropTypes.string.isRequired,
  colorized: PropTypes.bool,
  textBackground: PropTypes.bool,
  verbose: PropTypes.bool,
  enableInfo: PropTypes.bool,
};

export default DigitalSensorValue;
