import PropTypes from 'prop-types';
import { Backdrop } from '@mui/material';
import React from 'react';
import Spinner from '../../atoms/Spinner';

const Loader = ({ open }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 10001 }}>
      <Spinner noTestId />
    </Backdrop>
  );
};

Loader.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Loader;
