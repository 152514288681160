/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';

import {
  primaryColor,
  secondaryColor,
  listBackgroundGradient,
  assetItemSelectedBG,
} from '@themes';

export const Container = styled.div`
  background: linear-gradient(
    ${listBackgroundGradient} 0%,
    ${assetItemSelectedBG} 100%
  );
  height: fit-content;
  width: 20rem;
  max-width: 95%;
  max-height: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 3000;
  border: 2px solid ${primaryColor};
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  &.animate__animated.animate__fadeIn.animate__faster {
    animation-duration: 0.3s;
  }
`;

export const CloseButton = styled.div`
  & > i {
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    font-size: 1.2rem;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-right: 8px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const IndoorTitle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  & > i {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: ${primaryColor};
  }
`;

export const IndoorDescription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const IndoorName = styled.p`
  text-transform: uppercase;
  font-weight: 600;
  color: ${primaryColor};
  font-size: 18px;
`;

export const IndoorLocationName = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  margin-top: -1rem;
`;

export const OutdoorTitle = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  align-content: center;

  & > i {
    font-size: 2.5rem;
    color: ${primaryColor};
    margin-top: 0.5rem;
  }
`;

export const OutdoorDescription = styled.p`
  text-transform: uppercase;
  font-weight: 600;
`;

export const AssetName = styled.p``;

export const IndoorBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  padding: 0 1rem;
`;

export const IndoorListElement = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

export const Category = styled.p``;

export const Dots = styled.div`
  flex: 0.97 1 0%;
  height: 16px;
  border-bottom: 1.5px dashed white;
  margin: 0 2px;
  margin-top: 2px;
`;

export const Value = styled.p`
  font-weight: 600;
`;

export const OutdoorBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: -0.5rem;
`;

export const Coordinates = styled.p`
  font-weight: 600;
`;

export const Button = styled.div`
  display: flex;
  cursor: pointer;
  flex: 1;
  flex-direction: column;
  align-content: center;
  align-items: flex-end;
  background-color: ${primaryColor};
`;

export const ViewDetails = styled.p`
  color: ${secondaryColor};
  text-transform: uppercase;
  font-weight: 600;
  margin: 0.5rem 0;
  margin-right: 1rem;
`;
