import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import {
  AcUnit,
  Air,
  Cloud,
  LocalFireDepartment,
  NetworkCheck,
  Opacity,
  Pool,
  PropaneTank,
  Radar,
  Videocam,
  Water,
  WbSunny,
} from '@mui/icons-material';
import { v4 as uuidV4 } from 'uuid';
import { ReactComponent as Thermometer } from '../../../../../assets/icon-thermometer.svg';
import { ReactComponent as Energy } from '../../../../../assets/icon-energy.svg';
import DigitalSensorValue from '../../../../DigitalSensorValue';
import { useLocationsCacheContext } from '../../../../../context/LocationsCacheContext';

export const widgetIcons = {
  temperature: <Thermometer style={{ fontSize: '100px', color: 'white' }} />,
  'water-level': <Opacity style={{ fontSize: '100px', color: 'white' }} />,
  pool: <Pool style={{ fontSize: '100px', color: 'white' }} />,
  energy: (
    <Energy style={{ height: '100px', width: '100px', color: 'white' }} />
  ),
  water: <Water style={{ fontSize: '100px', color: 'white' }} />,
  'air-conditioning': <AcUnit style={{ fontSize: '100px', color: 'white' }} />,
  cloud: <Cloud style={{ fontSize: '100px', color: 'white' }} />,
  sun: <WbSunny style={{ fontSize: '100px', color: 'white' }} />,
  air: <Air style={{ fontSize: '100px', color: 'white' }} />,
  'propane-tank': <PropaneTank style={{ fontSize: '100px', color: 'white' }} />,
  camera: <Videocam style={{ fontSize: '100px', color: 'white' }} />,
  radar: <Radar style={{ fontSize: '100px', color: 'white' }} />,
  fire: <LocalFireDepartment style={{ fontSize: '100px', color: 'white' }} />,
  'internet-traffic': (
    <NetworkCheck style={{ fontSize: '100px', color: 'white' }} />
  ),
};

const Snapshot = ({ widget, height, width }) => {
  const { assets } = useLocationsCacheContext();
  const readingsDisplay = useMemo(
    () =>
      widget.items.reduce((prev, curr) => {
        const sensor = assets
          ?.find(asset => asset.properties.item_id === curr.asset)
          ?.properties.sensors?.find(sensor => sensor.item_id === curr.sensor);
        if (!sensor) return prev;
        let val;
        if (sensor.is_bool) {
          val = (
            <Box key={sensor.item_id} fontSize="45px">
              <DigitalSensorValue
                binarySensor={sensor.binary_sensors?.[sensor.reading]}
                sensorId={sensor.item_id}
                colorized
                textBackground
                verbose
              />
            </Box>
          );
        } else {
          val = (
            <Typography key={sensor.item_id} color="white" variant="h4">
              {Math.round(sensor.reading)}
              {sensor.measure_unit}
            </Typography>
          );
        }
        return prev.length > 0
          ? prev.concat([
              <Typography
                key={uuidV4()}
                padding="0 5px"
                color="white"
                variant="h4"
              >
                /
              </Typography>,
              val,
            ])
          : prev.concat(val);
      }, []),
    [assets, widget.items],
  );

  return (
    <Stack
      sx={{
        backgroundColor: widget.color,
        height: '100%',
        padding: '10px 15px',
      }}
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Stack spacing={1} width="100%">
        <Typography color="white" variant="h6">
          {widget.title}:
        </Typography>
        <Stack
          spacing={1}
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {(width > 1 || height > 2) && widgetIcons[widget.icon]}
          {height < 3 && (
            <Stack flexDirection="row" alignItems="center">
              {readingsDisplay}
            </Stack>
          )}
        </Stack>
      </Stack>
      {height > 2 && (
        <Stack flexDirection="row" alignItems="center">
          {readingsDisplay}
        </Stack>
      )}
    </Stack>
  );
};

Snapshot.propTypes = {
  widget: PropTypes.shape({
    color: PropTypes.any,
    icon: PropTypes.any,
    title: PropTypes.any,
    items: PropTypes.array,
  }).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Snapshot;
