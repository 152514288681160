/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { Logout, Menu, Notifications } from '@mui/icons-material';

import { logout } from '../../axios/axiosAuth';
import { useGeneralContext } from '../../context/GeneralContext';
import LanguageSelect from './LanguageSelect';
import * as S from './style';
import NotificationContainer from '../NotificationContainer';
import CustomToast from '../CustomToast';
import { updateToast } from '../../redux/actions';
import { fetchUserData } from '../../redux/actions/authActions';
import {
  closeAnimated,
  openAnimated,
} from '../../redux/actions/sideDrawerActions';
import CustomButton from '../shared/atoms/CustomButton';
import { useConfirmation } from '../../context/ConfirmationContext';
import CenterTitle from './CenterTitle';

const customLogo = `${process.env.REACT_APP_STORAGE_BASE_URL}/${process.env.REACT_APP_LOGO}-logo.png`;
const urlRegex = /^\/portal\/maps\/?[^/]*(?:\/?)$/;

const Navbar = ({ originalQuery }) => {
  const location = useLocation();
  const history = useHistory();
  const { user, resetUser } = useGeneralContext();
  const { openConfirmation } = useConfirmation();
  const dispatch = useDispatch();

  const updateToastDispatch = args => dispatch(updateToast(args));
  const fetchUserDataDispatch = args => dispatch(fetchUserData(args));
  const openAnimatedDispatch = args => dispatch(openAnimated(args));
  const closeAnimatedDispatch = args => dispatch(closeAnimated(args));

  const toast = useSelector(store => store.toastState.toast);
  const drawerIsOpen = useSelector(store => store.sideDrawerState.isOpen);

  const [notification, setNotification] = useState('closed');
  const [countNotifications, setCountNotifications] = useState(0);
  const { t } = useTranslation();
  const { sideMenuForm } = useGeneralContext();

  useEffect(() => {
    if (user) {
      fetchUserDataDispatch(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!urlRegex.test(location.pathname)) setNotification('closed');
  }, [location]);

  const goToStart = () => {
    const initialPosition = process.env.REACT_APP_MAP_INITIAL_POSITION?.split(
      ' ',
    ) || [-98.647, 31.904, 6];

    if (history.location.pathname.includes('/portal/maps') && window.map) {
      window.map.flyTo({
        center: initialPosition.slice(0, 2),
        zoom: initialPosition[2],
      });
    }

    history.push({
      pathname: '/portal/maps',
      search: originalQuery,
    });
  };

  const logoutAction = () => {
    openConfirmation({
      message: t('tools_menu.logout_confirmation'),
      handleAction: () => logout(resetUser),
      title: t('tools_menu.logout'),
      actionLabel: t('tools_menu.logout'),
    });
  };

  const getUserByEmail = email =>
    email?.indexOf('@') ? email.slice(0, email.indexOf('@')) : email;

  const switchSidebar = () => {
    if (drawerIsOpen && sideMenuForm) {
      openConfirmation({
        message: t('modal.discard.description'),
        handleAction: closeAnimatedDispatch,
        title: t('modal.cancel'),
      });
    } else {
      drawerIsOpen ? closeAnimatedDispatch() : openAnimatedDispatch();
    }
  };

  const switchNotification = () => {
    switch (notification) {
      case 'closed':
        setNotification('open');
        break;
      case 'open':
        setNotification('closed');
        break;
      default:
        break;
    }
  };

  // Fuction to get the target for clickOutside wrapper in the NotificationContainer
  // Clickoutide currently disabled.
  const clickOutsideNotification = e => {
    if (!e.target.className.includes('toggle-notification'))
      setNotification('closed');
  };

  const hideToast = () => {
    updateToastDispatch({
      message: '',
      show: false,
      isError: false,
    });
  };

  return (
    <AppBar
      position="fixed"
      sx={theme => ({
        zIndex: theme.zIndex.drawer + 1,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        color: 'white',
      })}
      className="cv-bg-secondary"
      id="navbar"
    >
      <Toolbar
        variant="dense"
        sx={{ maxHeight: '3.125rem', height: '3.125rem' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <IconButton
              id="sandwich-button"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={switchSidebar}
            >
              <Menu id="sandwich-button" />
            </IconButton>
            <S.img
              src={customLogo}
              alt="platform logo"
              onClick={() => goToStart()}
            />
          </Stack>
          <CenterTitle />
          <S.UserInfo>
            <LanguageSelect />
            <Typography sx={{ ml: 1 }} display={{ xs: 'none', sm: 'block' }}>
              {getUserByEmail(user?.email)}
            </Typography>
            <IconButton
              size="large"
              aria-label="show new notifications"
              color="inherit"
              onClick={switchNotification}
            >
              <Badge badgeContent={countNotifications} color="error">
                <Notifications sx={{ fontSize: { xs: 20, sm: 24 } }} />
              </Badge>
            </IconButton>
            <NotificationContainer
              containerState={notification}
              onClickOutside={clickOutsideNotification}
              onClickOutsideMobileOnly
              switchNotification={switchNotification}
              onUpdateCounter={setCountNotifications}
            />
            <CustomButton style={{ height: '30px' }} onClick={logoutAction}>
              <Logout sx={{ fontSize: { xs: 20, sm: 24 } }} />{' '}
              <Box display={{ xs: 'none', md: 'block' }}>
                {t('tools_menu.logout')}
              </Box>
            </CustomButton>
          </S.UserInfo>
          {!toast ? null : (
            <CustomToast
              autohide
              message={toast.message}
              show={toast.show}
              onToastClose={hideToast}
              isError={toast.isError}
              isWarning={toast.isWarning}
              topProp={60}
              rightProp={45}
            />
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

Navbar.defaultProps = {
  originalQuery: '',
};

Navbar.propTypes = {
  originalQuery: PropTypes.string,
};

export default Navbar;
