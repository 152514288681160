/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
import {
  FETCH_ASSETS,
  UPDATED_ASSETS_QUERY,
  RECEIVED_ASSETS,
  DELETE_ASSETS_FULFILLED,
  DELETE_ASSETS_PENDING,
  RECEIVED_ERROR,
  UPDATE_ASSETS_PENDING,
  UPDATE_ASSETS_FULFILLED,
  ADD_ASSETS_PENDING,
  ADD_ASSETS_FULFILLED,
  RESET_ASSET_ERROR,
  UPDATE_LOCATION_TO_FLY_ID,
} from './actionTypes';
import {
  delAsset,
  getAssets,
  postAsset,
  putAsset,
} from '../../services/assetService';

const fetchAssets = () => ({
  type: FETCH_ASSETS,
});

const updatedAssetsQuery = values => ({
  type: UPDATED_ASSETS_QUERY,
  newValue: values,
});

const receivedAssets = value => ({
  type: RECEIVED_ASSETS,
  newValue: value,
});

const willUpdateAsset = () => ({
  type: UPDATE_ASSETS_PENDING,
});

const updatedAsset = value => ({
  type: UPDATE_ASSETS_FULFILLED,
  newValue: value,
});

const willAddAsset = () => ({
  type: ADD_ASSETS_PENDING,
});

const addedAsset = value => ({
  type: ADD_ASSETS_FULFILLED,
  newValue: value,
});

const willDeleteAsset = () => ({
  type: DELETE_ASSETS_PENDING,
});

const deletedAsset = itemId => ({
  type: DELETE_ASSETS_FULFILLED,
  item_id: itemId,
});

const receivedError = err => ({
  type: RECEIVED_ERROR,
  error: err,
});

export const resetError = err => ({
  type: RESET_ASSET_ERROR,
  error: err,
});

export const updateLocationToFlyId = locationId => ({
  type: UPDATE_LOCATION_TO_FLY_ID,
  locationToFlyId: locationId,
});

export const getAssetsAction = ({
  page = null,
  amount = null,
  keyword = null,
  asset_type = null,
  assetTypes = null,
  sortingField = null,
  sortingOrder = null,
} = {}) => {
  const pageParam = page !== '' ? page : null;
  const amountParam = amount !== '' ? amount : null;
  const keywordParam = keyword !== '' ? keyword : null;
  const assetTypeParam = asset_type !== '' ? asset_type : null;
  const sortingFieldParam = sortingField || null;
  const sortingOrderParam = sortingOrder || null;

  return dispatch => {
    dispatch(fetchAssets());
    dispatch(
      updatedAssetsQuery({
        amount: parseInt(amount),
        keyword,
        asset_type,
        assetTypes,
      }),
    );

    return getAssets({
      page: pageParam,
      amount: amountParam,
      keyword: keywordParam,
      asset_type: assetTypeParam,
      sortingField: sortingFieldParam,
      sortingOrder: sortingOrderParam,
    })
      .then(res => {
        // eslint-disable-next-line no-prototype-builtins
        if (!res.data.success || res.hasOwnProperty('error'))
          dispatch(receivedError('Error'));
        else dispatch(receivedAssets(res.data.results));
      })
      .catch(err => dispatch(receivedError(err)));
  };
};

export const createAssetAction = body => {
  return dispatch => {
    dispatch(willAddAsset());

    return postAsset(body)
      .then(res => res.data)
      .then(data => {
        if (!data.success) return dispatch(receivedError('Error'));
        return dispatch(addedAsset({ item_id: data.results.item_id, ...body }));
      })
      .catch(err => dispatch(receivedError(err)));
  };
};

export const updateAssetsAction = (body, id) => {
  return dispatch => {
    dispatch(willUpdateAsset());

    return putAsset(id, body)
      .then(res => res.data)
      .then(data => {
        if (!data.success) return dispatch(receivedError('Error'));
        return dispatch(updatedAsset(body));
      })
      .catch(err => dispatch(receivedError(err)));
  };
};

export const deleteAssetAction = itemId => {
  return dispatch => {
    dispatch(willDeleteAsset());

    return delAsset(itemId)
      .then(res => res.data)
      .then(data => {
        if (!data.success) dispatch(receivedError('Error'));
        else dispatch(deletedAsset(itemId));
      })
      .catch(err => dispatch(receivedError(err)));
  };
};
