/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

const Building = createContext({});

const BuildingDetails = ({ children }) => {
  const [buildingDetailsVisibility, setBuildingDetailsVisibility] =
    useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [floorDetailsVisibility, setFloorDetailsVisibility] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedFloorFromModal, setSelectedFloorFromModal] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [hideDrawers, setHideDrawers] = useState(false);

  const changeBuildingDetailsVisibility = useCallback((state = false) => {
    setBuildingDetailsVisibility(state);
  }, []);

  const changeSelectedBuilding = useCallback((state = null) => {
    setSelectedBuilding(state);
  }, []);

  const changeFloorDetailsVisibility = useCallback((state = false) => {
    setFloorDetailsVisibility(state);
  }, []);

  const changeSelectedFloor = useCallback((state = null) => {
    setSelectedFloor(state);
  }, []);

  const changeSelectedFloorFromModal = useCallback((state = null) => {
    setSelectedFloorFromModal(state);
  }, []);

  const changeSelectedAsset = useCallback((state = null) => {
    setSelectedAsset(state);
  }, []);

  const changeFilteredStatus = useCallback((state = null) => {
    setFilteredStatus(state);
  }, []);

  const changeAssetTypes = useCallback((state = null) => {
    setAssetTypes(state);
  }, []);

  const changeSelectedFilter = useCallback((state = null) => {
    setSelectedFilter(state);
  }, []);

  const closeDrawers = useCallback(() => {
    setBuildingDetailsVisibility(false);
    setSelectedBuilding(null);
    setFloorDetailsVisibility(false);
    setSelectedFloor(null);
    setSelectedFloorFromModal(null);
    setFilteredStatus([]);
    setAssetTypes([]);
    setSelectedFilter('all');
  }, []);

  const value = useMemo(
    () => ({
      changeBuildingDetailsVisibility,
      buildingDetailsVisibility,
      changeSelectedBuilding,
      selectedBuilding,
      changeFloorDetailsVisibility,
      floorDetailsVisibility,
      changeSelectedFloor,
      selectedFloor,
      changeSelectedFloorFromModal,
      selectedFloorFromModal,
      changeSelectedAsset,
      selectedAsset,
      changeFilteredStatus,
      filteredStatus,
      assetTypes,
      changeAssetTypes,
      selectedFilter,
      changeSelectedFilter,
      closeDrawers,
      hideDrawers,
      setHideDrawers,
    }),
    [
      assetTypes,
      buildingDetailsVisibility,
      changeAssetTypes,
      changeBuildingDetailsVisibility,
      changeFilteredStatus,
      changeFloorDetailsVisibility,
      changeSelectedAsset,
      changeSelectedBuilding,
      changeSelectedFilter,
      changeSelectedFloor,
      changeSelectedFloorFromModal,
      closeDrawers,
      filteredStatus,
      floorDetailsVisibility,
      selectedAsset,
      selectedBuilding,
      selectedFilter,
      selectedFloor,
      selectedFloorFromModal,
      hideDrawers,
      setHideDrawers,
    ],
  );

  return <Building.Provider value={value}>{children}</Building.Provider>;
};

function useBuildingDetails() {
  return useContext(Building);
}

BuildingDetails.propTypes = {
  children: PropTypes.node.isRequired,
};

export { BuildingDetails, useBuildingDetails };
