/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';
import { Button } from 'react-bootstrap';

import {
  bgButtonHover,
  customButtonDisabled,
  customButtonFocus,
  primaryColor,
} from '@themes';

export const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: ${primaryColor};
  border-color: transparent;
  text-transform: uppercase;
  font-weight: normal;
  border: 1px solid ${primaryColor} !important;

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${bgButtonHover};
        border: 1px solid ${bgButtonHover};
        border-color: ${bgButtonHover};
        outline: ${bgButtonHover};
      }

      &:focus,
      &.btn:focus,
      &.btn.btn-primary:focus,
      &:active:focus,
      &.active:focus,
      &.focus,
      &:active.focus,
      &.active.focus {
        border: 1px solid ${primaryColor} !important;
        background-color: ${customButtonDisabled};
        box-shadow: 0 0 0 0.2rem ${customButtonFocus} !important;
      }
    `}

  ${props =>
    props.disabled &&
    css`
      pointer-events: unset;
      cursor: not-allowed;
      opacity: 0.4;
      border: 1px solid ${customButtonDisabled} !important;
      background-color: ${customButtonDisabled} !important;
      color: ${primaryColor};
    `}
`;
