/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import {
  FETCH_NOTIFICATIONS,
  RECEIVED_NOTIFICATIONS,
  NOTIFICATIONS_ERROR,
  ADDED_NOTIFICATIONS,
  ADD_NOTIFICATIONS,
  DISMISS_NOTIFICATIONS,
  DISMISSED_NOTIFICATIONS,
  DID_SINGLE_DISMISS,
  WILL_SINGLE_DISMISS,
} from '../actions/actionTypes';

const initialState = {
  notifications: [],
  loaded: false,
  loading: false,
  error: null,
};

export const notificationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
    case ADD_NOTIFICATIONS:
    case WILL_SINGLE_DISMISS:
    case DISMISS_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case RECEIVED_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.newValue || [],
        loading: false,
        loaded: true,
        error: null,
      };

    case ADDED_NOTIFICATIONS:
      return {
        ...state,
        notifications: [action.newValue]
          .concat(state.notifications)
          .slice(0, 200),
        loading: false,
        loaded: true,
        error: null,
      };

    case DID_SINGLE_DISMISS:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.item_id !== action.newValue,
        ),
        loading: false,
        loaded: true,
        error: null,
      };

    case DISMISSED_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification =>
            action.newValue.industries.indexOf(
              JSON.parse(notification.message).industry,
            ) === -1,
        ),
        loading: false,
        loaded: true,
        error: null,
      };

    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
