/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { Box, ListItemButton, Stack, Typography } from '@mui/material';

import * as S from './style';
import { getSensorsByAsset } from '../../../../services/sensorService';
import formatLatLng from '../../../../utils/formatLatLng';

const AssetSensorItem = ({
  asset,
  groupToEdit,
  itemsToAdd,
  somethingChanged,
}) => {
  const [sensors, setSensors] = useState([]);
  const [sensorsListHidden, setSensorsListHidden] = useState(true);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [arrowDirection, setArrowDirection] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    getSensorsByAsset(asset.item_id).then(resp => {
      checkComponentToEdit(resp?.data?.results);
    });

    const checkbox = document.getElementById(asset.item_id);
    if (checkbox) checkbox.classList.remove('position-static');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkComponentToEdit = sensorList => {
    let allChecked = false;
    let checkedCount = 0;
    let disabled = false;

    if (groupToEdit && sensorList) {
      sensorList.forEach(sensor => {
        groupToEdit.sensors.forEach(group => {
          if (sensor.item_id === group.sensor_id) {
            sensor.isChecked = true;
            checkedCount++;
          }
        });
      });
    }

    if (sensorList?.length === checkedCount) allChecked = true;

    if (sensorList?.length === 0) {
      disabled = true;
      allChecked = false;
    }

    setSensors(sensorList);
    setIsAllChecked(allChecked);
    setIsDisabled(disabled);
    setArrowDirection(checkedCount <= 0);
    setSensorsListHidden(checkedCount <= 0);

    itemsToAdd(sensorList);
  };

  const changeSensorList = e => {
    if (e.target.name !== 'checkbox') {
      setArrowDirection(!arrowDirection);
      setSensorsListHidden(!arrowDirection);
    }
  };

  const toUpperCase = text => {
    return text
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };

  const checkAllItems = () => {
    if (somethingChanged) somethingChanged(true);

    const checkboxes = document.getElementsByName(`checkbox_${asset.item_id}`);
    const mainCheckbox = document.getElementById(asset.item_id);

    Array.from(checkboxes).forEach(item => {
      item.checked = mainCheckbox.checked;
    });

    sensors.forEach(sensor => {
      sensor.isChecked = mainCheckbox.checked;
    });

    setSensors([...sensors]);
    setIsAllChecked(prev => !prev);

    itemsToAdd(sensors);
  };

  const setCheckedItem = item_id => {
    if (somethingChanged) somethingChanged(true);

    const checkBox = document.getElementById(
      `checkbox_${item_id}_${asset.item_id}`,
    );
    let checkedCount = 0;
    let allChecked = false;

    sensors.forEach(sensor => {
      if (sensor.item_id === item_id) sensor.isChecked = checkBox.checked;

      if (sensor.isChecked) checkedCount++;
    });

    if (sensors.length === checkedCount) {
      allChecked = true;
    }

    setSensors([...sensors]);
    setIsAllChecked(allChecked);

    itemsToAdd(sensors);
  };

  const sensorsTable = () =>
    sensors?.length === 0 ? (
      <S.NoSensors>This asset has no sensors.</S.NoSensors>
    ) : (
      [...sensors]
        .sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        })
        .map(sensor => (
          <S.SensorListItemGroup key={`key_${sensor.item_id}`}>
            <table>
              <tbody>
                <tr>
                  <th>
                    {sensor.isChecked === true ? (
                      <S.CheckboxAsset
                        id={`checkbox_${sensor.item_id}_${asset.item_id}`}
                        name={`checkbox_${asset.item_id}`}
                        type="checkbox"
                        onClick={() => setCheckedItem(sensor.item_id)}
                        defaultChecked
                      />
                    ) : (
                      <S.CheckboxAsset
                        id={`checkbox_${sensor.item_id}_${asset.item_id}`}
                        name={`checkbox_${asset.item_id}`}
                        type="checkbox"
                        onClick={() => setCheckedItem(sensor.item_id)}
                      />
                    )}
                  </th>
                  <th>
                    <S.NameAsset
                      htmlFor={`checkbox_${sensor.item_id}_${asset.item_id}`}
                    >
                      {toUpperCase(sensor.name)}
                    </S.NameAsset>
                  </th>
                </tr>
              </tbody>
            </table>
          </S.SensorListItemGroup>
        ))
    );

  return (
    <Stack>
      <ListItemButton
        direction="row"
        data-testid={asset.name}
        onClick={changeSensorList}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" alignItems="center">
            <Box padding="0 10px 20px 13px">
              <Form.Check
                id={asset.item_id}
                type="checkbox"
                onClick={checkAllItems}
                checked={isAllChecked}
                disabled={isDisabled}
                name="checkbox"
              />
            </Box>
            <Box>
              <Typography
                color="primary"
                sx={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  width: '160px',
                }}
              >
                {asset.name}
              </Typography>
              {asset.lat && asset.lng && (
                <Typography variant="subtitle2">
                  {formatLatLng(asset.lat)}; {formatLatLng(asset.lng)}
                </Typography>
              )}
            </Box>
          </Stack>
          <Box>
            <i
              className={
                arrowDirection ? 'fas fa-chevron-right' : 'fas fa-chevron-down'
              }
            />
          </Box>
        </Stack>
      </ListItemButton>
      {!sensorsListHidden && (
        <Box data-testid={`sensor-list-${asset.name}`}>
          {sensors && sensorsTable()}
        </Box>
      )}
    </Stack>
  );
};

AssetSensorItem.defaultProps = {
  groupToEdit: null,
  somethingChanged: null,
};

AssetSensorItem.propTypes = {
  asset: PropTypes.object.isRequired,
  groupToEdit: PropTypes.object,
  itemsToAdd: PropTypes.func.isRequired,
  somethingChanged: PropTypes.func,
};

export default AssetSensorItem;
