/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  ListItemButton,
  Typography,
  useMediaQuery,
  Stack,
  IconButton,
} from '@mui/material';
import area from '@turf/area';
import { Edit, LocationOn } from '@mui/icons-material';

import { useBuildingDetails } from '../../../../context/BuildingContext';
import { useGeneralContext } from '../../../../context/GeneralContext';
import Asset from './Asset';
import Building from './Building';
import * as S from './style';
import areaZoom from '../../../../utils/areaZoom';
import AssetIcon from '../../../shared/atoms/AssetIcon';
import { statusColorObj } from '../../../../themes';
import {
  closeAnimated,
  openAnimated,
} from '../../../../redux/actions/sideDrawerActions';
import CustomTooltip from '../../../shared/atoms/CustomTooltip';
import { useRolePermissions } from '../../../../hooks/useRolePermissions';
import { useConfirmation } from '../../../../context/ConfirmationContext';
import { useLocationsCacheContext } from '../../../../context/LocationsCacheContext';
import { updateSelectedBuilding } from '../../../../redux/actions/mapAction';

const Location = ({
  location,
  subItems,
  subItemsType,
  shouldStartOpen,
  onEdit,
  shouldFly,
  onClick,
}) => {
  const history = useHistory();
  const targetRef = useRef(null);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:450px)');
  const { openConfirmation } = useConfirmation();
  const [isOpen, setIsOpen] = useState(shouldStartOpen || false);
  const selectedBuildingId = useSelector(store => store.mapState.building);
  const industryFilterState = useSelector(store => store.industryFilterState);
  const isDrawerOpen = useSelector(store => store.sideDrawerState.isOpen);
  const { data: userPermissions } = useRolePermissions();
  const { closeDrawers } = useBuildingDetails();
  const { setLastSelectedLocation, lastSelectedLocation } = useGeneralContext();
  const { setShouldCollapse } = useLocationsCacheContext();
  const upToSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const closeAnimatedDispatch = useCallback(
    args => dispatch(closeAnimated(args)),
    [dispatch],
  );
  const openAnimatedDispatch = useCallback(
    args => dispatch(openAnimated(args)),
    [dispatch],
  );
  const updateSelectedBuildingAction = useCallback(
    args => dispatch(updateSelectedBuilding(args)),
    [dispatch],
  );

  const isSelected = useMemo(
    () =>
      location.properties.item_id === lastSelectedLocation?.properties.item_id,
    [lastSelectedLocation?.properties.item_id, location.properties.item_id],
  );

  const isMapPage = useMemo(
    () =>
      history.location.pathname.includes('/portal/maps') &&
      !history.location.pathname.includes('/facilities'),
    [history.location.pathname],
  );

  const handleClick = useCallback(() => {
    upToSm && !isOpen && closeAnimatedDispatch();
    setIsOpen(!isOpen);
  }, [closeAnimatedDispatch, isOpen, upToSm]);

  const flyToLocation = () => {
    if (
      location.properties &&
      shouldFly &&
      history.location.pathname.includes('/portal/maps') &&
      !history.location.pathname.includes('/facilities')
    ) {
      window.map.flyTo({
        center: [location.properties.lng, location.properties.lat],
        zoom: areaZoom(area(location.geometry)),
      });
      window.map.fire('flystart');
    }
  };

  const deselectBuilding = () => {
    if (window.map?.isStyleLoaded)
      window.map.removeFeatureState({
        source: 'buildings',
        id: selectedBuildingId,
      });
    updateSelectedBuildingAction(null);
  };

  useEffect(() => {
    if (
      location.properties.buildings
        ?.map(building => building.properties.item_id)
        .includes(selectedBuildingId)
    )
      setIsOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuildingId]);

  useEffect(() => {
    if (isSelected && !isOpen && onEdit) {
      handleClick();
      if (targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [handleClick, isOpen, isSelected, onEdit]);

  const collapseDrawers = () => {
    if (location.properties.location_type !== 'building') {
      closeDrawers();
      if (window?.map)
        window.map.setFilter('unclustered-point', [
          'all',
          ['!=', 'cluster', true],
          ['!has', 'is_building'],
        ]);
    } else {
      setShouldCollapse(false);
    }
  };

  const subItemsLengthMessage = useMemo(
    () =>
      subItemsType === 'assets'
        ? t('components.location.assets', { count: subItems.length })
        : t('components.location.buildings', { count: subItems.length }),
    [subItems.length, subItemsType, t],
  );

  const onEditHandler = e => {
    if (!isMapPage) {
      openConfirmation({
        message: t('tools_menu.location.redirect_to_map'),
        handleAction: () => {
          history.push('/portal/maps');
          openAnimatedDispatch();
        },
        actionLabel: t('components.main_list.modal.create_location.action'),
        title: t('tools_menu.location.edit_location'),
      });
    } else {
      onEdit?.(location.properties.item_id);
      flyToLocation();
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const onGoToLocation = e => {
    if (!isMapPage) {
      history.push('/portal/maps');
      setTimeout(() => flyToLocation(), 1000);
    } else {
      flyToLocation();
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <CustomTooltip
        title={
          !isDrawerOpen && (
            <Stack>
              <Box>
                {location.properties.name}{' '}
                {location.properties.status &&
                  `(${t(
                    `components.location.status.${location.properties.status.toUpperCase()}`,
                  )})`}
              </Box>
              <Box>
                {subItems.length ? `0${subItems.length}`.slice(-2) : 0}
                {subItemsLengthMessage}
              </Box>
            </Stack>
          )
        }
        placement="right"
      >
        <ListItemButton
          ref={targetRef}
          selected={isSelected && onEdit}
          sx={{
            padding: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '2px',
          }}
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              handleClick();
              deselectBuilding();
              if (!isSelected) {
                setLastSelectedLocation(location);
              } else if (isOpen && isSelected && isDrawerOpen) {
                setLastSelectedLocation(null);
              }
              if (!isOpen) {
                collapseDrawers();
                flyToLocation();
              } else if (location.properties.location_type === 'building') {
                closeDrawers();
              }
              if (!isDrawerOpen && !isMapPage) {
                history.push('/portal/maps');
              }
            }
          }}
        >
          <S.LocationContainer>
            <Box
              sx={{
                maxWidth: isMobile ? '280px' : '190px',
                marginTop: '0.5rem',
                marginBottom: '-0.5rem',
                marginLeft: '1rem',
                marginRight: isDrawerOpen ? '5px' : 0,
                whiteSpace: 'break-spaces',
              }}
            >
              <Typography
                color="primary"
                fontWeight={500}
                sx={{
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  whiteSpace: 'break-spaces',
                  color: theme =>
                    isDrawerOpen
                      ? theme.palette.primary.main
                      : statusColorObj[
                          (location.properties.status || '').toLowerCase()
                        ],
                }}
              >
                {isDrawerOpen
                  ? location.properties.name
                  : location.properties.name.substring(0, 3).toUpperCase()}
              </Typography>
              <p>
                {subItems.length ? `0${subItems.length}`.slice(-2) : 0}
                {isDrawerOpen && subItemsLengthMessage}
              </p>
            </Box>
          </S.LocationContainer>
          {isDrawerOpen && subItemsType === 'assets' && (
            <Stack direction="row" alignItems="center" paddingRight="10px">
              {onEdit && (
                <CustomTooltip title={t('components.location.go_to')}>
                  <IconButton onClick={onGoToLocation} size="small">
                    <LocationOn sx={{ color: 'white' }} fontSize="small" />
                  </IconButton>
                </CustomTooltip>
              )}
              {userPermissions?.map?.location?.manage && onEdit && (
                <CustomTooltip title={t('components.location.edit')}>
                  <IconButton
                    onClick={onEditHandler}
                    size="small"
                    sx={{ marginRight: '5px' }}
                  >
                    <Edit sx={{ color: 'white' }} fontSize="small" />
                  </IconButton>
                </CustomTooltip>
              )}
              <AssetIcon status={location.properties.status} includeTooltip />
            </Stack>
          )}
        </ListItemButton>
      </CustomTooltip>
      {subItems?.length ? (
        <S.ContainerOpened
          data-testid="container"
          $isOpen={isOpen && isDrawerOpen && onEdit}
        >
          {subItems
            .sort((a, b) => {
              return a.properties.name > b.properties.name ? 1 : -1;
            })
            .map(subItem =>
              subItemsType === 'assets' ? (
                industryFilterState[subItem.properties.industry] && (
                  <Asset
                    key={subItem.properties.item_id}
                    asset={subItem}
                    isOpen={isOpen}
                    collapseDrawers={collapseDrawers}
                    setLocationIsOpen={setIsOpen}
                  />
                )
              ) : (
                <Building key={subItem.properties.item_id} building={subItem} />
              ),
            )}
        </S.ContainerOpened>
      ) : (
        <div>
          {subItems.map(subItem =>
            subItemsType === 'assets' ? (
              <Asset
                key={subItem.properties.name}
                asset={subItem}
                collapseDrawers={collapseDrawers}
              />
            ) : (
              <Building key={subItem.properties.name} building={subItem} />
            ),
          )}
        </div>
      )}
    </>
  );
};

Location.defaultProps = {
  shouldStartOpen: false,
  onEdit: null,
  onClick: null,
};

Location.propTypes = {
  location: PropTypes.shape().isRequired,
  subItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  subItemsType: PropTypes.string.isRequired,
  shouldStartOpen: PropTypes.bool,
  onEdit: PropTypes.func,
  onClick: PropTypes.func,
  shouldFly: PropTypes.bool.isRequired,
};

export default Location;
