/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';

import {
  modalGradientPrimary,
  modalGradientSecondary,
  primaryColor,
  customButtonBg,
} from '../../themes';

export const ModalWithActions = styled(Modal)`
  .modal-name {
    font-weight: bold;
  }

  .modal-dialog {
    max-width: ${props => (props.width ? props.width : '')} !important;
  }

  body.modal-open div.modal-backdrop {
    z-index: 2000;
  }

  body.modal-open div.modal {
    z-index: 2000;
  }

  .modal-content {
    width: ${props =>
      props.$isTabletOrMobile ? '85% !important' : '50vw !important'};
    margin: 50px auto;
    background-image: linear-gradient(
      ${modalGradientPrimary},
      ${modalGradientSecondary}
    );
    border-radius: 10px;
    padding: 1em;
  }

  .modal-header {
    border-color: transparent !important;
    padding-inline: 0;
  }

  .modal-title {
    font-size: ${props => (props.titlesize ? props.titlesize : '1.25rem')};
    padding-inline: 0.9em;
  }

  .modal-text {
    margin-top: -0.938rem !important;
    margin-bottom: 0.625rem !important;
    font-size: 1rem;
    font-weight: 200;
    padding-inline: 1em 3em;
    text-align: justify;
  }

  .modal-body {
    border-color: transparent;
    text-align: center;
    margin-bottom: 0.313rem;
    padding-inline: 0;
  }

  .custom-with-action > .modal-content > .modal-footer {
    margin: 0;
    padding: 1em;
    padding-inline: 0;
    border-top: none;
    flex-wrap: nowrap;
    ${props =>
      props.$isTabletOrMobile &&
      css`
        flex-direction: column-reverse;
        gap: 1em;
        > button {
          width: 100%;
          margin: 0;
        }
      `}
  }

  .custom-with-action
    > div.modal-content
    > div.modal-footer
    > button.btn-footer,
  button.btn-secondary {
    ${props =>
      !props.$discreteFooter &&
      css`
        width: 50%;
        color: ${customButtonBg};
        border-color: transparent !important;
        text-transform: uppercase;
        font-weight: bold !important;
        background-color: ${primaryColor};
      `}
  }

  .custom-with-action
    > .modal-content
    > .modal-footer
    > button.btn-footer-left {
    margin: 0;
    width: 100%;
    border: 0.063rem solid ${primaryColor} !important;
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    border-bottom-right-radius: 0rem !important;
    border-bottom-left-radius: 0.313rem !important;
  }

  .custom-with-action
    > .modal-content
    > .modal-footer
    > button.btn-footer-left:focus {
    margin-bottom: 0;
  }

  .custom-with-action
    > .modal-content
    > .modal-footer
    > button.btn-footer-right:focus {
    margin-bottom: 0;
  }

  .custom-with-action
    > .modal-content
    > .modal-footer
    > button.btn-footer-right {
    margin: 0;
    width: 100%;
    border: 0.063rem solid ${primaryColor} !important;
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
    border-bottom-right-radius: 0.313rem !important;
  }

  .custom-with-action > .modal-content > .modal-footer > button.btn.disabled,
  button.btn:disabled,
  button.btn-footer-right.disabled,
  button.btn-footer-right:disabled,
  button.btn-footer-right[disabled] {
    pointer-events: unset;
    cursor: not-allowed;
    opacity: 0.4;
  }

  .custom-with-action
    > .modal-content
    > .modal-footer
    > button.btn-footer.btn-footer-left:hover:enabled,
  button.btn-footer.btn-footer-right:hover:enabled {
    background-color: ${customButtonBg} !important;
    color: ${primaryColor} !important;
    border-bottom-color: ${primaryColor} !important;
  }

  .custom-with-action > .modal-content > .modal-footer > button.btn:focus,
  button.btn:active:focus,
  button.btn.active:focus,
  button.btn.focus,
  button.btn:active.focus,
  button.btn.active.focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;
