/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled, { css } from 'styled-components';

import {
  primaryColor,
  wellListBackground,
  wellListBackgroundGradient,
} from '@themes';

export const WellList = styled.div`
  background: ${wellListBackground};
  background: linear-gradient(
    90deg,
    ${wellListBackground} 0%,
    ${wellListBackgroundGradient} 100%
  );
  bottom: 0;
  display: flex;
  left: 0;
  flex-direction: column;
  position: fixed;
  top: 3.125rem;
  padding-bottom: 0;
  opacity: 0;
  width: 17.5rem;
  z-index: -1;
  -webkit-transform: translateZ(0);
  -webkit-box-shadow: 0.4375rem 0.4375rem 0.375rem 0rem rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0.4375rem 0.4375rem 0.375rem 0rem rgba(0, 0, 0, 0.23);
  box-shadow: 0.4375rem 0.4375rem 0.375rem 0rem rgba(0, 0, 0, 0.23);
  transition:
    transform 0.07s,
    opacity 0.07s;
  transform: translate(-100%);

  ${props =>
    props.$openSide &&
    css`
      transition:
        transform 0.07s,
        opacity 0.07s;
      transform: translate(0%);
      opacity: 1;
      z-index: 999;
    `}

  @media (max-device-width: 450px) {
    width: 100%;
  }

  @media (max-device-width: 1224px) {
    ${props =>
      props.$buildingSelected &&
      css`
        transform: translate(-100%);
      `}
  }
`;

export const Button = styled.button`
  background-color: ${primaryColor};
  color: #ffffff;
  border: none;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-top: 2rem;
  padding: 5px 20px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  height: 2rem;
  font-weight: 600;
  width: 100%;
`;

export const Search = styled.div`
  width: 100%;
  color: #ffffff;
  padding: 1rem;
  background: linear-gradient(90deg, #02112d 0%, #152648 100%);
`;

export const ComboSearch = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -1rem;
`;

export const FilterIcon = styled.div`
  align-self: center;
  margin-left: 3rem;
  margin-right: 0.25rem;
`;

export const ComboButtons = styled.div`
  margin-top: -1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Create = styled.div`
  margin-top: 1rem;
  height: 2.375rem;
  width: 2.4375rem;
`;

export const ButtonCreate = styled.button`
  background-color: ${primaryColor};
  border: 0.0625rem solid ${primaryColor};
  border-radius: 0.25rem;
  height: 90%;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.1rem;
  margin-left: -0.2rem;

  ${props =>
    props.$isButtonDisabled &&
    css`
      opacity: 0.2;
    `}
`;

export const GroupedButtonsContainer = styled.div`
  width: 100%;
  padding-right: 1.1rem;
`;
