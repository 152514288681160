/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import styled from 'styled-components';
import { ButtonGroup } from 'react-bootstrap';

import { primaryColor } from '@themes';

export const GroupedButtons = styled.div`
  display: block;
  width: 100%;
`;

export const Button = styled.button`
  width: 50%;
  height: 2.125rem;
  background-color: ${props => (props.selected ? primaryColor : 'transparent')};
  border: 0.063rem solid ${primaryColor};
  color: ${props => (props.selected ? '#ffffff' : primaryColor)};
  border-radius: ${props =>
    props.$side ? '0.25rem 0 0 0.25rem' : '0rem 0.25rem 0.25rem 0rem'};

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    overflow: hidden;
    word-break: keep-all;
    font-size: 14px;
  }
`;

export const BtnsGroup = styled(ButtonGroup)`
  display: block;
  flex: 1 1;
  margin-top: 1rem;
`;
