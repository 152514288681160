import React from 'react';

import * as Flags from 'country-flag-icons/react/3x2';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { getCountry } from '../../../../utils/getCountry';
import { useIsSmallMobile } from '../../../../hooks/useIsSmallMobile';

const Content = ({ isToggle, language }) => {
  const Flag = Flags[getCountry(language)];
  const isMobile = useIsMobile();
  const isSmallMobile = useIsSmallMobile();

  return (
    <Box display="flex" alignItems="center">
      <Flag height={isSmallMobile ? 16 : 20} />
      {((!isMobile && isToggle) || !isToggle) && (
        <Box marginLeft="10px">{language}</Box>
      )}
    </Box>
  );
};

Content.defaultProps = {
  isToggle: false,
  language: '',
};

Content.propTypes = {
  isToggle: PropTypes.bool,
  language: PropTypes.string,
};

export default Content;
