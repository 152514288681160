/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Construction, Visibility } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { closeAnimated } from '../../../../../redux/actions/sideDrawerActions';
import * as S from './style';

import { clickOnWellList } from '../../../../../redux/actions';
import { useRolePermissions } from '../../../../../hooks/useRolePermissions';
import { updatedServiceLogsQuery } from '../../../../../redux/actions/serviceLogActions';
import AssetIcon from '../../../../shared/atoms/AssetIcon';
import CustomTooltip from '../../../../shared/atoms/CustomTooltip';

const Asset = ({ asset, collapseDrawers, setLocationIsOpen }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:450px)');
  const { data: userPermissions } = useRolePermissions();
  const dispatch = useDispatch();
  const closeAnimatedAction = () => dispatch(closeAnimated());
  const clickOnWellListDispatch = args => dispatch(clickOnWellList(args));
  const updatedServiceLogsQueryDispatch = () =>
    dispatch(
      updatedServiceLogsQuery({
        asset: asset?.properties.name,
        keyword: asset?.properties.name,
      }),
    );

  const selectedAsset = useSelector(store => store.wellsState.clickedWell);

  const isSelected = useMemo(
    () => asset?.properties.item_id === selectedAsset?.asset.item_id,
    [selectedAsset, asset],
  );

  useEffect(() => {
    if (isSelected) {
      // open parent location in sidebar
      setLocationIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssetClick = () => {
    if (
      history.location.pathname.includes('/portal/maps') &&
      !history.location.pathname.includes('/facilities')
    ) {
      // Page is "Map"
      window.map.flyTo({ center: asset.geometry.coordinates, zoom: 20 });
      window.map.fire('flystart');
    } else {
      // Page is not "Map"
      history.push({
        pathname: `/portal/maps/${parseFloat(
          asset.geometry.coordinates[0],
        ).toFixed(4)},${parseFloat(asset.geometry.coordinates[1]).toFixed(
          4,
        )};20.0`,
      });
    }

    clickOnWellListDispatch({
      asset: asset.properties,
      lngLat: asset.geometry.coordinates,
    });
    collapseDrawers();
    if (isMobile) closeAnimatedAction();

    // wait a little for the map to load
    setTimeout(() => {
      if (
        history.location.pathname.includes('/portal/maps') &&
        !history.location.pathname.includes('/facilities')
      ) {
        window.map.flyTo({ center: asset.geometry.coordinates, zoom: 20 });
        window.map.fire('flystart');
      } else {
        history.push({
          pathname: `/portal/maps/${parseFloat(
            asset.geometry.coordinates[0],
          ).toFixed(4)},${parseFloat(asset.geometry.coordinates[1]).toFixed(
            4,
          )};20.0`,
        });
      }
    }, 100);
  };

  const goToAssetDetails = useCallback(() => {
    history.push(
      `/portal/maps${
        asset?.geometry?.coordinates?.length
          ? `/${parseFloat(asset.geometry.coordinates[0]).toFixed(
              4,
            )},${parseFloat(asset.geometry.coordinates[1]).toFixed(4)}/`
          : '/'
      }facilities/${asset.properties.item_id}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, asset.properties.item_id]);

  const goToTickets = assetId => {
    if (!userPermissions?.management?.['service-tickets']?.see) return;
    updatedServiceLogsQueryDispatch();
    history.push({
      pathname: '/portal/management/tickets/',
      search: window.location.search,
      state: {
        asset_id: assetId,
      },
    });
  };

  return (
    <S.Asset $isSelected={isSelected}>
      <S.AssetDetails
        onClick={e => {
          e.preventDefault();

          handleAssetClick();
        }}
      >
        <Typography sx={{ maxWidth: isMobile ? '280px' : '190px' }}>
          {asset.properties.name}
        </Typography>
        <Stack direction="row" alignItems="center" paddingRight="10px">
          {asset.properties.ticketCount > 0 && (
            <CustomTooltip title={t('components.asset.open_tickets')}>
              <IconButton
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  goToTickets(asset.properties.item_id);
                }}
                size="small"
              >
                <Construction sx={{ width: '20px', color: 'white' }} />
              </IconButton>
            </CustomTooltip>
          )}
          <CustomTooltip title={t('components.asset.view_details')}>
            <IconButton
              sx={{ marginRight: '5px' }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                clickOnWellListDispatch({
                  asset: asset.properties,
                  lngLat: asset.geometry.coordinates,
                });

                goToAssetDetails();
              }}
              size="small"
            >
              <Visibility sx={{ width: '20px', color: 'white' }} />
            </IconButton>
          </CustomTooltip>
          <AssetIcon status={asset.properties.status} includeTooltip />
        </Stack>
      </S.AssetDetails>
    </S.Asset>
  );
};

Asset.propTypes = {
  asset: PropTypes.shape({
    properties: PropTypes.object,
    geometry: PropTypes.object,
    item_id: PropTypes.string,
  }).isRequired,
  collapseDrawers: PropTypes.func.isRequired,
  setLocationIsOpen: PropTypes.func.isRequired,
};

export default Asset;
