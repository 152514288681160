/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import { getGroupsAction } from '@redux/actions/groupsActions';
import { updateToast } from '@redux/actions';
import ModalActionsSave from '@components/ModalActionsSave';
import { useTranslation } from 'react-i18next';
import { putGroup } from '../../../../../services/groupService';
import CustomButton from '../../../../shared/atoms/CustomButton';

const GroupButtons = ({
  pageName,
  qtt,
  locationId,
  itemsToAdd,
  changesMade,
  isNameInputOK,
  isDisabled,
  groupName,
  groupId,
  goBackToBegining,
  invalidName,
  onCancel,
}) => {
  const [showSave, setShowSave] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const getGroupsActionDispatch = args => dispatch(getGroupsAction(args));
  const updateToastDispatch = args => dispatch(updateToast(args));

  const setShowToSave = newState => setShowSave(newState);

  const formatObjectToSave = () => {
    const sensorsList = [];

    itemsToAdd.forEach(item => {
      sensorsList.push({
        sensor_location_id: locationId,
        sensor_asset_id: item.asset_id,
        sensor_id: item.item_id,
        sensor_name: item.name,
      });
    });

    return {
      item_id: groupId,
      name: groupName,
      location_id: locationId,
      sensors: sensorsList,
    };
  };

  const editCurrentGroup = () => {
    putGroup(formatObjectToSave(), { item_id: groupId }).then(({ data }) => {
      if (data.success) {
        getGroupsActionDispatch().then(() => {
          updateToastDispatch({
            message: t('components.main_list.groups.modal.save.save_success'),
            show: true,
            isError: false,
          });
          goBackToBegining();
        });
      } else {
        updateToastDispatch({
          message: t('components.main_list.groups.modal.save.save_error'),
          show: true,
          isError: true,
        });
      }
    });
  };

  const qttDisplay = qtt > 0 ? ` (${qtt})` : '';

  return (
    <Stack direction="row" width="100%">
      <ModalActionsSave
        show={showSave}
        onHide={() => setShowToSave(false)}
        title={t('components.main_list.groups.modal.save.title')}
        modaltext={t('components.main_list.groups.modal.save.description')}
        goBack={() => goBackToBegining('groups')}
        locationId={locationId}
        itemsToAdd={itemsToAdd}
        groupName={groupName}
        groupId={groupId}
        invalidName={isInvalid => {
          invalidName(isInvalid);
          setShowToSave(false);
        }}
      />
      <CustomButton
        type="button"
        id="cancel"
        onClick={
          pageName === undefined || !changesMade ? goBackToBegining : onCancel
        }
      >
        {t('buttons.cancel')}
      </CustomButton>
      {groupId || isDisabled ? (
        <CustomButton
          id="save"
          type="button"
          onClick={() => editCurrentGroup()}
          disabled={isDisabled || !isNameInputOK}
        >
          {t('components.main_list.groups.buttons.save')}
          {qttDisplay}
        </CustomButton>
      ) : (
        <CustomButton
          id="save"
          type="button"
          onClick={() => setShowToSave(true)}
        >
          {t('components.main_list.groups.buttons.save')}
          {qttDisplay}
        </CustomButton>
      )}
    </Stack>
  );
};

GroupButtons.defaultProps = {
  changesMade: false,
  itemsToAdd: [],
  pageName: '',
  qtt: 0,
  locationId: '',
  isNameInputOK: false,
  groupName: '',
  groupId: '',
  invalidName: () => null,
  onCancel: () => null,
};

GroupButtons.propTypes = {
  goBackToBegining: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  changesMade: PropTypes.bool,
  itemsToAdd: PropTypes.array,
  pageName: PropTypes.string,
  qtt: PropTypes.number,
  locationId: PropTypes.string,
  isNameInputOK: PropTypes.bool,
  groupName: PropTypes.string,
  groupId: PropTypes.string,
  invalidName: PropTypes.func,
  onCancel: PropTypes.func,
};

export default GroupButtons;
