/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import Spinner from '../shared/atoms/Spinner';
import * as S from './style';

const warningIcon = `${process.env.REACT_APP_STORAGE_BASE_URL}/warning_icon.svg`;
const dangerIcon = `${process.env.REACT_APP_STORAGE_BASE_URL}/danger_icon.svg`;
const normalIcon = `${process.env.REACT_APP_STORAGE_BASE_URL}/normal_icon.svg`;
const offIcon = `${process.env.REACT_APP_STORAGE_BASE_URL}/off.svg`;

const Notification = ({ notification, onDismiss, dismissAll, getToast }) => {
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setDeleteInProgress(dismissAll);
  }, [dismissAll]);

  const formatDate = date => {
    if (!date) return null;

    return formatDistanceToNow(new Date(date), { addSuffix: true });
  };

  const getObjectTitle = message => {
    try {
      const assetObj = JSON.parse(message);
      if (!assetObj?.name) {
        return '';
      }

      return assetObj.name;
    } catch (e) {
      console.error(e);
      return '';
    }
  };

  const getMessage = message => {
    try {
      const assetObj = JSON.parse(message);
      if (!assetObj?.message) {
        return '';
      }

      return assetObj.message;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return '';
    }
  };

  const getTitleByEvent = () => {
    if (notification.event_type === 'ASSET_NOTIFICATION') {
      return getObjectTitle(notification.message);
    }
    return notification.title || '';
  };

  const getActionByEvent = () => {
    if (notification.event_type === 'ASSET_NOTIFICATION') {
      const assetId = JSON.parse(notification.message).item_id;
      history.push(
        `/portal/maps/facilities/${assetId + history.location.search}`,
      );
    }
  };

  const getMessageByEvent = () => {
    if (notification.event_type === 'ASSET_NOTIFICATION') {
      return getMessage(notification.message);
    }
    return notification.message;
  };

  const getAlertByEvent = () => {
    return (notification.status || '').toLowerCase();
  };

  const overlayDiv = (
    <S.OverlayCombo>
      <S.Spinner>
        <Spinner changecolor="true" />
      </S.Spinner>
      <S.OvlMessage>Removing notification</S.OvlMessage>
    </S.OverlayCombo>
  );

  const attrDeleting = deleteInProgress ? 'deleting' : '';

  const getAlertIcon = type => {
    switch (type) {
      case 'danger':
        return dangerIcon;
      case 'warning':
        return warningIcon;
      case 'normal':
        return normalIcon;
      default:
        return offIcon;
    }
  };

  return (
    <S.Notification
      role="alert"
      className="no"
      onClick={() => getActionByEvent()}
    >
      <S.VisualCombo
        src={getAlertIcon(getAlertByEvent())}
        $attrDeleting={attrDeleting === 'deleting'}
      />
      <S.InfoCombo
        $attrDeleting={attrDeleting === 'deleting'}
        className={`info `}
      >
        <S.SingleDismiss className="single-dismiss">
          <IconButton
            sx={{ padding: 0 }}
            size="small"
            onClick={e => {
              setDeleteInProgress(true);
              e.stopPropagation();
              onDismiss?.(notification.item_id, getToast);
            }}
          >
            <Close fontSize="small" sx={{ color: 'white' }} />
          </IconButton>
        </S.SingleDismiss>
        <S.Title $notificationType={getAlertByEvent()}>
          {getTitleByEvent()}
        </S.Title>
        <S.Message>{getMessageByEvent()}</S.Message>
        <S.Time>{formatDate(notification.created_at)}</S.Time>
      </S.InfoCombo>
      {deleteInProgress && overlayDiv}
    </S.Notification>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape().isRequired,
  onDismiss: PropTypes.func.isRequired,
  dismissAll: PropTypes.bool.isRequired,
  getToast: PropTypes.func.isRequired,
};

export default Notification;
