import { del, get, post, put } from '../axios/axiosGateway';

export const getAssets = params => {
  return get('/assets', params);
};

export const putAsset = (id, payload) => {
  return put(`/assets?item_id=${id}`, payload);
};

export const getAllMachines = asset_id => {
  return get('/get-all-machines', { asset_id });
};

export const postAsset = payload => {
  return post('/assets', payload);
};

export const delAsset = id => {
  return del(`/assets-management?item_id=${id}`);
};
