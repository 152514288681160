import { del, get, post, put } from '../axios/axiosGateway';

export const getSensors = params => {
  return get('/sensors', params);
};

export const getSensorsByAsset = assetId => {
  return get(`/sensors?asset_id=${assetId}`);
};

export const postSensor = data => {
  return post('/sensors', data);
};

export const putSensor = (data, params) => {
  return put(`/sensors?item_id=${data.item_id}`, data, params);
};

export const delSensor = params => {
  return del('/sensors', params);
};

export const getSensorTypes = () => {
  return get('/types-sensors');
};
