/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as S from './style';
import CustomDatePicker from '../shared/molecules/CustomDatePicker';

const CustomRange = forwardRef(({ onClose, setCustomTimeRange }, ref) => {
  const [dates, setDates] = useState({});
  const { t } = useTranslation();

  const handleChangeStartDate = date => {
    setDates({
      ...dates,
      start: { value: date, formatted: new Date(date).toISOString() },
    });
  };

  const handleChangeEndDate = date => {
    setDates({
      ...dates,
      end: { value: date, formatted: new Date(date).toISOString() },
    });
  };

  const handleConfirmDates = () => {
    setCustomTimeRange({
      key: 'custom',
      start: () => dates?.start?.formatted,
      end: () => dates?.end?.formatted,
    });
    onClose();
  };

  return (
    <S.Container
      ref={ref}
      className="animate__animated animate__fadeIn animate__faster"
      id="custom-range-modal-container"
    >
      <S.Body>
        <S.Title>{t('modal.custom_range.title')}</S.Title>
        <S.Label>
          {t('modal.custom_range.label', { label_type: 'start' })}
        </S.Label>
        <S.DatePickerContainer>
          <CustomDatePicker
            id="start_date"
            name="start_date"
            onChange={handleChangeStartDate}
            hasDatePlaceholder
            value={dates?.start?.value}
            selected={dates?.start?.value}
            maxDate={dates?.end?.value}
            timeIntervals={5}
            showTimeSelect
            className="form-control date-picker"
            autoComplete="off"
            popperPlacement="bottom"
          />
        </S.DatePickerContainer>
        <S.CalendarStart>
          <i className="fas fa-calendar-alt my_calendar" />
        </S.CalendarStart>
        <S.Label style={{ marginTop: '1.5rem' }}>
          {t('modal.custom_range.label', { label_type: 'end' })}
        </S.Label>
        <S.DatePickerContainer>
          <CustomDatePicker
            id="end_date"
            name="end_date"
            onChange={handleChangeEndDate}
            hasDatePlaceholder
            value={dates?.end?.value}
            selected={dates?.end?.value}
            minDate={dates?.start?.value}
            timeIntervals={5}
            showTimeSelect
            className="form-control date-picker"
            autoComplete="off"
            popperPlacement="bottom"
          />
        </S.DatePickerContainer>
        <S.CalendarEnd>
          <i className="fas fa-calendar-alt my_calendar" />
        </S.CalendarEnd>
      </S.Body>
      <S.Footer>
        <button type="button" onClick={onClose}>
          {t('modal.cancel')}
        </button>
        <button
          type="button"
          onClick={handleConfirmDates}
          disabled={!dates?.start?.formatted || !dates?.end?.formatted}
        >
          {t('modal.confirm')}
        </button>
      </S.Footer>
    </S.Container>
  );
});

CustomRange.defaultProps = {
  onClose: () => null,
};

CustomRange.propTypes = {
  onClose: PropTypes.func,
  setCustomTimeRange: PropTypes.func.isRequired,
};

CustomRange.displayName = 'CustomRange';

export default CustomRange;
