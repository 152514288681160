/*
 * Copyright (C) 2019-2022 Corvalent Corporation - All Rights Reserved
 * This software is licensed program property and is Proprietary and Confidential.
 * Unauthorized copying of this file via any medium is strictly prohibited unless
 * prior written permission has been obtained by Corvalent Corporation.
 * In addition, the look and feel of this software as well as the user interface
 * design is also Copyright ©.
 * Notice: This software is not open source.
 * This software is subject to the Corvalent Software License Agreement.
 * You should have received a copy of this license with the software.
 * If not, please write to: sales@corvalent.com
 * All information contained herein is, and remains, the property of
 * Corvalent Corporation and its suppliers, if any. This software may also
 * be covered by U.S. and Foreign Patents, patents in progress, and are
 * protected by trade secret or copyright law
 */

import theme from 'styled-theming';

// Used to get the variable outside styled-components
export const primaryColorObj = {
  default: '#02BDBD',
  oil: '#02BDBD',
  wind: '#02BDBD',
  defense: '#02BDBD',
  'medical equipment': '#02BDBD',
  'industrial machine': '#02BDBD',
  'industrial process': '#02BDBD',
  'building monitoring': '#02BDBD',
};

export const secondaryColorObj = {
  default: '#114851',
  oil: '#114851',
  wind: '#114851',
  defense: '#114851',
  'medical equipment': '#114851',
  'industrial machine': '#114851',
  'industrial process': '#114851',
  'building monitoring': '#114851',
};

export const MapBuildingSelectedColorObj = {
  default: '#CCD0D8',
  oil: '#CCD0D8',
  wind: '#CCD0D8',
  defense: '#CCD0D8',
  'medical equipment': '#CCD0D8',
  'industrial machine': '#CCD0D8',
  'industrial process': '#CCD0D8',
  'building monitoring': '#CCD0D8',
};

export const MapLocationPrimaryColorObj = {
  default: '#088888',
  oil: '#408de6',
  wind: '#697A21',
  defense: '#CBAE34',
  'medical equipment': '#0D8686',
  'industrial machine': '#8C949E',
  'industrial process': '#863386',
  'building monitoring': '#a87e2b',
  transportation: '#542E71',
};

export const FloorOutlineColor = {
  default: '#5A5A5A',
  oil: '#5A5A5A',
  wind: '#5A5A5A',
  defense: '#5A5A5A',
  'medical equipment': '#5A5A5A',
  'industrial machine': '#5A5A5A',
  'industrial process': '#5A5A5A',
  'building monitoring': '#5A5A5A',
};

export const FloorFillColor = {
  default: '#FFFFFF',
  oil: '#FFFFFF',
  wind: '#FFFFFF',
  defense: '#FFFFFF',
  'medical equipment': '#FFFFFF',
  'industrial machine': '#FFFFFF',
  'industrial process': '#FFFFFF',
  'building monitoring': '#FFFFFF',
};

export const FloorWallsColor = {
  default: '#707070',
  oil: '#707070',
  wind: '#707070',
  defense: '#707070',
  'medical equipment': '#707070',
  'industrial machine': '#707070',
  'industrial process': '#707070',
  'building monitoring': '#707070',
};

export const chartColors = {
  status: {
    OFF: '#535353',
    ON: '#1FB814',
    LOWEST: '#E11B00',
    LOW: '#EEA626',
    NORMAL: '#1FB814',
    HIGH: '#EEA626',
    HIGHEST: '#E11B00',
    OUTLIER: '#E440FF',
    PREDICTIVE: '#DAD2D2',
    NOTHRESHOLD: '#02BDBD',
  },
  primary: '#02BDBD',
  text: '#FFFFFF',
};

export const statusColorObj = {
  off: '#535353',
  danger: '#E11B00',
  normal: '#1FB814',
  warning: '#EEA626',
  primary: '#02BDBD',
  nothreshold: '#02BDBD',
};

export const lineChartBackgroundColor = {
  default: '#394457',
  oil: '#394457',
  wind: '#394457',
  defense: '#394457',
  'medical equipment': '#394457',
  'industrial machine': '#394457',
  'industrial process': '#394457',
  'building monitoring': '#394457',
};

export const spinnerCollors = {
  default: '#02BDBD',
  oil: '#02BDBD',
  wind: '#02BDBD',
  defense: '#02BDBD',
  'medical equipment': '#02BDBD',
  'industrial machine': '#02BDBD',
  'industrial process': '#02BDBD',
  'building monitoring': '#02BDBD',
};

export const inputColor = theme('industry', {
  default: '#0E244D',
  oil: '#0E244D',
  wind: '#0E244D',
  defense: '#0E244D',
  'medical equipment': '#0E244D',
  'industrial machine': '#0E244D',
  'industrial process': '#0E244D',
  'building monitoring': '#0E244D',
});

export const lineChartCartesianGrid = {
  default: '#106a78',
  oil: '#106a78',
  wind: '#106a78',
  defense: '#106a78',
  'medical equipment': '#106a78',
  'industrial machine': '#106a78',
  'industrial process': '#106a78',
  'building monitoring': '#106a78',
};

export const industryDefaults = {
  default: 'rgba(8, 48, 64, 0.4)',
  oil: 'rgba(64, 141, 230, 0.4)',
  wind: 'rgba(62, 155, 59, 0.4)',
  defense: 'rgba(203, 174, 52, 0.4)',
  'medical equipment': 'rgba(13, 134, 134, 0.4)',
  'industrial machine': 'rgba(140, 148, 158, 0.4)',
  'industrial process': 'rgba(134, 51, 134, 0.4)',
  'building monitoring': 'rgba(168, 126, 43, 0.4)',
};

export const popupColors = {
  default: {
    background: '#083040',
    gradient: '#106a78',
    primary: '#02BDBD',
    secondary: '#018484',
    font: '#114851',
  },
  oil: {
    background: '#083040',
    gradient: '#106a78',
    primary: '#02BDBD',
    secondary: '#018484',
    font: '#114851',
  },
  wind: {
    background: '#083040',
    gradient: '#106a78',
    primary: '#02BDBD',
    secondary: '#018484',
    font: '#114851',
  },
  defense: {
    background: '#083040',
    gradient: '#106a78',
    primary: '#02BDBD',
    secondary: '#018484',
    font: '#114851',
  },
  'medical equipment': {
    background: '#083040',
    gradient: '#106a78',
    primary: '#02BDBD',
    secondary: '#018484',
    font: '#114851',
  },
  'industrial machine': {
    background: '#083040',
    gradient: '#106a78',
    primary: '#02BDBD',
    secondary: '#018484',
    font: '#114851',
  },
  'industrial process': {
    background: '#083040',
    gradient: '#106a78',
    primary: '#02BDBD',
    secondary: '#018484',
    font: '#114851',
  },
  'building monitoring': {
    background: '#083040',
    gradient: '#106a78',
    primary: '#02BDBD',
    secondary: '#018484',
    font: '#114851',
  },
};

export const modalGradientPrimary = theme('industry', {
  default: '#083040',
  oil: '#083040',
  wind: '#083040',
  defense: '#083040',
  'medical equipment': '#083040',
  'industrial machine': '#083040',
  'industrial process': '#083040',
  'building monitoring': '#083040',
});

export const modalGradientSecondary = theme('industry', {
  default: '#106a78',
  oil: '#106a78',
  wind: '#106a78',
  defense: '#106a78',
  'medical equipment': '#106a78',
  'industrial machine': '#106a78',
  'industrial process': '#106a78',
  'building monitoring': '#106a78',
});

export const errorsPagesBackgroundColor = theme('industry', {
  default: '#040e20',
  oil: '#040e20',
  wind: '#040e20',
  defense: '#040e20',
  'medical equipment': '#040e20',
  'industrial machine': '#040e20',
  'industrial process': '#040e20',
  'building monitoring': '#040e20',
});

export const primaryColor = theme('industry', primaryColorObj);

export const secondaryColor = theme('industry', secondaryColorObj);

export const normalColor = theme('industry', {
  default: '#1FB814',
  oil: '#1FB814',
  wind: '#1FB814',
  defense: '#1FB814',
  'medical equipment': '#1FB814',
  'industrial machine': '#1FB814',
  'industrial process': '#1FB814',
  'building monitoring': '#1FB814',
});

export const warningColor = theme('industry', {
  default: '#EEA626',
  oil: '#EEA626',
  wind: '#EEA626',
  defense: '#EEA626',
  'medical equipment': '#EEA626',
  'industrial machine': '#EEA626',
  'industrial process': '#EEA626',
  'building monitoring': '#EEA626',
});

export const dangerColor = theme('industry', {
  default: '#E11B00',
  oil: '#E11B00',
  wind: '#E11B00',
  defense: '#E11B00',
  'medical equipment': '#E11B00',
  'industrial machine': '#E11B00',
  'industrial process': '#E11B00',
  'building monitoring': '#E11B00',
});

export const bgPrimary = theme('industry', {
  default: '#02BDBD',
  oil: '#02BDBD',
  wind: '#02BDBD',
  defense: '#02BDBD',
  'medical equipment': '#02BDBD',
  'industrial machine': '#02BDBD',
  'industrial process': '#02BDBD',
  'building monitoring': '#02BDBD',
});

export const bgSecondary = theme('industry', {
  default: '#071734',
  oil: '#071734',
  wind: '#071734',
  defense: '#071734',
  'medical equipment': '#071734',
  'industrial machine': '#071734',
  'industrial process': '#071734',
  'building monitoring': '#071734',
});

export const dashBoardCard = theme('industry', {
  default: 'rgba(17, 72, 81, 0.15)',
  oil: 'rgba(17, 72, 81, 0.15)',
  wind: 'rgba(17, 72, 81, 0.15)',
  defense: 'rgba(17, 72, 81, 0.15)',
  'medical equipment': 'rgba(17, 72, 81, 0.15)',
  'industrial machine': 'rgba(17, 72, 81, 0.15)',
  'industrial process': 'rgba(17, 72, 81, 0.15)',
  'building monitoring': 'rgba(17, 72, 81, 0.15)',
});

export const gradientBGPrimary = theme('industry', {
  default: 'rgba(32, 98, 109, 1)',
  oil: 'rgba(32, 98, 109, 1)',
  wind: 'rgba(32, 98, 109, 1)',
  defense: 'rgba(32, 98, 109, 1)',
  'medical equipment': 'rgba(32, 98, 109, 1)',
  'industrial machine': 'rgba(32, 98, 109, 1)',
  'industrial process': 'rgba(32, 98, 109, 1)',
  'building monitoring': 'rgba(32, 98, 109, 1)',
});

export const gradientBGSecondary = theme('industry', {
  default: 'rgba(11, 65, 75, 1)',
  oil: 'rgba(11, 65, 75, 1)',
  wind: 'rgba(11, 65, 75, 1)',
  defense: 'rgba(11, 65, 75, 1)',
  'medical equipment': 'rgba(11, 65, 75, 1)',
  'industrial machine': 'rgba(11, 65, 75, 1)',
  'industrial process': 'rgba(11, 65, 75, 1)',
  'building monitoring': 'rgba(11, 65, 75, 1)',
});

export const bgTertiary = theme('industry', {
  default: '#09152D',
  oil: '#09152D',
  wind: '#09152D',
  defense: '#09152D',
  'medical equipment': '#09152D',
  'industrial machine': '#09152D',
  'industrial process': '#09152D',
  'building monitoring': '#09152D',
});

export const textArea = theme('industry', {
  default: '#08152d',
  oil: '#08152d',
  wind: '#08152d',
  defense: '#08152d',
  'medical equipment': '#08152d',
  'industrial machine': '#08152d',
  'industrial process': '#08152d',
  'building monitoring': '#08152d',
});

export const bgButtonHover = theme('industry', {
  default: '#059898',
  oil: '#059898',
  wind: '#059898',
  defense: '#059898',
  'medical equipment': '#059898',
  'industrial machine': '#059898',
  'industrial process': '#059898',
  'building monitoring': '#059898',
});

export const lightGrey = theme('industry', {
  default: '#EDEBEB',
  oil: '#EDEBEB',
  wind: '#EDEBEB',
  defense: '#EDEBEB',
  'medical equipment': '#EDEBEB',
  'industrial machine': '#EDEBEB',
  'industrial process': '#EDEBEB',
  'building monitoring': '#EDEBEB',
});

export const darkGrey = theme('industry', {
  default: '#535d64',
  oil: '#535d64',
  wind: '#535d64',
  defense: '#535d64',
  'medical equipment': '#535d64',
  'industrial machine': '#535d64',
  'industrial process': '#535d64',
  'building monitoring': '#535d64',
});

export const redButton = theme('industry', {
  default: '#b71818',
  oil: '#b71818',
  wind: '#b71818',
  defense: '#b71818',
  'medical equipment': '#b71818',
  'industrial machine': '#b71818',
  'industrial process': '#b71818',
  'building monitoring': '#b71818',
});

export const assetItemBG = theme('industry', {
  default: '#11444f',
  oil: '#11444f',
  wind: '#11444f',
  defense: '#11444f',
  'medical equipment': '#11444f',
  'industrial machine': '#11444f',
  'industrial process': '#11444f',
  'building monitoring': '#11444f',
});

export const assetItemSelectedBG = theme('industry', {
  default: '#038787',
  oil: '#038787',
  wind: '#038787',
  defense: '#038787',
  'medical equipment': '#038787',
  'industrial machine': '#038787',
  'industrial process': '#038787',
  'building monitoring': '#038787',
});

export const assetItemHoverBG = theme('industry', {
  default: '#006666',
  oil: '#006666',
  wind: '#006666',
  defense: '#006666',
  'medical equipment': '#006666',
  'industrial machine': '#006666',
  'industrial process': '#006666',
  'building monitoring': '#006666',
});

export const buildingItemBG = theme('industry', {
  default: 'rgb(17, 68, 79, 0.9)',
  oil: 'rgb(17, 68, 79, 0.9)',
  wind: 'rgb(17, 68, 79, 0.9)',
  defense: 'rgb(17, 68, 79, 0.9)',
  'medical equipment': 'rgb(17, 68, 79, 0.9)',
  'industrial machine': 'rgb(17, 68, 79, 0.9)',
  'industrial process': 'rgb(17, 68, 79, 0.9)',
  'building monitoring': 'rgb(17, 68, 79, 0.9)',
});

export const buildingItemSelectedBG = theme('industry', {
  default: 'rgba(2, 189, 189, 0.4)',
  oil: 'rgba(2, 189, 189, 0.4)',
  wind: 'rgba(2, 189, 189, 0.4)',
  defense: 'rgba(2, 189, 189, 0.4)',
  'medical equipment': 'rgba(2, 189, 189, 0.4)',
  'industrial machine': 'rgba(2, 189, 189, 0.4)',
  'industrial process': 'rgba(2, 189, 189, 0.4)',
  'building monitoring': 'rgba(2, 189, 189, 0.4)',
});

export const assetBucketBG = theme('industry', {
  default: '#edebeb30',
  oil: '#edebeb30',
  wind: '#edebeb30',
  defense: '#edebeb30',
  'medical equipment': '#edebeb30',
  'industrial machine': '#edebeb30',
  'industrial process': '#edebeb30',
  'building monitoring': '#edebeb30',
});

export const tableBorder = theme('industry', {
  default: '#BEBEBE',
  oil: '#BEBEBE',
  wind: '#BEBEBE',
  defense: '#BEBEBE',
  'medical equipment': '#BEBEBE',
  'industrial machine': '#BEBEBE',
  'industrial process': '#BEBEBE',
  'building monitoring': '#BEBEBE',
});

export const tableTrHover = theme('industry', {
  default: '#FCFAFA',
  oil: '#FCFAFA',
  wind: '#FCFAFA',
  defense: '#FCFAFA',
  'medical equipment': '#FCFAFA',
  'industrial machine': '#FCFAFA',
  'industrial process': '#FCFAFA',
  'building monitoring': '#FCFAFA',
});

export const selectedObj = theme('industry', {
  default: '#37F0F0',
  oil: '#37F0F0',
  wind: '#37F0F0',
  defense: '#37F0F0',
  'medical equipment': '#37F0F0',
  'industrial machine': '#37F0F0',
  'industrial process': '#37F0F0',
  'building monitoring': '#37F0F0',
});

export const sensorListBg = theme('industry', {
  default: '#020E24',
  oil: '#020E24',
  wind: '#020E24',
  defense: '#020E24',
  'medical equipment': '#020E24',
  'industrial machine': '#020E24',
  'industrial process': '#020E24',
  'building monitoring': '#020E24',
});

export const emptyListTxt = theme('industry', {
  default: '#040e20',
  oil: '#040e20',
  wind: '#040e20',
  defense: '#040e20',
  'medical equipment': '#040e20',
  'industrial machine': '#040e20',
  'industrial process': '#040e20',
  'building monitoring': '#040e20',
});

export const dropdownItemCur = theme('industry', {
  default: '#212529',
  oil: '#212529',
  wind: '#212529',
  defense: '#212529',
  'medical equipment': '#212529',
  'industrial machine': '#212529',
  'industrial process': '#212529',
  'building monitoring': '#212529',
});

export const tableTd = theme('industry', {
  default: '#585858',
  oil: '#585858',
  wind: '#585858',
  defense: '#585858',
  'medical equipment': '#585858',
  'industrial machine': '#585858',
  'industrial process': '#585858',
  'building monitoring': '#585858',
});

export const backgroundStatusColor = theme.variants('industry', 'variant', {
  default: {
    default: '#808080',
    oil: '#808080',
    wind: '#808080',
    defense: '#808080',
    'medical equipment': '#808080',
    'industrial machine': '#808080',
    'industrial process': '#808080',
    'building monitoring': '#808080',
  },
  danger: {
    default: '#E11B00',
    oil: '#E11B00',
    wind: '#E11B00',
    defense: '#E11B00',
    'medical equipment': '#E11B00',
    'industrial machine': '#E11B00',
    'industrial process': '#E11B00',
    'building monitoring': '#E11B00',
  },
  normal: {
    default: '#1FB814',
    oil: '#1FB814',
    wind: '#1FB814',
    defense: '#1FB814',
    'medical equipment': '#1FB814',
    'industrial machine': '#1FB814',
    'industrial process': '#1FB814',
    'building monitoring': '#1FB814',
  },
  off: {
    default: '#808080',
    oil: '#808080',
    wind: '#808080',
    defense: '#808080',
    'medical equipment': '#808080',
    'industrial machine': '#808080',
    'industrial process': '#808080',
    'building monitoring': '#808080',
  },
  warning: {
    default: '#EEA626',
    oil: '#EEA626',
    wind: '#EEA626',
    defense: '#EEA626',
    'medical equipment': '#EEA626',
    'industrial machine': '#EEA626',
    'industrial process': '#EEA626',
    'building monitoring': '#EEA626',
  },
});

export const wellListBackground = theme('industry', {
  default: '#02112d',
  oil: '#02112d',
  wind: '#02112d',
  defense: '#02112d',
  'medical equipment': '#02112d',
  'industrial machine': '#02112d',
  'industrial process': '#02112d',
  'building monitoring': '#02112d',
});

export const pagesBackground = theme('industry', {
  default: '#09162e',
  oil: '#09162e',
  wind: '#09162e',
  defense: '#09162e',
  'medical equipment': '#09162e',
  'industrial machine': '#09162e',
  'industrial process': '#09162e',
  'building monitoring': '#09162e',
});

export const wellListBackgroundGradient = theme('industry', {
  default: '#152648',
  oil: '#152648',
  wind: '#152648',
  defense: '#152648',
  'medical equipment': '#152648',
  'industrial machine': '#152648',
  'industrial process': '#152648',
  'building monitoring': '#152648',
});

export const listBackground = theme('industry', {
  default: '#20626D',
  oil: '#20626D',
  wind: '#20626D',
  defense: '#20626D',
  'medical equipment': '#20626D',
  'industrial machine': '#20626D',
  'industrial process': '#20626D',
  'building monitoring': '#20626D',
});

export const floorDetailsBackground = theme('industry', {
  default: '#114851',
  oil: '#114851',
  wind: '#114851',
  defense: '#114851',
  'medical equipment': '#114851',
  'industrial machine': '#114851',
  'industrial process': '#114851',
  'building monitoring': '#114851',
});

export const listBackgroundGradient = theme('industry', {
  default: '#0B414B',
  oil: '#0B414B',
  wind: '#0B414B',
  defense: '#0B414B',
  'medical equipment': '#0B414B',
  'industrial machine': '#0B414B',
  'industrial process': '#0B414B',
  'building monitoring': '#0B414B',
});

export const customButtonBg = theme('industry', {
  default: '#0e373d',
  oil: '#0e373d',
  wind: '#0e373d',
  defense: '#0e373d',
  'medical equipment': '#0e373d',
  'industrial machine': '#0e373d',
  'industrial process': '#0e373d',
  'building monitoring': '#0e373d',
});

export const customButtonDisabled = theme('industry', {
  default: '#155761',
  oil: '#155761',
  wind: '#155761',
  defense: '#155761',
  'medical equipment': '#155761',
  'industrial machine': '#155761',
  'industrial process': '#155761',
  'building monitoring': '#155761',
});

export const treeBorder = theme('industry', {
  default: '#3949ab',
  oil: '#3949ab',
  wind: '#3949ab',
  defense: '#3949ab',
  'medical equipment': '#3949ab',
  'industrial machine': '#3949ab',
  'industrial process': '#3949ab',
  'building monitoring': '#3949ab',
});

export const selectedRow = {
  default: 'rgb(2 189 189 / 20%)',
  oil: 'rgb(2 189 189 / 20%)',
  wind: 'rgb(2 189 189 / 20%)',
  defense: 'rgb(2 189 189 / 20%)',
  'medical equipment': 'rgb(2 189 189 / 20%)',
  'industrial machine': 'rgb(2 189 189 / 20%)',
  'industrial process': 'rgb(2 189 189 / 20%)',
  'building monitoring': 'rgb(2 189 189 / 20%)',
};

export const usersManagmentRouter = theme('industry', {
  default: '#c5c5c52b',
  oil: '#c5c5c52b',
  wind: '#c5c5c52b',
  defense: '#c5c5c52b',
  'medical equipment': '#c5c5c52b',
  'industrial machine': '#c5c5c52b',
  'industrial process': '#c5c5c52b',
  'building monitoring': '#c5c5c52b',
});

export const customButtonFocus = theme('industry', {
  default: 'rgba(48, 202, 230, 0.5)',
  oil: 'rgba(48, 202, 230, 0.5)',
  wind: 'rgba(48, 202, 230, 0.5)',
  defense: 'rgba(48, 202, 230, 0.5)',
  'medical equipment': 'rgba(48, 202, 230, 0.5)',
  'industrial machine': 'rgba(48, 202, 230, 0.5)',
  'industrial process': 'rgba(48, 202, 230, 0.5)',
  'building monitoring': 'rgba(48, 202, 230, 0.5)',
});

export const inputBorder = theme('industry', {
  default: '#ced4da',
  oil: '#ced4da',
  wind: '#ced4da',
  defense: '#ced4da',
  'medical equipment': '#ced4da',
  'industrial machine': '#ced4da',
  'industrial process': '#ced4da',
  'building monitoring': '#ced4da',
});

export const facilitiesPaneBg = theme('industry', {
  default: '#02bdbd73',
  oil: '#02bdbd73',
  wind: '#02bdbd73',
  defense: '#02bdbd73',
  'medical equipment': '#02bdbd73',
  'industrial machine': '#02bdbd73',
  'industrial process': '#02bdbd73',
  'building monitoring': '#02bdbd73',
});
export const alertWarning = theme('industry', {
  default: '#eab91b',
  oil: '#eab91b',
  wind: '#eab91b',
  defense: '#eab91b',
  'medical equipment': '#eab91b',
  'industrial machine': '#eab91b',
  'industrial process': '#eab91b',
  'building monitoring': '#eab91b',
});

export const alertDanger = theme('industry', {
  default: '#b61818',
  oil: '#b61818',
  wind: '#b61818',
  defense: '#b61818',
  'medical equipment': '#b61818',
  'industrial machine': '#b61818',
  'industrial process': '#b61818',
  'building monitoring': '#b61818',
});

export const alertNormal = theme('industry', {
  default: '#1fb814',
  oil: '#1fb814',
  wind: '#1fb814',
  defense: '#1fb814',
  'medical equipment': '#1fb814',
  'industrial machine': '#1fb814',
  'industrial process': '#1fb814',
  'building monitoring': '#1fb814',
});

export const alertOff = theme('industry', {
  default: '#898989',
  oil: '#898989',
  wind: '#898989',
  defense: '#898989',
  'medical equipment': '#898989',
  'industrial machine': '#898989',
  'industrial process': '#898989',
  'building monitoring': '#898989',
});

export const disabled = theme('industry', {
  default: '#5B6373',
  oil: '#5B6373',
  wind: '#5B6373',
  defense: '#5B6373',
  'medical equipment': '#5B6373',
  'industrial machine': '#5B6373',
  'industrial process': '#5B6373',
  'building monitoring': '#5B6373',
});

export const errorColor = {
  default: '#FF5454',
  oil: '#FF5454',
  wind: '#FF5454',
  defense: '#FF5454',
  'medical equipment': '#FF5454',
  'industrial machine': '#FF5454',
  'industrial process': '#FF5454',
  'building monitoring': '#FF5454',
};

export const corvalentGreen = {
  default: '#63DC76',
  oil: '#63DC76',
  wind: '#63DC76',
  defense: '#63DC76',
  'medical equipment': '#63DC76',
  'industrial machine': '#63DC76',
  'industrial process': '#63DC76',
  'building monitoring': '#63DC76',
};

export const oceanShore = {
  default: '#02BDBD',
  oil: '#02BDBD',
  wind: '#02BDBD',
  defense: '#02BDBD',
  'medical equipment': '#02BDBD',
  'industrial machine': '#02BDBD',
  'industrial process': '#02BDBD',
  'building monitoring': '#02BDBD',
};

export const corvalentLightGreyObj = {
  default: '#DAD2D2',
  oil: '#DAD2D2',
  wind: '#DAD2D2',
  defense: '#DAD2D2',
  'medical equipment': '#DAD2D2',
  'industrial machine': '#DAD2D2',
  'industrial process': '#DAD2D2',
  'building monitoring': '#DAD2D2',
};

export const corvalentLightGrey = theme('industry', corvalentLightGreyObj);

export const corvalentMediumGrey = theme('industry', {
  default: '#616161',
  oil: '#616161',
  wind: '#616161',
  defense: '#616161',
  'medical equipment': '#616161',
  'industrial machine': '#616161',
  'industrial process': '#616161',
  'building monitoring': '#616161',
});

export const corvalentDarkBlueObj = {
  default: '#040E20',
  oil: '#040E20',
  wind: '#040E20',
  defense: '#040E20',
  'medical equipment': '#040E20',
  'industrial machine': '#040E20',
  'industrial process': '#040E20',
  'building monitoring': '#040E20',
};

export const corvalentDarkBlue = theme('industry', corvalentDarkBlueObj);

export const ticketPriorityPalette = {
  Low: oceanShore.default,
  Normal: corvalentGreen.default,
  High: errorColor.default,
};
